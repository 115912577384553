import React, { useCallback } from "react";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

import { removeClinicImage, uploadClinicImage } from "../../app/actions";
import { getPublicImage } from "../../utils/helpers";
import { fData } from "../../utils/formatNumber";
import { useDispatch } from "react-redux";

import FormProvider from "../minimal/hook-form/FormProvider";
import Image from "../image/Image";
import Modal from "../modal/Modal";
import { RHFUploadAvatar } from "../minimal/hook-form/RHFUpload";
import SvgIconStyle from "../minimal/SvgIconStyle";
import { toast } from "../notifications/Toast";

const initialValues = {
    images: [],
};

export default function EditClinicImagesModal({ show, handleClose, clinic = null }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const thumbnailsCount =
        clinic && clinic.clinic_images && clinic.clinic_images.length > 0 && 5 - clinic.clinic_images.length;

    const methods = useForm({
        initialValues,
    });

    const { setValue } = methods;

    const handleDrop = useCallback(
        async (acceptedFiles) => {
            const file = acceptedFiles[0];

            if (file) {
                setValue(
                    "photoURL",
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                );

                const formData = new FormData();
                formData.append("clinic", clinic.id);
                formData.append("image", file);

                const result = await dispatch(uploadClinicImage(formData));
                const { error } = result;

                if (error) {
                    toast.error("Error uploading image");
                }
            }
        },
        [setValue, clinic] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const removeImage = async (image) => {
        const result = await dispatch(removeClinicImage(image.id));
        const { error } = result;

        if (error) {
            toast.error("Error removing image");
        }
    };

    const PlaceholderThumbnails = () => {
        const placeholders = [];

        for (let i = 0; i < thumbnailsCount; i++) {
            placeholders.push(
                <Grid item key={i}>
                    <div className="ts-edit-image-placeholder">
                        <SvgIconStyle src={getPublicImage("ic_image.svg")} sx={{ color: theme.palette.grey[500] }} />
                    </div>
                </Grid>
            );
        }

        return placeholders;
    };

    return (
        <>
            <Modal
                show={show}
                content={
                    <div>
                        <FormProvider methods={methods}>
                            <Typography variant="h6" sx={{ my: 2 }}>
                                Edit Images
                            </Typography>
                            {clinic && clinic.clinic_images && clinic.clinic_images.length < 5 ? (
                                <RHFUploadAvatar
                                    name="images"
                                    maxSize={3145728}
                                    onDrop={handleDrop}
                                    helperText={
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                mt: 2,
                                                mx: "auto",
                                                display: "block",
                                                textAlign: "center",
                                                color: "text.secondary",
                                            }}
                                        >
                                            Allowed *.jpeg, *.jpg, *.png, *.gif
                                            <br /> max size of {fData(3145728)}
                                            <br /> 1080x720 recommended resolution
                                        </Typography>
                                    }
                                />
                            ) : (
                                <Typography variant="body2">
                                    Only 5 images per clinic are allowed. Remove an existing image to add more
                                </Typography>
                            )}
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="overline">Current Images</Typography>
                                <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", my: 1 }}>
                                    {clinic?.clinic_images?.map((clinicImage, idx) => (
                                        <Grid
                                            item
                                            key={idx}
                                            onClick={() => removeImage(clinicImage)}
                                            className="ts-edit-image-container hover-pointer"
                                        >
                                            <Image
                                                src={clinicImage.image}
                                                alt="clinic"
                                                className="ts-edit-image-thumbnail"
                                            />
                                            <SvgIconStyle
                                                src={getPublicImage("ic_remove.svg")}
                                                sx={{ color: theme.palette.error.main }}
                                                className="ts-edit-image-remove "
                                            />
                                        </Grid>
                                    ))}
                                    <PlaceholderThumbnails />
                                </Grid>
                            </Box>
                            <Stack direction="row" justifyContent="end" spacing={2} sx={{ my: 2 }}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    className="text-right"
                                    onClick={handleClose}
                                >
                                    Done
                                </LoadingButton>
                            </Stack>
                        </FormProvider>
                    </div>
                }
            />
        </>
    );
}
