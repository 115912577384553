import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import qs from "query-string";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";

import LoginLayout from "./LoginLayout";
import TextInput from "../text_input/TextInput";
import { toast } from "../notifications/Toast";
import Link from "../link/Link";

import {
    PASSWORD_MIN_LENGTH,
    PASSWORD_MAX_LENGTH,
    PASSWORD_REGEX,
    PASSWORD_MATCH,
    REQUIRED_FIELD,
} from "../../utils/validations";
import { submitResetPassword } from "../../app/actions";
import { executeRecaptcha } from "../../utils/helpers";
import { LOGIN_ROUTE } from "../../utils/routes";
import Button from "../button/Button";
import Page from "../minimal/Page";

const schema = yup.object().shape({
    password: yup
        .string()
        .required(REQUIRED_FIELD.message)
        .min(PASSWORD_MIN_LENGTH.value, PASSWORD_MIN_LENGTH.message)
        .max(PASSWORD_MAX_LENGTH.value, PASSWORD_MAX_LENGTH.message)
        .matches(PASSWORD_REGEX.value, PASSWORD_REGEX.message),
    confirmPassword: yup
        .string()
        .required(REQUIRED_FIELD.message)
        .min(PASSWORD_MIN_LENGTH.value, PASSWORD_MIN_LENGTH.message)
        .max(PASSWORD_MAX_LENGTH.value, PASSWORD_MAX_LENGTH.message)
        .matches(PASSWORD_REGEX.value, PASSWORD_REGEX.message)
        .oneOf([yup.ref("password")], PASSWORD_MATCH.message),
});

function PasswordReset({ dispatch, history, location }) {
    const [token, setToken] = useState();
    const mfrDomain =
        window.location.hostname.includes("mfrtherapists.com") || window.location.hostname.includes("mfrhealth.com");

    useEffect(() => {
        let token;
        const { search } = location;

        if (search && search.length > 0) {
            const { token: qsToken } = qs.parse(search);

            if (qsToken && qsToken.length > 0) {
                token = qsToken;
            }
        }

        setToken(token);
    }, [location]);

    const handleResetPassword = async (values) => {
        const { password, confirmPassword } = values;

        executeRecaptcha("reset_password")
            .then(async (recaptchaResponse) => {
                const params = {
                    token,
                    password,
                    confirmPassword,
                    "g-recaptcha-response": recaptchaResponse,
                };

                const result = await dispatch(submitResetPassword(params));
                const { error } = result;

                if (error) {
                    toast.error("Error resetting password");
                } else {
                    toast.success("Password reset successful. You may now log in with your new password.");
                    history.push(LOGIN_ROUTE);
                }
            })
            .catch((error) => {
                console.log("Error executing Recaptcha:", error.toString());
                toast.error(error);
            });
    };

    return (
        <Page title="Reset Password" mfr={mfrDomain}>
            <LoginLayout>
                <div className="ts-login">
                    {token ? (
                        <div className="ts-login__content">
                            <h2 className="mb-3">Create a New Password</h2>

                            <Formik
                                initialValues={{ password: "", confirmPassword: "" }}
                                onSubmit={handleResetPassword}
                                validationSchema={schema}
                            >
                                {({ errors, values, handleChange, handleSubmit, touched }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-row mb-3">
                                            <div className="col">
                                                <TextInput
                                                    id="password"
                                                    name="password"
                                                    label="New Password"
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    type="password"
                                                    error={errors.password}
                                                    touched={touched.password}
                                                    autoComplete="off"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col">
                                                <TextInput
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    label="Re-Enter Password"
                                                    onChange={handleChange}
                                                    value={values.confirmPassword}
                                                    type="password"
                                                    error={errors.confirmPassword}
                                                    touched={touched.confirmPassword}
                                                    autoComplete="off"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row mb-4">
                                            <div className="col">
                                                <Link
                                                    analyticsClass="button__password-reset-back-to-login"
                                                    to={LOGIN_ROUTE}
                                                >
                                                    Back to Login
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col">
                                                <Button
                                                    secondary
                                                    square
                                                    analyticsClass="button__reset-password"
                                                    type="submit"
                                                >
                                                    Reset Password
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <div className="alert alert-info">Missing or invalid reset token</div>
                    )}
                </div>
            </LoginLayout>
        </Page>
    );
}

const PasswordReset_ = withRouter(PasswordReset);

export default connect()(PasswordReset_);
