import { post } from "../../utils/network";
import { finishFlowSteps } from "./index";

export const RECEIVE_ONBOARDING_SURVEY_SUBMISSION = "RECEIVE_ONBOARDING_SURVEY_SUBMISSION";
export const RECEIVE_ONBOARDING_SURVEY_SUBMISSION_ERROR = "RECEIVE_ONBOARDING_SURVEY_SUBMISSION_ERROR";

export const submitOnboardingSurvey = (data) => async (dispatch) => {
    try {
        const result = await post("/api/v3/surveys/", data);

        return dispatch({
            type: RECEIVE_ONBOARDING_SURVEY_SUBMISSION,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_ONBOARDING_SURVEY_SUBMISSION_ERROR,
            error,
        });
    }
};

export const RECEIVE_CREATE_SMART_GOAL_DATA = "RECEIVE_CREATE_SMART_GOAL_DATA";
export const RECEIVE_CREATE_SMART_GOAL_ERROR = "RECEIVE_CREATE_SMART_GOAL_ERROR";

export const submitSmartGoal = (goal, motivator) => async (dispatch) => {
    try {
        const result = await post("/api/v3/smart_goals/", {
            goal,
            motivator,
        });

        return dispatch({
            type: RECEIVE_CREATE_SMART_GOAL_DATA,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_CREATE_SMART_GOAL_ERROR,
            error,
        });
    }
};

export const RECEIVE_SUBMIT_COVID_EXPERIENCE_DATA = "RECEIVE_SUBMIT_COVID_EXPERIENCE_DATA";
export const RECEIVE_SUBMIT_COVID_EXPERIENCE_ERROR = "RECEIVE_SUBMIT_COVID_EXPERIENCE_ERROR";

export const submitCovidExperience = (answer) => async (dispatch) => {
    try {
        const result = await post("/api/v3/covid_questions/", { answer });

        return dispatch({
            type: RECEIVE_SUBMIT_COVID_EXPERIENCE_DATA,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_SUBMIT_COVID_EXPERIENCE_ERROR,
            error,
        });
    }
};

export const RECEIVE_TIME_BASED_SURVEY_SUBMISSION = "RECEIVE_TIME_BASED_SURVEY_SUBMISSION";
export const RECEIVE_TIME_BASED_SURVEY_SUBMISSION_ERROR = "RECEIVE_TIME_BASED_SURVEY_SUBMISSION_ERROR";

export const submitTimeBasedSurvey = (data) => async (dispatch) => {
    try {
        const result = await post("/api/v3/surveys/", data);

        return dispatch({
            type: RECEIVE_TIME_BASED_SURVEY_SUBMISSION,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_TIME_BASED_SURVEY_SUBMISSION_ERROR,
            error,
        });
    }
};

export const RECEIVE_SATISFACTION_SURVEY_SUBMISSION = "RECEIVE_SATISFACTION_SURVEY_SUBMISSION";
export const RECEIVE_SATISFACTION_SURVEY_SUBMISSION_ERROR = "RECEIVE_SATISFACTION_SURVEY_SUBMISSION_ERROR";
export const submitSatisfactionSurvey = (data, stepGroupId) => async (dispatch) => {
    try {
        // submit satisfaction survey
        const surveyResult = await post("/api/v3/satisfaction_surveys/", data);

        // complete flow steps
        await dispatch(finishFlowSteps([stepGroupId]));

        return dispatch({
            type: RECEIVE_SATISFACTION_SURVEY_SUBMISSION,
            data: surveyResult.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_SATISFACTION_SURVEY_SUBMISSION_ERROR,
            error,
        });
    }
};
