import React, { useState } from "react";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { Accordion as RBAccordion } from "react-bootstrap";
import { Typography, useTheme } from "@mui/material";
import cx from "classnames";

import SvgIconStyle from "../minimal/SvgIconStyle";

import { getPublicImage } from "../../utils/helpers";

export default function Accordion({
    accordionClassName = "",
    children,
    clickOverride = null,
    collapsableClassName = "",
    eventKey = "0",
    expandedOverride = null,
    expandOnLoad = false,
    hideIcon = false,
    image,
    lazyLoad = false, // provides a way to skip rendering children until expansion occurs for performance reasons
    title,
    titleClassName,
}) {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(expandOnLoad);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };
    const decoratedOnClick = useAccordionToggle(eventKey, clickOverride ? clickOverride : toggleExpanded);

    return (
        <RBAccordion
            activeKey={expandedOverride ? expandedOverride : expanded ? eventKey : null}
            className={accordionClassName}
        >
            <RBAccordion.Toggle as="div" eventKey={eventKey} onClick={decoratedOnClick}>
                <div
                    className={cx(
                        "d-flex align-items-center hover-pointer user-select-none",
                        titleClassName,
                        expanded && "mb-2"
                    )}
                >
                    {!hideIcon && (
                        <SvgIconStyle
                            src={getPublicImage(image ? String(image) : "ic_location.svg")}
                            sx={{ color: theme.palette.grey[600] }}
                        />
                    )}
                    <Typography variant="subtitle2" className={cx("flex-fill", "ml-3 mr-0 my-0")}>
                        {title}
                    </Typography>
                    <i
                        className={cx(
                            "fas",
                            "mr-2",
                            expandedOverride === eventKey || expanded ? "fa-chevron-up" : "fa-chevron-down"
                        )}
                        role="button"
                    />
                </div>
            </RBAccordion.Toggle>
            <RBAccordion.Collapse eventKey={eventKey}>
                <div className={collapsableClassName}>
                    {(!lazyLoad || (lazyLoad && (expanded || expandedOverride))) && children}
                </div>
            </RBAccordion.Collapse>
        </RBAccordion>
    );
}
