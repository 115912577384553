export const MEETING_STATUS_CANCELLED_BY_COACH = "cancelled_by_coach";
export const MEETING_STATUS_CANCELLED_BY_PATIENT = "cancelled_by_patient";
export const MEETING_STATUS_COMPLETED = "completed";
export const MEETING_STATUS_IN_PROGRESS = "in_progress";
export const MEETING_STATUS_MISSED = "missed";
export const MEETING_STATUS_UPCOMING = "upcoming";

export const MAXIMUM_WAITING_ROOM_WAIT_TIME = 180000; // ms

export const HIP_LOCATION_ID = "hip";
export const KNEE_LOCATION_ID = "knee";
export const LOWER_BACK_LOCATION_ID = "lower_back";
export const NECK_LOCATION_ID = "neck";
export const OTHER_LOCATION_ID = "other";
export const SHOULDER_LOCATION_ID = "shoulder";
export const UPPER_BACK_LOCATION_ID = "upper_back";
export const WOMENS_HEALTH_LOCATION_ID = "womens_health";

export const PHONE_CONSULT_INTERACTION_TYPE_ID = "phone";
export const VIDEO_CONSULT_INTERACTION_TYPE_ID = "video";
export const PHYSICAL_CONSULT_INTERACTION_TYPE_ID = "In-person";

export const REVIVE_CONTENT_BRANDS = ["RVVE", "RVV2"];

export const consultInteractionTypes = {
    [PHONE_CONSULT_INTERACTION_TYPE_ID]: {
        label: "Phone",
    },
    [VIDEO_CONSULT_INTERACTION_TYPE_ID]: {
        label: "Video",
    },
    [PHYSICAL_CONSULT_INTERACTION_TYPE_ID]: {
        label: "In-person",
    },
};

export const meetingStatusTypes = {
    [MEETING_STATUS_CANCELLED_BY_COACH]: {
        label: "Cancelled by coach",
    },
    [MEETING_STATUS_CANCELLED_BY_PATIENT]: {
        label: "Cancelled by patient",
    },
    [MEETING_STATUS_COMPLETED]: {
        label: "Completed",
    },
    [MEETING_STATUS_IN_PROGRESS]: {
        label: "In progress",
    },
    [MEETING_STATUS_MISSED]: {
        label: "Missed",
    },
    [MEETING_STATUS_UPCOMING]: {
        label: "Upcoming",
    },
};
