import { send } from "@giantmachines/redux-websocket/dist";
import { get, patch, post } from "../../utils/network";
import { gen_error, gen_received, gen_requested } from "./generators";

export const REQUEST_UPDATE_COACH_BUSY_STATUS = "REQUEST_UPDATE_COACH_BUSY_STATUS";
export const RECEIVE_UPDATE_COACH_BUSY_STATUS = "RECEIVE_UPDATE_COACH_BUSY_STATUS";
export const RECEIVE_UPDATE_COACH_BUSY_STATUS_ERROR = "RECEIVE_UPDATE_COACH_BUSY_STATUS_ERROR";

export const updateCoachBusyStatus = (newStatus) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_COACH_BUSY_STATUS));

        const { me } = getState();

        if (newStatus !== me.is_busy) {
            // only send a websocket message if is_busy status is different than current
            // TODO: Depricated. That is an old way of setting coach status, we need it while there is v2 CD.
            dispatch(
                send({
                    type: "coach_status",
                    is_busy: newStatus,
                })
            );
        }

        return dispatch(
            gen_received(RECEIVE_UPDATE_COACH_BUSY_STATUS, {
                data: { is_busy: newStatus },
            })
        );
    } catch (error) {
        dispatch(gen_error(RECEIVE_UPDATE_COACH_BUSY_STATUS_ERROR, error));
    }
};

export const REQUEST_COACH = "REQUEST_COACH";
export const RECEIVE_COACH = "RECEIVE_COACH";
export const RECEIVE_COACH_ERROR = "RECEIVE_COACH_ERROR";
export const fetchCoach = (coachId, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_COACH));

        const result = await get(`/api/v3/coaches/${coachId}`, options);

        return dispatch(gen_received(RECEIVE_COACH, result));
    } catch (error) {
        dispatch(gen_error(RECEIVE_COACH_ERROR, error));
    }
};

export const REQUEST_COACHES = "REQUEST_COACHES";
export const RECEIVE_COACHES = "RECEIVE_COACHES";
export const RECEIVE_COACHES_ERROR = "RECEIVE_COACHES_ERROR";
export const fetchCoaches = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_COACHES));

        const result = await get("/api/v3/coaches/", options);

        return dispatch(gen_received(RECEIVE_COACHES, result));
    } catch (error) {
        dispatch(gen_error(RECEIVE_COACHES_ERROR, error));
    }
};

export const REQUEST_COACH_PATIENT_EPISODES = "REQUEST_COACH_PATIENT_EPISODES";
export const RECEIVE_COACH_PATIENT_EPISODES = "RECEIVE_COACH_PATIENT_EPISODES";
export const RECEIVE_COACH_PATIENT_EPISODES_ERROR = "RECEIVE_COACH_PATIENT_EPISODES_ERROR";
export const getCoachPatientEpisodes = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_COACH_PATIENT_EPISODES));

        const result = await get("/api/v3/episodes/", options);

        dispatch(gen_received(RECEIVE_COACH_PATIENT_EPISODES, result));
    } catch (error) {
        dispatch(gen_error(RECEIVE_COACH_PATIENT_EPISODES_ERROR, error));
    }
};

export const REQUEST_COACH_REMINDERS = "REQUEST_COACH_REMINDERS";
export const RECEIVE_COACH_REMINDERS = "RECEIVE_COACH_REMINDERS";
export const RECEIVE_COACH_REMINDERS_ERROR = "RECEIVE_COACH_REMINDERS_ERROR";
export const getCoachReminders = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_COACH_REMINDERS));

        const result = await get("/api/v3/reminders/", options);

        dispatch(gen_received(RECEIVE_COACH_REMINDERS, result));
    } catch (error) {
        dispatch(gen_error(RECEIVE_COACH_REMINDERS_ERROR, error));
    }
};

export const REQUEST_CREATE_COACH_PATIENT_ASSESSMENT = "REQUEST_CREATE_COACH_PATIENT_ASSESSMENT";
export const RECEIVE_CREATE_COACH_PATIENT_ASSESSMENT = "RECEIVE_CREATE_COACH_PATIENT_ASSESSMENT";
export const RECEIVE_CREATE_COACH_PATIENT_ASSESSMENT_ERROR = "RECEIVE_CREATE_COACH_PATIENT_ASSESSMENT_ERROR";
export const createCoachPatientAssessment = (patientId, data, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_COACH_PATIENT_ASSESSMENT));

        const result = await post("/api/v3/surveys/", data, options);

        return dispatch({
            type: RECEIVE_CREATE_COACH_PATIENT_ASSESSMENT,
            data: result.data,
            patientId,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_COACH_PATIENT_ASSESSMENT_ERROR, error));
    }
};

export const REQUEST_UPDATE_PATIENT_ASSESSMENT = "REQUEST_UPDATE_PATIENT_ASSESSMENT";
export const RECEIVE_UPDATE_PATIENT_ASSESSMENT = "RECEIVE_UPDATE_PATIENT_ASSESSMENT";
export const RECEIVE_UPDATE_PATIENT_ASSESSMENT_ERROR = "RECEIVE_UPDATE_PATIENT_ASSESSMENT_ERROR";
export const updatePatientAssessment = (patientId, assessmentId, data, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_PATIENT_ASSESSMENT));

        const result = await patch(`/api/v3/surveys/${assessmentId}/`, data, options);

        return dispatch({
            type: RECEIVE_UPDATE_PATIENT_ASSESSMENT,
            data: result.data,
            patientId,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_PATIENT_ASSESSMENT_ERROR, error));
    }
};

export const REQUEST_UPDATE_COACH_REMINDER = "REQUEST_UPDATE_COACH_REMINDER";
export const RECEIVE_UPDATE_COACH_REMINDER = "RECEIVE_UPDATE_COACH_REMINDER";
export const RECEIVE_UPDATE_COACH_REMINDER_ERROR = "RECEIVE_UPDATE_COACH_REMINDER_ERROR";
export const updateCoachReminder = (reminderId, data, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_COACH_REMINDER));

        const result = await patch(`/api/v3/reminders/${reminderId}/`, data, options);

        dispatch(gen_received(RECEIVE_UPDATE_COACH_REMINDER, result));
    } catch (error) {
        dispatch(gen_error(RECEIVE_UPDATE_COACH_REMINDER_ERROR, error));
    }
};
