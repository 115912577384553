import { gen_error, gen_received, gen_requested } from "./generators";
import { get, post } from "../../utils/network";

export const REQUEST_GET_USER_INVITE = "REQUEST_GET_USER_INVITE";
export const RECEIVE_GET_USER_INVITE = "RECEIVE_GET_USER_INVITE";
export const RECEIVE_GET_USER_INVITE_ERROR = "RECEIVE_GET_USER_INVITE_ERROR";
export const getUserInvite = (uuid) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SEND_USER_INVITE));

        const result = await get(`/api/v4/invitations/${uuid}/`);

        return dispatch(gen_received(RECEIVE_GET_USER_INVITE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_USER_INVITE_ERROR, error));
    }
};

export const REQUEST_ACTIVATE_USER_INVITE = "REQUEST_ACTIVATE_USER_INVITE";
export const RECEIVE_ACTIVATE_USER_INVITE = "RECEIVE_ACTIVATE_USER_INVITE";
export const RECEIVE_ACTIVATE_USER_INVITE_ERROR = "RECEIVE_ACTIVATE_USER_INVITE_ERROR";
export const activateUser = (uuid, body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ACTIVATE_USER_INVITE));

        const result = await post(`/api/v4/invitations/${uuid}/activate/`, body);

        return dispatch(gen_received(RECEIVE_ACTIVATE_USER_INVITE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ACTIVATE_USER_INVITE_ERROR, error));
    }
};

export const REQUEST_SEND_USER_INVITE = "REQUEST_SEND_USER_INVITE";
export const RECEIVE_SEND_USER_INVITE = "RECEIVE_SEND_USER_INVITE";
export const RECEIVE_SEND_USER_INVITE_ERROR = "RECEIVE_SEND_USER_INVITE_ERROR";
export const sendUserInvite = (body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SEND_USER_INVITE));

        const result = await post("/api/v4/invitations/", body);

        return dispatch(gen_received(RECEIVE_SEND_USER_INVITE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SEND_USER_INVITE_ERROR, error));
    }
};

export const REQUEST_RESEND_USER_INVITE = "REQUEST_RESEND_USER_INVITE";
export const RECEIVE_RESEND_USER_INVITE = "RECEIVE_RESEND_USER_INVITE";
export const RECEIVE_RESEND_USER_INVITE_ERROR = "RECEIVE_RESEND_USER_INVITE_ERROR";
export const resendUserInvite = (inviteId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_RESEND_USER_INVITE));

        const result = await post(`/api/v4/invitations/${inviteId}/resend/`);

        return dispatch(gen_received(RECEIVE_RESEND_USER_INVITE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_RESEND_USER_INVITE_ERROR, error));
    }
};
