import React from "react";
import cx from "classnames";
import styled from "styled-components";
import { darken } from "polished";

import {
    DARK_BACKGROUND_COLOR_ID,
    PRIMARY_COLOR_ID,
    PRIMARY_COLOR_DARK_ID,
    SECONDARY_COLOR_DARK_ID,
    SECONDARY_COLOR_ID,
} from "../../styles/colors";

import Spinner from "../spinner/Spinner";

const ThemedButton = styled.button`
    &.btn-link {
        color: ${(props) => props.theme[PRIMARY_COLOR_ID]};

        &:hover {
            color: ${(props) => props.theme[PRIMARY_COLOR_DARK_ID]} !important;
            text-decoration: none;
        }
    }

    &.btn-link-info {
        color: #637381;

        &:hover {
            color: #637381 !important;
            text-decoration: none;
        }
    }

    &.ts-btn-outline {
        border: 1px solid ${(props) => props.theme[DARK_BACKGROUND_COLOR_ID]};
        color: ${(props) => props.theme[DARK_BACKGROUND_COLOR_ID]};
    }

    &.ts-btn-outline-primary-dark {
        border: 1px solid ${(props) => props.theme[PRIMARY_COLOR_DARK_ID]};
        color: ${(props) => props.theme[PRIMARY_COLOR_DARK_ID]} !important;

        &:hover {
            background-color: ${(props) => props.theme[PRIMARY_COLOR_DARK_ID]} !important;
        }
    }

    &.ts-btn-outline-primary {
        border: 1px solid ${(props) => props.theme[PRIMARY_COLOR_ID]};
        color: ${(props) => props.theme[PRIMARY_COLOR_ID]};

        &:hover {
            background-color: ${(props) => props.theme[PRIMARY_COLOR_ID]} !important;
        }

        &:disabled {
            background-color: white !important;
        }
    }

    &.ts-btn-outline-secondary {
        background-color: white;
        border: 1px solid ${(props) => props.theme[SECONDARY_COLOR_ID]};
        color: ${(props) => props.theme[SECONDARY_COLOR_ID]};

        &:hover {
            background-color: ${(props) => props.theme[SECONDARY_COLOR_ID]};
            border: 1px solid ${(props) => props.theme[SECONDARY_COLOR_ID]};
            color: white;
        }
    }

    &.ts-btn-primary {
        background-color: ${(props) => props.theme[PRIMARY_COLOR_ID]};

        &:hover {
            background-color: ${(props) => darken(0.05, props.theme[PRIMARY_COLOR_ID])};
        }
    }

    &.ts-btn-secondary {
        background-color: ${(props) => props.theme[SECONDARY_COLOR_ID]};

        &:hover {
            background-color: ${(props) => darken(0.05, props.theme[SECONDARY_COLOR_ID])};
        }
    }

    &.ts-btn-secondary-dark {
        background-color: ${(props) => props.theme[SECONDARY_COLOR_DARK_ID]};

        &:hover {
            background-color: ${(props) => darken(0.05, props.theme[SECONDARY_COLOR_DARK_ID])};
        }
    }
`;

export default function Button({
    analyticsClass,
    children,
    className,
    compact = false,
    extraPadding = false,
    link = false,
    linkInfo = false,
    linkDefault,
    loading = false,
    noDecoration = false,
    outline = false,
    outlinePrimary = false,
    outlinePrimaryDark = false,
    outlineSecondary = false,
    primary = false,
    secondary = false,
    secondaryDark = false,
    square = false,
    ...rest
}) {
    return (
        <ThemedButton
            className={cx(
                "ts-button",
                "btn",
                compact && "compact",
                extraPadding && "px-5",
                link && "btn-link",
                linkInfo && "btn-link-info",
                linkDefault && "btn-link-default",
                (rest.disabled || loading) && "disabled",
                noDecoration && "ts-btn-no-decoration",
                outline && "ts-btn-outline",
                outlinePrimary && "ts-btn-outline-primary",
                outlinePrimaryDark && "ts-btn-outline-primary-dark",
                outlineSecondary && "ts-btn-outline-secondary",
                primary && "ts-btn-primary",
                secondary && "ts-btn-secondary",
                secondaryDark && "ts-btn-secondary-dark",
                square && "ts-btn-square",
                !outline &&
                    !outlinePrimary &&
                    !outlinePrimaryDark &&
                    !outlineSecondary &&
                    !primary &&
                    !secondary &&
                    "btn-default",
                className
            )}
            data-button={analyticsClass}
            type="button"
            {...rest}
        >
            {children}
            {loading && (
                <div className="absolute spinner">
                    <Spinner />
                </div>
            )}
        </ThemedButton>
    );
}
