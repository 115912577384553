import { exq } from "../../utils/helpers";
import { resourceFragment } from "./resources";

export const meFragment = {
    expand: exq`
        email_notifications
        group
        group.branding
        group.owner
        group.parent
        group.parent.branding
        group.parent.owner
        group.parent.products
        group.parent.products.prices
        group.parent.signup_config
        group.products
        group.products.prices
        group.products.product_items
        group.access_level
        group.signup_config
        provider_profiles
        recommended_list.resources.article
        recommended_list.resources.exercise.steps
        recommended_list.resources.exercise.tags
        resources_list.resources.article
        resources_list.resources.exercise.steps
        resources_list.resources.exercise.tags
        subscriptions.product
        subscriptions.product.prices
        ${resourceFragment.expand}`,
    fields: exq`
        assigned_program.id
        assigned_program.last_visited
        assigned_program.title
        bio
        birthday
        coach
        created_date
        description
        discount
        email
        email_notifications.id
        email_notifications.email_subscription
        first_name
        flow
        gender
        group
        group.branding
        group.domain
        group.dtc
        group.id
        group.owner
        group.parent
        group.partner_id
        group.products
        group.access_level
        id
        is_admin
        is_busy
        is_coach
        last_name
        last_visited
        on_hold
        owner
        partner_id
        pain_location
        phone
        preferred_name
        prices
        prices_override
        profile_image
        provider_profiles.id
        provider_profiles.slug
        recommended_list.resources.article
        recommended_list.resources.exercise
        recommended_list.resources.id
        resources_list.resources.article
        resources_list.resources.data_points
        resources_list.resources.exercise
        resources_list.resources.group
        resources_list.resources.id
        resources_list.resources.list
        resources_list.resources.order
        resources_list.resources.watched_items
        restrict_access_before
        role
        server_time
        steps
        stripe_customer_id
        stripe_subscription_id
        stripe_subscription_status
        subscriptions
        subscriptions.clinic_seats_amount
        subscriptions.created
        subscriptions.id
        subscriptions.product
        subscriptions.product.prices
        subscriptions.purchased_products
        subscriptions.seats_amount
        subscriptions.status
        subscriptions.stripe_id
        subscriptions.termination_date
        subscriptions.used_clinic_seats_amount
        subscriptions.used_seats_amount
        subscriptions.user
        subscriptions.user_clinic_seats_amount
        surveys
        timezone
        title
        ${resourceFragment.fields}
    `,
};
