import React, { useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

import { uploadProviderImage } from "../../app/actions";
import { fData } from "../../utils/formatNumber";
import { useDispatch } from "react-redux";

import FormProvider from "../minimal/hook-form/FormProvider";
import Modal from "../modal/Modal";
import { RHFUploadAvatar } from "../minimal/hook-form/RHFUpload";
import { toast } from "../notifications/Toast";

const initialValues = {
    images: [],
};

export default function EditProviderImagesModal({ show, handleClose, provider = null }) {
    const dispatch = useDispatch();

    const methods = useForm({
        initialValues,
    });

    const { setValue } = methods;

    const handleDrop = useCallback(
        async (acceptedFiles) => {
            const file = acceptedFiles[0];

            if (file) {
                setValue(
                    "photoURL",
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                );

                const formData = new FormData();
                formData.append("photo", file);

                const result = await dispatch(uploadProviderImage(provider.id, formData));
                const { error } = result;

                if (error) {
                    toast.error("Error uploading image");
                } else {
                    toast.success("Image updated");
                }
            }
        },
        [setValue, provider] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <>
            <Modal
                show={show}
                content={
                    <div>
                        <FormProvider methods={methods}>
                            <Typography variant="h6" sx={{ my: 2 }}>
                                Edit Profile Image
                            </Typography>
                            <RHFUploadAvatar
                                name="images"
                                maxSize={3145728}
                                onDrop={handleDrop}
                                helperText={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 2,
                                            mx: "auto",
                                            display: "block",
                                            textAlign: "center",
                                            color: "text.secondary",
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, *.gif
                                        <br /> max size of {fData(3145728)}
                                        <br /> 400x400 recommended resolution
                                    </Typography>
                                }
                            />
                            <Stack direction="row" justifyContent="end" spacing={2} sx={{ my: 2 }}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    className="text-right"
                                    onClick={handleClose}
                                >
                                    Done
                                </LoadingButton>
                            </Stack>
                        </FormProvider>
                    </div>
                }
            />
        </>
    );
}
