import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import * as articles from "./articles";
import * as groups from "./groups";
import * as chat from "./chat";
import * as coaches from "./coaches";
import * as consult from "./consult";
import * as listEditor from "./listEditor";
import * as mail from "./mail";
import * as me from "./me";
import * as mfr from "./mfr";
import * as outreach from "./outreach";
import * as patients from "./patients";
import * as prices from "./prices";
import * as profile from "./profile";
import * as programs from "./programs";
import * as reports from "./reports";
import * as resources from "./resources";
import * as scheduler from "./scheduler";
import * as signup from "./signup";
import * as subscriptions from "./subscriptions";
import * as user from "./user";
import * as websockets from "./websockets";

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        ...articles,
        ...groups,
        ...chat,
        ...coaches,
        ...consult,
        ...listEditor,
        ...mail,
        ...me,
        ...mfr,
        ...outreach,
        ...patients,
        ...prices,
        ...profile,
        ...programs,
        ...reports,
        ...resources,
        ...scheduler,
        ...signup,
        ...subscriptions,
        ...user,
        ...websockets,
    });
export default createRootReducer;
