// ****************************************************
// General
// ****************************************************

export const REQUIRED_FIELD = {
    message: "This field is required",
};

export const EMAIL = {
    message: "Invalid email",
};

// ****************************************************
// Pricing
// ****************************************************

export const MINIMUM_SUBSCRIPTION_AMOUNT = {
    value: 9,
    message: "Subscription fee must be greater than $10",
};

export const MINIMUM_CONSULT_AMOUNT = {
    value: 4,
    message: "Consult fee must be greater than $5",
};

export const PRICE_NOT_A_NUMBER = {
    message: "Price must be a number",
};

// ****************************************************
// User Info
// ****************************************************

export const MOBILE_NUMBER_LENGTH = {
    value: 10,
    message: "Mobile number must be exactly 10 digits long",
};

export const MOBILE_NUMBER_DIGITS = {
    value: /[0-9]{10}/,
    message: "Mobile number must only include numbers",
};

const minimumAge = 16;
export const DATE_OF_BIRTH_MINIMUM = {
    value: minimumAge,
    message: `You must be at least ${minimumAge} years of age to register`,
};

export const DATE_OF_BIRTH_PROFILE_MINIMUM = {
    value: minimumAge,
    message: `You must be at least ${minimumAge} years of age`,
};

export const INVALID_CODE = {
    message: "Invalid code",
};

export const INVALID_WELLVIEW_EMAIL = {
    message:
        "We were unable to verify this email address. Please contact your Sentry Health Concierge at (877)-293-9355 for further assistance.",
};

export const INVALID_DATE = {
    message: "Invalid date",
};

export const INVALID_DATE_FORMAT = {
    message: "Invalid date format. Date must be MM/DD/YYYY",
};

export const DISPLAY_NAME = {
    message: "Name is required",
};

// ****************************************************
// Chat
// ****************************************************

export const CHAT_CHARACTER_LIMIT = {
    value: 1024,
    message: "Message cannot be longer than 1024 characters",
};

// ****************************************************
// Consults
// ****************************************************

export const FUTURE_CONSULT_START_TIME = {
    message: "Start time cannot be in the future",
};

// ****************************************************
// Passwords
// ****************************************************

export const PASSWORD_MIN_LENGTH = {
    value: 8,
    message: "Too short",
};

export const PASSWORD_MAX_LENGTH = {
    value: 100,
    message: "Too long",
};

export const PASSWORD_REGEX = {
    // value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=])[A-Za-z\d^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]{8,}$/,
    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
    message: "Password must be 8 characters long and have: 1 uppercase letter, 1 lowercase letter, 1 number",
};

export const PASSWORD_MATCH = {
    message: "Passwords do not match",
};
