import React from "react";
import cx from "classnames";
import styled from "styled-components";

import { PRIMARY_COLOR_ID, SECONDARY_ORANGE_COLOR_ID } from "../../styles/colors";

const ThemedDiv = styled.div`
    &.primary {
        color: ${(props) => props.theme[PRIMARY_COLOR_ID]};
    }

    &.secondary {
        color: ${(props) => props.theme[SECONDARY_ORANGE_COLOR_ID]};
    }
`;

export default function Spinner({ fluid, screenReaderText = "Loading...", primary = true, secondary = false }) {
    const content = (
        <ThemedDiv className={cx("ts-spinner spinner-border", primary && "primary", secondary && "secondary")}>
            <span className="sr-only">{screenReaderText}</span>
        </ThemedDiv>
    );

    return fluid ? <div className="text-center">{content}</div> : content;
}

export function SpinnerPage(props) {
    return (
        <div className={"ts-spinner-page"}>
            <Spinner {...props} />
        </div>
    );
}
