import { EXERCISES_LIST_ITEM_ID } from "../../utils/resources";
import {
    UPDATE_PROFILE_FORM_DATA,
    CLEAR_PROFILE_FORM_DATA,
    RECEIVE_UPDATE_NOTIFICATION_SUBSCRIPTION,
    RECEIVE_UPDATE_NOTIFICATION_SUBSCRIPTION_ERROR,
    REQUEST_UPDATE_NOTIFICATION_SUBSCRIPTION,
    REQUEST_CHANGE_USER_PASSWORD,
    RECEIVE_CHANGE_USER_PASSWORD,
    RECEIVE_CHANGE_USER_PASSWORD_ERROR,
    RECEIVE_FINISH_SINGLE_FLOW_STEP,
    SET_CURRENT_ACCOUNT_TAB,
    SET_CURRENT_ACTIVE_TAB,
    SET_CURRENT_PROFILE_TAB,
    SET_CURRENT_RESOURCES_LIBRARY_TAB,
    SET_CURRENT_RESOURCES_IMPORT_TAB,
    SET_CURRENT_CARE_PLAN_BUILDER_TAB,
    SET_CURRENT_REPORTS_TAB,
} from "../actions";

const profileInitialState = {
    profileFormData: null,
    email_notifications: null,
    currentAccountTab: "general",
    currentActiveTab: "all",
    currentProfileTab: "profile",
    currentReportsTab: "population",
    currentResourcesLibraryTab: EXERCISES_LIST_ITEM_ID,
    currentResourcesImportTab: EXERCISES_LIST_ITEM_ID,
};

export function profile(state = profileInitialState, action) {
    switch (action.type) {
        case UPDATE_PROFILE_FORM_DATA:
            return {
                ...state,
                profileFormData: {
                    ...state.profileFormData,
                    ...action.data,
                },
            };
        case CLEAR_PROFILE_FORM_DATA: {
            const updatedFormData = { ...state.profileFormData };
            delete updatedFormData.email;
            delete updatedFormData.preferred_name;

            return {
                ...state,
                profileFormData: updatedFormData,
            };
        }
        case REQUEST_UPDATE_NOTIFICATION_SUBSCRIPTION:
            return {
                ...state,
                updateNotificationSubscriptionIsLoading: true,
                updateNotificationSubscriptionRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_NOTIFICATION_SUBSCRIPTION: {
            return {
                ...state,
                updateNotificationSubscriptionIsLoading: false,
                updateNotificationSubscriptionReceivedAt: action.receivedAt,
                email_notifications: {
                    ...state.email_notifications,
                    ...action.data,
                },
            };
        }
        case RECEIVE_UPDATE_NOTIFICATION_SUBSCRIPTION_ERROR:
            return {
                ...state,
                updateNotificationSubscriptionIsLoading: false,
                updateNotificationSubscriptionReceivedAt: action.receivedAt,
                updateNotificationSubscriptionError: action.error,
            };
        case REQUEST_CHANGE_USER_PASSWORD:
            return {
                ...state,
                changeUserPasswordIsLoading: true,
                changeUserPasswordRequestedAt: action.requestedAt,
            };
        case RECEIVE_CHANGE_USER_PASSWORD:
            return {
                ...state,
                changeUserPasswordIsLoading: false,
                changeUserPasswordReceivedAt: action.receivedAt,
            };
        case RECEIVE_CHANGE_USER_PASSWORD_ERROR:
            return {
                ...state,
                changeUserPasswordIsLoading: false,
                changeUserPasswordReceivedAt: action.receivedAt,
                changeUserPasswordError: action.error,
            };
        case RECEIVE_FINISH_SINGLE_FLOW_STEP:
            return {
                ...state,
                currentProfileTab: action.data.tab,
            };
        case SET_CURRENT_ACCOUNT_TAB:
            return {
                ...state,
                currentAccountTab: action.tab,
            };
        case SET_CURRENT_ACTIVE_TAB:
            return {
                ...state,
                currentActiveTab: action.tab,
            };
        case SET_CURRENT_PROFILE_TAB:
            return {
                ...state,
                currentProfileTab: action.tab,
            };
        case SET_CURRENT_RESOURCES_LIBRARY_TAB:
            return {
                ...state,
                currentResourcesLibraryTab: action.tab,
            };
        case SET_CURRENT_RESOURCES_IMPORT_TAB:
            return {
                ...state,
                currentResourcesImportTab: action.tab,
            };
        case SET_CURRENT_CARE_PLAN_BUILDER_TAB:
            return {
                ...state,
                currentCarePlanBuilderTab: action.tab,
            };
        case SET_CURRENT_REPORTS_TAB:
            return {
                ...state,
                currentReportsTab: action.tab,
            };
        default:
            return state;
    }
}
