import { gen_error, gen_received, gen_requested } from "./generators";
import { get, axiosDelete, post } from "../../utils/network";
import { creditsFragment } from "../fragments/prices";

export const REQUEST_GET_CLIENT_PRICES = "REQUEST_GET_CLIENT_PRICES";
export const RECEIVE_GET_CLIENT_PRICES = "RECEIVE_GET_CLIENT_PRICES";
export const RECEIVE_GET_CLIENT_PRICES_ERROR = "RECEIVE_GET_CLIENT_PRICES_ERROR";
export const getClientPrices = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_CLIENT_PRICES));

        const result = await get("/api/v3/org-prices/", { params: { page_size: 0 } });

        return dispatch(gen_received(RECEIVE_GET_CLIENT_PRICES, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_CLIENT_PRICES_ERROR, error));
    }
};

export const REQUEST_CREATE_CLIENT_PRICE = "REQUEST_CREATE_CLIENT_PRICE";
export const RECEIVE_CREATE_CLIENT_PRICE = "RECEIVE_CREATE_CLIENT_PRICE";
export const RECEIVE_CREATE_CLIENT_PRICE_ERROR = "RECEIVE_CREATE_CLIENT_PRICE_ERROR";
export const createClientPrice = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_CLIENT_PRICE));

        const result = await post("/api/v3/org-prices/", params);

        return dispatch(gen_received(RECEIVE_CREATE_CLIENT_PRICE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_CLIENT_PRICE_ERROR, error));
    }
};

export const REQUEST_REMOVE_CLIENT_PRICE = "REQUEST_REMOVE_CLIENT_PRICE";
export const RECEIVE_REMOVE_CLIENT_PRICE = "RECEIVE_REMOVE_CLIENT_PRICE";
export const RECEIVE_REMOVE_CLIENT_PRICE_ERROR = "RECEIVE_REMOVE_CLIENT_PRICE_ERROR";
export const removeClientPrice = (id) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REMOVE_CLIENT_PRICE));

        const result = await axiosDelete(`/api/v3/org-prices/${id}/`);

        return dispatch(gen_received(RECEIVE_REMOVE_CLIENT_PRICE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REMOVE_CLIENT_PRICE_ERROR, error));
    }
};

export const REQUEST_GET_CLIENT_CREDITS = "REQUEST_GET_CLIENT_CREDITS";
export const RECEIVE_GET_CLIENT_CREDITS = "RECEIVE_GET_CLIENT_CREDITS";
export const RECEIVE_GET_CLIENT_CREDITS_ERROR = "RECEIVE_GET_CLIENT_CREDITS_ERROR";
export const getClientCredits = (params) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_CLIENT_CREDITS));

        const result = await get("/api/v5/credits/", { params });

        return dispatch(gen_received(RECEIVE_GET_CLIENT_CREDITS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_CLIENT_CREDITS_ERROR, error));
    }
};

export const REQUEST_GET_CLIENT_SUBSCRIPTIONS = "REQUEST_GET_CLIENT_SUBSCRIPTIONS";
export const RECEIVE_GET_CLIENT_SUBSCRIPTIONS = "RECEIVE_GET_CLIENT_SUBSCRIPTIONS";
export const RECEIVE_GET_CLIENT_SUBSCRIPTIONS_ERROR = "RECEIVE_GET_CLIENT_SUBSCRIPTIONS_ERROR";
export const getClientSubscriptions = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_CLIENT_SUBSCRIPTIONS));

        const result = await get("/api/v5/subscriptions/", { params });

        return dispatch(gen_received(RECEIVE_GET_CLIENT_SUBSCRIPTIONS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_CLIENT_SUBSCRIPTIONS_ERROR, error));
    }
};

export const REQUEST_USE_CLIENT_CREDIT = "REQUEST_USE_CLIENT_CREDIT";
export const RECEIVE_USE_CLIENT_CREDIT = "RECEIVE_USE_CLIENT_CREDIT";
export const RECEIVE_USE_CLIENT_CREDIT_ERROR = "RECEIVE_USE_CLIENT_CREDIT_ERROR";
export const consumeCredit = (creditId, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_USE_CLIENT_CREDIT));

        const result = await get(`/api/v5/credits/${creditId}/consume/`, params);

        return dispatch(gen_received(RECEIVE_USE_CLIENT_CREDIT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_USE_CLIENT_CREDIT_ERROR, error));
    }
};

export const REQUEST_ALL_PURCHASES = "REQUEST_ALL_PURCHASES";
export const RECEIVE_ALL_PURCHASES = "RECEIVE_ALL_PURCHASES";
export const RECEIVE_ALL_PURCHASES_ERROR = "RECEIVE_ALL_PURCHASES_ERROR";
export const getAllPurchases = (params) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ALL_PURCHASES));

        const result = await get("/api/v5/purchased_products/", { params });

        return dispatch(gen_received(RECEIVE_ALL_PURCHASES, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ALL_PURCHASES_ERROR, error));
    }
};

export const REQUEST_ADD_CREDIT_CARD = "REQUEST_ADD_CREDIT_CARD";
export const RECEIVE_ADD_CREDIT_CARD = "RECEIVE_ADD_CREDIT_CARD";
export const RECEIVE_ADD_CREDIT_CARD_ERROR = "RECEIVE_ADD_CREDIT_CARD_ERROR";
export const addCreditCard = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ADD_CREDIT_CARD));

        const result = await post("/api/v5/stripe/save_card/", params);

        return dispatch(gen_received(RECEIVE_ADD_CREDIT_CARD, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ADD_CREDIT_CARD_ERROR, error));
    }
};

export const REQUEST_PURCHASE_PRODUCT = "REQUEST_PURCHASE_PRODUCT";
export const RECEIVE_PURCHASE_PRODUCT = "RECEIVE_PURCHASE_PRODUCT";
export const RECEIVE_PURCHASE_PRODUCT_ERROR = "RECEIVE_PURCHASE_PRODUCT_ERROR";
export const purchaseProduct = (productId, discount = null) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PURCHASE_PRODUCT));

        const result = await get(
            `/api/v5/products/${productId}/purchase/${discount && discount.length > 0 ? `?promocode=${discount}` : ""}`
        );

        return dispatch(gen_received(RECEIVE_PURCHASE_PRODUCT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PURCHASE_PRODUCT_ERROR, error));
    }
};

export const REQUEST_SCHEDULED_PURCHASE_PRODUCT = "REQUEST_SCHEDULED_PURCHASE_PRODUCT";
export const RECEIVE_SCHEDULED_PURCHASE_PRODUCT = "RECEIVE_SCHEDULED_PURCHASE_PRODUCT";
export const RECEIVE_SCHEDULED_PURCHASE_PRODUCT_ERROR = "RECEIVE_SCHEDULED_PURCHASE_PRODUCT_ERROR";
export const schedulePurchaseProduct = (productId, discountCode) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SCHEDULED_PURCHASE_PRODUCT));

        const result = await get(`/api/v5/products/${productId}/schedule_subscription/`);

        return dispatch(gen_received(RECEIVE_SCHEDULED_PURCHASE_PRODUCT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SCHEDULED_PURCHASE_PRODUCT_ERROR, error));
    }
};
//

export const REQUEST_ADD_PROVIDER_SEAT = "REQUEST_ADD_PROVIDER_SEAT";
export const RECEIVE_ADD_PROVIDER_SEAT = "RECEIVE_ADD_PROVIDER_SEAT";
export const RECEIVE_ADD_PROVIDER_SEAT_ERROR = "RECEIVE_ADD_PROVIDER_SEAT_ERROR";
export const addProviderSeat = (subscriptionId, qty = 0, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ADD_PROVIDER_SEAT));

        const result = await get(
            `/api/v5/subscriptions/${subscriptionId}/add_seat/${qty && qty > 0 ? `?qty=${qty}` : ""}`,
            params
        );

        return dispatch(gen_received(RECEIVE_ADD_PROVIDER_SEAT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ADD_PROVIDER_SEAT_ERROR, error));
    }
};

export const REQUEST_ADD_CLINIC_SEAT = "REQUEST_ADD_CLINIC_SEAT";
export const RECEIVE_ADD_CLINIC_SEAT = "RECEIVE_ADD_CLINIC_SEAT";
export const RECEIVE_ADD_CLINIC_SEAT_ERROR = "RECEIVE_ADD_CLINIC_SEAT_ERROR";
export const addClinicSeat = (subscriptionId, qty = 0, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ADD_CLINIC_SEAT));

        const result = await get(
            `/api/v5/subscriptions/${subscriptionId}/add_clinic_seat/${qty && qty > 0 ? `?qty=${qty}` : ""}`,
            params
        );

        return dispatch(gen_received(RECEIVE_ADD_CLINIC_SEAT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ADD_CLINIC_SEAT_ERROR, error));
    }
};

export const REQUEST_GET_CARD_ON_FILE = "REQUEST_GET_CARD_ON_FILE";
export const RECEIVE_GET_CARD_ON_FILE = "RECEIVE_GET_CARD_ON_FILE";
export const RECEIVE_GET_CARD_ON_FILE_ERROR = "RECEIVE_GET_CARD_ON_FILE_ERROR";
export const getCardOnFile = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_CARD_ON_FILE));

        const result = await get("/api/v5/stripe/get_last4/");

        return dispatch(gen_received(RECEIVE_GET_CARD_ON_FILE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_CARD_ON_FILE_ERROR, error));
    }
};

export const REQUEST_GET_PRODUCTS = "REQUEST_GET_PRODUCTS";
export const RECEIVE_GET_PRODUCTS = "RECEIVE_GET_PRODUCTS";
export const RECEIVE_GET_PRODUCTS_ERROR = "RECEIVE_GET_PRODUCTS_ERROR";
export const getProducts = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_PRODUCTS));

        const result = await get("/api/v5/prices/", { params });

        return dispatch(gen_received(RECEIVE_GET_PRODUCTS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_PRODUCTS_ERROR, error));
    }
};

export const REQUEST_GET_CLIENT_DISCOUNTS = "REQUEST_GET_CLIENT_DISCOUNTS";
export const RECEIVE_GET_CLIENT_DISCOUNTS = "RECEIVE_GET_CLIENT_DISCOUNTS";
export const RECEIVE_GET_CLIENT_DISCOUNTS_ERROR = "RECEIVE_GET_CLIENT_DISCOUNTS_ERROR";
export const getClientDiscounts = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_CLIENT_DISCOUNTS));

        const result = await get("/api/v5/stripe/get_coupon/", { params });

        return dispatch(gen_received(RECEIVE_GET_CLIENT_DISCOUNTS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_CLIENT_DISCOUNTS_ERROR, error));
    }
};

export const REQUEST_GET_AVAILABLE_PRODUCTS = "REQUEST_GET_AVAILABLE_PRODUCTS";
export const RECEIVE_GET_AVAILABLE_PRODUCTS = "RECEIVE_GET_AVAILABLE_PRODUCTS";
export const RECEIVE_GET_AVAILABLE_PRODUCTS_ERROR = "RECEIVE_GET_AVAILABLE_PRODUCTS_ERROR";
export const getAvailableProducts = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_AVAILABLE_PRODUCTS));

        const result = await get("/api/v5/products/get_available_products/", { params: { ...creditsFragment } });

        return dispatch(gen_received(RECEIVE_GET_AVAILABLE_PRODUCTS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_AVAILABLE_PRODUCTS_ERROR, error));
    }
};

export const REQUEST_GET_PRORATED_PRICE = "REQUEST_GET_PRORATED_PRICE";
export const RECEIVE_GET_PRORATED_PRICE = "RECEIVE_GET_PRORATED_PRICE";
export const RECEIVE_GET_PRORATED_PRICE_ERROR = "RECEIVE_GET_PRORATED_PRICE_ERROR";
export const getProratedPrice = (subscriptionId, type, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_GET_PRORATED_PRICE));

        const result = await get(
            `/api/v5/stripe/get_seat_proration/?subscription=${subscriptionId}&seat_type=${type}`,
            { params }
        );

        return dispatch(gen_received(RECEIVE_GET_PRORATED_PRICE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_PRORATED_PRICE_ERROR, error));
    }
};

export const REQUEST_CANCEL_PROVIDER_SUBSCRIPTION = "REQUEST_CANCEL_PROVIDER_SUBSCRIPTION";
export const RECEIVE_CANCEL_PROVIDER_SUBSCRIPTION = "RECEIVE_CANCEL_PROVIDER_SUBSCRIPTION";
export const RECEIVE_CANCEL_PROVIDER_SUBSCRIPTION_ERROR = "RECEIVE_CANCEL_PROVIDER_SUBSCRIPTION_ERROR";
export const cancelProviderSubscription = (subscriptionId, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CANCEL_PROVIDER_SUBSCRIPTION));

        const result = await get(`/api/v5/subscriptions/${subscriptionId}/cancel/`, params);

        return dispatch(gen_received(RECEIVE_CANCEL_PROVIDER_SUBSCRIPTION, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CANCEL_PROVIDER_SUBSCRIPTION_ERROR, error));
    }
};

export const REQUEST_CHECK_DISCOUNT_CODE = "REQUEST_CHECK_DISCOUNT_CODE";
export const RECEIVE_CHECK_DISCOUNT_CODE = "RECEIVE_CHECK_DISCOUNT_CODE";
export const RECEIVE_CHECK_DISCOUNT_CODE_ERROR = "RECEIVE_CHECK_DISCOUNT_CODE_ERROR";
export const checkDiscountCode = (product, discountCode, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CHECK_DISCOUNT_CODE));

        const result = await get(
            `/api/v5/stripe/get_discounted_price/?product=${product}&promocode=${discountCode}`,
            params
        );

        return dispatch(gen_received(RECEIVE_CHECK_DISCOUNT_CODE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CHECK_DISCOUNT_CODE_ERROR, error));
    }
};

export const SET_PURCHASE_STATUS = "SET_PURCHASE_STATUS";
export const setPurchaseStatus = (status, invoice) => (dispatch) => {
    return dispatch({ type: SET_PURCHASE_STATUS, status, invoice });
};

export const CLEAR_PURCHASE_STATUS = "CLEAR_PURCHASE_STATUS";
export const clearPurchaseStatus = () => (dispatch) => {
    return dispatch({ type: CLEAR_PURCHASE_STATUS });
};

export const CLEAR_SUBSCRIPTIONS_ERROR = "CLEAR_SUBSCRIPTIONS_ERROR";
export const clearSubscriptionsError = () => (dispatch) => {
    return dispatch({ type: CLEAR_SUBSCRIPTIONS_ERROR });
};
