import React, { useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { AppBar, Box, Drawer, MenuItem, Stack, Toolbar, Typography, useTheme } from "@mui/material";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { HEADER } from "../../../config";
// components
import ButtonLink from "../../../components/button/ButtonLink";
import BrandedLogo from "../../../components/logo/BrandedLogo";
import useResponsive from "../../../hooks/useResponsive";
import { getPublicImage } from "../../../utils/helpers";
import {
    LOGIN_ROUTE,
    PROVIDER_CLINIC_PROFILE_ROUTE,
    PROVIDER_PROFILE_ROUTE,
    PROVIDER_SEARCH_ROUTE,
    PROVIDER_SIGNUP_ROUTE,
} from "../../../utils/routes";

import ButtonMain from "../../../components/button/ButtonMain";
import MenuPopover from "../../../components/minimal/MenuPopover";
import SvgIconStyle from "../../../components/minimal/SvgIconStyle";

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: "none",
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(["width", "height"], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up("lg")]: {
        width: "100%",
        ...(isCollapse && {
            width: "100%",
        }),
        ...(isOffset && {
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        }),
        ...(verticalLayout && {
            width: "100%",
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
            backgroundColor: theme.palette.background.default,
        }),
    },
}));

// ----------------------------------------------------------------------

MFRHeader.propTypes = {
    onOpenSidebar: PropTypes.func,
    isCollapse: PropTypes.bool,
    verticalLayout: PropTypes.bool,
};

export default function MFRHeader({ isCollapse = false, verticalLayout = false, children }) {
    const theme = useTheme();
    const [mfrTherapistsAnchorEl, setMfrTherapistsAnchorEl] = useState(null);
    const [mfrPatientsAnchorEl, setMfrPatientsAnchorEl] = useState(null);
    const [mfrAboutAnchorEl, setMfrAboutAnchorEl] = useState(null);
    const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
    const showBackToSearch =
        matchPath(window.location.pathname, {
            path: PROVIDER_PROFILE_ROUTE,
        }) ||
        matchPath(window.location.pathname, {
            path: PROVIDER_CLINIC_PROFILE_ROUTE,
        });
    const isDesktop = useResponsive("up", "lg");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) setOpen(false);
    }, [isDesktop]); // eslint-disable-line

    const toggleMenu = () => {
        setOpen(!open);
    };

    const handleTherapistsMenuHover = (event) => {
        if (mfrTherapistsAnchorEl !== event.currentTarget) {
            setMfrTherapistsAnchorEl(event.currentTarget);
        }
    };

    const handlePatientsMenuHover = (event) => {
        if (mfrPatientsAnchorEl !== event.currentTarget) {
            setMfrPatientsAnchorEl(event.currentTarget);
        }
    };

    const handleAboutMenuHover = (event) => {
        if (mfrAboutAnchorEl !== event.currentTarget) {
            setMfrAboutAnchorEl(event.currentTarget);
        }
    };

    const handleMenuClose = () => {
        if (mfrTherapistsAnchorEl) setMfrTherapistsAnchorEl(null);
        if (mfrPatientsAnchorEl) setMfrPatientsAnchorEl(null);
        if (mfrAboutAnchorEl) setMfrAboutAnchorEl(null);
    };

    return (
        <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout} className="ts-header">
            <Toolbar
                sx={{
                    p: "0 !important",
                }}
            >
                {/* Desktop header */}
                <Stack
                    sx={{
                        display: { xs: "none", lg: "flex" },
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Stack sx={{ flexDirection: "row" }}>
                        <Box sx={{ p: 4, maxWidth: "200px", height: "100px" }} className="hover-pointer">
                            <a href="https://www.mfrtherapists.com/">
                                <BrandedLogo />
                            </a>
                        </Box>
                        {showBackToSearch && (
                            <ButtonLink
                                sx={{
                                    color: theme.palette.slate.main,
                                    height: "100%",
                                    borderRadius: 0,
                                    px: 2,

                                    "&:hover": {
                                        backgroundColor: `${theme.palette.grey[200]}`,
                                    },
                                }}
                                href={PROVIDER_SEARCH_ROUTE}
                            >
                                Return to search
                            </ButtonLink>
                        )}
                    </Stack>
                    <Stack sx={{ flexDirection: "row" }}>
                        <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href={PROVIDER_SEARCH_ROUTE}
                            >
                                Find a therapist
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/what-is-mfr/"
                            >
                                What is MFR
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                onClick={handleTherapistsMenuHover}
                                onMouseOver={handleTherapistsMenuHover}
                            >
                                MFR for Therapists
                                <SvgIconStyle src={getPublicImage("ic_chevron_down.svg")} />
                            </ButtonLink>
                            <MenuPopover
                                anchorEl={mfrTherapistsAnchorEl}
                                open={Boolean(mfrTherapistsAnchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/benefits/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/benefits/"
                                        onClick={handleMenuClose}
                                    >
                                        Therapist Benefits
                                    </ButtonLink>
                                </MenuItem>
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/pricing-plans/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/pricing-plans/"
                                        onClick={handleMenuClose}
                                    >
                                        Therapist Pricing Plans
                                    </ButtonLink>
                                </MenuItem>
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/skill-enhancement/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/skill-enhancement/"
                                        onClick={handleMenuClose}
                                    >
                                        Skill Enhancement
                                    </ButtonLink>
                                </MenuItem>
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/therapist-resources-2/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/therapist-resources-2/"
                                        onClick={handleMenuClose}
                                    >
                                        Therapist Resources
                                    </ButtonLink>
                                </MenuItem>
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/treatment-centers/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/treatment-centers/"
                                        onClick={handleMenuClose}
                                    >
                                        Treatment Centers
                                    </ButtonLink>
                                </MenuItem>
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/blog/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/blog/"
                                        onClick={handleMenuClose}
                                    >
                                        Blog
                                    </ButtonLink>
                                </MenuItem>
                            </MenuPopover>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                    display: "flex",
                                    justifyContent: "start",
                                }}
                                onClick={handlePatientsMenuHover}
                                onMouseOver={handlePatientsMenuHover}
                            >
                                MFR for Patients
                                <SvgIconStyle src={getPublicImage("ic_chevron_down.svg")} />
                            </ButtonLink>
                            <MenuPopover
                                anchorEl={mfrPatientsAnchorEl}
                                open={Boolean(mfrPatientsAnchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/intensives/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/intensives/"
                                        onClick={handleMenuClose}
                                    >
                                        Treatment Intensives
                                    </ButtonLink>
                                </MenuItem>
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/patient-resources-2/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/patient-resources-2/"
                                        onClick={handleMenuClose}
                                    >
                                        Patient Resources
                                    </ButtonLink>
                                </MenuItem>
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/treatment-centers/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/treatment-centers/"
                                        onClick={handleMenuClose}
                                    >
                                        Treatment Centers
                                    </ButtonLink>
                                </MenuItem>
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/blog/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/blog/"
                                        onClick={handleMenuClose}
                                    >
                                        Blog
                                    </ButtonLink>
                                </MenuItem>
                            </MenuPopover>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                onClick={handleAboutMenuHover}
                                onMouseOver={handleAboutMenuHover}
                            >
                                About Us
                                <SvgIconStyle src={getPublicImage("ic_chevron_down.svg")} />
                            </ButtonLink>
                            <MenuPopover
                                anchorEl={mfrAboutAnchorEl}
                                open={Boolean(mfrAboutAnchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/about-us/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/about-us/"
                                        onClick={handleMenuClose}
                                    >
                                        About John Barnes
                                    </ButtonLink>
                                </MenuItem>
                                <MenuItem
                                    component="a"
                                    href="https://www.mfrtherapists.com/contact-us/"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://www.mfrtherapists.com/contact-us/"
                                        onClick={handleMenuClose}
                                    >
                                        Contact Us
                                    </ButtonLink>
                                </MenuItem>
                                <MenuItem
                                    component="a"
                                    href="https://barkyhealth.freshdesk.com/support/home"
                                    onClick={handleMenuClose}
                                >
                                    <ButtonLink
                                        sx={{
                                            color: theme.palette.slate.main,
                                            "&:hover": {
                                                color: theme.palette.slate.darker,
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            },
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                        href="https://barkyhealth.freshdesk.com/support/home"
                                        onClick={handleMenuClose}
                                    >
                                        Help & Support
                                    </ButtonLink>
                                </MenuItem>
                            </MenuPopover>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
                            <ButtonMain compact href={PROVIDER_SIGNUP_ROUTE}>
                                Sign up
                            </ButtonMain>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", mx: 2 }}>
                            <ButtonMain compact href={LOGIN_ROUTE}>
                                Log in
                            </ButtonMain>
                        </Box>
                    </Stack>
                </Stack>
                {/* Mobile header */}
                <Stack
                    sx={{
                        display: { xs: "flex", lg: "none" },
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Stack sx={{ flexDirection: "row" }}>
                        <Box sx={{ p: 4, maxWidth: "200px", height: "100px" }}>
                            <a href="https://www.mfrtherapists.com/">
                                <BrandedLogo />
                            </a>
                        </Box>
                        {showBackToSearch && (
                            <ButtonLink
                                sx={{
                                    color: theme.palette.slate.main,
                                    height: "100%",
                                    borderRadius: 0,
                                    px: 2,

                                    "&:hover": {
                                        backgroundColor: `${theme.palette.grey[200]}`,
                                    },
                                }}
                                href={PROVIDER_SEARCH_ROUTE}
                            >
                                Return to search
                            </ButtonLink>
                        )}
                    </Stack>
                    <ButtonLink
                        sx={{
                            p: 2,
                            "&:hover": {
                                color: theme.palette.slate.main,
                                backgroundColor: theme.palette.common.white,
                            },
                        }}
                        onClick={toggleMenu}
                    >
                        <SvgIconStyle
                            src={getPublicImage("ic_menu.svg")}
                            sx={{
                                color: theme.palette.slate.main,
                            }}
                        />
                    </ButtonLink>
                    <Drawer open={open} sx={{ "& .MuiPaper-root": { minWidth: "300px", p: 3 } }} anchor="right">
                        <ButtonLink
                            sx={{
                                p: 2,
                                display: "flex",
                                justifyContent: "end",
                                "&:hover": {
                                    backgroundColor: theme.palette.common.white,
                                },
                            }}
                            onClick={toggleMenu}
                        >
                            <SvgIconStyle
                                src={getPublicImage("ic_close.svg")}
                                sx={{
                                    color: theme.palette.slate.main,
                                }}
                            />
                        </ButtonLink>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href={PROVIDER_SEARCH_ROUTE}
                            >
                                Find a therapist
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/what-is-mfr/"
                            >
                                What is MFR
                            </ButtonLink>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: theme.palette.grey[100],
                                width: "100%",
                            }}
                        >
                            <Typography
                                variant="caption"
                                sx={{
                                    color: theme.palette.slate.main,
                                    fontWeight: "bold",
                                    fontSize: "0.875rem",
                                    py: 0.5,
                                }}
                            >
                                MFR for Therapists
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/benefits/"
                            >
                                Therapist Benefits
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/pricing-plans/"
                            >
                                Therapist Pricing Plans
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/skill-enhancement/"
                            >
                                Skill Enhancement
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/therapist-resources-2/"
                            >
                                Therapist Resources
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mx: 1 }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/treatment-centers/"
                            >
                                Treatment Centers
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/blog/"
                            >
                                Blog
                            </ButtonLink>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: theme.palette.grey[100],
                            }}
                        >
                            <Typography
                                variant="caption"
                                sx={{
                                    color: theme.palette.slate.main,
                                    fontWeight: "bold",
                                    fontSize: "0.875rem",
                                }}
                            >
                                MFR for Patients
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/intensives/"
                            >
                                Treatment Intensives
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/patient-resources-2/"
                            >
                                Patient Resources
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/treatment-centers/"
                            >
                                Treatment Centers
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/blog/"
                            >
                                Blog
                            </ButtonLink>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: theme.palette.grey[100],
                            }}
                        >
                            <Typography
                                variant="caption"
                                sx={{
                                    color: theme.palette.slate.main,
                                    fontWeight: "bold",
                                    fontSize: "0.875rem",
                                }}
                            >
                                About Us
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/about-us/"
                            >
                                About John Barnes
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://www.mfrtherapists.com/contact-us/"
                            >
                                Contact Us
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                            <ButtonLink
                                compact
                                sx={{
                                    color: theme.palette.slate.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.common.white,
                                    },
                                }}
                                href="https://barkyhealth.freshdesk.com/support/home"
                            >
                                Help & Support
                            </ButtonLink>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", my: 2 }}>
                            <ButtonMain compact href={PROVIDER_SIGNUP_ROUTE} fullWidth>
                                Sign up
                            </ButtonMain>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <ButtonMain compact href={LOGIN_ROUTE} fullWidth>
                                Log in
                            </ButtonMain>
                        </Box>
                    </Drawer>
                </Stack>
            </Toolbar>
        </RootStyle>
    );
}
