import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LoadScript } from "@react-google-maps/api";
import DateFnsUtils from "@date-io/date-fns";

import { history } from "./app/store";
import { getDefaultGroup, getMe } from "./app/actions";
import { appWrapperClassName } from "./utils/helpers";
import ThemeProvider from "./theme";
import * as routes from "./utils/routes";

import ClinicProfile from "./components/mfr/ClinicProfile";
import DTCSignup from "./components/signup/DTCSignup";
import ErrorBoundary from "./components/error_boundary/ErrorBoundary";
import ForgotPassword from "./components/login/ForgotPassword";
import InviteSignup from "./components/signup/InviteSignup";
import Login from "./components/login/Login";
import MapSearchResults from "./components/mfr/MapSearchResults";
import MotionLazyContainer from "./components/minimal/animate/MotionLazyContainer";
import PasswordReset from "./components/login/PasswordReset";
import ProviderProfile from "./components/mfr/ProviderProfile";
import ProviderSearch from "./components/mfr/ProviderSearch";
import ProviderSignup from "./components/signup/ProviderSignup";
import RouteChangeTracker from "./components/route_change_tracker/RouteChangeTracker";
import Signup from "./components/signup/Signup";
import { SpinnerPage } from "./components/spinner/Spinner";
import ToastContainer from "./components/notifications/Toast";

const CoachApp = React.lazy(() => import("./components/coach/CoachApp"));
const PatientApp = React.lazy(() => import("./components/patient/PatientApp"));

const GOOGLE_LIBRARIES = ["places", "geocoding"];

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

export default function App() {
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);

    useEffect(() => {
        dispatch(getDefaultGroup());
        dispatch(getMe());
    }, [dispatch]);

    if (!me.meReceivedAt) {
        return <SpinnerPage />;
    }

    return (
        <MotionLazyContainer>
            <ThemeProvider>
                <Suspense fallback={<SpinnerPage />}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ErrorBoundary>
                            <div className={appWrapperClassName}>
                                <ConnectedRouter history={history}>
                                    <RouteChangeTracker history={history} />
                                    <LoadScript
                                        googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
                                        libraries={GOOGLE_LIBRARIES}
                                    >
                                        <Switch>
                                            <Route path={routes.LOGIN_ROUTE} component={Login} />
                                            <Route path={routes.INVITE_SIGNUP_ROUTE} component={InviteSignup} />
                                            <Route path={routes.DTC_SIGNUP_ROUTE} component={DTCSignup} />
                                            <Route path={routes.SIGNUP_ROUTE} component={Signup} />
                                            <Route path={routes.PROVIDER_SIGNUP_ROUTE} component={ProviderSignup} />
                                            <Route path={routes.RESET_PASSWORD_ROUTE} component={PasswordReset} />
                                            <Route path={routes.FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />

                                            {me.meReceivedAt &&
                                                !me.error &&
                                                (me.is_coach ? <CoachApp /> : <PatientApp />)}
                                            <Route path={routes.PROVIDER_SEARCH_ROUTE} component={ProviderSearch} />
                                            <Route
                                                path={routes.PROVIDER_MAP_RESULTS_ROUTE}
                                                component={MapSearchResults}
                                            />
                                            <Route path={routes.PROVIDER_PROFILE_ROUTE} component={ProviderProfile} />
                                            <Route
                                                path={routes.PROVIDER_CLINIC_PROFILE_ROUTE}
                                                component={ClinicProfile}
                                            />
                                        </Switch>
                                    </LoadScript>
                                </ConnectedRouter>
                                <ToastContainer />
                            </div>
                        </ErrorBoundary>
                    </MuiPickersUtilsProvider>
                </Suspense>
            </ThemeProvider>
        </MotionLazyContainer>
    );
}
