import { exq } from "../../utils/helpers";

export const meetingsFragment = {
    expand: exq`
        coach
        patient
    `,
    fields: exq`
        coach.id
        coach.first_name
        coach.last_name
        coach.profile_image
        patient.id
        patient.first_name
        patient.last_name
        start_time
        end_time
        on_demand
        status
    `,
};

export const slotsFragment = {
    expand: exq`
        coach
    `,
    fields: exq`
        id
        start_time
        end_time
        coach.id
        coach.first_name
        coach.last_name
        coach.bio
        coach.profile_image
    `,
};
