import moment from "moment-timezone";

import ChatMessageSound from "./res/ChatMessage.mp3";
import { showNotification } from "./notifications";
import { isMatch } from "date-fns";
import { fTime } from "./formatTime";

/**
 *
 * @param {*} conversations
 * @returns An array of conversation messages sorted by most recently created last
 */
export function getMessagesFromConversations(conversations = {}) {
    return Object.keys(conversations)
        .reduce((acc, val) => [...acc, ...conversations[val].messages], [])
        .sort((a, b) => b.created.valueOf() - a.created.valueOf());
}

/**
 *
 * @param {*} userId
 * @param {*} message
 * @returns True if a chat message has been read by a given user ID
 */
export function getMessageReadByUserId(userId, message) {
    const {
        created_by: { id: createdById },
        visit_tickets,
    } = message;

    return !(createdById !== userId && !visit_tickets.some((t) => t.user === userId && t.visited));
}

/**
 *
 * @param {*} conversations
 * @param {*} userId
 * @returns An array of messages not created or visited by a given user ID
 */
export function getUnreadMessagesFromConversations(conversations = {}, userId) {
    return getMessagesFromConversations(conversations).filter((message) => !getMessageReadByUserId(userId, message));
}

/**
 *
 * @param {*} body - Message body
 * @param {*} me - Current user
 * @returns A desktop notification titled "New chat message" with a given message body
 */
export function newMessageNotification(body) {
    const soundFile = ChatMessageSound;
    const title = "New chat message";

    showNotification(title, body, {
        soundFile,
        withWait: false,
        tag: Math.round(new Date().getTime() / 1000),
    });
}

/**
 *
 * @param {*} conversations
 * @returns An object of conversations with relevant dates converted to moment objects
 */
export function parseConversations(conversations = {}) {
    return Object.keys(conversations).reduce((acc, val) => {
        const conversation = conversations[val];

        return {
            ...acc,
            [conversation.id]: {
                ...conversation,
                created: moment.isMoment(conversation.created) ? conversation.created : moment(conversation.created),
                modified: moment.isMoment(conversation.modified)
                    ? conversation.modified
                    : moment(conversation.modified),
                messages: conversation.messages.map((message) => ({
                    ...message,
                    created: moment.isMoment(message.created) ? message.created : moment(message.created),
                    timestamp: isMatch(message.timestamp, "hh:mm aaa") ? message.timestamp : fTime(message.created),
                })),
            },
        };
    }, {});
}

/**
 *
 * @param {*} latestConversation
 * @returns True if the latest conversation was modified within the cutoff period
 */
export function useExistingConversation(latestConversation = {}) {
    const cutoff = moment().startOf("day").subtract(2, "days");
    const { modified } = latestConversation;

    return modified > cutoff;
}
