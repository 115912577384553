import { get, post } from "../../utils/network";
import { toast } from "../../components/notifications/Toast";
import { gen_error, gen_requested } from "./generators";
import moment from "moment-timezone";

export const REQUEST_REPORTS_CHANNELS = "REQUEST_REPORTS_CHANNELS";
export const RECEIVE_REPORTS_CHANNELS = "RECEIVE_REPORTS_CHANNELS";
export const RECEIVE_REPORTS_CHANNELS_ERROR = "RECEIVE_REPORTS_CHANNELS_ERROR";

export const getReportChannels = () => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_REPORTS_CHANNELS,
            requestedAt: Date.now(),
        });

        const result = await get("/api/v4/reports/channels/");

        return dispatch({
            type: RECEIVE_REPORTS_CHANNELS,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_REPORTS_CHANNELS_ERROR,
            error,
        });
    }
};

export const REPORTS_CHANGE_START_DATE = "REPORTS_CHANGE_START_DATE";

export function reportsChangeStartDate(date) {
    return {
        type: REPORTS_CHANGE_START_DATE,
        date: date,
    };
}

export const REPORTS_CHANGE_END_DATE = "REPORTS_CHANGE_END_DATE";

export function reportsChangeEndDate(date) {
    // Use the end of the date for the end date
    date.setHours(23, 59, 59, 999);
    return {
        type: REPORTS_CHANGE_END_DATE,
        date: date,
    };
}

export const REPORTS_CHANGE_SELECTED_CHANNEL_INDEX = "REPORTS_CHANGE_SELECTED_CHANNEL_INDEX";

export function reportsChangeSelectedChannelIndex(index) {
    return {
        type: REPORTS_CHANGE_SELECTED_CHANNEL_INDEX,
        index: index,
    };
}

export const REPORTS_SET_PAINLOCATION = "REPORTS_SET_PAINLOCATION";

export function reportsSetPainLocation(painLocation) {
    return {
        type: REPORTS_SET_PAINLOCATION,
        painLocation: painLocation,
    };
}

export const REPORTS_SET_DATE_INTERVAL = "REPORTS_SET_DATE_INTERVAL";

export function reportsSetDateInterval(dateInterval) {
    return {
        type: REPORTS_SET_DATE_INTERVAL,
        dateInterval: dateInterval,
    };
}

export const REQUEST_REPORTS_PAIN_DISTRIBUTION = "REQUEST_REPORTS_PAIN_DISTRIBUTION";
export const RECEIVE_REPORTS_PAIN_DISTRIBUTION = "RECEIVE_REPORTS_PAIN_DISTRIBUTION";
export const RECEIVE_REPORTS_PAIN_DISTRIBUTION_ERROR = "RECEIVE_REPORTS_PAIN_DISTRIBUTION_ERROR";

export const fetchReportsPainDistribution = (
    startDate = null,
    endDate = null,
    filterByChapters = null,
    filterByCode = null
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REQUEST_REPORTS_PAIN_DISTRIBUTION,
            requestedAt: Date.now(),
        });

        const state = getState();
        const requestData = {
            channel: state.reports.channels[state.reports.selectedChannelIndex].name,
            startDate: startDate || state.reports.startDate,
            endDate: endDate || state.reports.endDate,
            painLocation: state.reports.painLocation,
            filterByChapters: filterByChapters,
            filterByCode: filterByCode,
            noCache: state.reports.noCache,
        };
        const result = await post("/api/v3/reports/pain_distribution/", requestData);

        return dispatch({
            type: RECEIVE_REPORTS_PAIN_DISTRIBUTION,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_REPORTS_PAIN_DISTRIBUTION_ERROR,
            error,
        });
    }
};

export const REQUEST_REPORTS_BRANDING_GROUPS = "REQUEST_REPORTS_BRANDING_GROUPS";
export const RECEIVE_REPORTS_BRANDING_GROUPS = "RECEIVE_REPORTS_BRANDING_GROUPS";
export const RECEIVE_REPORTS_BRANDING_GROUPS_ERROR = "RECEIVE_REPORTS_BRANDING_GROUPS_ERROR";

export const fetchReportsBrandingGroups = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: REQUEST_REPORTS_BRANDING_GROUPS,
            requestedAt: Date.now(),
        });

        const state = getState();
        const requestData = {
            channel: state.reports.channels[state.reports.selectedChannelIndex].name,
        };
        const result = await post("/api/v3/reports/branding_groups/", requestData);

        return dispatch({
            type: RECEIVE_REPORTS_BRANDING_GROUPS,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_REPORTS_BRANDING_GROUPS_ERROR,
            error,
        });
    }
};

export const REQUEST_REPORTS_USER_REGISTRATIONS = "REQUEST_REPORTS_USER_REGISTRATIONS";
export const RECEIVE_REPORTS_USER_REGISTRATIONS = "RECEIVE_REPORTS_USER_REGISTRATIONS";
export const RECEIVE_REPORTS_USER_REGISTRATIONS_ERROR = "RECEIVE_REPORTS_USER_REGISTRATIONS_ERROR";

export const fetchReportsUserRegistrations = (
    startDate = null,
    endDate = null,
    filterByChapters = null,
    filterByCode = null
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REQUEST_REPORTS_USER_REGISTRATIONS,
        });

        const state = getState();
        const requestData = {
            channel: state.reports.channels[state.reports.selectedChannelIndex].name,
            startDate: startDate || state.reports.startDate,
            endDate: endDate || state.reports.endDate,
            benefitsStartDate: moment().startOf("year"),
            benefitsEndDate: moment().endOf("year").add(1, "days"),
            dateInterval: state.reports.dateInterval,
            filterByChapters: filterByChapters,
            filterByCode: filterByCode,
            noCache: state.reports.noCache,
        };
        const result = await post("/api/v3/reports/user_registrations/", requestData);

        return dispatch({
            type: RECEIVE_REPORTS_USER_REGISTRATIONS,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_REPORTS_USER_REGISTRATIONS_ERROR,
            error,
        });
    }
};

export const REQUEST_INVOICING_REPORT = "REQUEST_INVOICING_REPORT";
export const RECEIVE_INVOICING_REPORT = "RECEIVE_INVOICING_REPORT";
export const RECEIVE_INVOICING_REPORT_ERROR = "RECEIVE_INVOICING_REPORT_ERROR";
export const fetchEngagementReport = (channel, body) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REQUEST_INVOICING_REPORT,
        });

        const result = await post("/api/v4/reports/invoicing/", body);

        return dispatch({
            type: RECEIVE_INVOICING_REPORT,
            channel,
            data: { ...result.data, startDate: body.startDate, endDate: body.endDate },
            receivedAt: Date.now(),
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_INVOICING_REPORT_ERROR,
            error,
        });
    }
};

export const REQUEST_POPULATION_REPORT = "REQUEST_POPULATION_REPORT";
export const RECEIVE_POPULATION_REPORT = "RECEIVE_POPULATION_REPORT";
export const RECEIVE_POPULATION_REPORT_ERROR = "RECEIVE_POPULATION_REPORT_ERROR";
export const fetchPopulationReport = (channel, body) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_POPULATION_REPORT));

        const result = await post("/api/v4/reports/user_registrations/", body);

        return dispatch({ type: RECEIVE_POPULATION_REPORT, channel, data: result.data, receivedAt: Date.now() });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_POPULATION_REPORT_ERROR, error));
    }
};
export const REQUEST_COACHING_REPORT = "REQUEST_COACHING_REPORT";
export const RECEIVE_COACHING_REPORT = "RECEIVE_COACHING_REPORT";
export const RECEIVE_COACHING_REPORT_ERROR = "RECEIVE_COACHING_REPORT_ERROR";
export const fetchCoachingReport = (channel, body) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_COACHING_REPORT));

        const result = await post("/api/v4/reports/coaching/", body);

        return dispatch({ type: RECEIVE_COACHING_REPORT, channel, data: result.data, receivedAt: Date.now() });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_COACHING_REPORT_ERROR, error));
    }
};

export const REQUEST_RESOURCES_REPORT = "REQUEST_RESOURCES_REPORT";
export const RECEIVE_RESOURCES_REPORT = "RECEIVE_RESOURCES_REPORT";
export const RECEIVE_RESOURCES_REPORT_ERROR = "RECEIVE_RESOURCES_REPORT_ERROR";
export const fetchResourcesReport = (channel, body) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_RESOURCES_REPORT));

        const result = await post("/api/v4/reports/consults/", body);

        return dispatch({ type: RECEIVE_RESOURCES_REPORT, channel, data: result.data, receivedAt: Date.now() });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_RESOURCES_REPORT_ERROR, error));
    }
};

export const REQUEST_CLINICAL_REPORT = "REQUEST_CLINICAL_REPORT";
export const RECEIVE_CLINICAL_REPORT = "RECEIVE_CLINICAL_REPORT";
export const RECEIVE_CLINICAL_REPORT_ERROR = "RECEIVE_CLINICAL_REPORT_ERROR";
export const fetchClinicalReport = (channel, body) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_CLINICAL_REPORT));

        const result = await post("/api/v4/reports/clinical_outcomes/", body);

        return dispatch({ type: RECEIVE_CLINICAL_REPORT, channel, data: result.data, receivedAt: Date.now() });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CLINICAL_REPORT_ERROR, error));
    }
};

export const REQUEST_REPORTS_USERS = "REQUEST_REPORTS_USERS";
export const RECEIVE_REPORTS_USERS = "RECEIVE_REPORTS_USERS";
export const RECEIVE_REPORTS_USERS_ERROR = "RECEIVE_REPORTS_USERS_ERROR";

export const fetchReportsUsers = (startDate = null, endDate = null, filterByCode = null) => async (
    dispatch,
    getState
) => {
    try {
        dispatch({
            type: REQUEST_REPORTS_USERS,
        });

        const state = getState();
        const requestData = {
            channel: state.reports.channels[state.reports.selectedChannelIndex].name,
            startDate: startDate || state.reports.startDate,
            endDate: endDate || state.reports.endDate,
            filterByCode: filterByCode,
            noCache: state.reports.noCache,
        };
        const result = await post("/api/v3/reports/users/", requestData);

        return dispatch({
            type: RECEIVE_REPORTS_USERS,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_REPORTS_USERS_ERROR,
            error,
        });
    }
};

export const REQUEST_CONSULTS_REPORT = "REQUEST_CONSULTS_REPORT";
export const RECEIVE_CONSULTS_REPORT = "RECEIVE_CONSULTS_REPORT";
export const RECEIVE_CONSULTS_REPORT_ERROR = "RECEIVE_CONSULTS_REPORT_ERROR";
export const fetchConsultsReport = (channel, body) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_CONSULTS_REPORT,
        });
        const result = await post("/api/v3/reports/consults/", body);

        return dispatch({
            type: RECEIVE_CONSULTS_REPORT,
            channel,
            data: result.data,
            receivedAt: Date.now(),
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_CONSULTS_REPORT_ERROR,
            error,
        });
    }
};

export const REQUEST_QA_REPORT = "REQUEST_QA_REPORT";
export const RECEIVE_QA_REPORT = "RECEIVE_QA_REPORT";
export const RECEIVE_QA_REPORT_ERROR = "RECEIVE_QA_REPORT_ERROR";
export const fetchReportsQA = (body) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_QA_REPORT,
        });

        const result = await post("/api/v3/reports/qa/", body);

        return dispatch({
            type: RECEIVE_QA_REPORT,
            data: result.data,
            startDate: body.startDate,
            endDate: body.endDate,
            receivedAt: Date.now(),
        });
    } catch (error) {
        toast.error(error.message);
        return dispatch({
            type: RECEIVE_QA_REPORT_ERROR,
            error,
        });
    }
};

export const REQUEST_REPORTS_SATISFACTION = "REQUEST_REPORTS_SATISFACTION";
export const RECEIVE_REPORTS_SATISFACTION = "RECEIVE_REPORTS_SATISFACTION";
export const RECEIVE_REPORTS_SATISFACTION_ERROR = "RECEIVE_REPORTS_SATISFACTION_ERROR";

export const fetchReportsSatisfaction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: REQUEST_REPORTS_SATISFACTION,
            requestedAt: Date.now(),
        });
        const result = await get("/api/v3/reports/satisfaction/");
        return dispatch({
            type: RECEIVE_REPORTS_SATISFACTION,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_REPORTS_SATISFACTION_ERROR,
            error,
        });
    }
};

export const REQUEST_REPORTS_UTILIZATION = "REQUEST_REPORTS_UTILIZATION";
export const RECEIVE_REPORTS_UTILIZATION = "RECEIVE_REPORTS_UTILIZATION";
export const RECEIVE_REPORTS_UTILIZATION_ERROR = "RECEIVE_REPORTS_UTILIZATION_ERROR";

export const fetchReportsUtilization = (filterByChapters = null, filterByCode = null) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REQUEST_REPORTS_UTILIZATION,
            requestedAt: Date.now(),
        });

        const state = getState();
        const requestData = {
            channel: state.reports.channels[state.reports.selectedChannelIndex].name,
            startDate: state.reports.startDate,
            endDate: state.reports.endDate,
            filterByChapters: filterByChapters,
            filterByCode: filterByCode,
            noCache: state.reports.noCache,
        };
        const result = await post("/api/v3/reports/utilization_metrics/", requestData);

        return dispatch({
            type: RECEIVE_REPORTS_UTILIZATION,
            receivedAt: Date.now(),
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_REPORTS_UTILIZATION_ERROR,
            receivedAt: Date.now(),
            error,
        });
    }
};

export const CLEAR_INVOICING_REPORT = "CLEAR_INVOICING_REPORT";
export const clearInvoicingReport = () => {
    return {
        type: CLEAR_INVOICING_REPORT,
    };
};

export const CLEAR_QA_REPORT = "CLEAR_QA_REPORT";
export const clearQAReport = () => {
    return {
        type: CLEAR_QA_REPORT,
    };
};

export const CLEAR_CONSULTS_REPORT = "CLEAR_CONSULTS_REPORT";
export const clearConsultsReport = () => {
    return {
        type: CLEAR_CONSULTS_REPORT,
    };
};

export const REPORTS_CHANGE_START_DATE_SIGNUPS = "REPORTS_CHANGE_START_DATE_SIGNUPS";

export function reportsChangeStartDateSignups(date) {
    return {
        type: REPORTS_CHANGE_START_DATE_SIGNUPS,
        date: date,
    };
}

export const REPORTS_CHANGE_END_DATE_SIGNUPS = "REPORTS_CHANGE_END_DATE_SIGNUPS";

export function reportsChangeEndDateSignups(date) {
    // Use the end of the date for the end date
    date.setHours(23, 59, 59, 999);
    return {
        type: REPORTS_CHANGE_END_DATE_SIGNUPS,
        date: date,
    };
}

export const REPORTS_CHANGE_FILTER_USERS_BY_CHAPTERS = "REPORTS_CHANGE_FILTER_USERS_BY_CHAPTERS";

export function reportsChangefilterUsersByChapters(checked) {
    return {
        type: REPORTS_CHANGE_FILTER_USERS_BY_CHAPTERS,
        checked: checked,
    };
}

export const REPORTS_CHANGE_SHOW_BILLING_DATA = "REPORTS_CHANGE_SHOW_BILLING_DATA";

export function reportsChangeShowBillingData(checked) {
    return {
        type: REPORTS_CHANGE_SHOW_BILLING_DATA,
        checked: checked,
    };
}

export const REPORTS_CHANGE_NO_CACHE = "REPORTS_CHANGE_NO_CACHE";

export function reportsChangeNoCache(checked) {
    return {
        type: REPORTS_CHANGE_NO_CACHE,
        checked: checked,
    };
}

export const REPORTS_SELECT_CHAPTERS = "REPORTS_SELECT_CHAPTERS";

export function reportsSelectChapters(e) {
    const chapters = [...e.target.options].filter((o) => o.selected).map((o) => parseInt(o.value));
    return {
        type: REPORTS_SELECT_CHAPTERS,
        chapters: chapters,
    };
}

export const REPORTS_CHANGE_DRAW_USERS_BY_CHAPTERS = "REPORTS_CHANGE_DRAW_USERS_BY_CHAPTERS";

export function reportsChangeDrawUsersByChapters(checked) {
    return {
        type: REPORTS_CHANGE_DRAW_USERS_BY_CHAPTERS,
        checked: checked,
    };
}

export const REPORTS_CHANGE_FILTER_USERS_BY_CODE = "REPORTS_CHANGE_FILTER_USERS_BY_CODE";

export function reportsChangeFilterUsersByCode(checked) {
    return {
        type: REPORTS_CHANGE_FILTER_USERS_BY_CODE,
        checked: checked,
    };
}

export const REPORTS_SET_FILTER_CODE = "REPORTS_SET_FILTER_CODE";

export function reportsSetFilterCode(code) {
    return {
        type: REPORTS_SET_FILTER_CODE,
        filterCode: code,
    };
}

export function fetchReportsCommon() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(reportsSetDateInterval("month"));
        const chapters = state.reports.filterUsersByChapters ? state.reports.chapters : null;
        const filterCode = state.reports.filterUsersByCode ? state.reports.filterCode : null;
        dispatch(fetchReportsUtilization(chapters, filterCode));
        dispatch(
            fetchReportsUserRegistrations(
                state.reports.startDateSignups,
                state.reports.endDateSignups,
                chapters,
                filterCode
            )
        );
        dispatch(
            fetchReportsPainDistribution(
                state.reports.startDateSignups,
                state.reports.endDateSignups,
                chapters,
                filterCode
            )
        );

        if (state.reports.drawUsersByChapters) {
            dispatch(
                fetchReportsUsersByChaptersDistribution(state.reports.startDateSignups, state.reports.endDateSignups)
            );
        }
    };
}

export const REQUEST_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION = "REQUEST_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION";
export const RECEIVE_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION = "RECEIVE_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION";
export const RECEIVE_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION_ERROR =
    "RECEIVE_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION_ERROR";

export const fetchReportsUsersByChaptersDistribution = (startDate = null, endDate = null) => async (
    dispatch,
    getState
) => {
    try {
        dispatch({
            type: REQUEST_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION,
            requestedAt: Date.now(),
        });

        const state = getState();
        const requestData = {
            channel: state.reports.channels[state.reports.selectedChannelIndex].name,
            startDate: startDate || state.reports.startDate,
            endDate: endDate || state.reports.endDate,
        };
        const result = await post("/api/v3/reports/users_by_chapters_distribution", requestData);

        return dispatch({
            type: RECEIVE_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION_ERROR,
            error,
        });
    }
};
