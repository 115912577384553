import { createMuiTheme } from "@material-ui/core";

export const ARTICLE_RESOURCE_TYPE_ID = "article";
export const EXERCISE_RESOURCE_TYPE_ID = "exercise";

export const DEFAULT_LIST_TITLES = ["Program resources", "Basic resources"];
export const DEFAULT_PROVIDER_LIST_TITLES = ["Care plan resources", "Basic resources"];
export const PROGRAM_TAGS = ["Pain Severity", "Symptom"];

export const ALL_RESOURCES_LIST_ITEM_ID = "all resources";
export const ARTICLES_LIST_ITEM_ID = "articles";
export const EXERCISES_LIST_ITEM_ID = "exercises";
export const ROUTINES_LIST_ITEM_ID = "routine templates";
export const PROGRAM_LIST_ITEM_ID = "plan templates";
export const FAVORITES_LIST_ITEM_ID = "favorites";
export const RECOMMENDED_LIST_ITEM_ID = "recommended";
export const TREATMENT_LIST_ITEM_ID = "basics";
export const WEBINARS_LIST_ITEM_ID = "webinars";
export const GETTING_STARTED_LIST_ITEM_ID = "getting started";
export const MFR_BASICS_LIST_ITEM_ID = "MFR basics";
export const HELP_CENTER_LIST_ITEM_ID = "help center";

export const TITLE_CHARACTER_LIMIT = 70;

export const RESOURCE_FILTER_TYPES = [
    { label: "Video Resources", id: 0, tab: EXERCISES_LIST_ITEM_ID },
    { label: "Routine Templates", id: 1, tab: ROUTINES_LIST_ITEM_ID },
    { label: "Plan Templates", id: 2, tab: PROGRAM_LIST_ITEM_ID },
];
export const RESOURCE_TEMPLATE_FILTER_TYPES = [
    { label: "Video Resources", id: 0, tab: EXERCISES_LIST_ITEM_ID },
    { label: "Routine Templates", id: 1, tab: ROUTINES_LIST_ITEM_ID },
];
export const ROUTINE_TEMPLATE_FILTER_TYPES = [{ label: "Video Resources", id: 0, tab: EXERCISES_LIST_ITEM_ID }];

export const ITEM_TYPES = {
    SESSION: "session",
    RESOURCE: "resource",
};

export const PRIMARY_CONDITION_TAG_TYPE = "Condition";
export const SECONDARY_CONDITION_TAG_TYPE = "Secondary Condition";

export const textBoxTheme = createMuiTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    border: "none",
                },
                "&:hover $notchedOutline": {
                    border: "black",
                },
                "&$focused $notchedOutline": {
                    border: "black",
                },
            },
        },
    },
});

export function processResource(resourceItem) {
    if (resourceItem.exercise !== undefined && resourceItem.article !== undefined) {
        const processedResource = {
            ...resourceItem,
            type: resourceItem.exercise ? EXERCISE_RESOURCE_TYPE_ID : ARTICLE_RESOURCE_TYPE_ID,
        };

        if (resourceItem.article) {
            processedResource.article.preview = processedResource.article.img;
        }

        return processedResource;
    }

    return new Error("item is not a resource");
}

export function programToResource(exercise) {
    const resource = {
        exercise,
        article: null,
        type: EXERCISE_RESOURCE_TYPE_ID,
    };

    return resource;
}

export function exerciseToResource(exercise) {
    if (exercise.video && !exercise.img) {
        const resource = {
            exercise,
            article: null,
            type: EXERCISE_RESOURCE_TYPE_ID,
        };

        return resource;
    }

    return new Error("item is not an exercise");
}

export function articleToResource(article) {
    if (article.img && !article.video) {
        const resource = {
            exercise: null,
            article: {
                ...article,
                preview: article.img,
            },
            type: ARTICLE_RESOURCE_TYPE_ID,
        };

        return resource;
    }

    return new Error("item is not an article");
}

export function getResourceType(resource) {
    if (resource && resource.type)
        return resource.type === EXERCISE_RESOURCE_TYPE_ID ? EXERCISE_RESOURCE_TYPE_ID : ARTICLE_RESOURCE_TYPE_ID;

    return new Error("item is not a resource");
}

export function findResourceInList(list, resourceParent, resourceType) {
    const checkResource = resourceParent[resourceType];
    const foundResource =
        list &&
        list.length > 0 &&
        list.find((r) => {
            if (
                (r && r.type && r.type === resourceType && r.exercise && r.exercise.id === checkResource.id) ||
                (r && r.type && r.type === resourceType && r.article && r.article.id === checkResource.id)
            ) {
                return r;
            } else if (r.id === checkResource.id) {
                return r;
            } else {
                return null;
            }
        });

    return foundResource;
}

export function generateAddResourceBody(listId, listType, resourceId) {
    const requestBody = {
        list: listId,
        order: 0,
    };

    requestBody[listType] = resourceId;

    return requestBody;
}

/**
 *
 * @param {*} importSessions
 * @param {*} currentSessionsList
 * @returns A list of sessions with unique titles by appending a number
 * to any duplicate session titles
 */
export function resolveDuplicateSessionTitles(importSessions, currentSessionsList) {
    const currentProgramTitles = currentSessionsList.map((currentSessionsList) => currentSessionsList.title);
    const uniqueSessionsList = importSessions.map((sessionlistItem) => {
        let duplicateSession = null;
        if (currentProgramTitles.includes(sessionlistItem.title)) duplicateSession = sessionlistItem;

        let dupeCount = 2;
        let uniqueSessionTitle = sessionlistItem.title;
        let uniqueSessionResources = [...sessionlistItem.resources];

        if (duplicateSession) {
            // If we have a dupe, check all sessions to find any additional dupes that already have a number appended
            currentSessionsList.forEach((session) => {
                const checkTitle = `${duplicateSession.title} (${dupeCount})`;

                if (session.title === checkTitle) dupeCount++;
            });

            // Also check the list that is being imported to avoid dupes again
            importSessions.forEach((session) => {
                const checkTitle = `${duplicateSession.title} (${dupeCount})`;

                if (session.title === checkTitle) dupeCount++;
            });

            uniqueSessionTitle = `${duplicateSession.title} (${dupeCount})`;

            // Apply the same unique name to the individual resources
            uniqueSessionResources =
                duplicateSession.resources.length &&
                duplicateSession.resources.map((resource) => ({ ...resource, group: uniqueSessionTitle }));
        }

        return { resources: uniqueSessionResources, title: uniqueSessionTitle };
    });

    return uniqueSessionsList;
}
/**
 *
 * @param {*} list
 * @returns A list of resources in ascending order
 */
export function reorderList(list, reorder = false) {
    const flatList =
        list.length &&
        list.reduce((groupedList, value) => {
            groupedList.resources =
                groupedList.resources && groupedList.resources.length > 0
                    ? groupedList.resources.concat(value.resources)
                    : [];

            return groupedList;
        });

    if (reorder) flatList.resources.reverse();

    const orderedResourcesList =
        flatList && flatList.resources && flatList.resources.length
            ? flatList.resources.map((resource, idx) => {
                  const resourceType = getResourceType(resource);

                  return {
                      list: flatList.id,
                      [resourceType]: resource[resourceType].id,
                      group: resource.group,
                      order: idx,
                  };
              })
            : [];

    return orderedResourcesList;
}

/**
 *
 * @param {*} originalProgramList
 * @param {*} newProgramList
 * @param {*} movedRoutine
 * @returns Reordered list of programs
 */
export function reorderAssignedPrograms(originalProgramList, newProgramList, movedRoutine) {
    const originalIndex = originalProgramList.findIndex((program) => program.order === movedRoutine.order);
    const newIndex = newProgramList.findIndex((program) => program.order === movedRoutine.order);
    const direction = originalIndex > newIndex ? "up" : "down";
    let reorderedList = [];
    if (direction === "up") {
        reorderedList =
            newProgramList &&
            newProgramList.length > 0 &&
            newProgramList.map((program, idx) => {
                if (idx < newIndex) {
                    return program;
                } else {
                    return {
                        ...program,
                        order: originalProgramList[idx].order,
                    };
                }
            });
    } else {
        reorderedList =
            newProgramList &&
            newProgramList.length > 0 &&
            newProgramList.map((program, idx) => {
                if (idx > newIndex) {
                    return program;
                } else {
                    return {
                        ...program,
                        order: originalProgramList[idx].order,
                    };
                }
            });
    }

    return reorderedList;
}

/**
 *
 * @param {*} list
 * @returns Object with group title and ordered array of items
 */
export function reorderTagList(list) {
    const title = list[0].type;
    let image = null;

    switch (title) {
        case "Benefit":
            image = "ic_check_outline.svg";
            break;
        case "Category":
            image = "ic_label.svg";
            break;
        case "Equipment":
            image = "ic_tennis_ball.svg";
            break;
        case "Webinars":
            image = "ic_videocam.svg";
            break;
        case "Owner":
            image = "ic_person.svg";
            break;
        case "Condition":
            image = "ic_pain_location.svg";
            break;
        default:
            image = "ic_location.svg";
            break;
    }

    const reorderedList = list
        .sort((a, b) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();

            return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
        })
        .map((list) => list.title);

    return { image, title, list: reorderedList };
}
