import React from "react";
import { ThemeProvider as SCThemeProivder } from "styled-components";

import {
    DARK_BACKGROUND_COLOR_ID,
    getThemeColor,
    PRIMARY_ALT_COLOR_ID,
    PRIMARY_COLOR_ID,
    PRIMARY_COLOR_DARK_ID,
    SECONDARY_COLOR_ID,
    SECONDARY_COLOR_DARK_ID,
    SECONDARY_ALT_COLOR_ID,
    SECONDARY_ORANGE_COLOR_ID,
} from "./colors";

const theme = {
    [PRIMARY_COLOR_ID]: getThemeColor(PRIMARY_COLOR_ID),
    [PRIMARY_COLOR_DARK_ID]: getThemeColor(PRIMARY_COLOR_DARK_ID),
    [PRIMARY_ALT_COLOR_ID]: getThemeColor(PRIMARY_ALT_COLOR_ID),
    [SECONDARY_COLOR_ID]: getThemeColor(SECONDARY_COLOR_ID),
    [SECONDARY_COLOR_DARK_ID]: getThemeColor(SECONDARY_COLOR_DARK_ID),
    [SECONDARY_ALT_COLOR_ID]: getThemeColor(SECONDARY_ALT_COLOR_ID),
    [SECONDARY_ORANGE_COLOR_ID]: getThemeColor(SECONDARY_ORANGE_COLOR_ID),
    [DARK_BACKGROUND_COLOR_ID]: getThemeColor(DARK_BACKGROUND_COLOR_ID),
};

export default function ThemeProivder({ children }) {
    return <SCThemeProivder theme={theme}>{children}</SCThemeProivder>;
}
