import React from "react";
import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Chip, Stack, Button } from "@mui/material";
// components
import Iconify from "./Iconify";

// ----------------------------------------------------------------------

const RootStyle = styled("div")({
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
});

const WrapperStyle = styled("div")(({ theme }) => ({
    display: "flex",
    overflow: "hidden",
    alignItems: "stretch",
    margin: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${theme.palette.divider}`,
}));

const LabelStyle = styled("span")(({ theme }) => ({
    ...theme.typography.subtitle2,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    borderRight: `solid 1px ${theme.palette.divider}`,
}));

// ----------------------------------------------------------------------

AppliedTags.propTypes = {
    filters: PropTypes.object,
    isShowReset: PropTypes.bool,
    onRemoveTag: PropTypes.func,
    onRemoveSearch: PropTypes.func,
    onResetAll: PropTypes.func,
};

export default function AppliedTags({ tags, search, isShowReset, onRemoveTag, onRemoveSearch, onResetAll }) {
    return (
        <RootStyle>
            {tags && tags.length > 0 && (
                <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
                    {tags.map((tag) => (
                        <Chip key={tag} label={tag} size="small" onDelete={() => onRemoveTag(tag)} sx={{ m: 0.5 }} />
                    ))}
                </Stack>
            )}

            {search && (
                <WrapperStyle>
                    <LabelStyle>Search:</LabelStyle>
                    <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
                        <Chip key={search} label={search} size="small" onDelete={onRemoveSearch} sx={{ m: 0.5 }} />
                    </Stack>
                </WrapperStyle>
            )}

            {isShowReset && ((tags && tags.length > 0) || (search && search.length > 0)) && (
                <Button
                    size="small"
                    onClick={onResetAll}
                    startIcon={<Iconify icon={"ic:round-clear-all"} />}
                    sx={{ ml: 2, color: "#00adef !important", "&:hover": { backgroundColor: "#fff" } }}
                >
                    Clear All
                </Button>
            )}
        </RootStyle>
    );
}
