import {
    REQUEST_COACH,
    RECEIVE_COACH,
    RECEIVE_COACH_ERROR,
    REQUEST_COACHES,
    RECEIVE_COACHES,
    RECEIVE_COACHES_ERROR,
    REQUEST_UPDATE_COACH,
    RECEIVE_UPDATE_COACH,
    RECEIVE_UPDATE_COACH_ERROR,
    REQUEST_COACH_PATIENT_EPISODES,
    RECEIVE_COACH_PATIENT_EPISODES,
    RECEIVE_COACH_PATIENT_EPISODES_ERROR,
    REQUEST_COACH_REMINDERS,
    RECEIVE_COACH_REMINDERS,
    RECEIVE_COACH_REMINDERS_ERROR,
    REQUEST_UPDATE_COACH_REMINDER,
    RECEIVE_UPDATE_COACH_REMINDER,
    RECEIVE_UPDATE_COACH_REMINDER_ERROR,
} from "../actions";
import { toast } from "../../components/notifications/Toast";

export function coaches(state = {}, action) {
    switch (action.type) {
        case REQUEST_COACH:
            return {
                ...state,
                coachRequestedAt: action.requestedAt,
                coachIsFetching: true,
            };
        case RECEIVE_COACH:
            return {
                ...state,
                coachReceivedAt: action.receivedAt,
                coachIsFetching: false,
                profile: action.data,
            };
        case RECEIVE_COACH_ERROR:
            return {
                ...state,
                coachReceivedAt: action.receivedAt,
                coachIsFetching: false,
                coachError: action.error,
            };
        case REQUEST_COACHES:
            return {
                ...state,
                coachListRequestedAt: action.requestedAt,
                coachListIsFetching: true,
            };
        case RECEIVE_COACHES:
            return {
                ...state,
                coachListReceivedAt: action.receivedAt,
                coachListIsFetching: false,
                coachList: action.data,
            };
        case RECEIVE_COACHES_ERROR:
            return {
                ...state,
                coachListReceivedAt: action.receivedAt,
                coachListIsFetching: false,
                coachListError: action.error,
            };
        case REQUEST_UPDATE_COACH:
            return {
                ...state,
                updateCoachIsLoading: true,
                updateCoachRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_COACH:
            return {
                ...state,
                updateCoachIsLoading: false,
                updateCoachReceivedAt: action.receivedAt,
                profile: action.data,
            };
        case RECEIVE_UPDATE_COACH_ERROR:
            return {
                ...state,
                updateCoachIsLoading: false,
                updateCoachReceivedAt: action.receivedAt,
                updateCoachError: action.error,
            };
        case REQUEST_COACH_PATIENT_EPISODES:
            return {
                ...state,
                coachPatientEpisodesIsLoading: true,
                coachPatientEpisodesRequestedAt: action.requestedAt,
            };
        case RECEIVE_COACH_PATIENT_EPISODES:
            return {
                ...state,
                coachPatientEpisodesIsLoading: false,
                coachPatientEpisodesReceivedAt: action.receivedAt,
                coachPatientEpisodesData: action.data,
            };
        case RECEIVE_COACH_PATIENT_EPISODES_ERROR:
            return {
                ...state,
                coachPatientEpisodesIsLoading: false,
                coachPatientEpisodesReceivedAt: action.receivedAt,
                coachPatientEpisodesError: action.error,
            };
        case REQUEST_COACH_REMINDERS:
            return {
                ...state,
                coachRemindersIsLoading: true,
                coachRemindersRequestedAt: action.requestedAt,
            };
        case RECEIVE_COACH_REMINDERS:
            return {
                ...state,
                coachRemindersIsLoading: false,
                coachRemindersReceivedAt: action.receivedAt,
                coachRemindersData: action.data,
            };
        case RECEIVE_COACH_REMINDERS_ERROR:
            return {
                ...state,
                coachRemindersIsLoading: false,
                coachRemindersReceivedAt: action.receivedAt,
                coachRemindersError: action.error,
            };
        case REQUEST_UPDATE_COACH_REMINDER:
            return {
                ...state,
                coachReminderIsLoading: true,
                coachReminderRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_COACH_REMINDER: {
            const {
                data: { id: updatedId },
            } = action;
            const { coachRemindersData: newCoachRemindersData = [] } = state;
            const existingIndex = newCoachRemindersData.findIndex((r) => r.id === updatedId);

            if (existingIndex > -1) {
                newCoachRemindersData[existingIndex] = {
                    ...newCoachRemindersData[existingIndex],
                    ...action.data,
                };
            }

            return {
                ...state,
                coachReminderIsLoading: false,
                coachReminderReceivedAt: action.receivedAt,
                coachRemindersData: newCoachRemindersData,
            };
        }
        case RECEIVE_UPDATE_COACH_REMINDER_ERROR:
            toast.error(`Error updating reminder: ${action.error.toString()}`);

            return {
                ...state,
                coachReminderIsLoading: false,
                coachReminderReceivedAt: action.receivedAt,
                coachReminderError: action.error,
            };
        default:
            return state;
    }
}
