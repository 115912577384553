import React from "react";
import logger from "../../utils/logger";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: undefined,
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        return { error, hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // send message to third party logging service
        logger.error(error, JSON.stringify(errorInfo));
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h1>Something went wrong</h1>
                    <p>{this.state.error.toString()}</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
