import React from "react";
import { Button, InputLabel } from "@material-ui/core";
import { Field } from "formik";
import cx from "classnames";

import { genderId, GENDER_OPTIONS, otherGenderId } from "../../utils/user";
import { sendTextFieldFocusEvent } from "../../utils/analytics";

import ButtonGroup from "../button_group/ButtonGroup";
import FormError from "../form_error/FormError";
import FormRow from "../form_row/FormRow";
import TextInput from "../text_input/TextInput";

export default function GenderSelectionButtonGroup({
    values,
    status,
    errors,
    touched,
    setFieldValue,
    setStatus,
    handleGenderChange,
    handleFormChange,
    required = true,
    disabled = false,
}) {
    const mappedGenderOptions = GENDER_OPTIONS.map((option, idx) => (
        <Button
            key={idx}
            id={genderId}
            name={genderId}
            type="button"
            value={option.value}
            onClick={handleGenderChange(genderId, option.value, setFieldValue)}
            className={cx("px-4 py-2", values.gender === option.value && "selected")}
            data-button={`button__gender--${option.value}`}
            analy
            disabled={disabled}
        >
            <div data-button={`button__gender--${option.value}`}>{option.label}</div>
        </Button>
    ));

    const GenderButtonGroup = ({ className, children, orientation = "horizontal" }) => {
        return (
            <Field as={ButtonGroup} className={cx("ts-gender-button-group", className)} orientation={orientation}>
                {children}
            </Field>
        );
    };

    const handleFormFocus = () => (event) => {
        const {
            target: { id },
        } = event;

        if (id) sendTextFieldFocusEvent(id);
    };

    return (
        <>
            <InputLabel className="mb-0" disabled={disabled} shrink>
                Gender {required ? "*" : ""}
            </InputLabel>
            <GenderButtonGroup className="d-lg-none w-100 mb-3" orientation="vertical" label="Select Gender">
                {mappedGenderOptions}
            </GenderButtonGroup>
            <GenderButtonGroup className="d-none d-lg-flex mb-3" label="Select Gender">
                {mappedGenderOptions}
            </GenderButtonGroup>
            <FormError show={errors && errors.gender && touched.gender} message={errors.gender} />
            <FormError show={errors && !errors.gender && status && status.gender} message={status && status.gender} />
            {values && values.gender === otherGenderId && (
                <FormRow bottomSpacing>
                    <TextInput
                        id="otherGender form-field__other-gender"
                        name="otherGender"
                        label="Enter gender"
                        onFocus={handleFormFocus()}
                        onChange={handleFormChange(status, setStatus, setFieldValue)}
                        value={values.otherGender}
                        required
                        disabled={disabled}
                    />
                    <FormError
                        show={errors && errors.otherGender && touched.otherGender}
                        message={errors.otherGender}
                    />
                    <FormError
                        show={errors && !errors.otherGender && status && status.otherGender}
                        message={status && status.otherGender}
                    />
                </FormRow>
            )}
        </>
    );
}
