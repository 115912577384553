import React from "react";
import { send } from "@giantmachines/redux-websocket/dist";
import qs from "query-string";

import {
    fetchMeeting,
    fetchMeetings,
    getConversation,
    logMeOut,
    setPurchaseStatus,
    updateCoachBusyStatus,
    updateVideoSessionData,
    videoSession,
} from "../actions";

import OnDemandNotification from "../../components/coach/OnDemandNotification";
import { toast } from "../../components/notifications/Toast";

const websocketsInitialState = {};

export function websockets(state = websocketsInitialState, action) {
    let pongSent = false;

    const {
        location: { search },
    } = window;

    switch (action.type) {
        case "REDUX_WEBSOCKET::MESSAGE":
            const {
                payload: { message },
            } = action;

            switch (message.type) {
                case "video_session":
                    action.asyncDispatch(videoSession(message));
                    break;
                // TODO: Fix flow to not interrupt video consult if coach set new program for a patient and uncomment update_me
                // case "update_me":
                //     action.asyncDispatch(getMe());
                //     message.message && toast.info(message.message);
                //     break
                case "user_reload":
                    // It will brake video session if coach will update user somehow. Disable for now.
                    // window.location.reload();
                    break;
                case "user_signout":
                    action.asyncDispatch(logMeOut({ inactive: true }));
                    break;
                case "update_meetings":
                    action.asyncDispatch(fetchMeetings());
                    break;
                case "meeting_updated":
                    action.asyncDispatch(fetchMeeting(message.id));

                    const meetingId = qs.parse(search).sid;

                    if (message && message.meeting && meetingId && meetingId === message.meeting.opentok_session_id) {
                        action.asyncDispatch(updateVideoSessionData(message.meeting));
                    }

                    break;
                case "user_payment":
                    action.asyncDispatch(setPurchaseStatus(message.status, message.invoice));
                    break;
                case "conversation_created":
                case "conversation_updated":
                    action.asyncDispatch(getConversation(message.id));
                    break;
                // case "user_updated":
                //     const { user_id } = message;
                //     // TODO: need a way to discern if the user updated is the current user or not
                //     action.asyncDispatch(getPatient(user_id));
                //     break;
                case "coach_status":
                    action.asyncDispatch(updateCoachBusyStatus(message.is_busy));
                    break;
                case "user_start_video_call":
                    toast.info(<OnDemandNotification {...message} />, { autoClose: false, closeOnClick: false });
                    break;
                case "user_ping":
                    action.asyncDispatch(send({ type: "user_pong" }));
                    break;
                default:
                    break;
            }
            return state;
        case "SUBSCRIBE_TO_USER_STATUS":
            const subscribedUsers = state.subscribedUsers || [];
            if (!subscribedUsers.includes(action.userId)) {
                subscribedUsers.push(action.userId);
                action.asyncDispatch(send({ type: "coach_subscribe", user_id: action.userId }));
            }

            return {
                ...state,
                subscribedUsers,
            };
        case "REDUX_WEBSOCKET::OPEN":
            // Ping BE 5 secs after connection is established
            setInterval(() => {
                if (!pongSent) {
                    action.asyncDispatch(send({ type: "user_pong" }));
                    pongSent = true;
                }
            }, 5 * 1000);

            // Continue pinging BE every 5 minutes
            // setInterval(() => {
            //     action.asyncDispatch(send({ type: "user_pong" }));
            // }, 300 * 1000); // 5 minutes

            return {
                ...state,
                connected: true,
            };
        case "REDUX_WEBSOCKET::CLOSED":
            return {
                ...state,
                connected: false,
            };
        default:
            return state;
    }
}
