import { exq } from "../../utils/helpers";

export const creditsFragment = {
    expand: exq`
        sales_credits
        purchased_product
        purchased_product.product
        purchased_product.product.prices
        purchased_product.sales_credits
        prices
        product
        product.prices
        product_items
    `,
};

export const purchaseDataFragment = {
    expand: exq`
        user
        product.prices
    `,
    fields: exq`
        created
        product.prices
        stripe_invoice_id
        user.email
        user.first_name
        user.last_name
        user.id
    `,
};

export const productFragment = {
    expand: exq`
        product
        product.prices
    `,
    fields: exq`
        amount
        created
        id
        interval_count
        product
        product.clinic_seat_price_amount
        product.clinic_seat_stripe_id
        product.description
        product.id
        product.included_clinic_seats
        product.included_seats
        product.prices
        product.seat_price_amount
        product.seat_stripe_id
        product.stripe_id
        product.title
        recurring
        stripe_id
        title
    `,
};
