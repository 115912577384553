import moment from "moment-timezone";

import { gen_error, gen_received, gen_requested } from "./generators";
import { getUnreadMessagesFromConversations, useExistingConversation } from "../../utils/chat";
import { conversationFragment, conversationMessageFragment } from "../fragments/chat";
import { sidebarPatientFragment } from "../fragments/chat";
import { get, post } from "../../utils/network";

export const checkPatientUnreadMessages = (conversations) => async (dispatch, getState) => {
    const {
        me: { id: myId },
        chat: { chatboxOpen },
    } = getState();
    const unreadMessages = getUnreadMessagesFromConversations(conversations, myId);
    const messageIds = unreadMessages.length && unreadMessages.map((m) => m.id);

    if (chatboxOpen && messageIds && messageIds.length) {
        dispatch(viewChatMessages(messageIds));
    }
};

export const REQUEST_CREATE_CHAT_MESSAGE = "REQUEST_CREATE_CHAT_MESSAGE";
export const RECEIVE_CREATE_CHAT_MESSAGE = "RECEIVE_CREATE_CHAT_MESSAGE";
export const RECEIVE_CREATE_CHAT_MESSAGE_ERROR = "RECEIVE_CREATE_CHAT_MESSAGE_ERROR";
export const createChatMessage = (messageData, options = {}) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_CHAT_MESSAGE));

        const {
            me: { is_coach },
            chat: { sidebarConversations },
        } = getState();
        let conversationsToSort = {};
        const conversationBody = {};

        if (is_coach) {
            // find selected patient's latest conversation
            const {
                chat: { selectedPatientId },
            } = getState();

            if (!selectedPatientId) throw new Error("Requires selected patient");

            // add patient participant to conversation body
            conversationBody.recipient = selectedPatientId;

            const selectedPatientConversationIds = Object.keys(sidebarConversations).filter((c) =>
                sidebarConversations[c].participants.some((p) => p.id === selectedPatientId)
            );

            if (selectedPatientConversationIds.length) {
                // patient has existing conversations, find latest
                conversationsToSort = selectedPatientConversationIds.reduce(
                    (acc, val) => ({ ...acc, [val]: sidebarConversations[val] }),
                    {}
                );
            }
        } else {
            // just use all conversations since user is a patient
            conversationsToSort = sidebarConversations;
        }

        const keys = Object.keys(conversationsToSort);
        const latestConversationId = Object.keys(conversationsToSort).reduce(
            (acc, val) => (conversationsToSort[acc].modified < conversationsToSort[val].modified ? val : acc),
            keys.length > 0 ? keys[0] : 0
        );

        let returnData;
        if (latestConversationId && useExistingConversation(conversationsToSort[latestConversationId])) {
            // use existing conversation
            const result = await post(
                `/api/v3/conversations/${conversationsToSort[latestConversationId].id}/message/`,
                messageData,
                { params: conversationMessageFragment }
            );

            returnData = {
                ...sidebarConversations[latestConversationId],
                messages: [result.data, ...sidebarConversations[latestConversationId].messages],
            };
        } else {
            // create new conversation with body
            const conversationData = {
                ...conversationBody,
                messages: [],
                body: messageData.body,
            };
            const result = await post("/api/v3/conversations/", conversationData, { params: conversationFragment });

            returnData = result.data;
        }

        return dispatch(gen_received(RECEIVE_CREATE_CHAT_MESSAGE, { data: returnData }));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_CHAT_MESSAGE_ERROR, error));
    }
};

export const REQUEST_COACH_SIDEBAR_PATIENT_CONVERSATIONS = "REQUEST_COACH_SIDEBAR_PATIENT_CONVERSATIONS";
export const RECEIVE_COACH_SIDEBAR_PATIENT_CONVERSATIONS = "RECEIVE_COACH_SIDEBAR_PATIENT_CONVERSATIONS";
export const RECEIVE_COACH_SIDEBAR_PATIENT_CONVERSATIONS_ERROR = "RECEIVE_COACH_SIDEBAR_PATIENT_CONVERSATIONS_ERROR";
export const getCoachSidebarPatientConversations = (patientId, options = {}) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_COACH_SIDEBAR_PATIENT_CONVERSATIONS));

        const {
            me: { id: myId },
        } = getState();
        const params = { ...conversationFragment, ordering: "-created" };
        const result = await get(`/api/v3/conversations/?participants=${patientId}`, { params });

        return dispatch(gen_received(RECEIVE_COACH_SIDEBAR_PATIENT_CONVERSATIONS, { data: { ...result.data, myId } }));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_COACH_SIDEBAR_PATIENT_CONVERSATIONS_ERROR, error));
    }
};

export const REQUEST_COACH_SIDEBAR_RECENT_CONVERSATIONS = "REQUEST_COACH_SIDEBAR_RECENT_CONVERSATIONS";
export const RECEIVE_COACH_SIDEBAR_RECENT_CONVERSATIONS = "RECEIVE_COACH_SIDEBAR_RECENT_CONVERSATIONS";
export const RECEIVE_COACH_SIDEBAR_RECENT_CONVERSATIONS_ERROR = "RECEIVE_COACH_SIDEBAR_RECENT_CONVERSATIONS_ERROR";
export const getCoachSidebarRecentConversations = () => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_COACH_SIDEBAR_RECENT_CONVERSATIONS));

        const {
            me: { id: myId },
        } = getState();
        const params = {
            page_size: 50,
            ordering: "-created",
            ...conversationFragment,
        };
        const result = await get(`/api/v3/conversations/?participants=${myId}`, { params });

        return dispatch(gen_received(RECEIVE_COACH_SIDEBAR_RECENT_CONVERSATIONS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_COACH_SIDEBAR_RECENT_CONVERSATIONS_ERROR, error));
    }
};

export const REQUEST_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS = "REQUEST_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS";
export const RECEIVE_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS = "RECEIVE_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS";
export const RECEIVE_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS_ERROR =
    "RECEIVE_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS_ERROR";
export const getCoachSidebarUnansweredConversations = () => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS));

        const { me } = getState();
        const params = {
            page_size: 0,
            ordering: "-created",
            created__gte: moment().subtract(14, "days").startOf("day").format(),
            ...conversationFragment,
        };
        const result = await get("/api/v3/conversations/?no_coach_inside=true", { params });

        return dispatch({
            type: RECEIVE_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS,
            data: result.data,
            me,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS_ERROR, error));
    }
};

export const getConversation = (conversationId) => async (dispatch, getState) => {
    const result = await get(`/api/v3/conversations/${conversationId}/`, { params: conversationFragment });
    const fn = getState().me.is_coach ? getCoachConversation : getPatientConversation;

    return dispatch(fn(result));
};

export const REQUEST_GET_COACH_CONVERSATION = "REQUEST_GET_COACH_CONVERSATION";
export const RECEIVE_GET_COACH_CONVERSATION = "RECEIVE_GET_COACH_CONVERSATION";
export const RECEIVE_GET_COACH_CONVERSATION_ERROR = "RECEIVE_GET_COACH_CONVERSATION_ERROR";
export const getCoachConversation = (conversationResult) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_GET_COACH_CONVERSATION));

        const { me } = getState();

        return dispatch(gen_received(RECEIVE_GET_COACH_CONVERSATION, { data: { ...conversationResult.data, me } }));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_COACH_CONVERSATION_ERROR, error));
    }
};

export const REQUEST_GET_PATIENT_CONVERSATION = "REQUEST_GET_PATIENT_CONVERSATION";
export const RECEIVE_GET_PATIENT_CONVERSATION = "RECEIVE_GET_PATIENT_CONVERSATION";
export const RECEIVE_GET_PATIENT_CONVERSATION_ERROR = "RECEIVE_GET_PATIENT_CONVERSATION_ERROR";
export const getPatientConversation = (conversationResult) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_GET_PATIENT_CONVERSATION));

        const {
            me: { id: myId },
        } = getState();

        return dispatch({ type: RECEIVE_GET_PATIENT_CONVERSATION, data: conversationResult.data, myId });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_GET_PATIENT_CONVERSATION_ERROR, error));
    }
};

export const REQUEST_GET_PATIENT_SIDEBAR_CONVERSATIONS = "REQUEST_GET_PATIENT_SIDEBAR_CONVERSATIONS";
export const RECEIVE_GET_PATIENT_SIDEBAR_CONVERSATIONS = "RECEIVE_GET_PATIENT_SIDEBAR_CONVERSATIONS";
export const RECEIVE_GET_PATIENT_SIDEBAR_CONVERSATIONS_ERROR = "RECEIVE_GET_PATIENT_SIDEBAR_CONVERSATIONS_ERROR";
export const getPatientSidebarConversations = (options = {}) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_GET_PATIENT_SIDEBAR_CONVERSATIONS));

        const result = await get("/api/v3/conversations/", options);
        const {
            me: { id: myId },
        } = getState();

        dispatch({ type: RECEIVE_GET_PATIENT_SIDEBAR_CONVERSATIONS, data: result.data, myId, receivedAt: new Date() });
    } catch (error) {
        dispatch(gen_error(RECEIVE_GET_PATIENT_SIDEBAR_CONVERSATIONS_ERROR, error));
    }
};

export const REQUEST_SELECT_COACH_SIDEBAR_PATIENT = "REQUEST_SELECT_COACH_SIDEBAR_PATIENT";
export const RECEIVE_SELECT_COACH_SIDEBAR_PATIENT = "RECEIVE_SELECT_COACH_SIDEBAR_PATIENT";
export const RECEIVE_SELECT_COACH_SIDEBAR_PATIENT_ERROR = "RECEIVE_SELECT_COACH_SIDEBAR_PATIENT_ERROR";
export const selectCoachSidebarPatient = (patientId) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_SELECT_COACH_SIDEBAR_PATIENT));
        let patient;

        if (patientId) {
            patient = await get(`/api/v3/patients/${patientId}/`, { params: sidebarPatientFragment });
        }

        return dispatch({
            type: RECEIVE_SELECT_COACH_SIDEBAR_PATIENT,
            data: {
                patient: patient && patient.data,
            },
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SELECT_COACH_SIDEBAR_PATIENT_ERROR, error));
    }
};

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const toggleSidebar = () => (dispatch) => {
    dispatch({
        type: TOGGLE_SIDEBAR,
    });
};

export const SET_CHATBOX_OPEN = "SET_CHATBOX_OPEN";
export const setChatboxOpen = (value) => (dispatch, getState) => {
    dispatch({
        type: SET_CHATBOX_OPEN,
        data: value,
        sidebarConversations: getState().chat.sidebarConversations,
    });
};

export const REQUEST_VIEW_CHAT_MESSAGES = "REQUEST_VIEW_CHAT_MESSAGES";
export const RECEIVE_VIEW_CHAT_MESSAGES = "RECEIVE_VIEW_CHAT_MESSAGES";
export const RECEIVE_VIEW_CHAT_MESSAGES_ERROR = "RECEIVE_VIEW_CHAT_MESSAGES_ERROR";
export const viewChatMessages = (messageIds = []) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_VIEW_CHAT_MESSAGES));

        const result = await post("/api/v3/messages/visit/", { messages: messageIds });

        return dispatch({ type: RECEIVE_VIEW_CHAT_MESSAGES, data: result.data, messageIds });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_VIEW_CHAT_MESSAGES_ERROR, error));
    }
};
