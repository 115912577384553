import { exq } from "../../utils/helpers";

export const meetingsListFragment = {
    expand: exq`
        coach
        patient
    `,
    fields: exq`
        coach.email
        coach.first_name
        coach.id
        coach.is_coach
        coach.last_name
        coach.profile_image
        created
        id
        interaction_type
        opentok_session_id
        on_demand
        patient
        patient.email
        patient.first_name
        patient.id
        patient.last_name
        patient.online
        start_time
        status
        subject
        type
    `,
};

export const videoSessionParams = {
    params: {
        expand: exq`
            coach
            patient
            status_changed_by
        `,
        fields: exq`
            id
            on_demand
            opentok_token
            opentok_session_id
            patient_joined
            status
            start_time
            coach.first_name
            coach.profile_image
            coach.bio
            coach.preferred_name
            patient.first_name
            patient.id
            patient.preferred_name
            status_changed_by.is_coach
        `,
    },
};
