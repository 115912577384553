import React from "react";
import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string,
};

export default function RHFSelect({ name, children, defaultValue, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                const value = field.value ? field.value : field[name] ? field[name] : "";

                return (
                    <TextField
                        {...field}
                        select
                        fullWidth
                        size="small"
                        value={value}
                        defaultValue={defaultValue}
                        SelectProps={{ native: true }}
                        error={!!error}
                        helperText={error?.message}
                        {...other}
                    >
                        {children}
                    </TextField>
                );
            }}
        />
    );
}
