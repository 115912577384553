import "babel-polyfill";
import "@fortawesome/fontawesome-free/css/all.css";
import "./styles/index.scss";
import "./utils/highlight";

import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import MuiThemeProvider from "./styles/mui-theme";

import App from "./App";
import configureStore from "./app/store";
import StyledComponentsThemeProvider from "./styles/styled-components-theme";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";

// import store from './app/store';
export const store = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MuiThemeProvider>
                        <StyledComponentsThemeProvider>
                            <CollapseDrawerProvider>
                                <App />
                            </CollapseDrawerProvider>
                        </StyledComponentsThemeProvider>
                    </MuiThemeProvider>
                </LocalizationProvider>
            </Provider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
