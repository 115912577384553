// export const BASENAME_ROUTE = '/v3';
//
// export const COACH_APP_ROUTE = '/coach';
// export const PATIENT_APP_ROUTE = '/patient';

export const ARTICLE_ROUTE = "/v3/patient/article/:id";
export const FORGOT_PASSWORD_ROUTE = "/v3/forgot-password";
export const HOME_ROUTE = "/v3/patient";
export const LOGIN_ROUTE = "/v3/login";
// export const MY_ROUTINE_ROUTE = "/v3/patient/my-routine";
export const CLIENT_ROUTINE_ROUTE = "/v3/patient/my-routine";
export const MY_ACCOUNT_ROUTE = "/v3/patient/account";
export const ON_DEMAND_WAITING_ROOM_ROUTE = "/v3/patient/finding-coach";
export const PROVIDER_SIGNUP_ROUTE = "/v3/provider-signup";
export const RESET_PASSWORD_ROUTE = "/v3/reset-password";
export const RESOURCES_ROUTE = "/v3/patient/resources";
export const SAML_LANDING_ROUTE = "/v3/saml-landing";
export const SCHEDULE_MEETING_ROUTE = "/v3/patient/schedule";
export const INVITE_SIGNUP_ROUTE_PUBLIC = "/v3/invitation";
export const INVITE_SIGNUP_ROUTE = "/v3/invitation/:uuid";
export const SIGNUP_ROUTE = "/v3/signup";
export const DTC_SIGNUP_ROUTE = "/v3/dtc-signup";
export const STRIPE_CHECKOUT_FAILURE_ROUTE = "/v3/patient/checkout-fail";
export const STRIPE_CHECKOUT_SUCCESS_ROUTE = "/v3/patient/checkout-success";
export const VIDEO_SESSION_ROUTE = "/v3/patient/video-session";

// v2
export const V2_HOME_ROUTE = "/";
export const V2_COACHING_ROUTE = "/coaching";
export const V2_COACH_CALENDAR_ROUTE = "/coach/#/calendar";

// surveys
export const ONBOARDING_SURVEY_ROUTE = "/v3/patient/onboarding";
export const TIME_BASED_SURVEY_ROUTE = "/v3/patient/health-assessment";
export const TELESPINE_INTRODUCTION_SURVEY_ROUTE = "/v3/patient/telespine-introduction";
export const PROGRESS_SURVEY_ROUTE = "/v3/patient/progress-satisfaction";
export const ABANDON_SURVEY_ROUTE = "/v3/patient/abandon-survey";
export const FINAL_SURVEY_ROUTE = "/v3/patient/final-survey";

// Coach
export const COACH_CALENDAR_ROUTE = "/v3/coach/calendar";
export const COACH_CLIENT_DETAILS_ROUTE = "/v3/coach/clients/:clientId";
export const COACH_CLIENT_MANAGEMENT_ROUTE = "/v3/coach/clients";
export const COACH_HOME_ROUTE = "/v3/coach";
export const COACH_KANBAN_ROUTE = "/v3/coach/kanban";
export const COACH_MANAGE_ORGANIZATION_ROUTE = "/v3/coach/organization";
export const COACH_MESSAGE_CENTER_ROUTE = "/v3/coach/message-center";
export const COACH_MESSAGE_CENTER_BOX_ROUTE = "/v3/coach/message-center/:systemLabel";
export const COACH_MESSAGE_CENTER_EMAIL_ROUTE = "/v3/coach/message-center/:systemLabel/:mailId";
export const COACH_MY_ACCOUNT_ROUTE = "/v3/coach/account";
export const COACH_LEAD_DETAILS_ROUTE = "/v3/coach/leads/:leadId";
export const COACH_OUTREACH_ROUTE = "/v3/coach/outreach";
export const COACH_PATIENT_DETAILS_ROUTE = "/v3/coach/patients/:patientId";
export const COACH_PATIENTS_ROUTE = "/v3/coach/patients";
export const COACH_PROGRAM_BUILDER_ROUTE = "/v3/coach/program-builder";
export const COACH_REPORTS_ROUTE = "/v3/coach/reports";
export const COACH_RESOURCES_ROUTE = "/v3/coach/resources";
export const COACH_VIDEO_PREVIEW = "/v3/coach/video-preview/:videoId";
export const COACH_VIDEO_TEST = "/v3/coach/video-test";
export const COACH_VIDEO_SESSION_ROUTE = "/v3/coach/video-session";

// MFR
export const PROVIDER_CLINIC_PROFILE_ROUTE = "/clinic/:clinicId";
export const PROVIDER_CLINIC_ROOT = "/clinic/";
export const PROVIDER_MAP_RESULTS_ROUTE = "/providers";
export const PROVIDER_PROFILE_ROUTE = "/provider/:providerId";
export const PROVIDER_PROFILE_SLUG_ROUTE = "/provider/:providerId/:providerSlug";
export const PROVIDER_RESULT_ROUTE = "/provider/";
export const PROVIDER_SEARCH_ROUTE = "/search";

export const PAGE_TITLES = [
    { path: ABANDON_SURVEY_ROUTE, title: "abandon_survey" },
    { path: ARTICLE_ROUTE, title: "articles" },
    { path: COACH_CALENDAR_ROUTE, title: "coach_calendar" },
    { path: COACH_CLIENT_MANAGEMENT_ROUTE, title: "coach_client_management" },
    { path: COACH_HOME_ROUTE, title: "coach_homepage" },
    { path: COACH_KANBAN_ROUTE, title: "coach_kanban" },
    { path: COACH_MY_ACCOUNT_ROUTE, title: "coach_my_account" },
    { path: COACH_MANAGE_ORGANIZATION_ROUTE, title: "coach_manage_organization" },
    { path: COACH_PATIENT_DETAILS_ROUTE, title: "coach_patient_details" },
    { path: COACH_PATIENTS_ROUTE, title: "coach_patients" },
    { path: COACH_PROGRAM_BUILDER_ROUTE, title: "coach_program_builder" },
    { path: COACH_REPORTS_ROUTE, title: "coach_reports" },
    { path: COACH_VIDEO_PREVIEW, title: "coach_video_preview" },
    { path: COACH_VIDEO_SESSION_ROUTE, title: "coach_video_session" },
    { path: DTC_SIGNUP_ROUTE, title: "dtc_signup" },
    { path: FINAL_SURVEY_ROUTE, title: "final_survey" },
    { path: FORGOT_PASSWORD_ROUTE, title: "forgot_password" },
    { path: HOME_ROUTE, title: "homepage" },
    { path: LOGIN_ROUTE, title: "login" },
    { path: MY_ACCOUNT_ROUTE, title: "my_account" },
    { path: CLIENT_ROUTINE_ROUTE, title: "my_routine" },
    { path: ONBOARDING_SURVEY_ROUTE, title: "onboarding" },
    { path: ON_DEMAND_WAITING_ROOM_ROUTE, title: "waiting_room" },
    { path: PROGRESS_SURVEY_ROUTE, title: "progress_satisfaction" },
    { path: RESET_PASSWORD_ROUTE, title: "reset_password" },
    { path: RESOURCES_ROUTE, title: "resources" },
    { path: SAML_LANDING_ROUTE, title: "homepage" },
    { path: SCHEDULE_MEETING_ROUTE, title: "scheduling" },
    { path: SIGNUP_ROUTE, title: "signup" },
    { path: PROVIDER_SIGNUP_ROUTE, title: "provider_signup" },
    { path: INVITE_SIGNUP_ROUTE, title: "invite_signup" },
    { path: STRIPE_CHECKOUT_FAILURE_ROUTE, title: "stripe_checkout_failure" },
    { path: STRIPE_CHECKOUT_SUCCESS_ROUTE, title: "stripe_checkout_success" },
    { path: TELESPINE_INTRODUCTION_SURVEY_ROUTE, title: "telespine_intro_survey" },
    { path: TIME_BASED_SURVEY_ROUTE, title: "time_based_survey" },
    { path: VIDEO_SESSION_ROUTE, title: "video_session" },
];

export function getPageTitle(path) {
    const page = PAGE_TITLES.find((page) => {
        if (page.path.includes("/:")) {
            const pathRoot = page.path.split(":")[0];
            const regex = new RegExp(`^(${pathRoot}[0-9]+).*$`);

            if (regex.test(path)) return page.title;
        }

        return page.path === path;
    });

    return page && page.title;
}
