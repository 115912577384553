import logger from "./logger";

export function requestNotificationPermissions() {
    const requestStatusList = ["default", "denied"];

    if (window.Notification && requestStatusList.includes(window.Notification.permission)) {
        Notification.requestPermission();
    }
}

export function showNotification(title, body, options = {}) {
    const { noSound, soundFile, withWait, ...rest } = options;
    const audioPlayer = new Audio(soundFile);
    const showFallbackNotification = () => logger.info("Tried to send browser notification but failed");
    const displayNotification = () => {
        const createNotification = () => {
            new Notification(title, { body, ...rest });

            if (!noSound) audioPlayer.play();
        };

        if (withWait) {
            // sometimes a slight wait is needed because too much else is going on
            setTimeout(() => {
                createNotification();
            }, 200);
        } else {
            createNotification();
        }
    };

    if (window.Notification && Notification.permission === "granted") {
        // already permitted
        displayNotification();
    } else if (window.Notification && Notification.permission !== "denied") {
        Notification.requestPermission(function (status) {
            if (status === "granted") {
                // user said okay
                displayNotification();
            } else {
                // fallback
                showFallbackNotification();
            }
        });
    } else {
        // user refuses to get notified or Notification API not available
        showFallbackNotification();
    }
}
