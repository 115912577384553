import React from "react";
import cx from "classnames";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { CHAT_CHARACTER_LIMIT, REQUIRED_FIELD } from "../../utils/validations";
import { createChatMessage, updatePatient } from "../../app/actions";
import { conversationMessageFragment } from "../../app/fragments/chat";
import { exq, getProviderCopy } from "../../utils/helpers";
import { isEnterKey, isWorkingHour } from "../../utils/helpers";

import IconButton from "../button/IconButton";
import { toast } from "../notifications/Toast";
import TextInput from "../text_input/TextInput";

const formSchema = yup.object().shape({
    message: yup.string().required(REQUIRED_FIELD.message),
});

export const chatMessageInputId = "ts-chat-message-input";

export default function ChatMessageInput() {
    const dispatch = useDispatch();
    const me = useSelector(({ me }) => me);
    const { is_coach, id: userId } = useSelector(({ me }) => me);
    const chat = useSelector((state) => state.chat);
    const workingHour = isWorkingHour();

    const handleKeyDown = () => (event) => {
        if (isEnterKey(event)) {
            event.preventDefault();
        }
    };

    const handleKeyUp = (actions) => (event) => {
        if (isEnterKey(event)) {
            event.preventDefault();
            actions.handleSubmit();
        }
    };

    const handleFormSubmit = async (values, { resetForm }) => {
        if (values.message.length > CHAT_CHARACTER_LIMIT.value) {
            toast.error(CHAT_CHARACTER_LIMIT.message);
            return;
        }

        resetForm();
        const { createChatMessageIsLoading } = chat;

        if (!createChatMessageIsLoading) {
            const newMessage = {
                body: values.message,
            };

            const result = await dispatch(createChatMessage(newMessage, { params: conversationMessageFragment }));
            const { error } = result;

            if (error) return toast.error(error.toString());

            // If the sender is a coach, check to see if the patient has an owner
            // If they don't, make the coach that replied the owner
            if (is_coach) {
                const { coachSidebarPatients, selectedPatientId } = chat;
                const currentPatient = coachSidebarPatients.find((patient) => patient.id === selectedPatientId);

                if (!currentPatient.owner) {
                    const values = {
                        owner: userId,
                    };
                    const params = {
                        expand: exq`owner`,
                        fields: exq`owner id`,
                    };

                    const patientUpdateResults = await dispatch(updatePatient(selectedPatientId, values, { params }));
                    const { error: patientUpdateError } = patientUpdateResults;

                    if (patientUpdateError) {
                        toast.error("Error assigning patient owner");
                    }
                }
            }
        }
    };

    return (
        <div className="p-2">
            {!is_coach && !workingHour && (
                <div className={cx("alert alert-info", "mb-2", "text-sm", "px-2 py-1")}>
                    {getProviderCopy(me.branding, "chat_out_of_office")}
                </div>
            )}
            <div className={cx("ts-chat-message-input", "position-relative", "bg-white", "pl-3 pr-5")}>
                <Formik
                    initialValues={{
                        message: "",
                    }}
                    onSubmit={handleFormSubmit}
                    validationSchema={formSchema}
                >
                    {({ values, handleChange, handleSubmit }) => {
                        const commonInputProps = {
                            id: chatMessageInputId,
                            name: "message",
                            bare: true,
                            multiline: true,
                            placeholder: "Send a new message...",
                            onChange: handleChange,
                            value: values.message,
                            required: true,
                            onKeyUp: handleKeyUp({ handleSubmit }),
                            onKeyDown: handleKeyDown(),
                        };

                        return (
                            <Form>
                                <TextInput containerClassName="d-lg-none" {...commonInputProps} />

                                {/* autofocus desktop only because mobile keyboard bugs on iOS */}
                                <TextInput containerClassName="d-none d-lg-block" autoFocus {...commonInputProps} />

                                <IconButton className={cx("send-button")} secondary type="submit">
                                    <i className={cx("fas fa-arrow-up fa-lg")} />
                                </IconButton>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}
