import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip, TextField, Typography } from "@mui/material";

import { clearCityList, getCityList } from "../../app/actions";

import { toast } from "../notifications/Toast";

export default function CitySelector({ prefillCity, handleSelectCity, errors }) {
    const dispatch = useDispatch();
    const mfr = useSelector(({ mfr }) => mfr);
    const [inputActivated, setInputActivated] = useState(false);
    const [isSearchingTags, setIsSearchingTags] = useState(false);
    const [inputValue, setInputValue] = useState(prefillCity);

    useEffect(() => {
        const credsFilterTimer = setTimeout(() => {
            if (isSearchingTags && inputValue && inputValue.length > 1) {
                findCity(inputValue);
            }
        }, 500);

        return () => {
            clearTimeout(credsFilterTimer);
        };
    }, [inputValue]); //eslint-disable-line

    async function findCity(city) {
        const result = await dispatch(getCityList(city));
        const { error } = result;

        if (error) {
            toast.error("Error finding city");
        }
    }

    const selectCity = (city) => {
        dispatch(clearCityList());
        handleSelectCity(city);
        setInputValue(city.name);
        setIsSearchingTags(false);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setIsSearchingTags(true);
        setInputActivated(true);
    };

    return (
        <>
            <div className="flex-column">
                <TextField
                    name="City"
                    label="City"
                    className="rounded-pill outline-none"
                    placeholder="City..."
                    variant="outlined"
                    onChange={handleInputChange}
                    value={inputValue}
                    error={!!errors.city}
                    fullWidth
                    sx={{
                        "& legend": { display: "none" },
                        "& label": { top: "-7px" },
                        "& fieldset": { top: 0 },
                        "& input": { p: "8.5px 14px", height: "23px" },
                        width: "100%",
                    }}
                />
            </div>
            {inputActivated && mfr && mfr.cityList && mfr.cityList.length > 0 && (
                <Typography variant="overline" sx={{ px: 2 }}>
                    Select a city:
                </Typography>
            )}
            {inputActivated && mfr && mfr.cityList && mfr.cityList.length > 0 && (
                <div className="ts-city-suggestions px-3 py-2 flex-column">
                    {mfr &&
                        mfr.cityList.map((city, idx) => (
                            <Chip
                                key={idx}
                                size="small"
                                label={`${city.name}, ${
                                    city && city.state ? city.state : city.state_name ? city.state_name : ""
                                }${city.state || city.state_name ? "," : ""}  ${city.country}`}
                                sx={{ m: 0.5 }}
                                onClick={() => selectCity(city)}
                            />
                        ))}
                </div>
            )}
            {errors.city && (
                <Typography variant="caption" color="error" sx={{ margin: "8px 14px 0px 14px" }}>
                    {errors.city.message}
                </Typography>
            )}
        </>
    );
}
