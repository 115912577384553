import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, MenuItem } from "@mui/material";

import { copyToClipboard, getPublicImage } from "../../utils/helpers";
import { createClinicShareDataPoint, createProfileShareDataPoint } from "../../app/actions";

import ButtonMain from "../button/ButtonMain";
import MenuPopover from "../minimal/MenuPopover";
import SvgIconStyle from "../minimal/SvgIconStyle";

export default function ShareButton({ profileId, clinic = false }) {
    const dispatch = useDispatch();
    const [showShareMenu, setShowShareMenu] = useState(false);

    const openShareMenu = (event) => {
        setShowShareMenu(event.currentTarget);
    };

    const copyLink = () => {
        copyToClipboard(window.location.href);
        if (clinic) {
            dispatch(createClinicShareDataPoint(clinic, "link"));
        } else {
            dispatch(createProfileShareDataPoint(profileId, "link"));
        }
        closeShareMenu();
    };

    const closeShareMenu = () => {
        setShowShareMenu(null);
    };

    const menuItemClick = (menuItem) => () => {
        if (clinic) {
            dispatch(createClinicShareDataPoint(clinic, menuItem));
        } else {
            dispatch(createProfileShareDataPoint(profileId, menuItem));
        }
    };

    return (
        <>
            <ButtonMain
                compact
                analyticsClass="button__share-provider-profile"
                className="flex-center"
                onClick={openShareMenu}
            >
                <SvgIconStyle src={getPublicImage("ic_share.svg")} sx={{ mr: 1 }} />
                Share
            </ButtonMain>
            <MenuPopover
                open={Boolean(showShareMenu)}
                anchorEl={showShareMenu}
                onClose={closeShareMenu}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    "& .MuiMenuItem-root": {
                        typography: "body2",
                        borderRadius: 0.75,
                    },
                }}
                className="ts-user-dropdown"
            >
                <Box className="my-2">
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdirectory.mfrtherapists.com%2F${
                            clinic ? "clinic" : "provider"
                        }%2F${profileId ? profileId : "2"}&amp;src=sdkpreparse`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ts-share-link"
                    >
                        <MenuItem
                            className="d-flex justify-content-start border-radius-none py-2 w-100"
                            onClick={menuItemClick("facebook")}
                        >
                            <SvgIconStyle
                                src={getPublicImage("ic_facebook_logo.svg")}
                                sx={{ width: "24px", height: "24px", mr: 2 }}
                            />
                            Facebook
                        </MenuItem>
                    </a>
                    <a
                        href={`https://twitter.com/intent/tweet?text=https%3A%2F%2Fdirectory.mfrtherapists.com%2F${
                            clinic ? "clinic" : "provider"
                        }%2F${profileId ? profileId : "2"}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ts-share-link"
                    >
                        <MenuItem
                            className="d-flex justify-content-start border-radius-none py-2 w-100"
                            onClick={menuItemClick("twitter")}
                        >
                            <SvgIconStyle
                                src={getPublicImage("ic_twitter_logo.svg")}
                                sx={{ width: "24px", height: "24px", mr: 2 }}
                            />
                            Twitter
                        </MenuItem>
                    </a>
                    <a
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fdirectory.mfrtherapists.com%2F${
                            clinic ? "clinic" : "provider"
                        }%2F${profileId ? profileId : "2"}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ts-share-link"
                    >
                        <MenuItem
                            className="d-flex justify-content-start border-radius-none py-2 w-100"
                            onClick={menuItemClick("linkedin")}
                        >
                            <SvgIconStyle
                                src={getPublicImage("ic_linkedin_logo.svg")}
                                sx={{ width: "24px", height: "24px", mr: 2 }}
                            />
                            Linkedin
                        </MenuItem>
                    </a>
                    <MenuItem className="d-flex justify-content-start border-radius-none py-2 w-100" onClick={copyLink}>
                        <SvgIconStyle
                            src={getPublicImage("ic_link.svg")}
                            sx={{ width: "24px", height: "24px", mr: 2 }}
                        />
                        Copy link
                    </MenuItem>
                </Box>
            </MenuPopover>
        </>
    );
}
