import { gen_error, gen_received, gen_requested } from "./generators";

import { axiosDelete, get, patch, post } from "../../utils/network";

export const REQUEST_CREATE_NEW_PROGRAM = "REQUEST_CREATE_NEW_PROGRAM";
export const RECEIVE_CREATE_NEW_PROGRAM = "RECEIVE_CREATE_NEW_PROGRAM";
export const RECEIVE_CREATE_NEW_PROGRAM_ERROR = "RECEIVE_CREATE_NEW_PROGRAM_ERROR";
export const createNewProgram = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_NEW_PROGRAM));

        const result = await post("/api/v4/programs/", options);

        return dispatch(gen_received(RECEIVE_CREATE_NEW_PROGRAM, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_NEW_PROGRAM_ERROR, error));
    }
};

export const REQUEST_CREATE_NEW_ROUTINE_TEMPLATE = "REQUEST_CREATE_NEW_ROUTINE_TEMPLATE";
export const RECEIVE_CREATE_NEW_ROUTINE_TEMPLATE = "RECEIVE_CREATE_NEW_ROUTINE_TEMPLATE";
export const RECEIVE_CREATE_NEW_ROUTINE_TEMPLATE_ERROR = "RECEIVE_CREATE_NEW_ROUTINE_TEMPLATE_ERROR";
export const createNewRoutineTemplate = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_NEW_ROUTINE_TEMPLATE));

        const result = await post("/api/v4/programs/", options);

        return dispatch(gen_received(RECEIVE_CREATE_NEW_ROUTINE_TEMPLATE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_NEW_ROUTINE_TEMPLATE_ERROR, error));
    }
};

export const REQUEST_DUPLICATE_PROGRAM_TEMPLATE = "REQUEST_DUPLICATE_PROGRAM_TEMPLATE";
export const RECEIVE_DUPLICATE_PROGRAM_TEMPLATE = "RECEIVE_DUPLICATE_PROGRAM_TEMPLATE";
export const RECEIVE_DUPLICATE_PROGRAM_TEMPLATE_ERROR = "RECEIVE_DUPLICATE_PROGRAM_TEMPLATE_ERROR";
export const duplicateProgramTemplate = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_DUPLICATE_PROGRAM_TEMPLATE));

        const result = await post("/api/v4/programs/", options);

        return dispatch(gen_received(RECEIVE_DUPLICATE_PROGRAM_TEMPLATE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_DUPLICATE_PROGRAM_TEMPLATE_ERROR, error));
    }
};

export const REQUEST_RESOURCE_SEARCH = "REQUEST_RESOURCE_SEARCH";
export const RECEIVE_RESOURCE_SEARCH = "RECEIVE_RESOURCE_SEARCH";
export const RECEIVE_RESOURCE_SEARCH_ERROR = "RECEIVE_RESOURCE_SEARCH_ERROR";
export const searchResources = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_RESOURCE_SEARCH));

        const result = await get("/api/v3/exercises/", params);

        return dispatch(gen_received(RECEIVE_RESOURCE_SEARCH, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_RESOURCE_SEARCH_ERROR, error));
    }
};

export const REQUEST_UPDATE_EXISTING_PROGRAM = "REQUEST_UPDATE_EXISTING_PROGRAM";
export const RECEIVE_UPDATE_EXISTING_PROGRAM = "RECEIVE_UPDATE_EXISTING_PROGRAM";
export const RECEIVE_UPDATE_EXISTING_PROGRAM_ERROR = "RECEIVE_UPDATE_EXISTING_PROGRAM_ERROR";
export const updateExistingProgram = (id, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_EXISTING_PROGRAM));

        const result = await patch(`/api/v4/programs/${id}/`, options);

        return dispatch(gen_received(RECEIVE_UPDATE_EXISTING_PROGRAM, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_EXISTING_PROGRAM_ERROR, error));
    }
};

export const ADD_EDITING_LIST_TO_RESOURCES_LIST = "ADD_EDITING_LIST_TO_RESOURCES_LIST";
export const addEditingListToResourcesList = (title, notes) => (dispatch) => {
    dispatch({
        type: ADD_EDITING_LIST_TO_RESOURCES_LIST,
        title,
        notes,
    });
};

export const REQUEST_ADD_RESOURCE_TO_ROUTINE_TEMPLATE = "REQUEST_ADD_RESOURCE_TO_ROUTINE_TEMPLATE";
export const RECEIVE_ADD_RESOURCE_TO_ROUTINE_TEMPLATE = "RECEIVE_ADD_RESOURCE_TO_ROUTINE_TEMPLATE";
export const RECEIVE_ADD_RESOURCE_TO_ROUTINE_TEMPLATE_ERROR = "RECEIVE_ADD_RESOURCE_TO_ROUTINE_TEMPLATE_ERROR";
export const addResourceToRoutineTemplate = (body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ADD_RESOURCE_TO_ROUTINE_TEMPLATE));

        const result = await post("/api/v3/resources/", body, { params: { expand: "exercise,article" } });

        return dispatch(gen_received(RECEIVE_ADD_RESOURCE_TO_ROUTINE_TEMPLATE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ADD_RESOURCE_TO_ROUTINE_TEMPLATE_ERROR, error));
    }
};

export const REQUEST_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE = "REQUEST_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE";
export const RECEIVE_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE = "RECEIVE_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE";
export const RECEIVE_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE_ERROR =
    "RECEIVE_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE_ERROR";
export const removeResourceFromTemplateRoutine = (resourceId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE));

        const result = await axiosDelete(`/api/v3/resources/${resourceId}/`);

        return dispatch({
            type: RECEIVE_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE,
            receivedAt: Date.now(),
            id: resourceId,
            result,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE_ERROR, error));
    }
};

export const CANCEL_PROGRAM_CREATION = "CANCEL_PROGRAM_CREATION";
export const cancelProgramCreation = () => (dispatch) => {
    dispatch({
        type: CANCEL_PROGRAM_CREATION,
    });
};

export const CANCEL_RESOURCES_LIST_CHANGES = "CANCEL_RESOURCES_LIST_CHANGES";
export const cancelResourcesListChanges = () => (dispatch) => {
    dispatch({
        type: CANCEL_RESOURCES_LIST_CHANGES,
    });
};

export const CLEAR_ASSIGNED_PROGRAM_SESSIONS = "CLEAR_ASSIGNED_PROGRAM_SESSIONS";
export const clearAssignedProgramSessions = () => (dispatch) => {
    dispatch({
        type: CLEAR_ASSIGNED_PROGRAM_SESSIONS,
    });
};

export const CLEAR_PROGRAM_SESSIONS = "CLEAR_PROGRAM_SESSIONS";
export const clearProgramSessions = () => (dispatch) => {
    dispatch({
        type: CLEAR_PROGRAM_SESSIONS,
    });
};

export const CLEAR_IMPORT_LISTS = "CLEAR_IMPORT_LISTS";
export const clearImportList = () => (dispatch) => {
    dispatch({
        type: CLEAR_IMPORT_LISTS,
    });
};

export const CLEAR_RESOURCE_SEARCH = "CLEAR_RESOURCE_SEARCH";
export const clearSearchResults = () => (dispatch) => {
    dispatch({
        type: CLEAR_RESOURCE_SEARCH,
    });
};

export const CLOSE_EDIT_LIST = "CLOSE_EDIT_LIST";
export const closeEditList = () => (dispatch) => {
    dispatch({
        type: CLOSE_EDIT_LIST,
    });
};

export const IMPORT_SELECTED_LISTS = "IMPORT_SELECTED_LISTS";
export const importSelectedLists = (creating = false) => (dispatch) => {
    dispatch({
        type: IMPORT_SELECTED_LISTS,
        creating,
    });
};

export const REMOVE_LIST_CONTAINER = "REMOVE_LIST_CONTAINER";
export const removeListContainer = (index) => (dispatch) => {
    dispatch({
        type: REMOVE_LIST_CONTAINER,
        index,
    });
};

export const REMOVE_PROGRAM_LIST_CONTAINER = "REMOVE_PROGRAM_LIST_CONTAINER";
export const removeProgramListContainer = (index) => (dispatch) => {
    dispatch({
        type: REMOVE_PROGRAM_LIST_CONTAINER,
        index,
    });
};

export const DUPLICATE_LIST_CONTAINER = "DUPLICATE_LIST_CONTAINER";
export const duplicateListContainer = (index) => (dispatch) => {
    dispatch({
        type: DUPLICATE_LIST_CONTAINER,
        index,
    });
};

export const RESET_TEMPLATE_CREATION = "RESET_TEMPLATE_CREATION";
export const resetTemplateCreation = () => (dispatch) => {
    dispatch({
        type: RESET_TEMPLATE_CREATION,
    });
};

export const RESET_ROUTINE_CREATION = "RESET_ROUTINE_CREATION";
export const resetRoutineCreation = () => (dispatch) => {
    dispatch({
        type: RESET_ROUTINE_CREATION,
    });
};

export const RESET_VIEWING_PROGRAM_TEMPLATE = "RESET_VIEWING_PROGRAM_TEMPLATE";
export const resetViewingProgramTemplate = () => (dispatch) => {
    dispatch({
        type: RESET_VIEWING_PROGRAM_TEMPLATE,
    });
};

export const RESET_PROGRAM_TEMPLATE_IMPORT = "RESET_PROGRAM_TEMPLATE_IMPORT";
export const resetProgramTemplateImport = () => (dispatch) => {
    dispatch({
        type: RESET_PROGRAM_TEMPLATE_IMPORT,
    });
};

export const SELECT_IMPORT_SESSION = "SELECT_IMPORT_SESSION";
export const selectImportSession = (list) => (dispatch) => {
    dispatch({
        type: SELECT_IMPORT_SESSION,
        list,
    });
};

export const COPY_PROGRAM_TEMPLATE_ROUTINE = "COPY_PROGRAM_TEMPLATE_ROUTINE";
export const copyProgramTemplateRoutine = (order) => {
    return {
        type: COPY_PROGRAM_TEMPLATE_ROUTINE,
        order,
    };
};

export const REMOVE_PROGRAM_TEMPLATE_ROUTINE = "REMOVE_PROGRAM_TEMPLATE_ROUTINE";
export const removeProgramTemplateRoutine = (order) => {
    return {
        type: REMOVE_PROGRAM_TEMPLATE_ROUTINE,
        order,
    };
};

export const SET_EDITING_LIST = "SET_EDITING_LIST";
export const setEditingList = (list) => (dispatch) => {
    dispatch({
        type: SET_EDITING_LIST,
        list,
    });
};

export const SET_EDITING_ROUTINE_TEMPLATE = "SET_EDITING_ROUTINE_TEMPLATE";
export const setEditingRoutineTemplate = (list) => (dispatch) => {
    dispatch({
        type: SET_EDITING_ROUTINE_TEMPLATE,
        list,
    });
};

export const SELECT_ROUTINE_FOR_IMPORT = "SELECT_ROUTINE_FOR_IMPORT";
export const selectRoutineForImport = (routine) => (dispatch) => {
    dispatch({
        type: SELECT_ROUTINE_FOR_IMPORT,
        routine,
    });
};

export const CLEAR_SELECTED_IMPORT_ROUTINES = "CLEAR_SELECTED_IMPORT_ROUTINES";
export const clearSelectedImportRoutines = () => (dispatch) => {
    dispatch({
        type: CLEAR_SELECTED_IMPORT_ROUTINES,
    });
};

export const SET_EDITING_PROGRAM_TEMPLATE = "SET_EDITING_PROGRAM_TEMPLATE";
export const setEditingProgramTemplate = (template) => (dispatch) => {
    dispatch({
        type: SET_EDITING_PROGRAM_TEMPLATE,
        template,
    });
};

export const SET_VIEW_IMPORT_PROGRAM_TEMPLATE = "SET_VIEW_IMPORT_PROGRAM_TEMPLATE";
export const setViewImportProgramTemplate = (template) => (dispatch) => {
    dispatch({
        type: SET_VIEW_IMPORT_PROGRAM_TEMPLATE,
        template,
    });
};

export const SET_PROGRAM_EDITING = "SET_PROGRAM_EDITING";
export const setProgramEditing = (isEditingProgram) => (dispatch) => {
    dispatch({
        type: SET_PROGRAM_EDITING,
        isEditingProgram,
    });
};

export const SET_TEMPLATES_PAGINATION_PAGE = "SET_TEMPLATES_PAGINATION_PAGE";
export const setTemplatesPaginationPage = (page) => (dispatch) => {
    dispatch({
        type: SET_TEMPLATES_PAGINATION_PAGE,
        page,
    });
};

export const SET_ASSIGNED_PROGRAMS = "SET_ASSIGNED_PROGRAMS";
export const setAssignedPrograms = (assignedProgramsList) => (dispatch) => {
    dispatch({
        type: SET_ASSIGNED_PROGRAMS,
        assignedProgramsList,
    });
};

export const SET_EDITING_RESOURCE_NOTES = "SET_EDITING_RESOURCE_NOTES";
export const setEditingResourceNotes = (isEditing) => (dispatch) => {
    dispatch({
        type: SET_EDITING_RESOURCE_NOTES,
        isEditing,
    });
};

export const SET_SELECTED_RESOURCES_LIST = "SET_SELECTED_RESOURCES_LIST";
export const setSelectedResourcesList = (list, limit, title) => (dispatch) => {
    dispatch({
        type: SET_SELECTED_RESOURCES_LIST,
        list,
        limit,
        title,
    });
};

export const SET_EDITING_PROGRAM_RESOURCES = "SET_EDITING_PROGRAM_RESOURCES";
export const setEditingProgramResources = (
    list,
    limit,
    programInfo = {},
    editingProgramLists = {},
    activeList = null
) => (dispatch) => {
    dispatch({
        type: SET_EDITING_PROGRAM_RESOURCES,
        list,
        limit,
        programInfo,
        editingProgramLists,
        activeList,
    });
};

export const SET_VIEWING_PROGRAM_RESOURCES = "SET_VIEWING_PROGRAM_RESOURCES";
export const setViewingProgramResources = (list, resources, title) => (dispatch) => {
    dispatch({
        type: SET_VIEWING_PROGRAM_RESOURCES,
        list,
        resources,
        title,
    });
};

export const SET_SESSION_NOTE = "SET_SESSION_NOTE";
export const updateSessionNote = (note) => (dispatch) => {
    dispatch({
        type: SET_SESSION_NOTE,
        note,
    });
};

export const SHIFT_SELECTED_RESOURCES_LIST = "SHIFT_SELECTED_RESOURCES_LIST";
export const shiftSelectedResourcesList = (list) => (dispatch) => {
    dispatch({
        type: SHIFT_SELECTED_RESOURCES_LIST,
        list,
    });
};

export const SHIFT_PROGRAM_SESSION_LIST = "SHIFT_PROGRAM_SESSION_LIST";
export const shiftProgramSessionList = (sessionIndex, session) => (dispatch) => {
    dispatch({
        type: SHIFT_PROGRAM_SESSION_LIST,
        sessionIndex,
        session,
    });
};

export const SHIFT_SESSION_LIST = "SHIFT_SESSION_LIST";
export const shiftSessionList = (sessionIndex, session) => (dispatch) => {
    dispatch({
        type: SHIFT_SESSION_LIST,
        sessionIndex,
        session,
    });
};

export const UPDATE_ASSIGNED_PROGRAM_LIST = "UPDATE_ASSIGNED_PROGRAM_LIST";
export const updateAssignedProgramList = (newList) => (dispatch) => {
    dispatch({
        type: UPDATE_ASSIGNED_PROGRAM_LIST,
        newList,
    });
};

export const UPDATE_EDITING_RESOURCES_LIST = "UPDATE_EDITING_RESOURCES_LIST";
export const updateEditingResourcesList = (routine) => (dispatch) => {
    dispatch({
        type: UPDATE_EDITING_RESOURCES_LIST,
        routine,
    });
};

export const UPDATE_TEMPLATE_TITLE = "UPDATE_TEMPLATE_TITLE";
export const updateTemplateTitle = (title) => (dispatch) => {
    dispatch({
        type: UPDATE_TEMPLATE_TITLE,
        title,
    });
};

export const UPDATE_TEMPLATE_DETAILS = "UPDATE_TEMPLATE_DETAILS";
export const updateTemplateDetails = (details) => (dispatch) => {
    dispatch({
        type: UPDATE_TEMPLATE_DETAILS,
        details,
    });
};

export const UPDATE_ROUTINE_DETAILS = "UPDATE_ROUTINE_DETAILS";
export const updateRoutineDetails = (details) => (dispatch, getState) => {
    const state = getState();

    dispatch({
        type: UPDATE_ROUTINE_DETAILS,
        details,
        tagsList: state.resources.tags,
    });
};

export const REQUEST_UPDATE_ROUTINE_TEMPLATE_DETAILS = "REQUEST_UPDATE_ROUTINE_TEMPLATE_DETAILS";
export const RECEIVE_UPDATE_ROUTINE_TEMPLATE_DETAILS = "RECEIVE_UPDATE_ROUTINE_TEMPLATE_DETAILS";
export const RECEIVE_UPDATE_ROUTINE_TEMPLATE_DETAILS_ERROR = "RECEIVE_UPDATE_ROUTINE_TEMPLATE_DETAILS_ERROR";
export const updateRoutineTemplateDetails = (programId, body, tags, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_ROUTINE_TEMPLATE_DETAILS));

        const result = await patch(`/api/v4/programs/${programId}/`, body, params);

        return dispatch({ type: RECEIVE_UPDATE_ROUTINE_TEMPLATE_DETAILS, data: result.data, tags });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_ROUTINE_TEMPLATE_DETAILS_ERROR, error));
    }
};
