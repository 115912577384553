import {
    REQUEST_GET_USER_INVITE,
    RECEIVE_GET_USER_INVITE,
    RECEIVE_GET_USER_INVITE_ERROR,
    REQUEST_ACTIVATE_USER_INVITE,
    RECEIVE_ACTIVATE_USER_INVITE,
    RECEIVE_ACTIVATE_USER_INVITE_ERROR,
    REQUEST_SEND_USER_INVITE,
    RECEIVE_SEND_USER_INVITE,
    RECEIVE_SEND_USER_INVITE_ERROR,
    REQUEST_RESEND_USER_INVITE,
    RECEIVE_RESEND_USER_INVITE,
    RECEIVE_RESEND_USER_INVITE_ERROR,
    SET_GA_TAGS,
} from "../actions";

const initialState = {
    userInfo: {},
    GATags: {},
};

export function user(state = initialState, action) {
    switch (action.type) {
        case REQUEST_GET_USER_INVITE:
            return {
                ...state,
                sendGetUserInviteIsLoading: true,
                sendGetUserInviteRequestedAt: action.requestedAt,
            };
        case RECEIVE_GET_USER_INVITE:
            return {
                ...state,
                sendGetUserInviteIsLoading: false,
                sendGetUserInviteReceivedAt: action.receivedAt,
                userInfo: action.data,
            };
        case RECEIVE_GET_USER_INVITE_ERROR:
            return {
                ...state,
                sendGetUserInviteIsLoading: false,
                sendGetUserInviteReceivedAt: action.receivedAt,
            };
        case REQUEST_ACTIVATE_USER_INVITE:
            return {
                ...state,
                sendActivateUserInvite: true,
                sendActivateUserInviteRequestedAt: action.requestedAt,
            };
        case RECEIVE_ACTIVATE_USER_INVITE:
            return {
                ...state,
                sendActivateUserInvite: false,
                sendActivateUserInviteReceivedAt: action.receivedAt,
                activeStatus: action.data,
            };
        case RECEIVE_ACTIVATE_USER_INVITE_ERROR:
            return {
                ...state,
                sendActivateUserInvite: false,
                sendActivateUserInviteReceivedAt: action.receivedAt,
                userInvitationError: action.error,
            };
        case REQUEST_SEND_USER_INVITE:
            return {
                ...state,
                sendUserInviteIsLoading: true,
                sendUserInviteRequestedAt: action.requestedAt,
            };
        case RECEIVE_SEND_USER_INVITE:
            return {
                ...state,
                sendUserInviteIsLoading: false,
                sendUserInviteReceivedAt: action.receivedAt,
            };
        case RECEIVE_SEND_USER_INVITE_ERROR:
            return {
                ...state,
                sendUserInviteIsLoading: false,
                sendUserInviteReceivedAt: action.receivedAt,
                sendUserInviteError: action.error.response.data,
            };
        case REQUEST_RESEND_USER_INVITE:
            return {
                ...state,
                resendUserInviteIsLoading: true,
                resendUserInviteRequestedAt: action.requestedAt,
            };
        case RECEIVE_RESEND_USER_INVITE:
            return {
                ...state,
                resendUserInviteIsLoading: false,
                resendUserInviteReceivedAt: action.receivedAt,
            };
        case RECEIVE_RESEND_USER_INVITE_ERROR:
            return {
                ...state,
                resendUserInviteIsLoading: false,
                resendUserInviteReceivedAt: action.receivedAt,
                resendUserInviteError: action.error.response.data,
            };
        case SET_GA_TAGS:
            const updatedTags = { ...state.GATags, ...action.params };

            return {
                ...state,
                GATags: updatedTags,
            };
        default:
            return state;
    }
}
