import React from "react";

import createAvatar from "../../utils/createAvatar";

import Avatar from "../minimal/Avatar";

export default function TSAvatar({ image = null, name, size = "sm" }) {
    const width = { xs: "24px", sm: "64px", md: "175px", lg: "256px" }[size];
    const height = { xs: "24px", sm: "64px", md: "175px", lg: "256px" }[size];
    const fontSize = { xs: "14px", sm: "1.25rem", md: "2.5rem", lg: "5rem" }[size];

    return (
        <Avatar
            color={image ? "default" : createAvatar(name).color}
            alt={`${name} `}
            src={image || ""}
            sx={{ width, height, fontSize }}
        >
            {createAvatar(name).name}
        </Avatar>
    );
}
