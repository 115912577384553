import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";

import { updateClinicProfile, updateProviderProfile } from "../../app/actions";
import { useForm } from "react-hook-form";

import ButtonLink from "../button/ButtonLink";
import FormProvider from "../minimal/hook-form/FormProvider";
import Modal from "../modal/Modal";
import RHFTextField from "../minimal/hook-form/RHFTextField";
import { toast } from "../notifications/Toast";

const initialValues = {
    keywords: "",
};

export default function EditKeywordsModal({ show, clinic = null, provider = null, handleClose }) {
    const dispatch = useDispatch();
    const [keywords, setKeywords] = useState();
    const methods = useForm({
        initialValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset({
            ...initialValues,
            keywords: clinic ? clinic.keywords : provider ? provider.keywords : "",
        });
        setKeywords(clinic ? clinic.keywords : provider ? provider.keywords : "");
    }, [show]); // eslint-disable-line

    const handleKeywordsChange = (event) => {
        const {
            target: { value },
        } = event;

        setKeywords(value);
    };

    const handleFormSubmit = async () => {
        let result = null;

        const body = {
            keywords,
        };

        if (clinic) {
            result = await dispatch(updateClinicProfile(clinic.id, body));
        } else if (provider) {
            result = await dispatch(updateProviderProfile(provider.id, body));
        }

        if (result.error) {
            toast.error("Error updating keywords");
        } else {
            toast.success("Keywords updated");
            handleClose();
        }
    };

    return (
        <>
            <Modal
                show={show}
                toggleShow={handleClose}
                content={
                    <div>
                        <FormProvider methods={methods} onSubmit={handleSubmit(handleFormSubmit)}>
                            <Typography variant="h6" sx={{ my: 2 }}>
                                Edit Keywords
                            </Typography>
                            <RHFTextField
                                name="keywords"
                                label="Keywords"
                                value={keywords}
                                onChange={handleKeywordsChange}
                                sx={{ mb: 2 }}
                            />
                            <Typography variant="caption">Separate each keyword with a comma</Typography>
                            <Stack direction="row" justifyContent="end" spacing={2} sx={{ my: 2 }}>
                                <ButtonLink link onClick={handleClose}>
                                    Cancel
                                </ButtonLink>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    className="text-right"
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </FormProvider>
                    </div>
                }
            />
        </>
    );
}
