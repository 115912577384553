import { exq } from "../../utils/helpers";

export const coachReminderFragment = {
    expand: exq`patient`,
    fields: exq`
        id
        completed
        remind_at
        value

        patient.id
        patient.first_name
        patient.last_name
        patient.preferred_name
    `,
};

export const providerSearchFragment = {
    expand: exq`
        clinics
        found_clinics
        user.invitation
        profile_credentials
        user
        user.group
        user.roles
    `,
    fields: exq`
        active    
        bio
        clinics
        expertise_level
        found_clinics
        id
        user.invitation
        last_login
        photo
        profile_credentials
        timezone
        title
        slug
        user.email
        user.first_name
        user.id
        user.last_name
        user.last_login
        user.roles
    `,
};

export const providerProfileFragment = {
    expand: exq`
        user
        user.group.clinics
        profile_credentials
        clinics
    `,
    fields: exq`
        active
        alt_email
        alt_phone
        alt_website
        bio
        clarifying_content_blurb
        clarifying_content_subheadline
        clinics
        company_info
        expertise_level
        facebook
        google
        id
        instagram
        keywords
        linkedin
        meta_description
        optimized_seo_title
        phone
        photo
        services
        profile_credentials
        slug
        tagline
        tags
        title
        twitter
        user
        vanity_phone
    `,
};
