import moment from "moment-timezone";
import { push } from "connected-react-router";
import { knownFlowStep, getStepGroupsFromSteps } from "../../utils/flows";

import {
    HOME_ROUTE,
    ONBOARDING_SURVEY_ROUTE,
    TELESPINE_INTRODUCTION_SURVEY_ROUTE,
    TIME_BASED_SURVEY_ROUTE,
    PROGRESS_SURVEY_ROUTE,
    ABANDON_SURVEY_ROUTE,
    FINAL_SURVEY_ROUTE,
    COACH_HOME_ROUTE,
    LOGIN_ROUTE,
} from "../../utils/routes";
import { get, patch, post } from "../../utils/network";
import { checkCanJoinMeeting, redirectToLogin } from "../../utils/helpers";
import { sendSubscriptionPromptDisplayEvent } from "../../utils/analytics";
import { gen_error, gen_received, gen_requested } from "./generators";
import { fetchMeetings } from "./consult";
import { knownFlowStepGroups } from "../../utils/flows";
import { meFragment } from "../fragments/me";
import { groupsFragment } from "../fragments/groups";
import { meetingsListFragment } from "../fragments/consult";

export const REQUEST_ME = "REQUEST_ME";
export const RECEIVE_ME = "RECEIVE_ME";
export const RECEIVE_ME_ERROR = "RECEIVE_ME_ERROR";

export const getMe = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ME));

        const result = await get("/api/v3/me", { params: meFragment });
        const { data } = result;

        // TODO: It should be in reducer
        if (data && data.restrict_access_before) {
            const accessBeforeDate = moment(data.restrict_access_before).format("MMMM Do, YYYY");

            if (accessBeforeDate && accessBeforeDate.length) {
                return window.location.assign(`https://earlyenroll.telespine.com/?start_date=${accessBeforeDate}`);
            }
        }

        // initialize websocket layer
        // initWebsocket();

        return dispatch(gen_received(RECEIVE_ME, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ME_ERROR, error));
    }
};

export const REQUEST_LOGOUT = "REQUEST_LOGOUT";
export const RECEIVE_LOGOUT_DATA = "RECEIVE_LOGOUT_DATA";
export const RECEIVE_LOGOUT_ERROR = "RECEIVE_LOGOUT_ERROR";

export const logMeOut = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_LOGOUT));
        const result = await get("/api/v3/signout");
        // deactivate websocket layer
        // deactivateWebsocket();

        await dispatch(gen_received(RECEIVE_LOGOUT_DATA, result));

        // TODO: Redirect from reducer
        const { inactive = false } = options;
        redirectToLogin({ inactive });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_LOGOUT_ERROR, error));
    }
};

export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const RECEIVE_LOGIN_DATA = "RECEIVE_LOGIN_DATA";
export const RECEIVE_LOGIN_ERROR = "RECEIVE_LOGIN_ERROR";

export const logMeIn = (data) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_LOGIN));

        const result = await post("/api/v3/signin", data, { params: meFragment });
        const { data: resultData } = result;

        if (resultData && resultData.restrict_access_before) {
            const accessBeforeDate = moment(resultData.restrict_access_before).format("MMMM Do, YYYY");

            if (accessBeforeDate && accessBeforeDate.length) {
                return window.location.assign(`https://earlyenroll.telespine.com/?start_date=${accessBeforeDate}`);
            }
        }

        // initialize websocket layer
        // initWebsocket();

        return dispatch(gen_received(RECEIVE_LOGIN_DATA, { data: resultData }));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_LOGIN_ERROR, error));
    }
};

export const REQUEST_TIMEZONE_UPDATE = "REQUEST_TIMEZONE_UPDATE";
export const RECEIVE_TIMEZONE_UPDATE = "RECEIVE_TIMEZONE_UPDATE";
export const RECEIVE_TIMEZONE_UPDATE_ERROR = "RECEIVE_TIMEZONE_UPDATE_ERROR";
export const updateMyTimezone = (myId, newTimezone) => async (dispatch) => {
    try {
        await patch(`/api/v3/patients/${myId}/`, { timezone: newTimezone });

        return dispatch(gen_received(RECEIVE_TIMEZONE_UPDATE, { data: newTimezone }));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_TIMEZONE_UPDATE_ERROR, error));
    }
};

export const REQUEST_ALL_PARENT_GROUPS = "REQUEST_ALL_PARENT_GROUPS";
export const RECEIVE_ALL_PARENT_GROUPS = "RECEIVE_ALL_PARENT_GROUPS";
export const RECEIVE_ALL_PARENT_GROUPS_ERROR = "RECEIVE_ALL_PARENT_GROUPS_ERROR";
export const getAllParentGroups = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ALL_PARENT_GROUPS));

        const result = await get("/api/v5/parent_groups/", params);

        return dispatch(gen_received(RECEIVE_ALL_PARENT_GROUPS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ALL_PARENT_GROUPS_ERROR, error));
    }
};

export const REQUEST_DEFAULT_GROUP = "REQUEST_DEFAULT_GROUP";
export const RECEIVE_DEFAULT_GROUP = "RECEIVE_DEFAULT_GROUP";
export const RECEIVE_DEFAULT_GROUP_ERROR = "RECEIVE_DEFAULT_GROUP_ERROR";
export const getDefaultGroup = (params = { ...groupsFragment }) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_DEFAULT_GROUP));

        const result = await get("/api/v5/parent_groups/default/", { params });

        return dispatch(gen_received(RECEIVE_DEFAULT_GROUP, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_DEFAULT_GROUP_ERROR, error));
    }
};

// export const REQUEST_ALL_BRANDINGS = "REQUEST_ALL_BRANDINGS";
// export const RECEIVE_ALL_BRANDINGS = "RECEIVE_ALL_BRANDINGS";
// export const RECEIVE_ALL_BRANDINGS_ERROR = "RECEIVE_ALL_BRANDINGS_ERROR";

// export const getAllBrandings = (options = {}) => async (dispatch) => {
//     try {
//         dispatch(gen_requested(REQUEST_ALL_BRANDINGS));

//         const result = await get(`/api/v3/brandings/`, options);

//         return dispatch(gen_received(RECEIVE_ALL_BRANDINGS, result));
//     } catch (error) {
//         return dispatch(gen_error(RECEIVE_ALL_BRANDINGS_ERROR, error));
//     }
// };

// export const REQUEST_BRANDING = "REQUEST_BRANDING";
// export const RECEIVE_BRANDING = "RECEIVE_BRANDING";
// export const RECEIVE_BRANDING_ERROR = "RECEIVE_BRANDING_ERROR";

// export const getBranding = () => async (dispatch) => {
//     const subdomain = getBrandingSubdomain();
//     const options = { params: brandingFragment };

//     try {
//         dispatch(gen_requested(REQUEST_BRANDING));
//         // first try to get branding by the current subdomain
//         const subdomainResult = await get(`/api/v3/brandings/?subdomain=${subdomain}`, options);

//         return dispatch(gen_received(RECEIVE_BRANDING, subdomainResult));
//     } catch (error1) {
//         // subdomain may not have active or valid branding, fallback to default `app`
//         try {
//             const fallbackResult = await get("/api/v3/brandings/?subdomain=app", options);

//             return dispatch(gen_received(RECEIVE_BRANDING, fallbackResult));
//         } catch (error2) {
//             return dispatch(gen_error(RECEIVE_BRANDING_ERROR, error1)); // return original error, not fallback
//         }
//     }
// };

export const RECEIVE_RESET_PASSWORD_DATA = "RECEIVE_RESET_PASSWORD_DATA";
export const RECEIVE_RESET_PASSWORD_ERROR = "RECEIVE_RESET_PASSWORD_ERROR";

export const submitResetPassword = (data) => async (dispatch) => {
    try {
        const result = await post("/api/v3/password_reset", data);

        return dispatch({
            type: RECEIVE_RESET_PASSWORD_DATA,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_RESET_PASSWORD_ERROR,
            error,
        });
    }
};

export const REQUEST_CHANGE_USER_PASSWORD = "REQUEST_CHANGE_USER_PASSWORD";
export const RECEIVE_CHANGE_USER_PASSWORD = "RECEIVE_CHANGE_USER_PASSWORD";
export const RECEIVE_CHANGE_USER_PASSWORD_ERROR = "RECEIVE_CHANGE_USER_PASSWORD_ERROR";
export const changeUserPassword = (data) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CHANGE_USER_PASSWORD));

        const result = await post("/api/v3/coaches/reset_password/", data);

        return dispatch(gen_received(RECEIVE_CHANGE_USER_PASSWORD, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CHANGE_USER_PASSWORD_ERROR, error));
    }
};

export const RECEIVE_FORGOT_PASSWORD_DATA = "RECEIVE_FORGOT_PASSWORD_DATA";
export const RECEIVE_FORGOT_PASSWORD_ERROR = "RECEIVE_FORGOT_PASSWORD_ERROR";

export const submitForgotPassword = (data) => async (dispatch) => {
    try {
        const result = await post("/api/v3/request_password_reset", data);

        return dispatch({
            type: RECEIVE_FORGOT_PASSWORD_DATA,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_FORGOT_PASSWORD_ERROR,
            error,
        });
    }
};

export const REQUEST_FINISH_FLOW_STEPS = "REQUEST_FINISH_FLOW_STEPS";
export const RECEIVE_FINISH_FLOW_STEPS_DATA = "RECEIVE_FINISH_FLOW_STEPS_DATA";
export const RECEIVE_FINISH_FLOW_STEPS_ERROR = "RECEIVE_FINISH_FLOW_STEPS_ERROR";

export const finishFlowSteps = (stepIds, options = {}) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_FINISH_FLOW_STEPS));

        await post("/api/v3/steps/", { step_ids: stepIds });

        return dispatch(gen_received(RECEIVE_FINISH_FLOW_STEPS_DATA, { data: { ...options, stepIds } }));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_FINISH_FLOW_STEPS_ERROR, error));
    }
};

export const REQUEST_FINISH_SINGLE_FLOW_STEP = "REQUEST_FINISH_SINGLE_FLOW_STEP";
export const RECEIVE_FINISH_SINGLE_FLOW_STEP = "RECEIVE_FINISH_SINGLE_FLOW_STEP";
export const RECEIVE_FINISH_SINGLE_FLOW_STEP_ERROR = "RECEIVE_FINISH_SINGLE_FLOW_STEP_ERROR";

export const finishSingleFlowStep = (stepIds, options = {}) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_FINISH_SINGLE_FLOW_STEP));

        await post("/api/v3/steps/", { step_ids: stepIds });

        return dispatch(gen_received(RECEIVE_FINISH_SINGLE_FLOW_STEP, { data: { ...options, stepIds } }));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_FINISH_SINGLE_FLOW_STEP_ERROR, error));
    }
};

export const REQUEST_SAVE_CHAT_MESSAGE = "REQUEST_SAVE_CHAT_MESSAGE";
export const RECEIVE_SAVE_CHAT_MESSAGE_DATA = "RECEIVE_SAVE_CHAT_MESSAGE_DATA";
export const RECEIVE_SAVE_CHAT_MESSAGE_ERROR = "RECEIVE_SAVE_CHAT_MESSAGE_ERROR";

export const saveChatMessage = (message) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SAVE_CHAT_MESSAGE));

        // snapengage only sometimes sends us message text that contains HTML entities
        // that need decoding. this is the internet's preferred method of doing that.
        const txt = document.createElement("textarea");
        txt.innerHTML = message.text;
        message.text = txt.value;

        const result = await post("/api/v1/chat_messages/", message);
        await dispatch(gen_received(RECEIVE_SAVE_CHAT_MESSAGE_DATA, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SAVE_CHAT_MESSAGE_ERROR, error));
    }
};

export const REQUEST_SUBMIT_SUBDOMAIN = "REQUEST_SUBMIT_SUBDOMAIN";
export const RECEIVE_SUBMIT_SUBDOMAIN = "RECEIVE_SUBMIT_SUBDOMAIN";
export const RECEIVE_SUBMIT_SUBDOMAIN_ERROR = "RECEIVE_SUBMIT_SUBDOMAIN_ERROR";
export const requestSubdomain = (id, subdomain) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SUBMIT_SUBDOMAIN));

        const params = { subdomain };
        const result = await patch(`/api/v3/brandings/${id}/`, params);

        return dispatch(gen_received(RECEIVE_SUBMIT_SUBDOMAIN, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SUBMIT_SUBDOMAIN_ERROR, error));
    }
};

export const REQUEST_CONNECT_ACCOUNT_LINK = "REQUEST_CONNECT_ACCOUNT_LINK";
export const RECEIVE_CONNECT_ACCOUNT_LINK = "RECEIVE_CONNECT_ACCOUNT_LINK";
export const RECEIVE_CONNECT_ACCOUNT_LINK_ERROR = "RECEIVE_CONNECT_ACCOUNT_LINK_ERROR";
export const connectAccountLink = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CONNECT_ACCOUNT_LINK));

        const result = await get("/api/v3/subscriptions/stripe-connect-account");
        await dispatch(gen_received(RECEIVE_CONNECT_ACCOUNT_LINK, result));

        window.location = result.data.account_link.url;
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CONNECT_ACCOUNT_LINK_ERROR, error));
    }
};

export const REQUEST_SUBSCRIPTION_CUSTOMER_PORTAL = "REQUEST_SUBSCRIPTION_CUSTOMER_PORTAL";
export const RECEIVE_SUBSCRIPTION_CUSTOMER_PORTAL_DATA = "RECEIVE_SUBSCRIPTION_CUSTOMER_PORTAL_DATA";
export const RECEIVE_SUBSCRIPTION_CUSTOMER_PORTAL_ERROR = "RECEIVE_SUBSCRIPTION_CUSTOMER_PORTAL_ERROR";
export const subscriptionCustomerPortal = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SUBSCRIPTION_CUSTOMER_PORTAL));

        const result = await get("/api/v5/stripe/customer_portal/");
        await dispatch(gen_received(RECEIVE_SUBSCRIPTION_CUSTOMER_PORTAL_DATA, result));

        window.location = result.data.customer_portal_url;
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SUBSCRIPTION_CUSTOMER_PORTAL_ERROR, error));
    }
};

export const REQUEST_REFRESH_PROVIDER_SUBSCRIPTION = "REQUEST_REFRESH_PROVIDER_SUBSCRIPTION";
export const RECEIVE_REFRESH_PROVIDER_SUBSCRIPTION_DATA = "RECEIVE_REFRESH_PROVIDER_SUBSCRIPTION_DATA";
export const RECEIVE_REFRESH_PROVIDER_SUBSCRIPTION_ERROR = "RECEIVE_REFRESH_PROVIDER_SUBSCRIPTION_ERROR";
export const refreshProviderSubscription = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REFRESH_PROVIDER_SUBSCRIPTION));

        const result = await post("/api/v3/subscriptions/refresh-provider");

        await dispatch(gen_received(RECEIVE_REFRESH_PROVIDER_SUBSCRIPTION_DATA, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REFRESH_PROVIDER_SUBSCRIPTION_ERROR, error));
    }
};

export const REQUEST_REFRESH_SUBSCRIPTION = "REQUEST_REFRESH_SUBSCRIPTION";
export const RECEIVE_REFRESH_SUBSCRIPTION_DATA = "RECEIVE_REFRESH_SUBSCRIPTION_DATA";
export const RECEIVE_REFRESH_SUBSCRIPTION_ERROR = "RECEIVE_REFRESH_SUBSCRIPTION_ERROR";
export const refreshSubscription = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REFRESH_SUBSCRIPTION));

        const result = await post("/api/v3/subscriptions/refresh");

        await dispatch(gen_received(RECEIVE_REFRESH_SUBSCRIPTION_DATA, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REFRESH_SUBSCRIPTION_ERROR, error));
    }
};

export const RECEIVE_FLOW_REDIRECT_ERROR = "RECEIVE_FLOW_REDIRECT_ERROR";
export const flowRedirect = (stepGroupId, options = {}) => async (dispatch, getState) => {
    try {
        const { me } = getState();
        const { redirect = me.is_coach ? COACH_HOME_ROUTE : HOME_ROUTE } = options;
        if (me.is_coach && me.branding && !me.branding.is_organzation) {
            return dispatch(push(redirect));
        }

        switch (stepGroupId) {
            case knownFlowStepGroups.ONBOARDING_STEP_GROUP.id:
                return dispatch(push(ONBOARDING_SURVEY_ROUTE));
            case knownFlowStepGroups.TELESPINE_INTRODUCTION_STEP_GROUP.id:
                return dispatch(push(TELESPINE_INTRODUCTION_SURVEY_ROUTE));
            case knownFlowStepGroups.TIME_BASED_ASSESSMENT_STEP_GROUP.id:
                return dispatch(push(TIME_BASED_SURVEY_ROUTE));
            case knownFlowStepGroups.PROGRESS_SATISFACTION_STEP_GROUP.id:
                return dispatch(push(PROGRESS_SURVEY_ROUTE));
            case knownFlowStepGroups.ABANDON_SURVEY_STEP_GROUP.id:
                return dispatch(push(ABANDON_SURVEY_ROUTE));
            case knownFlowStepGroups.FINAL_SURVEY_STEP_GROUP.id:
                return dispatch(push(FINAL_SURVEY_ROUTE));
            default:
                return dispatch(push(redirect));
        }
    } catch (error) {
        return dispatch(gen_error(RECEIVE_FLOW_REDIRECT_ERROR, error));
    }
};

export const REQUEST_INITIALIZE_FLOW = "REQUEST_INITIALIZE_FLOW";
export const RECEIVE_INITIALIZE_FLOW = "RECEIVE_INITIALIZE_FLOW";
export const RECEIVE_INITIALIZE_FLOW_ERROR = "RECEIVE_INITIALIZE_FLOW_ERROR";

export const initializeFlow = (meData) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_INITIALIZE_FLOW));

        // checking for an upcoming meeting
        await dispatch(fetchMeetings({ params: { ...meetingsListFragment } }));
        const {
            consult: { upcomingMeeting },
        } = getState();

        // modify flow based on any upcoming meetings
        let { flow = [] } = meData;
        if (upcomingMeeting && checkCanJoinMeeting(upcomingMeeting)) {
            // remove certain flow steps since there is a joinable upcoming meeting. we don't
            // want to force users into a survey if they are trying to join a meeting.
            const filterIds = [
                knownFlowStepGroups.TELESPINE_INTRODUCTION_STEP_GROUP.id,
                knownFlowStepGroups.TIME_BASED_ASSESSMENT_STEP_GROUP.id,
                knownFlowStepGroups.PROGRESS_SATISFACTION_STEP_GROUP.id,
                knownFlowStepGroups.ABANDON_SURVEY_STEP_GROUP.id,
                knownFlowStepGroups.FINAL_SURVEY_STEP_GROUP.id,
            ];

            flow = flow.filter((step) => !filterIds.find((stepId) => step.step_id.includes(stepId)));
        }

        // if a flow exists, do some post-processing to make it easier to work with
        let parsedFlow;
        if (flow && flow.length > 0) {
            const groupedSteps = getStepGroupsFromSteps(flow);

            // get first known step
            const currentStepGroupId =
                groupedSteps &&
                Object.keys(groupedSteps).length > 0 &&
                Object.keys(groupedSteps).find((key) => knownFlowStep(key));

            parsedFlow = {
                stepGroups: groupedSteps,
                currentStepGroupId,
            };

            if ((meData.is_coach && window.location.pathname === "/v3/login") || !meData.is_coach)
                dispatch(flowRedirect(currentStepGroupId));
        } else {
            if (meData.is_coach) {
                if (
                    !window.location.pathname.startsWith(COACH_HOME_ROUTE) &&
                    window.location.pathname.startsWith(LOGIN_ROUTE)
                ) {
                    dispatch(push(COACH_HOME_ROUTE));
                }
            } else {
                // Redirect patient to home page if he outside app. But heep his location if he inside (maybe reloaded page or something)
                if (
                    !window.location.pathname.startsWith(COACH_HOME_ROUTE) &&
                    window.location.pathname.startsWith(LOGIN_ROUTE)
                ) {
                    !window.location.pathname.startsWith(HOME_ROUTE) && dispatch(push(HOME_ROUTE));
                }
            }
        }

        // NOTE: uncomment to test different flows locally. sample flow responses in utils/flows.js
        // const testGroups = getStepGroupsFromSteps(progressSurveyFlowResponse);
        // parsedFlow = {
        //   stepGroups: testGroups,
        //   currentStepGroupId:
        //     testGroups && Object.keys(testGroups).length > 0 && Object.keys(testGroups)[0],
        // };

        return dispatch(
            gen_received(RECEIVE_INITIALIZE_FLOW, {
                data: parsedFlow,
            })
        );
    } catch (error) {
        return dispatch(gen_error(RECEIVE_INITIALIZE_FLOW_ERROR, error));
    }
};

export const FORCE_SUBSCRIPTION = "FORCE_SUBSCRIPTION";
export const forceSubscription = (hidePaymentForm = false, isOnDemand = false, isConsult = true) => async (
    dispatch
) => {
    return dispatch({ type: FORCE_SUBSCRIPTION, data: { hidePaymentForm, isOnDemand, isConsult } });
};

export const PROMPT_FOR_SUBSCRIPTION = "PROMPT_FOR_SUBSCRIPTION";
export const PROMPT_FOR_SUBSCRIPTION_ERROR = "PROMPT_FOR_SUBSCRIPTION_ERROR";
export const promptForSubscription = (eventMessage = "") => async (dispatch, getState) => {
    try {
        const {
            me: { id: meId, is_coach, assigned_program },
        } = getState();

        // never prompt for coaches or patients that do not yet have a program or while on onboarding
        if (is_coach || !assigned_program || window.location.pathname.startsWith(ONBOARDING_SURVEY_ROUTE)) {
            return false;
        }

        sendSubscriptionPromptDisplayEvent(meId, eventMessage);
        return dispatch({ type: PROMPT_FOR_SUBSCRIPTION });
    } catch (error) {
        return dispatch(gen_error(PROMPT_FOR_SUBSCRIPTION_ERROR, error));
    }
};

export const PROMPT_PROVIDER_SUBSCRIPTION = "PROMPT_PROVIDER_SUBSCRIPTION";
export const promptProviderSubscription = () => async (dispatch) => {
    return dispatch({ type: PROMPT_PROVIDER_SUBSCRIPTION });
};

export const CLOSE_SUBSCRIPTION_PROMPT = "CLOSE_SUBSCRIPTION_PROMPT";
export const closeSubscriptionPrompt = () => async (dispatch) => {
    return dispatch({ type: CLOSE_SUBSCRIPTION_PROMPT });
};

export const REQUEST_BRANDING_TIERS = "REQUEST_BRANDING_TIERS";
export const RECEIVE_BRANDING_TIERS = "RECEIVE_BRANDING_TIERS";
export const RECEIVE_BRANDING_TIERS_ERROR = "RECEIVE_BRANDING_TIERS_ERROR";
export const fetchProviderTiers = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_BRANDING_TIERS));

        const result = await get("/api/v3/tiers/", options);

        return dispatch(gen_received(RECEIVE_BRANDING_TIERS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_BRANDING_TIERS_ERROR, error));
    }
};

export const SHOW_PROVIDER_PROFILE_BANNER = "SHOW_PROVIDER_PROFILE_BANNER";
export const showProviderProfileBanner = () => (dispatch) => {
    dispatch({
        type: SHOW_PROVIDER_PROFILE_BANNER,
    });
};

export const CLOSE_PROVIDER_PROFILE_BANNER = "CLOSE_PROVIDER_PROFILE_BANNER";
export const closeProviderProfileBanner = () => (dispatch) => {
    dispatch({
        type: CLOSE_PROVIDER_PROFILE_BANNER,
    });
};

export const REQUEST_CHECK_PROMO_CODE = "REQUEST_PROMO_CODE";
export const RECEIVE_CHECK_PROMO_CODE = "RECEIVE_PROMO_CODE";
export const RECEIVE_CHECK_PROMO_CODE_ERROR = "RECEIVE_PROMO_CODE_ERROR";
export const checkPromoCode = (code) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CHECK_PROMO_CODE));

        const result = await get(`/api/v3/subscriptions/promotion-code?promotion_code_id=${code}`);

        return dispatch(gen_received(RECEIVE_CHECK_PROMO_CODE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CHECK_PROMO_CODE_ERROR, error));
    }
};

export const REQUEST_CREATE_SUBSCRIPTION_PAYMENT_KEY = "REQUEST_CREATE_SUBSCRIPTION_PAYMENT_KEY";
export const RECEIVE_CREATE_SUBSCRIPTION_PAYMENT_KEY = "RECEIVE_CREATE_SUBSCRIPTION_PAYMENT_KEY";
export const RECEIVE_CREATE_SUBSCRIPTION_PAYMENT_KEY_ERROR = "RECEIVE_CREATE_SUBSCRIPTION_PAYMENT_KEY_ERROR";
export const createSubscriptionPaymentKey = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_SUBSCRIPTION_PAYMENT_KEY));

        const result = await post("/api/v3/subscriptions/create-payment-key", params);

        return dispatch(gen_received(RECEIVE_CREATE_SUBSCRIPTION_PAYMENT_KEY, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_SUBSCRIPTION_PAYMENT_KEY_ERROR, error));
    }
};

export const REQUEST_CREATE_ONE_TIME_PAYMENT_KEY = "REQUEST_CREATE_ONE_TIME_PAYMENT_KEY";
export const RECEIVE_CREATE_ONE_TIME_PAYMENT_KEY = "RECEIVE_CREATE_ONE_TIME_PAYMENT_KEY";
export const RECEIVE_CREATE_ONE_TIME_PAYMENT_KEY_ERROR = "RECEIVE_CREATE_ONE_TIME_PAYMENT_KEY_ERROR";
export const createOneTimePaymentKey = (priceId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_ONE_TIME_PAYMENT_KEY));

        const result = await get(`/api/v5/products/${priceId}/purchase/`);

        return dispatch(gen_received(RECEIVE_CREATE_ONE_TIME_PAYMENT_KEY, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_ONE_TIME_PAYMENT_KEY_ERROR, error));
    }
};

export const SET_CLIENT_PAID = "SET_CLIENT_PAID";
export const setClientPaid = (paid) => (dispatch) => {
    dispatch({
        type: SET_CLIENT_PAID,
        paid,
    });
};

export const SET_GA_TAGS = "SET_GA_TAGS";
export const setGATags = (params) => async (dispatch) => {
    dispatch({
        type: SET_GA_TAGS,
        params,
    });
};
