import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";

import { getBrandingLogo } from "../../utils/branding";

import Image from "../image/Image";

export default function BrandedLogo({ className, type }) {
    const { branding } = useSelector(({ groups }) => groups);
    const me = useSelector(({ me }) => me);
    const src = getBrandingLogo(branding, me, type);

    return <Image className={cx("ts-branded-logo", className)} src={src} alt="branded logo" />;
}
