import { mfrThemeRegex, orbitThemeRegex } from "../utils/branding";

export const PRIMARY_COLOR_ID = "primary";
export const PRIMARY_ALT_COLOR_ID = "primaryAlt";
export const PRIMARY_COLOR_DARK_ID = "primaryDark";
export const SECONDARY_COLOR_ID = "secondary";
export const SECONDARY_COLOR_DARK_ID = "secondaryDark";
export const SECONDARY_ALT_COLOR_ID = "secondaryAlt";
export const SECONDARY_ORANGE_COLOR_ID = "secondary-orange";
export const DARK_BACKGROUND_COLOR_ID = "darkBackground";

const telespineTheme = {
    [PRIMARY_COLOR_ID]: "#00adef",
    [PRIMARY_ALT_COLOR_ID]: "#45c3f3",
    [PRIMARY_COLOR_DARK_ID]: "#45c3f3",
    [SECONDARY_COLOR_ID]: "#fd8b35",
    [SECONDARY_COLOR_DARK_ID]: "#ffa867",
    [SECONDARY_ALT_COLOR_ID]: "#ffa867",
    [SECONDARY_ORANGE_COLOR_ID]: "#fd8b35",
    [DARK_BACKGROUND_COLOR_ID]: "#002b3c",
};

const orbitTheme = {
    [PRIMARY_COLOR_ID]: "#00adef",
    [PRIMARY_ALT_COLOR_ID]: "#45c3f3",
    [PRIMARY_COLOR_DARK_ID]: "#3f648e",
    [SECONDARY_COLOR_ID]: "#ee9a56",
    [SECONDARY_COLOR_DARK_ID]: "#d68a4d",
    [SECONDARY_ALT_COLOR_ID]: "#ffa867",
    [SECONDARY_ORANGE_COLOR_ID]: "#fd8b35",
    [DARK_BACKGROUND_COLOR_ID]: "#3E4C5B",
};

export function getThemeColor(colorId) {
    const {
        location: { hostname },
    } = window;

    let theme = telespineTheme;
    if (orbitThemeRegex.test(hostname) || mfrThemeRegex.test(hostname)) {
        theme = orbitTheme;
    }

    if (!Object.prototype.hasOwnProperty.call(theme, colorId)) {
        throw new Error("Color not found in theme");
    }

    return theme[colorId];
}
