import { axiosDelete, get, patch, post } from "../../utils/network";
import { toast } from "../../components/notifications/Toast";
import { gen_error, gen_received, gen_requested } from "./generators";
import moment from "moment-timezone";

import { dataPointsFragment, programFragment } from "../fragments/programs";

export const REQUEST_CLIENT_ASSIGNED_PROGRAMS = "REQUEST_CLIENT_ASSIGNED_PROGRAMS";
export const RECEIVE_CLIENT_ASSIGNED_PROGRAMS = "RECEIVE_CLIENT_ASSIGNED_PROGRAMS";
export const RECEIVE_CLIENT_ASSIGNED_PROGRAMS_ERROR = "RECEIVE_CLIENT_ASSIGNED_PROGRAMS_ERROR";
export const fetchClientAssignedPrograms = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CLIENT_ASSIGNED_PROGRAMS));
        const result = await get(`/api/v4/assigned_programs/`, { params });
        return dispatch(gen_received(RECEIVE_CLIENT_ASSIGNED_PROGRAMS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CLIENT_ASSIGNED_PROGRAMS_ERROR, error));
    }
};

export const REQUEST_PATIENT_ASSIGNED_PROGRAMS = "REQUEST_PATIENT_ASSIGNED_PROGRAMS";
export const RECEIVE_PATIENT_ASSIGNED_PROGRAMS = "RECEIVE_PATIENT_ASSIGNED_PROGRAMS";
export const RECEIVE_PATIENT_ASSIGNED_PROGRAMS_ERROR = "RECEIVE_PATIENT_ASSIGNED_PROGRAMS_ERROR";
export const fetchPatientAssignedPrograms = (patientId, params = {}) => async (dispatch) => {
    try {
        dispatch({ type: REQUEST_PATIENT_ASSIGNED_PROGRAMS, requestedAt: Date.now(), id: patientId });

        const result = await get(`/api/v4/assigned_programs/?patient=${patientId}`, { params });

        return dispatch({
            type: RECEIVE_PATIENT_ASSIGNED_PROGRAMS,
            receivedAt: Date.now(),
            id: patientId,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_PATIENT_ASSIGNED_PROGRAMS_ERROR,
            receivedAt: Date.now(),
            error: error,
        });
    }
};

export const REQUEST_PROGRAM = "REQUEST_PROGRAM";
export const RECEIVE_PROGRAM = "RECEIVE_PROGRAM";
export const RECEIVE_PROGRAM_ERROR = "RECEIVE_PROGRAM_ERROR";
export const fetchProgram = (programId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PROGRAM));
        const result = await get(`/api/v4/programs/${programId}/`, { params: programFragment });
        return dispatch(gen_received(RECEIVE_PROGRAM, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PROGRAM_ERROR, error));
    }
};

export const REQUEST_PROGRAMS = "REQUEST_PROGRAMS";
export const RECEIVE_PROGRAMS = "RECEIVE_PROGRAMS";
export const RECEIVE_PROGRAMS_ERROR = "RECEIVE_PROGRAMS_ERROR";
export const fetchPrograms = (params = { page_size: 0, ordering: "-created" }) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PROGRAMS));
        const result = await get("/api/v4/programs/", { params });
        return dispatch(gen_received(RECEIVE_PROGRAMS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PROGRAMS_ERROR, error));
    }
};

export const REQUEST_PROGRAM_TEMPLATES = "REQUEST_PROGRAM_TEMPLATES";
export const RECEIVE_PROGRAM_TEMPLATES = "RECEIVE_PROGRAM_TEMPLATES";
export const RECEIVE_PROGRAM_TEMPLATES_ERROR = "RECEIVE_PROGRAM_TEMPLATES_ERROR";
export const fetchProgramTemplates = (params = { page_size: 0, ordering: "-created" }) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PROGRAM_TEMPLATES));
        const result = await get("/api/v4/programs/", { params });

        return dispatch(gen_received(RECEIVE_PROGRAM_TEMPLATES, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PROGRAM_TEMPLATES_ERROR, error));
    }
};

export const REQUEST_UPDATE_RESOURCES_LIST = "REQUEST_UPDATE_RESOURCES_LIST";
export const RECEIVE_UPDATE_RESOURCES_LIST = "RECEIVE_UPDATE_RESOURCES_LIST";
export const RECEIVE_UPDATE_RESOURCES_LIST_ERROR = "RECEIVE_UPDATE_RESOURCES_LIST_ERROR";
export const updateResourcesList = (body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_RESOURCES_LIST));

        const result = await post("/api/v3/resources/", body);

        return dispatch(gen_received(RECEIVE_UPDATE_RESOURCES_LIST, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_RESOURCES_LIST_ERROR, error));
    }
};

export const REQUEST_REORDER_RESOURCES_LIST = "REORDER_RESOURCES_LIST";
export const RECEIVE_REORDER_RESOURCES_LIST = "RECEIVE_REORDER_RESOURCES_LIST";
export const RECEIVE_REORDER_RESOURCES_LIST_ERROR = "RECEIVE_REORDER_RESOURCES_LIST_ERROR";
export const reorderResourcesList = (resourceId, body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REORDER_RESOURCES_LIST));

        const result = await patch(`/api/v3/resources/${resourceId}/`, body);

        return dispatch(gen_received(RECEIVE_REORDER_RESOURCES_LIST, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REORDER_RESOURCES_LIST_ERROR, error));
    }
};

export const REQUEST_ADD_RESOURCE_TO_LIST = "REQUEST_ADD_RESOURCE_TO_LIST";
export const RECEIVE_ADD_RESOURCE_TO_LIST = "RECEIVE_ADD_RESOURCE_TO_LIST";
export const RECEIVE_ADD_RESOURCE_TO_LIST_ERROR = "RECEIVE_ADD_RESOURCE_TO_LIST_ERROR";
export const addResourceToList = (body) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ADD_RESOURCE_TO_LIST));

        const result = await post("/api/v3/resources/", body, { params: { expand: "exercise,article" } });

        return dispatch(gen_received(RECEIVE_ADD_RESOURCE_TO_LIST, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ADD_RESOURCE_TO_LIST_ERROR, error));
    }
};

export const REQUEST_REMOVE_RESOURCE_FROM_LIST = "REQUEST_REMOVE_RESOURCE_FROM_LIST";
export const RECEIVE_REMOVE_RESOURCE_FROM_LIST = "RECEIVE_REMOVE_RESOURCE_FROM_LIST";
export const RECEIVE_REMOVE_RESOURCE_FROM_LIST_ERROR = "RECEIVE_REMOVE_RESOURCE_FROM_LIST_ERROR";
export const removeResourceFromList = (resourceId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REMOVE_RESOURCE_FROM_LIST));

        const result = await axiosDelete(`/api/v3/resources/${resourceId}/`);

        return dispatch({
            type: RECEIVE_REMOVE_RESOURCE_FROM_LIST,
            receivedAt: Date.now(),
            id: resourceId,
            result,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REMOVE_RESOURCE_FROM_LIST_ERROR, error));
    }
};

export const REQUEST_REORDER_ASSIGNED_PROGRAM_LIST = "REQUEST_REORDER_ASSIGNED_PROGRAM_LIST";
export const RECEIVE_REORDER_ASSIGNED_PROGRAM_LIST = "RECEIVE_REORDER_ASSIGNED_PROGRAM_LIST";
export const RECEIVE_REORDER_ASSIGNED_PROGRAM_LIST_ERROR = "RECEIVE_REORDER_ASSIGNED_PROGRAM_LIST_ERROR";
export const reorderAssignedProgramList = (id, body, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REORDER_ASSIGNED_PROGRAM_LIST));

        const result = await patch(`/api/v4/assigned_programs/${id}/`, body, params);

        return dispatch(gen_received(RECEIVE_REORDER_ASSIGNED_PROGRAM_LIST, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_ASSIGNED_PROGRAM_INFO_ERROR, error));
    }
};

export const REQUEST_UPDATE_ASSIGNED_PROGRAM_INFO = "REQUEST_UPDATE_ASSIGNED_PROGRAM_INFO";
export const RECEIVE_UPDATE_ASSIGNED_PROGRAM_INFO = "RECEIVE_UPDATE_ASSIGNED_PROGRAM_INFO";
export const RECEIVE_UPDATE_ASSIGNED_PROGRAM_INFO_ERROR = "RECEIVE_UPDATE_ASSIGNED_PROGRAM_INFO_ERROR";
export const updateAssignedProgramInfo = (id, body, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_ASSIGNED_PROGRAM_INFO));

        const result = await patch(`/api/v4/assigned_programs/${id}/`, body, params);

        return dispatch(gen_received(RECEIVE_UPDATE_ASSIGNED_PROGRAM_INFO, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_ASSIGNED_PROGRAM_INFO_ERROR, error));
    }
};

export const REQUEST_UPDATE_ROUTINE_TEMPLATE_INFO = "REQUEST_UPDATE_ROUTINE_TEMPLATE_INFO";
export const RECEIVE_UPDATE_ROUTINE_TEMPLATE_INFO = "RECEIVE_UPDATE_ROUTINE_TEMPLATE_INFO";
export const RECEIVE_UPDATE_ROUTINE_TEMPLATE_INFO_ERROR = "RECEIVE_UPDATE_ROUTINE_TEMPLATE_INFO_ERROR";
export const updateRoutineTemplateInfo = (programId, body, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_ROUTINE_TEMPLATE_INFO));

        const result = await patch(`/api/v4/programs/${programId}/`, body, params);

        return dispatch(gen_received(RECEIVE_UPDATE_ROUTINE_TEMPLATE_INFO, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_ROUTINE_TEMPLATE_INFO_ERROR, error));
    }
};

export const REQUEST_UPDATE_ASSIGNED_PROGRAM_STATUS = "REQUEST_UPDATE_ASSIGNED_PROGRAM_STATUS";
export const RECEIVE_UPDATE_ASSIGNED_PROGRAM_STATUS = "RECEIVE_UPDATE_ASSIGNED_PROGRAM_STATUS";
export const RECEIVE_UPDATE_ASSIGNED_PROGRAM_STATUS_ERROR = "RECEIVE_UPDATE_ASSIGNED_PROGRAM_STATUS_ERROR";
export const updateAssignedProgramStatus = (id, body, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_ASSIGNED_PROGRAM_STATUS));

        const result = await patch(`/api/v4/assigned_programs/${id}/`, body, params);

        return dispatch(gen_received(RECEIVE_UPDATE_ASSIGNED_PROGRAM_STATUS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_ASSIGNED_PROGRAM_STATUS_ERROR, error));
    }
};

export const REQUEST_CREATE_PROGRAM = "REQUEST_CREATE_PROGRAM";
export const RECEIVE_CREATE_PROGRAM = "RECEIVE_CREATE_PROGRAM";
export const RECEIVE_CREATE_PROGRAM_ERROR = "RECEIVE_CREATE_PROGRAM_ERROR";
export const createProgram = (program, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_PROGRAM));

        const result = await post("/api/v4/assigned_programs/", program, { params });

        return dispatch(gen_received(RECEIVE_CREATE_PROGRAM, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_PROGRAM_ERROR, error));
    }
};

export const REQUEST_BULK_CREATE_PROGRAMS = "REQUEST_BULK_CREATE_PROGRAMS";
export const RECEIVE_BULK_CREATE_PROGRAMS = "RECEIVE_BULK_CREATE_PROGRAMS";
export const RECEIVE_BULK_CREATE_PROGRAMS_ERROR = "RECEIVE_BULK_CREATE_PROGRAMS_ERROR";
export const bulkCreatePrograms = (programs, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_BULK_CREATE_PROGRAMS));

        const result = await post("/api/v4/assigned_programs/", programs, { params });

        return dispatch(gen_received(RECEIVE_BULK_CREATE_PROGRAMS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_BULK_CREATE_PROGRAMS_ERROR, error));
    }
};

export const REQUEST_REMOVE_CLIENT_ASSIGNED_PROGRAM = "REQUEST_REMOVE_CLIENT_ASSIGNED_PROGRAM";
export const RECEIVE_REMOVE_CLIENT_ASSIGNED_PROGRAM = "RECEIVE_REMOVE_CLIENT_ASSIGNED_PROGRAM";
export const RECEIVE_REMOVE_CLIENT_ASSIGNED_PROGRAM_ERROR = "RECEIVE_REMOVE_CLIENT_ASSIGNED_PROGRAM_ERROR";
export const removeAssignedProgram = (programid, params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REMOVE_CLIENT_ASSIGNED_PROGRAM));

        params = { ...params, hidden: moment() };
        const result = await patch(`/api/v4/assigned_programs/${programid}/`, params);

        return dispatch(gen_received(RECEIVE_REMOVE_CLIENT_ASSIGNED_PROGRAM, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REMOVE_CLIENT_ASSIGNED_PROGRAM_ERROR, error));
    }
};

export const REQUEST_PROGRAM_UPDATED_EMAIL_NOTIFICATION = "REQUEST_PROGRAM_UPDATED_EMAIL_NOTIFICATION";
export const RECEIVE_PROGRAM_UPDATED_EMAIL_NOTIFICATION = "RECEIVE_PROGRAM_UPDATED_EMAIL_NOTIFICATION";
export const RECEIVE_PROGRAM_UPDATED_EMAIL_NOTIFICATION_ERROR = "RECEIVE_PROGRAM_UPDATED_EMAIL_NOTIFICATION_ERROR";
export const sendProgramUpdatedEmailNotification = (patientId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PROGRAM_UPDATED_EMAIL_NOTIFICATION));

        const result = await post(`/api/v3/patients/${patientId}/program_updated_notification/`);

        return dispatch(gen_received(RECEIVE_PROGRAM_UPDATED_EMAIL_NOTIFICATION, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PROGRAM_UPDATED_EMAIL_NOTIFICATION_ERROR, error));
    }
};

export const SELECT_ITEM = "SELECT_ITEM";
export function selectItem(item_position) {
    return {
        type: SELECT_ITEM,
        current_item: item_position,
    };
}

export const NEXT_ITEM = "NEXT_ITEM";
export function nextItem() {
    return {
        type: NEXT_ITEM,
    };
}

export const PREV_ITEM = "PREV_ITEM";
export function prevItem() {
    return {
        type: PREV_ITEM,
    };
}

export const ITEM_WATCHED = "ITEM_WATCHED";
export function itemWatched(resourceId = null) {
    return {
        type: ITEM_WATCHED,
        resourceId,
    };
}

export const REQUEST_CREATE_RESOURCES_DATAPOINT = "REQUEST_CREATE_RESOURCES_DATAPOINT";
export const RECEIVE_CREATE_RESOURCES_DATAPOINT = "RECEIVE_CREATE_RESOURCES_DATAPOINT";
export const RECEIVE_CREATE_RESOURCES_DATAPOINT_ERROR = "RECEIVE_CREATE_RESOURCES_DATAPOINT_ERROR";
export const createResourcesDataPoint = (resource, params = { ...dataPointsFragment }) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_RESOURCES_DATAPOINT));

        const result = await post(
            `/api/v4/data_points/resources/`,
            {
                type: "resource.opened",
                resource,
            },
            { params }
        );

        return dispatch({
            type: RECEIVE_CREATE_RESOURCES_DATAPOINT,
            data: result.data,
            resource,
            receivedAt: Date.now(),
        });
    } catch (error) {
        toast.error(error.message);
        return dispatch(gen_error(RECEIVE_CREATE_RESOURCES_DATAPOINT_ERROR, error));
    }
};

export const REQUEST_ITEM_WATCHED = "REQUEST_ITEM_WATCHED";
export const RECEIVE_ITEM_WATCHED = "RECEIVE_ITEM_WATCHED";
export const RECEIVE_ITEM_WATCHED_ERROR = "RECEIVE_ITEM_WATCHED_ERROR";
export const fetchItemWatched = (resource) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ITEM_WATCHED));
        const result = await post(`/api/v4/watched_items/`, {
            resource,
        });

        return dispatch(gen_received(RECEIVE_ITEM_WATCHED, result));
    } catch (error) {
        toast.error(error.message);
        return dispatch(gen_error(RECEIVE_ITEM_WATCHED_ERROR, error));
    }
};

export const ITEM_VISITED = "ITEM_VISITED";
export const itemVisited = () => async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: ITEM_VISITED });
    dispatch(
        fetchItemVisited(
            state.programs && state.programs.currentProgram && state.programs.currentProgram.id,
            state.programs.current_item
        )
    );
};

export const REQUEST_ITEM_VISITED = "REQUEST_ITEM_VISITED";
export const RECEIVE_ITEM_VISITED = "RECEIVE_ITEM_VISITED";
export const RECEIVE_ITEM_VISITED_ERROR = "RECEIVE_ITEM_VISITED_ERROR";
export const fetchItemVisited = (assignedProgramId, itemPostion) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ITEM_VISITED));
        const result = await patch(`/api/v4/assigned_programs/${assignedProgramId}/`, { last_visited: itemPostion });
        return dispatch(gen_received(RECEIVE_ITEM_VISITED, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ITEM_VISITED_ERROR, error));
    }
};

export const REQUEST_ARTICLE_WATCHED = "REQUEST_ARTICLE_WATCHED";
export const RECEIVE_ARTICLE_WATCHED = "RECEIVE_ARTICLE_WATCHED";
export const RECEIVE_ARTICLE_WATCHED_ERROR = "RECEIVE_ARTICLE_WATCHED_ERROR";
export const articleWatched = (articleId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ARTICLE_WATCHED));

        const result = await post(`/api/v4/data_points/articles/`, { type: "article.opened", article: articleId });
        return dispatch(gen_received(RECEIVE_ARTICLE_WATCHED, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ARTICLE_WATCHED_ERROR, error));
    }
};

export const SELECT_ROUTINE = "SELECT_ROUTINE";
export function selectRoutine(routine) {
    return {
        type: SELECT_ROUTINE,
        routine,
    };
}

export const INIT_PLAYER = "INIT_PLAYER";
export function initPlayer(player) {
    return {
        type: INIT_PLAYER,
        player,
    };
}

export const DEINIT_PLAYER = "DEINIT_PLAYER";
export function deInitPlayer() {
    return { type: DEINIT_PLAYER };
}

export const PLAY_PLAYER = "PLAY_PLAYER";
export function playPlayer() {
    return { type: PLAY_PLAYER };
}

export const PAUSE_PLAYER = "PAUSE_PLAYER";
export function pausePlayer() {
    return { type: PAUSE_PLAYER };
}

export const SEEK_VIDEO_PLAYER = "SEEK_VIDEO_PLAYER";
export function seekVideoPlayer(secs) {
    return {
        type: SEEK_VIDEO_PLAYER,
        secs,
    };
}

export const SEEK_PLAYER = "SEEK_PLAYER";
export function seekPlayer(sec) {
    return {
        type: SEEK_PLAYER,
        sec,
    };
}
