// steps
export const ABOUT_TELESPINE_STEP_ID = "about-telespine";
export const COVID_EXPERIENCE_STEP_ID = "covid-experience";
export const DISABILITY_SCORE_STEP_ID = "disability-score";
export const ONBOARDING_INTRO_STEP_ID = "onboarding-introduction";
export const ONBOARDING_DTC_INTRO_STEP_ID = "onboarding-dtc-introduction";
export const PAIN_LOCATION_SELECT_STEP_ID = "pain-location";
export const PAIN_SCORE_STEP_ID = "pain-score";
export const RED_FLAGS_STEP_ID = "red-flags";
export const RESILIENCE_SCORE_STEP_ID = "resilience-score";
export const SMART_GOAL_STEP_ID = "smart-goal";
export const STRESS_SCORE_STEP_ID = "stress-score";
export const THANK_YOU_FEEDBACK_STEP_ID = "thank-you-feedback";
export const TIME_BASED_CONCLUSION_STEP_ID = "time-based-conclusion";
export const TIME_BASED_INTRODUCTION_STEP_ID = "time-based-introduction";
export const WELCOME_BACK_STEP_ID = "welcome-back";
export const WELCOME_TO_YOUR_PROGRAM_STEP_ID = "welcome-to-your-program";
export const CALL_TO_YOUR_COACH_STEP_ID = "call-to-your-health-coach";
export const TERMS_OF_SERVICE_STEP_ID = "tos";
export const ORBIT_REBRAND_NOTIFICATION_STEP_ID = "orbit-rebrand-notification";

export const ONBOARDING_TYPEFORM_ANSWERS_LOCAL_STORAGE_KEY = "X_ONBOARDING_TYPEFORM_ANSWERS";

// step groups
export const knownFlowStepGroups = {
    ONBOARDING_STEP_GROUP: { id: "onboarding", priority: 100 },
    TELESPINE_INTRODUCTION_STEP_GROUP: { id: "telespine-introduction", priority: 200 },
    TIME_BASED_ASSESSMENT_STEP_GROUP: { id: "time-based-assessment", priority: 300 },
    TERMS_OF_SERVICE_STEP_GROUP: { id: TERMS_OF_SERVICE_STEP_ID, priority: 400 },
    PROGRESS_SATISFACTION_STEP_GROUP: { id: "progress-satisfaction", priority: 500 },
    ABANDON_SURVEY_STEP_GROUP: { id: "abandon-survey", priority: 600 },
    FINAL_SURVEY_STEP_GROUP: { id: "final-survey", priority: 700 },
    ORBIT_REBRAND_NOTIFICATION_STEP_GROUP: { id: ORBIT_REBRAND_NOTIFICATION_STEP_ID, priority: 800 },
    INITIAL_PROMPT_GROUP: { id: "initial-prompt", priority: 900 },
    PROVIDER_ONBOARDING: { id: "provider-onboarding", priority: 1000 },
};

export const newUserFlowResponse = [
    { step_id: `${knownFlowStepGroups.ONBOARDING_STEP_GROUP.id}/${ONBOARDING_INTRO_STEP_ID}`, order: 1 },
    { step_id: `${knownFlowStepGroups.ONBOARDING_STEP_GROUP.id}/${SMART_GOAL_STEP_ID}`, order: 2 },
    { step_id: `${knownFlowStepGroups.ONBOARDING_STEP_GROUP.id}/${PAIN_LOCATION_SELECT_STEP_ID}`, order: 3 },
    { step_id: `${knownFlowStepGroups.ONBOARDING_STEP_GROUP.id}/${RED_FLAGS_STEP_ID}`, order: 4 },
    { step_id: `${knownFlowStepGroups.ONBOARDING_STEP_GROUP.id}/${PAIN_SCORE_STEP_ID}`, order: 5 },
    { step_id: `${knownFlowStepGroups.ONBOARDING_STEP_GROUP.id}/${DISABILITY_SCORE_STEP_ID}`, order: 6 },
    { step_id: `${knownFlowStepGroups.ONBOARDING_STEP_GROUP.id}/${STRESS_SCORE_STEP_ID}`, order: 7 },
    { step_id: `${knownFlowStepGroups.ONBOARDING_STEP_GROUP.id}/${RESILIENCE_SCORE_STEP_ID}`, order: 8 },
    { step_id: `${knownFlowStepGroups.ONBOARDING_STEP_GROUP.id}/${COVID_EXPERIENCE_STEP_ID}`, order: 9 },
    {
        step_id: `${knownFlowStepGroups.TELESPINE_INTRODUCTION_STEP_GROUP.id}/${WELCOME_TO_YOUR_PROGRAM_STEP_ID}`,
        order: 10,
    },
    { step_id: `${knownFlowStepGroups.TELESPINE_INTRODUCTION_STEP_GROUP.id}/${ABOUT_TELESPINE_STEP_ID}`, order: 11 },
];

export const returningUserFlowResponse = [
    {
        step_id: `${knownFlowStepGroups.TIME_BASED_ASSESSMENT_STEP_GROUP.id}/${TIME_BASED_INTRODUCTION_STEP_ID}`,
        order: 1,
    },
    { step_id: `${knownFlowStepGroups.TIME_BASED_ASSESSMENT_STEP_GROUP.id}/${PAIN_SCORE_STEP_ID}`, order: 2 },
    { step_id: `${knownFlowStepGroups.TIME_BASED_ASSESSMENT_STEP_GROUP.id}/${DISABILITY_SCORE_STEP_ID}`, order: 3 },
    { step_id: `${knownFlowStepGroups.TIME_BASED_ASSESSMENT_STEP_GROUP.id}/${STRESS_SCORE_STEP_ID}`, order: 4 },
    { step_id: `${knownFlowStepGroups.TIME_BASED_ASSESSMENT_STEP_GROUP.id}/${RESILIENCE_SCORE_STEP_ID}`, order: 5 },
    { step_id: `${knownFlowStepGroups.TIME_BASED_ASSESSMENT_STEP_GROUP.id}/${COVID_EXPERIENCE_STEP_ID}`, order: 6 },
    {
        step_id: `${knownFlowStepGroups.TIME_BASED_ASSESSMENT_STEP_GROUP.id}/${TIME_BASED_CONCLUSION_STEP_ID}`,
        order: 7,
    },
];

export const telespineIntroFlowResponse = [
    {
        step_id: `${knownFlowStepGroups.TELESPINE_INTRODUCTION_STEP_GROUP.id}/${WELCOME_TO_YOUR_PROGRAM_STEP_ID}`,
        order: 1,
    },
    { step_id: `${knownFlowStepGroups.TELESPINE_INTRODUCTION_STEP_GROUP.id}/${ABOUT_TELESPINE_STEP_ID}`, order: 2 },
    {
        step_id: `${knownFlowStepGroups.TELESPINE_INTRODUCTION_STEP_GROUP.id}/${CALL_TO_YOUR_COACH_STEP_ID}`,
        order: 3,
    },
];

export const progressSurveyFlowResponse = [
    { step_id: `${knownFlowStepGroups.PROGRESS_SATISFACTION_STEP_GROUP.id}`, order: 1 },
];

export function getStepGroupsFromSteps(stepList) {
    // sort by priority
    const orderedSteps = stepList.sort((a, b) => {
        const aStepParts = a.step_id.split("/");
        const aGroupId = aStepParts[0];
        const aGroup =
            knownFlowStepGroups[
                Object.keys(knownFlowStepGroups).find((key) => knownFlowStepGroups[key].id === aGroupId)
            ];
        const bStepParts = b.step_id.split("/");
        const bGroupId = bStepParts[0];
        const bGroup =
            knownFlowStepGroups[
                Object.keys(knownFlowStepGroups).find((key) => knownFlowStepGroups[key].id === bGroupId)
            ];

        return (aGroup ? aGroup.priority : 0) - (bGroup ? bGroup.priority : 0);
    });

    // get a list of step ids
    const stepListArray = orderedSteps.map((step) => step.step_id);

    // return an object grouped by step id
    return (
        stepListArray &&
        stepListArray.length > 0 &&
        stepListArray.reduce((acc, val) => {
            const stepParts = val.split("/");
            const groupId = stepParts[0];
            const stepId = stepParts[1];

            return {
                ...acc,
                [groupId]: {
                    complete: false,
                    steps: [...(acc[groupId] && acc[groupId].steps ? acc[groupId].steps : []), stepId],
                },
            };
        }, {})
    );
}

export function knownFlowStep(stepId) {
    return Object.keys(knownFlowStepGroups).find((knownKey) => knownFlowStepGroups[knownKey].id === stepId);
}
