import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

SvgIconStyle.propTypes = {
    src: PropTypes.string.isRequired,
    sx: PropTypes.object,
};

export default function SvgIconStyle({ className = "", src, sx }) {
    return (
        <Box
            component="div"
            sx={{
                minWidth: 24,
                minHeight: 24,
                display: "inline-block",
                bgcolor: "currentColor",
                mask: `url(${src}) no-repeat center / contain`,
                WebkitMask: `url(${src}) no-repeat center / contain`,
                ...sx,
            }}
            className={className}
        />
    );
}
