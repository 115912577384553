import {
    REQUEST_OUTREACH_CHANNELS,
    RECEIVE_OUTREACH_CHANNELS,
    RECEIVE_OUTREACH_CHANNELS_ERROR,
    OUTREACH_SELECT_CHANNEL,
    REQUEST_OUTREACH_USERS,
    RECEIVE_OUTREACH_USERS,
    RECEIVE_OUTREACH_USERS_ERROR,
    OUTREACH_USER_SELECT,
    OUTREACH_USER_SELECT_ALL,
    OUTREACH_LAST_SIGNIN_START_DATE,
    OUTREACH_LAST_SIGNIN_END_DATE,
    OUTREACH_ENABLE_LAST_SIGNIN_INTERVAL,
    OUTREACH_ENABLE_PERSONALIZE_MESSAGE,
    OUTREACH_ENABLE_PERSONALIZE_MESSAGE_SOAP_NOTE_ADDITIONAL,
    OUTREACH_PREPEND_TEXT_CHANGE,
    RECEIVE_OUTREACH_GENERATE_MESSAGE,
    OUTREACH_SET_SOAP_NOTE_ADDITIONAL,
    SoapNoteAdditional,
    SmartGoal,
    OUTREACH_LAST_BILLABLE_SURVEY_START_DATE,
    OUTREACH_LAST_BILLABLE_SURVEY_END_DATE,
    OUTREACH_ENABLE_LAST_BILLABLE_SURVEY_INTERVAL,
    OUTREACH_ENABLE_PERSONALIZE_MESSAGE_SMART_GOAL,
} from "../actions";

// var today = new Date();
// var monthStart = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0);
// var yearStart = new Date(today.getFullYear(), 0, 1, 0, 0, 0);
// var tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 23, 59, 59, 999);

const outreachInitialState = {
    users: [],
    messages: {},
    perosnalizeMessage: true,
    perosnalizeMessageSoapNoteAdditional: true,
    perosnalizeMessageSmartGoal: true,
};

export function outreach(state = outreachInitialState, action) {
    switch (action.type) {
        case REQUEST_OUTREACH_CHANNELS:
            return {
                ...state,
                channelsRequestedAt: action.requestedAt,
                channelsIsFetching: true,
            };
        case RECEIVE_OUTREACH_CHANNELS:
            return {
                ...state,
                channelsReceivedAt: action.receivedAt,
                channelsIsFetching: false,
                channels: action.data,
                selectedChannelIndex: 0,
            };
        case RECEIVE_OUTREACH_CHANNELS_ERROR:
            return {
                ...state,
                channelsReceivedAt: action.receivedAt,
                channelsIsFetching: false,
                error: action.error,
            };
        case OUTREACH_SELECT_CHANNEL:
            return {
                ...state,
                selectedChannelIndex: action.index,
            };

        case REQUEST_OUTREACH_USERS:
            return {
                ...state,
                usersRequestedAt: action.requestedAt,
                usersIsFetching: true,
            };
        case RECEIVE_OUTREACH_USERS:
            return {
                ...state,
                usersReceivedAt: action.receivedAt,
                usersIsFetching: false,
                users: action.data.results.map((user, i) => ({
                    ...user,
                    smartGoal: SmartGoal(user),
                    soapNoteAdditional: SoapNoteAdditional(user),
                })),
                messages: {},
            };
        case RECEIVE_OUTREACH_USERS_ERROR:
            return {
                ...state,
                usersReceivedAt: action.receivedAt,
                usersIsFetching: false,
                error: action.error,
            };
        case OUTREACH_USER_SELECT:
            return {
                ...state,
                users: state.users.map((user, i) =>
                    i === action.index ? { ...user, selected: !user.selected } : user
                ),
            };
        case OUTREACH_USER_SELECT_ALL:
            return {
                ...state,
                users: state.users.map((user, i) => ({ ...user, selected: action.selected })),
            };
        case OUTREACH_LAST_SIGNIN_START_DATE:
            return {
                ...state,
                lastSigninStartDate: action.date,
            };
        case OUTREACH_LAST_SIGNIN_END_DATE:
            return {
                ...state,
                lastSigninEndDate: action.date,
            };
        case OUTREACH_ENABLE_LAST_SIGNIN_INTERVAL:
            return {
                ...state,
                lastSigninIntervalEnabled: action.enabled,
                lastSigninStartDate: action.enabled ? state.lastSigninStartDate : null,
                lastSigninEndDate: action.enabled ? state.lastSigninEndDate : null,
            };
        case OUTREACH_LAST_BILLABLE_SURVEY_START_DATE:
            return {
                ...state,
                lastBillableSurveyStartDate: action.date,
            };
        case OUTREACH_LAST_BILLABLE_SURVEY_END_DATE:
            return {
                ...state,
                lastBillableSurveyEndDate: action.date,
            };
        case OUTREACH_ENABLE_LAST_BILLABLE_SURVEY_INTERVAL:
            return {
                ...state,
                lastBillableSurveyIntervalEnabled: action.enabled,
                lastBillableSurveyStartDate: action.enabled ? state.lastBillableSurveyStartDate : null,
                lastBillableSurveyEndDate: action.enabled ? state.lastBillableSurveyEndDate : null,
            };
        case OUTREACH_ENABLE_PERSONALIZE_MESSAGE:
            return {
                ...state,
                perosnalizeMessage: action.enabled,
            };
        case OUTREACH_ENABLE_PERSONALIZE_MESSAGE_SOAP_NOTE_ADDITIONAL:
            return {
                ...state,
                perosnalizeMessageSoapNoteAdditional: action.enabled,
            };
        case OUTREACH_ENABLE_PERSONALIZE_MESSAGE_SMART_GOAL:
            return {
                ...state,
                perosnalizeMessageSmartGoal: action.enabled,
            };
        case OUTREACH_PREPEND_TEXT_CHANGE:
            return {
                ...state,
                prependText: action.value,
            };

        case RECEIVE_OUTREACH_GENERATE_MESSAGE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [action.i]: action.result,
                },
            };
        case OUTREACH_SET_SOAP_NOTE_ADDITIONAL:
            return {
                ...state,
                users: state.users.map((user, i) =>
                    i === action.i ? { ...user, soapNoteAdditional: action.value } : user
                ),
            };
        default:
            return state;
    }
}
