import { axiosDelete, get, patch, post } from "../../utils/network";
import { cancelMeeting } from "./index";
import { send } from "@giantmachines/redux-websocket";
import { gen_error, gen_received, gen_requested } from "./generators";
import { exq } from "../../utils/helpers";
import { PRIMARY_CONDITION_TAG_TYPE, SECONDARY_CONDITION_TAG_TYPE } from "../../utils/resources";

export const REQUEST_ALL_PATIENTS = "REQUEST_ALL_PATIENTS";
export const RECEIVE_ALL_PATIENTS = "RECEIVE_ALL_PATIENTS";
export const RECEIVE_ALL_PATIENTS_ERROR = "RECEIVE_ALL_PATIENTS_ERROR";

export const getPatients = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ALL_PATIENTS));

        const result = await get("/api/v3/patients/", { params });

        return dispatch(gen_received(RECEIVE_ALL_PATIENTS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ALL_PATIENTS_ERROR, error));
    }
};

export const REQUEST_PATIENTS_COUNT = "REQUEST_PATIENTS_COUNT";
export const RECEIVE_PATIENTS_COUNT = "RECEIVE_PATIENTS_COUNT";
export const RECEIVE_PATIENTS_COUNT_ERROR = "RECEIVE_PATIENTS_COUNT_ERROR";
export const getPatientsCount = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PATIENTS_COUNT));

        const result = await get("/api/v3/patients/", params);

        return dispatch(gen_received(RECEIVE_PATIENTS_COUNT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PATIENTS_COUNT_ERROR, error));
    }
};

export const REQUEST_PATIENT = "REQUEST_PATIENT";
export const RECEIVE_PATIENT = "RECEIVE_PATIENT";
export const RECEIVE_PATIENT_ERROR = "RECEIVE_PATIENT_ERROR";

export const getPatient = (patientId, options = {}) => async (dispatch, getState) => {
    try {
        const existingPatientData = getState().patients[patientId] || {};

        dispatch({
            type: REQUEST_PATIENT,
            requestedAt: Date.now(),
            id: patientId,
            data: existingPatientData,
        });

        const result = await get(`/api/v3/patients/${patientId}/`, options);

        return dispatch({
            type: RECEIVE_PATIENT,
            receivedAt: Date.now(),
            id: patientId,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_PATIENT_ERROR,
            receivedAt: Date.now(),
            id: patientId,
            error: error,
        });
    }
};

export const REQUEST_UPDATE_CLIENT_CONDITION = "REQUEST_UPDATE_CLIENT_CONDITION";
export const RECEIVE_UPDATE_CLIENT_CONDITION = "RECEIVE_UPDATE_CLIENT_CONDITION";
export const RECEIVE_UPDATE_CLIENT_CONDITION_ERROR = "RECEIVE_UPDATE_CLIENT_CONDITION_ERROR";
export const updateClientCondition = (body) => async (dispatch, getState) => {
    try {
        dispatch({ type: REQUEST_UPDATE_CLIENT_CONDITION, requrestAt: Date.now(), id: body.user });
        const {
            resources: {
                [PRIMARY_CONDITION_TAG_TYPE]: primaryConditions,
                [SECONDARY_CONDITION_TAG_TYPE]: secondaryConditions,
            },
        } = getState();

        const result = await post(`/api/v3/conditions/`, body);

        return dispatch({
            type: RECEIVE_UPDATE_CLIENT_CONDITION,
            receivedAt: Date.now(),
            id: body.user,
            data: result.data,
            primaryConditions,
            secondaryConditions,
        });
    } catch (error) {
        return dispatch({ type: RECEIVE_UPDATE_CLIENT_CONDITION_ERROR, receivedAt: Date.now(), id: body.user, error });
    }
};

export const REQUEST_UPDATE_PATIENT = "REQUEST_UPDATE_PATIENT";
export const RECEIVE_UPDATE_PATIENT = "RECEIVE_UPDATE_PATIENT";
export const RECEIVE_UPDATE_PATIENT_ERROR = "RECEIVE_UPDATE_PATIENT_ERROR";

export const updatePatient = (patientId, data, options = {}) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_UPDATE_PATIENT,
            requestedAt: Date.now(),
            id: patientId,
        });

        const result = await patch(`/api/v3/patients/${patientId}/`, data, options);

        return dispatch({
            type: RECEIVE_UPDATE_PATIENT,
            receivedAt: Date.now(),
            id: patientId,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_UPDATE_PATIENT_ERROR,
            receivedAt: Date.now(),
            id: patientId,
            error,
        });
    }
};

export const UPDATE_PATIENTS_DATA_PARAMS = "UPDATE_PATIENTS_DATA_PARAMS";
export const updatePatientsDataParams = (data) => (dispatch) => {
    return dispatch({
        type: UPDATE_PATIENTS_DATA_PARAMS,
        data,
    });
};

export const REQUEST_UPDATE_PATIENT_ASSIGNED_PROGRAM = "REQUEST_UPDATE_PATIENT_ASSIGNED_PROGRAM";
export const RECEIVE_UPDATE_PATIENT_ASSIGNED_PROGRAM = "RECEIVE_UPDATE_PATIENT_ASSIGNED_PROGRAM";
export const RECEIVE_UPDATE_PATIENT_ASSIGNED_PROGRAM_ERROR = "RECEIVE_UPDATE_PATIENT_ASSIGNED_PROGRAM_ERROR";

export const updatePatientAssignedProgram = (body, patientId) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_UPDATE_PATIENT_ASSIGNED_PROGRAM,
            requestedAt: Date.now(),
            id: patientId,
        });

        const result = await post("/api/v4/assigned_programs/", body);

        return dispatch({
            type: RECEIVE_UPDATE_PATIENT_ASSIGNED_PROGRAM,
            receivedAt: Date.now(),
            data: result.data,
            id: patientId,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_UPDATE_PATIENT_ASSIGNED_PROGRAM_ERROR,
            receivedAt: Date.now(),
            id: patientId,
            error,
        });
    }
};

export const REQUEST_CANCEL_PATIENT_MEETING = "REQUEST_CANCEL_PATIENT_MEETING";
export const RECEIVE_CANCEL_PATIENT_MEETING = "RECEIVE_CANCEL_PATIENT_MEETING";
export const RECEIVE_CANCEL_PATIENT_MEETING_ERROR = "RECEIVE_CANCEL_PATIENT_MEETING_ERROR";

export const cancelPatientMeeting = (patientId, meetingId) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_CANCEL_PATIENT_MEETING,
            requestedAt: Date.now(),
            patientId,
            meetingId,
        });

        const result = await dispatch(cancelMeeting(meetingId));

        return dispatch({
            type: RECEIVE_CANCEL_PATIENT_MEETING,
            receivedAt: Date.now(),
            patientId,
            meetingId,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_CANCEL_PATIENT_MEETING_ERROR,
            receivedAt: Date.now(),
            patientId,
            meetingId,
            error,
        });
    }
};

export const subscribeForPatient = (patientId) => async (dispatch) => {
    dispatch(
        send({
            type: "coach_subscribe",
            user_id: patientId,
        })
    );
};

export const unsubscribeForPatient = (patientId) => async (dispatch) => {
    dispatch(
        send({
            type: "coach_unsubscribe",
            user_id: patientId,
        })
    );
};

export const REQUEST_PATIENT_SUGGESTIONS = "REQUEST_PATIENT_SUGGESTIONS";
export const RECEIVE_PATIENT_SUGGESTIONS = "RECEIVE_PATIENT_SUGGESTIONS";
export const RECEIVE_PATIENT_SUGGESTIONS_ERROR = "RECEIVE_PATIENT_SUGGESTIONS_ERROR";

export const getPatientSuggestions = (text, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PATIENT_SUGGESTIONS));

        const { params: optionsParams = {}, ...optionsRest } = options;
        const params = {
            ...optionsParams,
            search: text,
        };
        const result = await get("/api/v3/patients/", { params, ...optionsRest });

        return dispatch(gen_received(RECEIVE_PATIENT_SUGGESTIONS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PATIENT_SUGGESTIONS_ERROR, error));
    }
};

export const REQUEST_PATIENT_CHAT_HISTORY = "REQUEST_PATIENT_CHAT_HISTORY";
export const RECEIVE_PATIENT_CHAT_HISTORY = "RECEIVE_PATIENT_CHAT_HISTORY";
export const RECEIVE_PATIENT_CHAT_HISTORY_ERROR = "RECEIVE_PATIENT_CHAT_HISTORY_ERROR";

export const getPatientChatHistory = (patientId) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_PATIENT_CHAT_HISTORY,
            requestedAt: Date.now(),
            patientId,
        });

        const result = await get(`/api/v3/patients/${patientId}/chat_messages/`);

        return dispatch({
            type: RECEIVE_PATIENT_CHAT_HISTORY,
            receivedAt: Date.now(),
            patientId,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_PATIENT_CHAT_HISTORY_ERROR,
            receivedAt: Date.now(),
            patientId,
            error,
        });
    }
};

export const REQUEST_PATIENT_WATCHED_ITEMS = "REQUEST_PATIENT_WATCHED_ITEMS";
export const RECEIVE_PATIENT_WATCHED_ITEMS = "RECEIVE_PATIENT_WATCHED_ITEMS";
export const RECEIVE_PATIENT_WATCHED_ITEMS_ERROR = "RECEIVE_PATIENT_WATCHED_ITEMS_ERROR";

export const getPatientWatchedItems = (patientId, options = {}) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_PATIENT_WATCHED_ITEMS,
            requestedAt: Date.now(),
            patientId,
        });

        const result = await get(`/api/v4/data_points/resources/`, options);

        return dispatch({
            type: RECEIVE_PATIENT_WATCHED_ITEMS,
            receivedAt: Date.now(),
            patientId,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_PATIENT_WATCHED_ITEMS_ERROR,
            receivedAt: Date.now(),
            patientId,
            error,
        });
    }
};

export const REQUEST_CREATE_PATIENT_MEETING = "REQUEST_CREATE_PATIENT_MEETING";
export const RECEIVE_CREATE_PATIENT_MEETING = "RECEIVE_CREATE_PATIENT_MEETING";
export const RECEIVE_CREATE_PATIENT_MEETING_ERROR = "RECEIVE_CREATE_PATIENT_MEETING_ERROR";
export const createPatientMeeting = (data, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_PATIENT_MEETING));

        const result = await post(`/api/v3/meetings/`, data, options);

        return dispatch(gen_received(RECEIVE_CREATE_PATIENT_MEETING, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_PATIENT_MEETING_ERROR, error));
    }
};

export const REQUEST_UPDATE_PATIENT_MEETING_ANSWERS = "REQUEST_UPDATE_PATIENT_MEETING_ANSWERS";
export const RECEIVE_UPDATE_PATIENT_MEETING_ANSWERS = "RECEIVE_UPDATE_PATIENT_MEETING_ANSWERS";
export const RECEIVE_UPDATE_PATIENT_MEETING_ANSWERS_ERROR = "RECEIVE_UPDATE_PATIENT_MEETING_ANSWERS_ERROR";
export const updatePatientMeetingAnswers = (patientId, meetingId, data) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_UPDATE_PATIENT_MEETING_ANSWERS,
            requestedAt: Date.now(),
            patientId,
            meetingId,
        });

        const updateParams = {
            params: {
                expand: exq`answers`,
                fields: exq`answers`,
            },
        };
        const result = await post(`/api/v3/meetings/${meetingId}/answers/`, data, updateParams);

        return dispatch({
            type: RECEIVE_UPDATE_PATIENT_MEETING_ANSWERS,
            receivedAt: Date.now(),
            patientId,
            meetingId,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_UPDATE_PATIENT_MEETING_ANSWERS_ERROR,
            receivedAt: Date.now(),
            patientId,
            meetingId,
            error,
        });
    }
};

export const REQUEST_UPDATE_PATIENT_MEETING = "REQUEST_UPDATE_PATIENT_MEETING";
export const RECEIVE_UPDATE_PATIENT_MEETING = "RECEIVE_UPDATE_PATIENT_MEETING";
export const RECEIVE_UPDATE_PATIENT_MEETING_ERROR = "RECEIVE_UPDATE_PATIENT_MEETING_ERROR";
export const updatePatientMeeting = (patientId, meetingId, data, options = {}) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_UPDATE_PATIENT_MEETING,
            requestedAt: Date.now(),
            patientId,
            meetingId,
        });

        const result = await patch(`/api/v3/meetings/${meetingId}/`, data, options);

        return dispatch({
            type: RECEIVE_UPDATE_PATIENT_MEETING,
            receivedAt: Date.now(),
            patientId,
            meetingId,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_UPDATE_PATIENT_MEETING_ERROR,
            receivedAt: Date.now(),
            patientId,
            meetingId,
            error,
        });
    }
};

export const REQUEST_CREATE_PATIENT_REMINDER = "REQUEST_CREATE_PATIENT_REMINDER";
export const RECEIVE_CREATE_PATIENT_REMINDER = "RECEIVE_CREATE_PATIENT_REMINDER";
export const RECEIVE_CREATE_PATIENT_REMINDER_ERROR = "RECEIVE_CREATE_PATIENT_REMINDER_ERROR";
export const createPatientReminder = (data, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_PATIENT_REMINDER));

        const result = await post("/api/v3/reminders/", data, options);

        return dispatch(gen_received(RECEIVE_CREATE_PATIENT_REMINDER, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_PATIENT_MEETING_ERROR, error));
    }
};

export const REQUEST_UPDATE_PATIENT_REMINDER = "REQUEST_UPDATE_PATIENT_REMINDER";
export const RECEIVE_UPDATE_PATIENT_REMINDER = "RECEIVE_UPDATE_PATIENT_REMINDER";
export const RECEIVE_UPDATE_PATIENT_REMINDER_ERROR = "RECEIVE_UPDATE_PATIENT_REMINDER_ERROR";
export const updatePatientReminder = (reminderId, data, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_PATIENT_REMINDER));

        const result = await patch(`/api/v3/reminders/${reminderId}/`, data, options);

        return dispatch(gen_received(RECEIVE_UPDATE_PATIENT_REMINDER, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_PATIENT_REMINDER_ERROR, error));
    }
};

export const REQUEST_DELETE_PATIENT_REMINDER = "REQUEST_DELETE_PATIENT_REMINDER";
export const RECEIVE_DELETE_PATIENT_REMINDER = "RECEIVE_DELETE_PATIENT_REMINDER";
export const RECEIVE_DELETE_PATIENT_REMINDER_ERROR = "RECEIVE_DELETE_PATIENT_REMINDER_ERROR";
export const deletePatientReminder = (reminderId, patientId, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_DELETE_PATIENT_REMINDER));

        await axiosDelete(`/api/v3/reminders/${reminderId}/`, options);
        const data = {
            id: reminderId,
            patientId,
        };
        return dispatch(gen_received(RECEIVE_DELETE_PATIENT_REMINDER, { data }));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_DELETE_PATIENT_REMINDER_ERROR, error));
    }
};

export const REQUEST_CREATE_DEMO_PATIENT = "REQUEST_CREATE_DEMO_PATIENT";
export const RECEIVE_CREATE_DEMO_PATIENT = "RECEIVE_CREATE_DEMO_PATIENT";
export const RECEIVE_CREATE_DEMO_PATIENT_ERROR = "RECEIVE_CREATE_DEMO_PATIENT_ERROR";
export const createDemoPatient = () => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CREATE_DEMO_PATIENT));

        const result = await post("/api/v3/patients/make_random_demo/");

        return dispatch(gen_received(RECEIVE_CREATE_DEMO_PATIENT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_DEMO_PATIENT_ERROR, error));
    }
};

export const REQUEST_DELETE_DEMO_PATIENT = "REQUEST_DELETE_DEMO_PATIENT";
export const RECEIVE_DELETE_DEMO_PATIENT = "RECEIVE_DELETE_DEMO_PATIENT";
export const RECEIVE_DELETE_DEMO_PATIENT_ERROR = "RECEIVE_DELETE_DEMO_PATIENT_ERROR";
export const deleteDemoPatient = (patientId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_DELETE_DEMO_PATIENT));

        const result = await axiosDelete(`/api/v3/patients/${patientId}/`);

        return dispatch(gen_received(RECEIVE_DELETE_DEMO_PATIENT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_DELETE_DEMO_PATIENT_ERROR, error));
    }
};

export const REQUEST_INVITED_CLIENTS = "REQUEST_INVITED_CLIENTS";
export const RECEIVE_INVITED_CLIENTS = "RECEIVE_INVITED_CLIENTS";
export const RECEIVE_INVITED_CLIENTS_ERROR = "RECEIVE_INVITED_CLIENTS_ERROR";
export const fetchInvitedClients = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_INVITED_CLIENTS));

        const result = await get("/api/v3/patients/?invitation__isnull=false", { params });

        return dispatch(gen_received(RECEIVE_INVITED_CLIENTS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_INVITED_CLIENTS_ERROR, error));
    }
};

export const REQUEST_REMOVE_CLIENT = "REQUEST_REMOVE_CLIENT";
export const RECEIVE_REMOVE_CLIENT = "RECEIVE_REMOVE_CLIENT";
export const RECEIVE_REMOVE_CLIENT_ERROR = "RECEIVE_REMOVE_CLIENT_ERROR";
export const removeClient = (clientId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REMOVE_CLIENT));

        const result = await patch(`/api/v3/patients/${clientId}/`, { archived: true });

        return dispatch(gen_received(RECEIVE_REMOVE_CLIENT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REMOVE_CLIENT_ERROR, error));
    }
};

export const REQUEST_FETCH_ALL_LEADS = "REQUEST_FETCH_ALL_LEADS";
export const RECEIVE_FETCH_ALL_LEADS = "RECEIVE_FETCH_ALL_LEADS";
export const RECEIVE_FETCH_ALL_LEADS_ERROR = "RECEIVE_FETCH_ALL_LEADS_ERROR";
export const fetchAllLeads = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_FETCH_ALL_LEADS));

        const result = await get("/api/v4/leads/", { params });

        return dispatch(gen_received(RECEIVE_FETCH_ALL_LEADS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_FETCH_ALL_LEADS_ERROR, error));
    }
};

export const REQUEST_FETCH_LEAD = "REQUEST_FETCH_LEAD";
export const RECEIVE_FETCH_LEAD = "RECEIVE_FETCH_LEAD";
export const RECEIVE_FETCH_LEAD_ERROR = "RECEIVE_FETCH_LEAD_ERROR";
export const fetchLead = (leadId, params = {}) => async (dispatch) => {
    try {
        dispatch({ type: REQUEST_FETCH_LEAD, requestedAt: Date.now(), id: leadId });

        const result = await get(`/api/v4/leads/${leadId}/`, { params });

        return dispatch({
            type: RECEIVE_FETCH_LEAD,
            data: result.data,
            receivedAt: Date.now(),
            id: leadId,
        });
    } catch (error) {
        return dispatch({ type: RECEIVE_FETCH_LEAD_ERROR, id: leadId, receivedAt: Date.now(), error });
    }
};

export const HANDLE_ADD_PATIENT_RESOURCES = "HANDLE_ADD_PATIENT_RESOURCES";
export const handleAddPatientResources = (patientId, resourcesData) => async (dispatch) => {
    dispatch({
        type: HANDLE_ADD_PATIENT_RESOURCES,
        data: resourcesData,
        patientId,
    });
};

export const HANDLE_REMOVE_PATIENT_RESOURCE = "HANDLE_REMOVE_PATIENT_RESOURCE";
export const handleRemovePatientResource = (patientId, resourceId) => async (dispatch) => {
    dispatch({
        type: HANDLE_REMOVE_PATIENT_RESOURCE,
        resourceId,
        patientId,
    });
};

export const SET_SORT_ORDER = "SET_SORT_ORDER";
export const setSortOrder = (sortOrder) => async (dispatch) => {
    dispatch({
        type: SET_SORT_ORDER,
        sortOrder,
    });
};

export const SET_SORT_ORDER_BY = "SET_SORT_ORDER_BY";
export const setSortOrderBy = (sortOrderBy) => async (dispatch) => {
    dispatch({
        type: SET_SORT_ORDER_BY,
        sortOrderBy,
    });
};

export const SET_PATIENT_FILTER_PARAMS = "SET_PATIENT_FILTER_PARAMS";
export const setPatientFilterParams = (patientFilterParams) => async (dispatch) => {
    dispatch({
        type: SET_PATIENT_FILTER_PARAMS,
        patientFilterParams,
    });
};

export const SET_PATIENT_FILTER_VALUES = "SET_PATIENT_FILTER_VALUES";
export const setPatientFilterValues = (patientFilterValues) => async (dispatch) => {
    dispatch({
        type: SET_PATIENT_FILTER_VALUES,
        patientFilterValues,
    });
};

export const SET_PATIENTS_PAGE = "SET_PATIENTS_PAGE";
export const setPatientsPage = (patientsPage) => async (dispatch) => {
    dispatch({
        type: SET_PATIENTS_PAGE,
        patientsPage,
    });
};
