export const genderId = "gender";
export const otherGenderId = "other";

export const GENDER_OPTIONS = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "ratherNotSay", label: "Rather not say" },
    { value: otherGenderId, label: "Other" },
];

/**
 *
 * @param {*} me
 * @returns Determines if user is a DTC user and returns boolean
 */
export function isDTCUser(me) {
    // This function used to be a bit more complicated and isn't really necessary anymore
    // but it is used in a lot of places so its faster to leave this in for now.
    return me.group && me.group.dtc;
}
