import moment from "moment-timezone";

import {
    EXERCISES_LIST_ITEM_ID,
    exerciseToResource,
    processResource,
    resolveDuplicateSessionTitles,
} from "../../utils/resources";
import { createSessionsFromProgramList } from "../../utils/programs";
import { COACH_PROGRAM_BUILDER_ROUTE } from "../../utils/routes";
import { assignedProgramsFragment } from "../fragments/programs";

import {
    REQUEST_CREATE_NEW_PROGRAM,
    RECEIVE_CREATE_NEW_PROGRAM,
    RECEIVE_CREATE_NEW_PROGRAM_ERROR,
    REQUEST_CREATE_NEW_ROUTINE_TEMPLATE,
    RECEIVE_CREATE_NEW_ROUTINE_TEMPLATE,
    RECEIVE_CREATE_NEW_ROUTINE_TEMPLATE_ERROR,
    REQUEST_DUPLICATE_PROGRAM_TEMPLATE,
    RECEIVE_DUPLICATE_PROGRAM_TEMPLATE,
    RECEIVE_DUPLICATE_PROGRAM_TEMPLATE_ERROR,
    RECEIVE_CREATE_PROGRAM,
    RECEIVE_BULK_CREATE_PROGRAMS,
    REQUEST_RESOURCE_SEARCH,
    RECEIVE_RESOURCE_SEARCH,
    RECEIVE_RESOURCE_SEARCH_ERROR,
    REQUEST_UPDATE_EXISTING_PROGRAM,
    RECEIVE_UPDATE_EXISTING_PROGRAM,
    RECEIVE_UPDATE_EXISTING_PROGRAM_ERROR,
    REQUEST_REMOVE_CLIENT_ASSIGNED_PROGRAM,
    RECEIVE_REMOVE_CLIENT_ASSIGNED_PROGRAM,
    RECEIVE_REMOVE_CLIENT_ASSIGNED_PROGRAM_ERROR,
    REQUEST_PROGRAM_TEMPLATES,
    RECEIVE_PROGRAM_TEMPLATES,
    RECEIVE_PROGRAM_TEMPLATES_ERROR,
    ADD_EDITING_LIST_TO_RESOURCES_LIST,
    CANCEL_PROGRAM_CREATION,
    CANCEL_RESOURCES_LIST_CHANGES,
    CLEAR_ASSIGNED_PROGRAM_SESSIONS,
    CLEAR_PROGRAM_SESSIONS,
    CLEAR_IMPORT_LISTS,
    CLEAR_RESOURCE_SEARCH,
    CLOSE_EDIT_LIST,
    IMPORT_SELECTED_LISTS,
    REMOVE_LIST_CONTAINER,
    REMOVE_PROGRAM_LIST_CONTAINER,
    RESET_TEMPLATE_CREATION,
    RESET_ROUTINE_CREATION,
    SELECT_IMPORT_SESSION,
    REMOVE_PROGRAM_TEMPLATE_ROUTINE,
    COPY_PROGRAM_TEMPLATE_ROUTINE,
    SET_EDITING_LIST,
    SET_EDITING_PROGRAM_TEMPLATE,
    SET_EDITING_PROGRAM_RESOURCES,
    SET_PROGRAM_EDITING,
    SET_TEMPLATES_PAGINATION_PAGE,
    SET_ASSIGNED_PROGRAMS,
    SET_EDITING_RESOURCE_NOTES,
    SET_SELECTED_RESOURCES_LIST,
    SET_VIEWING_PROGRAM_RESOURCES,
    SHIFT_SELECTED_RESOURCES_LIST,
    SHIFT_PROGRAM_SESSION_LIST,
    SHIFT_SESSION_LIST,
    UPDATE_EDITING_RESOURCES_LIST,
    UPDATE_TEMPLATE_TITLE,
    fetchPrograms,
    SET_SESSION_NOTE,
    DUPLICATE_LIST_CONTAINER,
    UPDATE_ASSIGNED_PROGRAM_LIST,
    RECEIVE_ADD_RESOURCE_TO_LIST,
    RECEIVE_REORDER_ASSIGNED_PROGRAM_LIST,
    RECEIVE_UPDATE_ASSIGNED_PROGRAM_INFO,
    RECEIVE_COACH_UPDATE_RESOURCE,
    RECEIVE_REMOVE_RESOURCE_FROM_LIST,
    REQUEST_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE,
    RECEIVE_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE,
    RECEIVE_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE_ERROR,
    UPDATE_TEMPLATE_DETAILS,
    SET_EDITING_ROUTINE_TEMPLATE,
    SELECT_ROUTINE_FOR_IMPORT,
    CLEAR_SELECTED_IMPORT_ROUTINES,
    fetchProgramTemplates,
    REQUEST_ADD_RESOURCE_TO_ROUTINE_TEMPLATE,
    RECEIVE_ADD_RESOURCE_TO_ROUTINE_TEMPLATE,
    RECEIVE_ADD_RESOURCE_TO_ROUTINE_TEMPLATE_ERROR,
    REQUEST_UPDATE_ROUTINE_TEMPLATE_INFO,
    RECEIVE_UPDATE_ROUTINE_TEMPLATE_INFO,
    RECEIVE_UPDATE_ROUTINE_TEMPLATE_INFO_ERROR,
    REQUEST_UPDATE_ROUTINE_TEMPLATE_DETAILS,
    RECEIVE_UPDATE_ROUTINE_TEMPLATE_DETAILS,
    RECEIVE_UPDATE_ROUTINE_TEMPLATE_DETAILS_ERROR,
    UPDATE_ROUTINE_DETAILS,
    SET_VIEW_IMPORT_PROGRAM_TEMPLATE,
    RESET_VIEWING_PROGRAM_TEMPLATE,
    RESET_PROGRAM_TEMPLATE_IMPORT,
    resetProgramTemplateImport,
    setCurrentResourcesLibraryTab,
} from "../actions";

const initialState = {
    searchResults: [],
    editingList: null,
    editingListOpen: false,
    editingProgramInfo: null,
    editingProgramResources: [],
    editingProgramTemplate: {},
    editingRoutineTemplate: null,
    initialEditingProgramResources: [],
    initialProgramTemplate: null,
    initialResourcesList: [],
    isEditingProgram: false,
    routineTitle: "",
    selectedResourcesList: [],
    selectedImportLists: [],
    selectedImportRoutines: [],
    viewingProgramResources: [],
    templatesPaginationPage: 1,
};

export function listEditor(state = initialState, action) {
    switch (action.type) {
        case REQUEST_CREATE_NEW_PROGRAM:
            return {
                ...state,
                createNewProgramIsLoading: true,
                createNewProgramRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_NEW_PROGRAM: {
            const { editingProgramTemplate: currentEditingProgramTemplate } = { ...state };
            const currentResources = JSON.parse(JSON.stringify(currentEditingProgramTemplate.program.resources_list));
            const tags = currentEditingProgramTemplate.program.tags;
            const updatedEditingProgramTemplate = {
                program: { ...action.data, tags, resources_list: [...currentResources] },
            };
            const initialProgramTemplate = JSON.parse(JSON.stringify(updatedEditingProgramTemplate.program));

            action.asyncDispatch(fetchPrograms());

            return {
                ...state,
                createNewProgramIsLoading: false,
                createNewProgramReceivedAt: action.receivedAt,
                editingProgramTemplate: updatedEditingProgramTemplate,
                initialProgramTemplate: { program: initialProgramTemplate },
                isEditingProgram: false,
            };
        }
        case RECEIVE_CREATE_NEW_PROGRAM_ERROR:
            return {
                ...state,
                createNewProgramIsLoading: false,
                createNewProgramReceivedAt: action.receivedAt,
                createNewProgramError: action.error,
            };
        case REQUEST_CREATE_NEW_ROUTINE_TEMPLATE:
            return {
                ...state,
                createNewRoutineTemplateIsLoading: true,
                createNewRoutineTemplateRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_NEW_ROUTINE_TEMPLATE: {
            const params = {
                page_size: 20,
                is_routine: true,
                ordering: "-created",
                page: 1,
                ...assignedProgramsFragment,
            };
            action.asyncDispatch(fetchPrograms(params));

            return {
                ...state,
                createNewRoutineTemplateIsLoading: false,
                createNewRoutineTemplateReceivedAt: action.receivedAt,
                editingList: null,
                editingRoutineTemplate: null,
                editingRoutineTemplateOpen: false,
                initialEditingRoutineTemplate: null,
            };
        }
        case RECEIVE_CREATE_NEW_ROUTINE_TEMPLATE_ERROR:
            return {
                ...state,
                createNewRoutineTemplateIsLoading: false,
                createNewRoutineTemplateReceivedAt: action.receivedAt,
                createNewRoutineTemplateError: action.error,
            };
        case REQUEST_DUPLICATE_PROGRAM_TEMPLATE:
            return {
                ...state,
                duplicateProgramTemplateIsLoading: true,
                duplicateProgramTemplateRequestedAt: action.requestedAt,
            };
        case RECEIVE_DUPLICATE_PROGRAM_TEMPLATE: {
            action.asyncDispatch(
                fetchProgramTemplates({
                    page_size: 20,
                    page: 1,
                    ordering: "-created",
                    is_routine: false,
                    ...assignedProgramsFragment,
                })
            );

            return {
                ...state,
                duplicateProgramTemplateIsLoading: false,
                duplicateProgramTemplateReceivedAt: action.receivedAt,
            };
        }
        case RECEIVE_DUPLICATE_PROGRAM_TEMPLATE_ERROR:
            return {
                ...state,
                duplicateProgramTemplateIsLoading: false,
                duplicateProgramTemplateReceivedAt: action.receivedAt,
                duplicateProgramTemplateError: action.error,
            };
        case RECEIVE_CREATE_PROGRAM: {
            const { assignedProgramsList: currentAssignedProgramsList } = { ...state };
            const { data: newProgram } = action;
            const newProgramResources = newProgram.resources_list.resources.map((resource) =>
                processResource(resource)
            );
            newProgram.resources_list.resources = newProgramResources;

            currentAssignedProgramsList.unshift(newProgram);
            const currentProgram = currentAssignedProgramsList.find((program) => program.latest_current);
            const currentProgramIndex = currentAssignedProgramsList.findIndex((program) => program.latest_current);
            if (currentProgram && currentProgramIndex && currentProgramIndex > 0) {
                currentAssignedProgramsList[currentProgramIndex - 1].up_next = true;
            }

            return {
                ...state,
                assignedProgramsList: currentAssignedProgramsList,
                editingListOpen: false,
                initialEditingList: null,
            };
        }
        case RECEIVE_BULK_CREATE_PROGRAMS: {
            const { assignedProgramsList: currentAssignedProgramsList } = { ...state };
            const { data: newPrograms } = action;

            const newProcessedPrograms =
                newPrograms &&
                newPrograms
                    .map((program) => {
                        let newProgram = { ...program };
                        const newProgramResources = newProgram.resources_list.resources.map((resource) =>
                            processResource(resource)
                        );
                        newProgram.resources_list.resources = newProgramResources;

                        return newProgram;
                    })
                    .reverse();

            const newAssignedProgramsList = newProcessedPrograms.concat(currentAssignedProgramsList);
            const currentProgram = newAssignedProgramsList.find((program) => program.latest_current);
            const currentProgramIndex = newAssignedProgramsList.findIndex((program) => program.latest_current);
            if (currentProgram && currentProgramIndex && currentProgramIndex > 0) {
                newAssignedProgramsList[currentProgramIndex - 1].up_next = true;
            }

            action.asyncDispatch(resetProgramTemplateImport());
            action.asyncDispatch(setCurrentResourcesLibraryTab(EXERCISES_LIST_ITEM_ID));

            return {
                ...state,
                assignedProgramsList: newAssignedProgramsList,
            };
        }
        case REQUEST_RESOURCE_SEARCH:
            return {
                ...state,
                resourceSearchIsLoading: true,
                resourceSearchRequestedAt: action.requestedAt,
            };
        case RECEIVE_RESOURCE_SEARCH: {
            const {
                data,
                data: { count, results, next },
            } = action;
            let searchResults = [];

            if (count) {
                searchResults =
                    results && results.length > 0 ? results.map((result) => exerciseToResource(result)) : [];
            } else {
                searchResults = data && data.length > 0 ? data.map((result) => exerciseToResource(result)) : [];
            }

            return {
                ...state,
                resourceSearchIsLoading: false,
                resourceSearchReceivedAt: action.receivedAt,
                searchResults,
                count,
                next,
            };
        }
        case RECEIVE_RESOURCE_SEARCH_ERROR:
            return {
                ...state,
                resourceSearchIsLoading: false,
                resourceSearchReceivedAt: action.receivedAt,
                error: action.error,
            };
        case REQUEST_UPDATE_EXISTING_PROGRAM:
            return {
                ...state,
                updateExistingProgramIsLoading: true,
                updateExistingProgramRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_EXISTING_PROGRAM: {
            const { editingProgramTemplate: currentEditingProgramTemplate } = state;

            const initialTemplate = JSON.parse(JSON.stringify(currentEditingProgramTemplate));

            return {
                ...state,
                updateExistingProgramIsLoading: false,
                updateExistingProgramReceivedAt: action.receivedAt,
                initialProgramTemplates: initialTemplate,
            };
        }
        case RECEIVE_UPDATE_EXISTING_PROGRAM_ERROR:
            return {
                ...state,
                updateExistingProgramIsLoading: false,
                updateExistingProgramReceivedAt: action.receivedAt,
                updateExistingProgramError: action.error,
            };
        case REQUEST_REMOVE_CLIENT_ASSIGNED_PROGRAM:
            return {
                ...state,
                removeClientAssignedProgramIsLoading: true,
                removeClientAssignedProgramRequestedAt: action.requestedAt,
            };
        case RECEIVE_REMOVE_CLIENT_ASSIGNED_PROGRAM: {
            const { assignedProgramsList: currentAssignedProgramsList } = { ...state };
            const { data: removedProgram } = action;

            const removedProgramIndex = currentAssignedProgramsList.findIndex(
                (program) => program.id === removedProgram.id
            );

            currentAssignedProgramsList.splice(removedProgramIndex, 1);

            const currentProgram = currentAssignedProgramsList.find((program) => program.latest_current);
            const currentProgramIndex = currentAssignedProgramsList.findIndex((program) => program.latest_current);

            if (currentProgram && currentProgramIndex > 0) {
                currentAssignedProgramsList[currentProgramIndex - 1].up_next = true;
            }

            return {
                ...state,
                removeClientAssignedProgramIsLoading: false,
                removeClientAssignedProgramReceivedAt: action.receivedAt,
            };
        }
        case RECEIVE_REMOVE_CLIENT_ASSIGNED_PROGRAM_ERROR:
            return {
                ...state,
                removeClientAssignedProgramIsLoading: false,
                removeClientAssignedProgramReceivedAt: action.receivedAt,
                removeClientAssignedProgramError: action.error,
            };
        case REQUEST_PROGRAM_TEMPLATES:
            return {
                ...state,
                programTemplatesIsLoading: true,
                programTemplatesRequestedAt: action.requestedAt,
                initialEditingList: null,
            };
        case RECEIVE_PROGRAM_TEMPLATES: {
            const { count, next, results } = action.data;
            const programTemplates = results.map((template) => {
                const processedResources = createSessionsFromProgramList(template.resources_list.resources);
                template.resources_list = processedResources;

                return template;
            });

            return {
                ...state,
                programTemplatesIsLoading: false,
                programTemplatesReceivedAt: action.receivedAt,
                programTemplates,
                templatesCount: count,
                next,
            };
        }
        case RECEIVE_PROGRAM_TEMPLATES_ERROR:
            return {
                ...state,
                programTemplatesIsLoading: false,
                programTemplatesReceivedAt: action.receivedAt,
                programTemplatesError: action.error,
            };
        case REQUEST_UPDATE_ROUTINE_TEMPLATE_DETAILS:
            return {
                ...state,
                updateRoutineTemplateDetailsIsLoading: true,
                updateRoutineTemplateDetailsRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_ROUTINE_TEMPLATE_DETAILS: {
            const { editingRoutineTemplate: currentEditingRoutineTemplate } = { ...state };
            const {
                description,
                editable_by_orbit_coaches,
                is_global,
                pain_location,
                provider_copy_allowed,
            } = action.data;

            currentEditingRoutineTemplate.description = description;
            currentEditingRoutineTemplate.editable_by_orbit_coaches = editable_by_orbit_coaches;
            currentEditingRoutineTemplate.is_global = is_global;
            currentEditingRoutineTemplate.pain_location = pain_location;
            currentEditingRoutineTemplate.provider_copy_allowed = provider_copy_allowed;
            currentEditingRoutineTemplate.tags = [...action.tags];

            return {
                ...state,
                updateRoutineTemplateDetailsIsLoading: false,
                updateRoutineTemplateDetailsReceivedAt: action.receivedAt,
                editingRoutineTemplate: currentEditingRoutineTemplate,
            };
        }
        case RECEIVE_UPDATE_ROUTINE_TEMPLATE_DETAILS_ERROR:
            return {
                ...state,
                updateRoutineTemplateDetailsIsLoading: false,
                updateRoutineTemplateDetailsReceivedAt: action.receivedAt,
                updateRoutineTemplateDetailsError: action.error,
            };
        case ADD_EDITING_LIST_TO_RESOURCES_LIST: {
            const isCreatingProgram = window.location.pathname.startsWith(COACH_PROGRAM_BUILDER_ROUTE);
            const {
                selectedResourcesList: currentSelectedResourcesList,
                editingProgramResources: currentEditingProgramResources,
            } = state;
            const { title, notes } = action;
            const resources =
                state.editingList.resources &&
                state.editingList.resources.map((resource) => ({ ...resource, group: title }));

            const returnState = {
                ...state,
                editingList: {},
            };

            if (isCreatingProgram) {
                if (resources && resources.length === 0) {
                    returnState.editingProgramResources = [];
                } else {
                    currentEditingProgramResources.push({ title, resources, notes });
                    returnState.editingProgramResources = currentEditingProgramResources;
                }
            } else {
                currentSelectedResourcesList.push({ title, resources, notes });
                returnState.selectedResourcesList = currentSelectedResourcesList;
            }

            return returnState;
        }
        case CANCEL_PROGRAM_CREATION: {
            const { initialEditingProgramResources } = state;
            const initialResourcesList = JSON.parse(JSON.stringify(initialEditingProgramResources));

            return {
                ...state,
                editingProgramResources: initialResourcesList,
            };
        }
        case CANCEL_RESOURCES_LIST_CHANGES: {
            const { initialResourcesList } = state;
            const selectedResourcesList = JSON.parse(JSON.stringify(initialResourcesList));

            return {
                ...state,
                selectedResourcesList,
            };
        }
        case CLEAR_ASSIGNED_PROGRAM_SESSIONS:
            return {
                ...state,
                selectedResourcesList: [],
            };
        case CLEAR_PROGRAM_SESSIONS:
            return {
                ...state,
                editingProgramResources: [],
            };
        case CLEAR_IMPORT_LISTS:
            return {
                ...state,
                selectedImportLists: [],
                viewingProgramResources: [],
            };
        case CLEAR_RESOURCE_SEARCH: {
            const { editingListOpen: isEditingListOpen } = state;

            return {
                ...state,
                searchResults: [],
                editingListOpen: isEditingListOpen ? false : true,
            };
        }
        case CLOSE_EDIT_LIST:
            return {
                ...state,
                editingList: null,
                editingListOpen: false,
                initialEditingList: null,
            };
        case IMPORT_SELECTED_LISTS: {
            const {
                selectedImportLists: currentSelectedImportLists,
                selectedResourcesList: currentSelectedResourcesList,
                editingProgramResources: currentEditingProgramResources,
                viewingProgramResources,
            } = state;
            const { creating } = action;
            let importSessionsList = [];

            if (currentSelectedImportLists && currentSelectedImportLists.length > 0) {
                importSessionsList = currentSelectedImportLists.map((list) => list.session);
            } else {
                importSessionsList = viewingProgramResources;
            }

            const uniqueSessionsList = resolveDuplicateSessionTitles(
                importSessionsList,
                creating ? currentEditingProgramResources : currentSelectedResourcesList
            );
            const mergedResourcesLists = creating
                ? currentEditingProgramResources.concat(uniqueSessionsList)
                : currentSelectedResourcesList.concat(uniqueSessionsList);

            const returnState = {
                ...state,
                selectedImportLists: [],
                viewingProgramResources: [],
            };

            if (creating) {
                returnState.editingProgramResources = mergedResourcesLists;
            } else {
                returnState.selectedResourcesList = mergedResourcesLists;
            }

            return returnState;
        }
        case REMOVE_LIST_CONTAINER: {
            const { selectedResourcesList: currentSelectedResourcesList } = { ...state };

            currentSelectedResourcesList.splice(action.index, 1);

            return {
                ...state,
                selectedResourcesList: currentSelectedResourcesList,
            };
        }
        case REMOVE_PROGRAM_LIST_CONTAINER: {
            const { editingProgramResources: currentEditingProgramResources } = { ...state };

            currentEditingProgramResources.splice(action.index, 1);

            return {
                ...state,
                editingProgramResources: currentEditingProgramResources,
            };
        }
        case DUPLICATE_LIST_CONTAINER: {
            const { selectedResourcesList: currentSelectedResourcesList } = { ...state };

            const duplicateContainer = { ...currentSelectedResourcesList[action.index] };
            const { title: originalTitle } = duplicateContainer;
            duplicateContainer.title = `${originalTitle} (copy)`;

            currentSelectedResourcesList.push(duplicateContainer);

            return {
                ...state,
                selectedResourcesList: currentSelectedResourcesList,
            };
        }
        case RESET_TEMPLATE_CREATION: {
            const {
                programTemplates: currentProgramTemplates,
                editingProgramTemplate: currentEditingProgramTemplate,
                initialProgramTemplate,
            } = state;
            const editingProgramIndex =
                currentProgramTemplates &&
                currentProgramTemplates.length > 0 &&
                currentEditingProgramTemplate &&
                currentEditingProgramTemplate.program &&
                currentProgramTemplates.findIndex(
                    (template) => template.id === currentEditingProgramTemplate.program.id
                );

            if (editingProgramIndex && initialProgramTemplate && initialProgramTemplate.program) {
                currentProgramTemplates[editingProgramIndex] = initialProgramTemplate.program;
            }

            return {
                ...state,
                editingList: null,
                editingListOpen: false,
                editingProgramTemplate: {},
                initialProgramTemplate: null,
                initialEditingList: null,
                isEditingProgram: false,
                programTemplates: currentProgramTemplates,
                templatesPaginationPage: 1,
            };
        }
        case RESET_ROUTINE_CREATION: {
            return {
                ...state,
                editingRoutineTemplate: null,
                editingRoutineTemplateOpen: false,
                initialEditingRoutineTemplate: null,
            };
        }
        case RESET_VIEWING_PROGRAM_TEMPLATE:
            return {
                ...state,
                viewingImportProgramTemplate: null,
                selectedImportRoutines: [],
            };
        case RESET_PROGRAM_TEMPLATE_IMPORT:
            return {
                ...state,
                viewingImportProgramTemplate: null,
                editingList: null,
                editingListOpen: false,
                initialEditingList: null,
            };
        case SELECT_IMPORT_SESSION: {
            const { selectedImportLists: currentSelectedImportLists } = { ...state };
            const selected = currentSelectedImportLists.find((list) => list.index === action.list.index);

            if (selected) {
                const index = currentSelectedImportLists.indexOf(selected);
                currentSelectedImportLists.splice(index, 1);
            } else {
                currentSelectedImportLists.push(action.list);
            }

            return {
                ...state,
                selectedImportLists: currentSelectedImportLists,
            };
        }
        case COPY_PROGRAM_TEMPLATE_ROUTINE: {
            const {
                editingProgramTemplate: { program: currentEditingProgramTemplate },
            } = state;
            const { order } = action;
            const copyRoutine = JSON.parse(
                JSON.stringify(currentEditingProgramTemplate.resources_list.find((routine) => routine.order === order))
            );
            const updatedRoutineResources = copyRoutine.resources.map((res) => ({
                ...res,
                group: copyRoutine.title + " (copy)",
            }));
            copyRoutine.resources = updatedRoutineResources;

            currentEditingProgramTemplate.resources_list.unshift({
                ...copyRoutine,
                title: copyRoutine.title + " (copy)",
            });

            const orderedResourcesList = currentEditingProgramTemplate.resources_list.map((resource, idx) => ({
                ...resource,
                order: idx,
            }));

            currentEditingProgramTemplate.resources_list = orderedResourcesList;

            return {
                ...state,
            };
        }
        case REMOVE_PROGRAM_TEMPLATE_ROUTINE: {
            const {
                editingProgramTemplate: { program: currentEditingProgramTemplate },
            } = state;
            const { order } = action;
            const removeRoutineIndex = currentEditingProgramTemplate.resources_list.findIndex(
                (routine) => routine.order === order
            );
            currentEditingProgramTemplate.resources_list.splice(removeRoutineIndex, 1);

            const orderedResourcesList = currentEditingProgramTemplate.resources_list.map((resource, idx) => ({
                ...resource,
                order: idx,
            }));

            currentEditingProgramTemplate.resources_list = orderedResourcesList;

            return {
                ...state,
            };
        }
        case SET_EDITING_LIST: {
            const {
                list: {
                    id,
                    notes,
                    order,
                    resources: [...resources],
                    title,
                },
            } = action;

            const editingList = { id, title, resources, notes, order };
            const initialEditingList = JSON.parse(JSON.stringify(editingList));

            return {
                ...state,
                editingList,
                editingListOpen: true,
                initialEditingList,
                isEditingExistingList: id && resources.length > 0 ? true : false,
            };
        }
        case SET_EDITING_ROUTINE_TEMPLATE: {
            const {
                list: {
                    created,
                    description,
                    editable_by_orbit_coaches,
                    id,
                    is_global,
                    notes,
                    modified,
                    order,
                    pain_location,
                    provider_copy_allowed,
                    resources: [...resources],
                    tags,
                    title,
                },
            } = action;

            const editingRoutineTemplate = {
                created,
                description,
                editable_by_orbit_coaches,
                id,
                is_global,
                modified,
                pain_location,
                provider_copy_allowed,
                tags,
                title,
                resources,
                notes,
                order,
            };
            const initialEditingRoutineTemplate = JSON.parse(JSON.stringify(editingRoutineTemplate));

            return {
                ...state,
                editingRoutineTemplate,
                editingRoutineTemplateOpen: true,
                initialEditingRoutineTemplate,
            };
        }
        case SELECT_ROUTINE_FOR_IMPORT: {
            const { selectedImportRoutines: currentSelectedImportRoutines } = { ...state };
            const { routine: selectedRoutine } = action;
            const selectedRoutineIndex =
                currentSelectedImportRoutines &&
                currentSelectedImportRoutines.length > 0 &&
                currentSelectedImportRoutines.findIndex((routine) => routine.title === selectedRoutine.title);

            if (currentSelectedImportRoutines.length > 0 && selectedRoutineIndex > -1) {
                currentSelectedImportRoutines.splice(selectedRoutineIndex, 1);
            } else {
                currentSelectedImportRoutines.push(selectedRoutine);
            }

            return {
                ...state,
                selectedImportRoutines: currentSelectedImportRoutines,
            };
        }
        case CLEAR_SELECTED_IMPORT_ROUTINES:
            return {
                ...state,
                selectedImportRoutines: [],
            };
        case SET_EDITING_PROGRAM_TEMPLATE: {
            const { initialProgramTemplate } = state;
            const { template: currentTemplate } = action;
            const orderedResourcesList = currentTemplate.program.resources_list.map((resource, idx) => ({
                ...resource,
                order: idx,
            }));
            currentTemplate.program.resources_list = orderedResourcesList;

            const returnState = {
                ...state,
                editingProgramTemplate: currentTemplate,
                isViewingProgramDetails: false,
            };

            if (!initialProgramTemplate) {
                const initialTemplate = JSON.parse(JSON.stringify(currentTemplate));
                returnState.initialProgramTemplate = initialTemplate;
            }

            return returnState;
        }
        case SET_VIEW_IMPORT_PROGRAM_TEMPLATE: {
            const { template: currentTemplate } = action;
            const orderedResourcesList = currentTemplate.program.resources_list.map((resource, idx) => ({
                ...resource,
                order: idx,
            }));
            currentTemplate.program.resources_list = orderedResourcesList;

            const returnState = {
                ...state,
                viewingImportProgramTemplate: currentTemplate,
            };

            return returnState;
        }
        case SET_EDITING_PROGRAM_RESOURCES: {
            const { activeList, editingProgramLists, limit, list, programInfo: editingProgramInfo } = action;
            const processedResources = list && list.map((resource) => processResource(resource));
            const editingProgramResources =
                processedResources && processedResources.length
                    ? createSessionsFromProgramList(processedResources)
                    : [];
            const multiSessionList = limit && limit >= 0;
            const initialEditingProgramResources = JSON.parse(JSON.stringify(editingProgramResources));

            return {
                ...state,
                activeList,
                editingProgramInfo,
                editingProgramLists,
                editingProgramResources,
                initialEditingProgramResources,
                listMaxItems: limit,
                multiSessionList,
            };
        }
        case SET_PROGRAM_EDITING: {
            const { isEditingProgram } = action;

            return {
                ...state,
                isEditingProgram,
            };
        }
        case SET_TEMPLATES_PAGINATION_PAGE:
            return {
                ...state,
                templatesPaginationPage: action.page,
            };
        case SET_ASSIGNED_PROGRAMS: {
            const { assignedProgramsList } = action;

            const initialProgramsList = JSON.parse(JSON.stringify(assignedProgramsList));

            return {
                ...state,
                initialProgramsList,
                assignedProgramsList,
            };
        }
        case SET_EDITING_RESOURCE_NOTES:
            return {
                ...state,
                isEditingResourceNotes: action.isEditing,
            };
        case SET_SELECTED_RESOURCES_LIST: {
            const { list, limit, title } = action;

            const processedResourcesList = list.map((resource) => processResource(resource));
            const selectedResourcesList = createSessionsFromProgramList(processedResourcesList);
            const multiSessionList = limit && limit >= 0;
            const initialResourcesList = JSON.parse(JSON.stringify(selectedResourcesList));

            return {
                ...state,
                selectedResourcesList,
                initialResourcesList,
                selectedListTitle: title,
                listMaxItems: limit,
                multiSessionList,
            };
        }
        case SET_VIEWING_PROGRAM_RESOURCES: {
            const { resources, title } = action;
            const processedResources = resources.map((resource) => processResource(resource));
            const resourcesList = createSessionsFromProgramList(processedResources);
            const viewingProgramResources = { title, resources: resourcesList };

            return {
                ...state,
                viewingProgramResources,
                selectedImportLists: [],
            };
        }
        case SET_SESSION_NOTE: {
            const { editingList } = state;
            editingList.notes = action.note;

            return {
                ...state,
                editingList,
            };
        }
        case SHIFT_SELECTED_RESOURCES_LIST: {
            const { list: selectedResourcesList } = action;

            return {
                ...state,
                selectedResourcesList,
            };
        }
        case SHIFT_PROGRAM_SESSION_LIST: {
            const { sessionIndex, session } = action;
            const currentEditingProgramResources = [...state.editingProgramResources];
            currentEditingProgramResources[sessionIndex].resources = session;

            return {
                ...state,
                editingProgramResources: currentEditingProgramResources,
            };
        }
        case SHIFT_SESSION_LIST: {
            const { sessionIndex, session } = action;
            const currentSelectedResourcesList = [...state.selectedResourcesList];
            currentSelectedResourcesList[sessionIndex].resources = session;

            return {
                ...state,
                selectedResourcesList: currentSelectedResourcesList,
            };
        }
        case UPDATE_ASSIGNED_PROGRAM_LIST: {
            const { assignedProgramsList: currentAssignedProgramsList } = { ...state };
            const { newList } = action;
            const currentEditingProgram =
                currentAssignedProgramsList &&
                currentAssignedProgramsList.length > 0 &&
                currentAssignedProgramsList.find(
                    (program) => program && program.resources_list && program.resources_list.id === newList.id
                );

            currentEditingProgram.resources_list = newList;

            return {
                ...state,
                assignedProgramsList: currentAssignedProgramsList,
            };
        }
        case UPDATE_EDITING_RESOURCES_LIST: {
            const { editingProgramTemplate: currentEditingProgramTemplate, editingList } = state;
            const { routine } = action;
            const resources = editingList.resources.map((resource, idx) => ({ ...resource, group: routine.title }));
            const editingRoutineIndex =
                currentEditingProgramTemplate &&
                currentEditingProgramTemplate.program &&
                currentEditingProgramTemplate.program.resources_list &&
                currentEditingProgramTemplate.program.resources_list.length > 0 &&
                currentEditingProgramTemplate.program.resources_list.findIndex((r) => r.order === editingList.order);

            if (
                currentEditingProgramTemplate &&
                currentEditingProgramTemplate.program &&
                currentEditingProgramTemplate.program.resources_list.length > 0 &&
                currentEditingProgramTemplate.program.resources_list[editingRoutineIndex]
            ) {
                currentEditingProgramTemplate.program.resources_list[editingRoutineIndex] = { ...routine, resources };
            } else {
                currentEditingProgramTemplate.program.resources_list.unshift({ ...routine, resources });
            }

            currentEditingProgramTemplate.program.resources_list = currentEditingProgramTemplate.program.resources_list.map(
                (routine, idx) => ({ ...routine, order: idx })
            );

            return {
                ...state,
                initialEditingList: null,
                editingList: null,
                editingProgramTemplate: currentEditingProgramTemplate,
                editingListOpen: false,
            };
        }
        case UPDATE_TEMPLATE_TITLE: {
            const { editingProgramTemplate: currentEditingProgramTemplate } = state;
            currentEditingProgramTemplate.program.title = action.title;

            return {
                ...state,
                editingProgramTemplate: currentEditingProgramTemplate,
            };
        }
        case UPDATE_TEMPLATE_DETAILS: {
            const { editingProgramTemplate: currentEditingProgramTemplate } = state;
            const {
                details: {
                    description,
                    editable_by_orbit_coaches,
                    is_global,
                    pain_location,
                    provider_copy_allowed,
                    tags,
                },
            } = action;

            currentEditingProgramTemplate.program.description = description;
            currentEditingProgramTemplate.program.editable_by_orbit_coaches = editable_by_orbit_coaches;
            currentEditingProgramTemplate.program.is_global = is_global;
            currentEditingProgramTemplate.program.pain_location = pain_location;
            currentEditingProgramTemplate.program.provider_copy_allowed = provider_copy_allowed;
            currentEditingProgramTemplate.program.tags = tags;

            return {
                ...state,
                editingProgramTemplate: currentEditingProgramTemplate,
            };
        }
        case UPDATE_ROUTINE_DETAILS: {
            const { editingRoutineTemplate: currentEditingRoutineTemplate } = state;
            const {
                details: {
                    description,
                    editable_by_orbit_coaches,
                    is_global,
                    pain_location,
                    provider_copy_allowed,
                    tags,
                    title,
                },
                tagsList,
            } = action;

            const tagLabels =
                tags &&
                tags.length > 0 &&
                tags.map((tag) => {
                    const matchingTagIndex = tagsList && tagsList.findIndex((t) => t.id === tag);
                    const matchingTag =
                        matchingTagIndex > -1 && tagsList && tagsList.length > 0 && tagsList[matchingTagIndex]
                            ? tagsList[matchingTagIndex].title
                            : null;

                    return matchingTag;
                });

            currentEditingRoutineTemplate.description = description;
            currentEditingRoutineTemplate.editable_by_orbit_coaches = editable_by_orbit_coaches;
            currentEditingRoutineTemplate.is_global = is_global;
            currentEditingRoutineTemplate.pain_location = pain_location;
            currentEditingRoutineTemplate.provider_copy_allowed = provider_copy_allowed;
            currentEditingRoutineTemplate.tags = tagLabels;
            currentEditingRoutineTemplate.title = title;

            return {
                ...state,
                editingRoutineTemplate: currentEditingRoutineTemplate,
            };
        }
        case RECEIVE_ADD_RESOURCE_TO_LIST: {
            const { editingList: currentEditingList } = { ...state };
            const updatedResource = processResource(action.data);

            currentEditingList.resources.push(updatedResource);

            return {
                ...state,
                editingList: currentEditingList,
            };
        }
        case RECEIVE_REMOVE_RESOURCE_FROM_LIST: {
            const { editingList: currentEditingList } = { ...state };
            const indexOfRemovedResource = currentEditingList.resources.findIndex(
                (resource) => resource.id === action.id
            );

            currentEditingList.resources.splice(indexOfRemovedResource, 1);

            return {
                ...state,
                editingList: currentEditingList,
            };
        }
        case REQUEST_ADD_RESOURCE_TO_ROUTINE_TEMPLATE:
            return {
                ...state,
                isAddingResourceToRoutineTemplate: true,
                addResourceToRoutineTemplateRequestedAt: action.requestedAt,
            };
        case RECEIVE_ADD_RESOURCE_TO_ROUTINE_TEMPLATE: {
            const { editingRoutineTemplate: currentEditingRoutineTemplate } = { ...state };
            const updatedResource = processResource(action.data);

            currentEditingRoutineTemplate.resources.push(updatedResource);

            return {
                ...state,
                editingList: currentEditingRoutineTemplate,
            };
        }
        case RECEIVE_ADD_RESOURCE_TO_ROUTINE_TEMPLATE_ERROR:
            return {
                ...state,
                isAddingResourceToRoutineTemplate: false,
                addResourceToRoutineTemplateReceivedAt: action.receivedAt,
                addResourceToRoutineTemplateError: action.error,
            };
        case REQUEST_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE:
            return {
                ...state,
                isRemovingResourceFromRoutineTemplate: true,
                removeResourceFromRoutineTemplateRequestedAt: action.requestedAt,
            };
        case RECEIVE_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE: {
            const { editingRoutineTemplate: currentEditingRoutineTemplate } = { ...state };
            const indexOfRemovedResource = currentEditingRoutineTemplate.resources.findIndex(
                (resource) => resource.id === action.id
            );

            currentEditingRoutineTemplate.resources.splice(indexOfRemovedResource, 1);

            return {
                ...state,
            };
        }
        case RECEIVE_REMOVE_RESOURCE_FROM_ROUTINE_TEMPLATE_ERROR:
            return {
                ...state,
                isRemovingResourceFromRoutineTemplate: false,
                removeResourceFromRoutineTemplateReceivedAt: action.receivedAt,
                removeResourceFromRoutineTemplateError: action.error,
            };
        case RECEIVE_COACH_UPDATE_RESOURCE: {
            const { editingList: currentEditingList } = { ...state };

            if (!currentEditingList) return { ...state };

            const { data } = action;
            const currentResource =
                currentEditingList &&
                currentEditingList.resources &&
                currentEditingList.resources.find((resource) => resource.id === action.data.id);
            currentResource.notes = data.notes;

            return {
                ...state,
                editingList: currentEditingList,
            };
        }
        case RECEIVE_REORDER_ASSIGNED_PROGRAM_LIST: {
            const { assignedProgramsList: currentAssignedProgramsList } = { ...state };
            const existingUpNextProgram = currentAssignedProgramsList.find((program) => program.up_next);

            if (existingUpNextProgram) delete existingUpNextProgram.up_next;

            const latestProgramIndex = currentAssignedProgramsList.findIndex((program) => program.latest_current);

            // Set next program as up next
            if (latestProgramIndex > 0) {
                currentAssignedProgramsList[latestProgramIndex - 1].up_next = true;
            }

            return {
                ...state,
                assignedProgramsList: currentAssignedProgramsList,
            };
        }
        case RECEIVE_UPDATE_ASSIGNED_PROGRAM_INFO: {
            const { assignedProgramsList: currentAssignedProgramsList } = { ...state };
            const {
                data: { current, hidden, initiated, id: programId, title, notes, modified },
            } = action;
            const editingProgram = currentAssignedProgramsList.find((program) => program.id === programId);
            const currentLatestProgram = currentAssignedProgramsList.find((program) => program.latest_current);

            editingProgram.hidden = hidden;
            editingProgram.initiated = initiated;
            editingProgram.title = title;
            editingProgram.modified = modified;
            editingProgram.notes = notes;
            editingProgram.current = current;

            if (
                currentLatestProgram &&
                editingProgram.current &&
                moment(editingProgram.current).valueOf() > moment(currentLatestProgram.current).valueOf()
            ) {
                const existingUpNextProgram = currentAssignedProgramsList.find((program) => program.up_next);

                if (currentLatestProgram) delete currentLatestProgram.latest_current;
                if (existingUpNextProgram) delete existingUpNextProgram.up_next;

                const latestProgramIndex = currentAssignedProgramsList.findIndex(
                    (program) => program.id === editingProgram.id
                );

                // Set next program as up next
                if (latestProgramIndex > 0) {
                    currentAssignedProgramsList[latestProgramIndex - 1].up_next = true;
                }

                editingProgram.latest_current = true;
            } else if (!currentLatestProgram && editingProgram.current) {
                editingProgram.latest_current = true;
            }

            return {
                ...state,
                assignedProgramsList: currentAssignedProgramsList,
            };
        }
        case REQUEST_UPDATE_ROUTINE_TEMPLATE_INFO:
            return {
                ...state,
                isUpdatingRoutineTemplateInfo: true,
                updateRoutineTemplateInfoRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_ROUTINE_TEMPLATE_INFO: {
            const { editingRoutineTemplate: currentEditingRoutineTemplate } = { ...state };
            const {
                data: { created, current, hidden, initiated, title, notes, modified },
            } = action;

            currentEditingRoutineTemplate.created = created;
            currentEditingRoutineTemplate.hidden = hidden;
            currentEditingRoutineTemplate.initiated = initiated;
            currentEditingRoutineTemplate.title = title;
            currentEditingRoutineTemplate.modified = modified;
            currentEditingRoutineTemplate.notes = notes;
            currentEditingRoutineTemplate.current = current;

            return {
                ...state,
                isUpdatingRoutineTemplateInfo: false,
                updateRoutineTemplateInfoReceivedAt: action.receivedAt,
                editingRoutineTemplate: currentEditingRoutineTemplate,
            };
        }
        case RECEIVE_UPDATE_ROUTINE_TEMPLATE_INFO_ERROR:
            return {
                ...state,
                isUpdatingRoutineTemplateInfo: false,
                updateRoutineTemplateInfoReceivedAt: action.receivedAt,
                updateRoutineTemplateInfoError: action.error,
            };
        default:
            return state;
    }
}
