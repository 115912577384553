import axios from "axios";

import { logMeOut } from "../app/actions";
import { store } from "../index";

function getCookie(name) {
    if (!document.cookie) {
        return null;
    }

    const xsrfCookies = document.cookie
        .split(";")
        .map((c) => c.trim())
        .filter((c) => c.startsWith(name + "="));

    if (xsrfCookies.length === 0) {
        return null;
    }

    return decodeURIComponent(xsrfCookies[0].split("=")[1]);
}

// default post headers
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(function (config) {
    config.headers.common["X-CSRFToken"] = getCookie("csrftoken");
    return config;
});

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const {
            response: { status },
        } = error;

        if (status === 401 && !error.response.config.url.startsWith("/api/v3/me")) {
            store.dispatch(logMeOut({ inactive: true }));
        }

        return Promise.reject(error);
    }
);

export const axiosDelete = (...args) => axios.delete(...args);
export const get = (...args) => axios.get(...args);
export const post = (...args) => axios.post(...args);
export const patch = (...args) => axios.patch(...args);
export const put = (...args) => axios.put(...args);

export function websocketURL() {
    const {
        location: { hostname },
    } = window;

    const windowSubdomain = hostname.split(".")[0];
    let websocketProtocol = "wss";
    let websocketHost = hostname;

    if (windowSubdomain === "localhost") {
        const { REACT_APP_DJANGO_SERVER_PORT = 8000 } = process.env;

        websocketProtocol = "ws";
        websocketHost = `localhost:${REACT_APP_DJANGO_SERVER_PORT}`;
    }

    return `${websocketProtocol}://${websocketHost}/ws/`;
}

// const websocketMessageHandlers = {
//     user_signout: async (data) => {
//         await store.dispatch(logMeOut({inactive: true}));
//     },
//     init_video_session: async(data) => {
//         await store.dispatch(videoSession(data));
//     }
// };
//
// export function initWebsocket() {
//     const {
//         location: {hostname},
//     } = window;
//
//     const windowSubdomain = hostname.split('.')[0];
//     let websocketProtocol = 'wss';
//     let websocketHost = hostname;
//
//     if (windowSubdomain === 'localhost') {
//         const {REACT_APP_DJANGO_SERVER_PORT = 8000} = process.env;
//
//         websocketProtocol = 'ws';
//         websocketHost = `localhost:${REACT_APP_DJANGO_SERVER_PORT}`;
//     }
//
//     const websocketUrl = `${websocketProtocol}://${websocketHost}/ws/`;
//
//     // create new Websocket instance and store on window object for global access
//     if (!window.tsWebsocket || window.tsWebsocket.readyState !== WebSocket.OPEN) {
//         window.tsWebsocket = new WebSocket(websocketUrl);
//     } else {
//         return;
//     }
//
//     window.tsWebsocket.onopen = (event) => {
//         // connection established successfully
//         if (window.tsWebsocket && window.tsWebsocket.retryHandler !== undefined) {
//             // clear reconnect timeout if exists
//             clearTimeout(window.tsWebsocket.retryHandler);
//             window.tsWebsocket.retryHandler = undefined;
//         }
//     };
//
//     window.tsWebsocket.onclose = (event) => {
//         // connection closed without an explicit error
//         const {code, reason = 'unknown', wasClean} = event;
//
//         if (wasClean) {
//             // close handshake received from server
//             console.log('Websocket connection closed');
//         } else {
//             // connection closed without receiving a handshake from the server
//             logger.warn('Websocket connection closed with code', code, 'and reason', reason);
//         }
//
//         if (window.tsWebsocket && window.tsWebsocket.retryHandler === undefined) {
//             // set an timeout to keep retrying new connection
//             window.tsWebsocket.retryHandler = setTimeout(() => {
//                 initWebsocket();
//             }, 5000);
//         }
//     };
//
//     window.tsWebsocket.onerror = (event) => {
//         // something went wrong
//         logger.error('Error from websocket:', event.message || event);
//     };
//
//     window.tsWebsocket.onmessage = (event) => {
//         // message received from the server
//         try {
//             const {data = ''} = event;
//             const dataObject = JSON.parse(data);
//             const {type} = dataObject;
//
//             websocketMessageHandlers[type](dataObject);
//         } catch (error) {
//             logger.error('Error handling websocket message:', error);
//         }
//     };
// }
//
// export function deactivateWebsocket() {
//     const {tsWebsocket} = window;
//
//     if (tsWebsocket) {
//         try {
//             tsWebsocket.close();
//             delete window.tsWebsocket;
//         } catch (error) {
//             logger.error('Error deactivating websocket:', error.toString());
//         }
//     }
// }
