import { getPublicImage } from "./helpers";

export const orbitThemeRegex = /(orbittelehealth.com|localhost)/i;
export const mfrThemeRegex = /(mfrtherapists.com|localhost)/i;

// feature flag ids
export const ASSESSMENTS_FEATURE_FLAG_ID = "assessments";
export const ON_DEMANDS_FEATURE_FLAG_ID = "on_demands";
export const SOAP_NOTES_FEATURE_FLAG_ID = "soap_notes";
export const VIDEO_CONSULTS_FEATURE_FLAG_ID = "video_consults";

export const INCOMPLETE_STRIPE_STATUSES = ["canceled", "past_due", "incomplete_expired", "incomplete"];

const orbitSSOGroupIds = [
    "297532",
    "297534",
    "297537",
    "300954",
    "300958",
    "300959",
    "300960",
    "300961",
    "300962",
    "306653",
    "306654",
    "306655",
    "306656",
    "306657",
    "12345", // Test SSO Group ID for staging
];

export const PATIENT_INVITE_LIMIT = 5;

export const commonTierFeatures = [
    "Provider Dashboard",
    "Care Plan Builder",
    "Orbit Resource Library",
    "HIPAA Compliant App",
    "Real Time Messaging",
];

export function brandingCan(branding, featureFlagId) {
    // const { tier } = branding;

    // return !tier || (tier && tier.feature_flags && tier.feature_flags.includes(featureFlagId));
    return true;
}

/**
 *
 * @param {*} group
 * @returns Returns branding assosicated with the group. If the group does not have branding, return the parent group's branding.
 */
export function getBrandingFromGroup(group) {
    if (group && group.branding && group.branding.display_name) {
        return group.branding;
    }

    return group && group.parent && group.parent.branding;
}

/**
 *
 * @param {*} group
 * @returns Returns signup config assosicated with the group. If the group does not have a signup config, return the parent group's config.
 */
export function getSignupConfigFromGroup(group) {
    if (group && group.signup_config) {
        return group.signup_config;
    }

    return group && group.parent && group.parent.signup_config;
}

export function getBrandingSubdomain() {
    const {
        location: { hostname },
    } = window;

    const windowSubdomain = hostname.split(".")[0];
    let subdomain = windowSubdomain;

    if (windowSubdomain === "localhost") {
        subdomain = "app";
    }

    return subdomain;
}

export function getGroupDomain(group) {
    if (group && group.domain) {
        return group.domain;
    } else if (group && group.parent && group.parent.domain) {
        return group.parent.domain;
    } else {
        return null;
    }
}

export function isOrbitSSOGroup(id) {
    return orbitSSOGroupIds.includes(id);
}

export function getBrandingLogo(branding, me, logoField = "color_logo") {
    const SSOGroupId = me.group && me.group.partner_id;
    const meBranding =
        me.group && me.group.branding
            ? me.group.branding
            : me.group && me.group.parent && me.group.parent.branding
            ? me.group.parent.branding
            : null;

    // Hardcode centura logo because the email logo is different from the app logo
    if (meBranding && meBranding.name === "CNTR") {
        const logoValue =
            logoField === "white_logo"
                ? getPublicImage("inspire_wellbeing_logo_white.webp")
                : getPublicImage("inspire_wellbeing_logo.webp");

        if (logoValue && logoValue.length > 0) {
            return logoValue;
        }
    }

    if (SSOGroupId && isOrbitSSOGroup(SSOGroupId)) {
        switch (logoField) {
            case "white_logo":
                return getPublicImage("orbit_logo_white.png");
            case "product_logo":
                return getPublicImage("orbit_teladoc_product_logo.png");
            default:
                return getPublicImage("orbit_logo_color.png");
        }
    }

    if (
        (meBranding && !me.is_coach && Object.prototype.hasOwnProperty.call(meBranding, logoField)) ||
        (meBranding &&
            me.is_coach &&
            meBranding.is_organization &&
            Object.prototype.hasOwnProperty.call(meBranding, logoField))
    ) {
        const logoValue = meBranding[logoField];

        if (logoValue && logoValue.length > 0) {
            return logoValue;
        }
    }

    if (Object.prototype.hasOwnProperty.call(branding, logoField)) {
        const logoValue = branding[logoField];

        if (logoValue && logoValue.length > 0) {
            return logoValue;
        }
    }

    return getPublicImage(logoField === "white_logo" ? "orbit_logo_white.png" : "orbit_logo_color.png");
}

export function getProductName(branding, me) {
    const SSOGroupId = me.group && me.group.partner_id;
    if (SSOGroupId && isOrbitSSOGroup(SSOGroupId)) return "Orbit";

    return branding.product_name || "Telespine";
}

export function getOrbitRedirectLocation() {
    const orbitBrandings = [
        "2020",
        "animalsupply",
        "app",
        "backjoy",
        "backlab",
        "centura-staging",
        "cintas",
        "demo",
        "dorman",
        "emi",
        "emi-staging",
        "h2u",
        "healthyback-staging",
        "hlth",
        "hpe",
        "neckdemo",
        "nimblehealth",
        "nimblehealth-staging",
        "mhp",
        "panorama",
        "pepsico",
        "revive",
        "revive-staging",
        "savvy",
        "staging",
        "tdee",
        "uhc",
        "usmag",
        "teladoc-staging",
        "teladoc",
        "hytdh-staging",
        "hytdh",
    ];
    let {
        location: { hostname, href },
    } = window;
    const shouldRedirect = !orbitThemeRegex.test(hostname) && orbitBrandings.includes(getBrandingSubdomain());

    if (shouldRedirect) {
        href = href.replace("telespine", "orbittelehealth");

        return href;
    }

    return false;
}

export function getWellviewRedirectLocation() {
    let {
        location: { hostname, href },
    } = window;
    const shouldRedirect = hostname.includes("wellview");

    if (shouldRedirect) {
        href = href.replace("telespine", "orbittelehealth");
        href = href.replace("wellview", "sentry");

        return href;
    }

    return false;
}

export function getSignupCopy(type, branding, productName) {
    if (type === "title") {
        if (branding.is_organization && branding.tier) {
            switch (branding.tier.title) {
                case "Virtual clinic":
                    return "Getting the support you need has never been easier";
                default:
                    return "Getting better has never been so convenient";
            }
        } else {
            return `${productName} is a simple, proven solution for back and joint pain.`;
        }
    } else {
        if (branding.is_organization && branding.tier) {
            switch (branding.tier.title) {
                case "Virtual clinic":
                    return "Connect with your provider over video or chat to get the support and guidance you need, whenever or wherever you may need it";
                default:
                    return "Your provider will work with you one-on-one to create a personalized care plan that meets your needs, whenever or wherever you are.";
            }
        } else {
            return "Our approach puts you in control of your recovery. A dedicated health coach will provide you with all the expert guidance, resources and tools you need to start feeling better now and avoid what causes pain going forward.";
        }
    }
}
