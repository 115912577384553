import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
    name: PropTypes.string,
};

export default function RHFTextField({ name, phone = false, ...other }) {
    const { control, setValue } = useFormContext();
    const history = useHistory();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const state = searchParams.get('state');
        const city = searchParams.get('city');

        if(name === "address" && city && state) {
            setValue(name, `${city}, ${state}`)
        }
    },[history.location.search, name, setValue])

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    value={typeof field.value === "number" && field.value === 0 ? "" : field.value || ""}
                    error={!!error}
                    size="small"
                    helperText={error?.message}
                    className="rhf-text-field"
                    {...other}
                />
            )}
        />
    );
}
