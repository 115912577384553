// ----------------------------------------------------------------------

const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        mobile: 0,
        tablet: 769,
        desktop: 1200,
    },
};

export default breakpoints;
