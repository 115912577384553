import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";

import LoginLayout from "./LoginLayout";
import TextInput from "../text_input/TextInput";
import { toast } from "../notifications/Toast";
import Link from "../link/Link";

import { REQUIRED_FIELD, EMAIL } from "../../utils/validations";
import { submitForgotPassword } from "../../app/actions";
import { LOGIN_ROUTE } from "../../utils/routes";
import { executeRecaptcha } from "../../utils/helpers";
import Button from "../button/Button";

const schema = yup.object().shape({
    email: yup.string().required(REQUIRED_FIELD.message).email(EMAIL.message),
});

function ForgotPassword({ dispatch }) {
    const [submittedEmail, setSubmittedEmail] = useState("");
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const mfrDomain =
        window.location.hostname.includes("mfrtherapists.com") || window.location.hostname.includes("mfrhealth.com");

    const handleForgotPassword = async (values, notifySuccess = false) => {
        const { email } = values;

        executeRecaptcha("forgot_password")
            .then(async (recaptchaResponse) => {
                const params = {
                    email: email || submittedEmail,
                    "g-recaptcha-response": recaptchaResponse,
                };
                const result = await dispatch(submitForgotPassword(params));
                const { error } = result;

                if (error) {
                    toast.error("Error resetting password");
                } else {
                    // submission successful
                    if (notifySuccess === true) {
                        toast.success("Email sent");
                    }

                    setSubmittedEmail(email);
                    setSubmissionSuccess(true);
                }
            })
            .catch((error) => {
                console.log("Error executing Recaptcha:", error.toString());
                toast.error(error);
            });
    };

    return (
        <LoginLayout>
            <div className="ts-login">
                <div className="ts-login__content">
                    {submissionSuccess ? (
                        <>
                            <h2 className="mb-3">Success!</h2>
                            <p>Check your email for a link to reset your password.</p>

                            <Formik
                                initialValues={{ email: submittedEmail }}
                                onSubmit={() => handleForgotPassword({ email: submittedEmail }, true)}
                            >
                                {({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-row mb-4">
                                            <div className="col">
                                                <Link
                                                    analyticsClass="button__forgot-password-back-to-login"
                                                    to={LOGIN_ROUTE}
                                                >
                                                    Back to Login
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col">
                                                <Button
                                                    square
                                                    secondary
                                                    analyticsClass="button__resend-reset-link"
                                                    type="submit"
                                                >
                                                    Resend reset link
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </>
                    ) : (
                        <Formik initialValues={{ email: "" }} onSubmit={handleForgotPassword} validationSchema={schema}>
                            {({ errors, values, handleChange, handleSubmit, touched }) => (
                                <form onSubmit={handleSubmit}>
                                    <h1 className="mb-3 text-xl">
                                        {mfrDomain ? "Myofascial Release Therapist Forgot Password" : "Forgot Password"}
                                    </h1>
                                    <p>Enter your email address below and we'll send you a reset link.</p>

                                    <div className="form-row mb-3">
                                        <div className="col">
                                            <TextInput
                                                id="email"
                                                name="email"
                                                label="Email"
                                                onChange={handleChange}
                                                value={values.email}
                                                type="email"
                                                error={errors.email}
                                                touched={touched.email}
                                                autoComplete="email"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row mb-4">
                                        <div className="col">
                                            <Link
                                                analyticsClass="button__forgot-password-back-to-login"
                                                to={LOGIN_ROUTE}
                                            >
                                                Back to Login
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col">
                                            <Button
                                                square
                                                secondary
                                                analyticsClass="button__send-reset-link"
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
        </LoginLayout>
    );
}

const ForgotPassword_ = withRouter(ForgotPassword);

export default connect()(ForgotPassword_);
