import React from "react";
import moment from "moment-timezone";
import { get, patch, post } from "../../utils/network";
import { matchPath } from "react-router-dom";

import logger from "../../utils/logger";
import { exq } from "../../utils/helpers";
import { gen_error, gen_received, gen_requested } from "./generators";
import { ON_DEMAND_WAITING_ROOM_ROUTE, VIDEO_SESSION_ROUTE } from "../../utils/routes";

import { toast } from "../../components/notifications/Toast";
import OnDemandNotification from "../../components/coach/OnDemandNotification";

export const RECEIVE_VIDEO_SESSION_CONFIG = "RECEIVE_VIDEO_SESSION_CONFIG";
export const RECEIVE_VIDEO_SESSION_CONFIG_ERROR = "RECEIVE_VIDEO_SESSION_CONFIG_ERROR";

// TODO: Remove that
export const getVideoSessionConfig = () => async (dispatch) => {
    try {
        const result = await post("/api/v1/meetings/video_call/");

        return dispatch({
            type: RECEIVE_VIDEO_SESSION_CONFIG,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_VIDEO_SESSION_CONFIG_ERROR,
            error: error,
        });
    }
};

export const REQUEST_VIDEO_SESSION_DATA = "REQUEST_VIDEO_SESSION_DATA";
export const RECEIVE_VIDEO_SESSION_DATA = "RECEIVE_VIDEO_SESSION_DATA";
export const RECEIVE_VIDEO_SESSION_DATA_ERROR = "RECEIVE_VIDEO_SESSION_DATA_ERROR";
export const getVideoSessionData = (sessionId, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_VIDEO_SESSION_DATA));

        const result = await get(`/api/v3/meetings/session/${sessionId}`, options);

        return dispatch(gen_received(RECEIVE_VIDEO_SESSION_DATA, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_VIDEO_SESSION_DATA_ERROR, error));
    }
};

export const UPDATE_VIDEO_SESSION_DATA = "UPDATE_VIDEO_SESSION_DATA";
export const updateVideoSessionData = (sessionData) => async (dispatch) => {
    return dispatch({
        type: UPDATE_VIDEO_SESSION_DATA,
        sessionData,
    });
};

export const RECEIVE_STOP_RECORDING_DATA = "RECEIVE_STOP_RECORDING_DATA";
export const RECEIVE_STOP_RECORDING_DATA_ERROR = "RECEIVE_STOP_RECORDING_DATA_ERROR";

export const getStopRecordingData = (meetingId) => async (dispatch) => {
    try {
        const result = await get(`/api/v3/meetings/${meetingId}/stop_recording/`);

        return dispatch({
            type: RECEIVE_STOP_RECORDING_DATA,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_STOP_RECORDING_DATA_ERROR,
            error: error,
        });
    }
};

export const RECEIVE_COMPLETE_MEETING_DATA = "RECEIVE_COMPLETE_MEETING_DATA";
export const RECEIVE_COMPLETE_MEETING_ERROR = "RECEIVE_COMPLETE_MEETING_ERROR";

export const completeMeeting = (meetingId) => async (dispatch, getState) => {
    try {
        const {
            me: { id },
        } = getState();

        const result = await get(`/api/v3/meetings/${meetingId}/complete_meeting/`);
        logger.info(`Complete meeting called by user ${id}`);

        return dispatch({
            type: RECEIVE_COMPLETE_MEETING_DATA,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_COMPLETE_MEETING_ERROR,
            error,
        });
    }
};

export const REQUEST_ON_DEMAND_CONSULT = "REQUEST_ON_DEMAND_CONSULT";
export const RECEIVE_ON_DEMAND_CONSULT = "RECEIVE_ON_DEMAND_CONSULT";
export const RECEIVE_ON_DEMAND_CONSULT_ERROR = "RECEIVE_ON_DEMAND_CONSULT_ERROR";
export const requestOnDemandConsult = () => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_ON_DEMAND_CONSULT));

        const {
            me: { id },
        } = getState();

        logger.info(`On demand requested by user ${id} on location ${window.location.href}`);
        const params = {
            patient: id,
            on_demand: true,
            answers: [],
        };
        const result = await post("/api/v3/meetings/", params);

        return dispatch(gen_received(RECEIVE_ON_DEMAND_CONSULT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ON_DEMAND_CONSULT_ERROR, error));
    }
};

export const RECEIVE_WAITING_ROOM_MEETING = "RECEIVE_WAITING_ROOM_MEETING";
export const checkWaitingRoom = (meetingData) => async (dispatch, getState) => {
    const {
        me: { is_coach },
    } = getState();

    // coaches cannot be in the waiting room, do nothing
    if (is_coach) return;

    // only dispatch the action if the user is actively in the waiting room
    const inWaitRoom = matchPath(window.location.pathname, ON_DEMAND_WAITING_ROOM_ROUTE);
    if (inWaitRoom) {
        dispatch({
            type: RECEIVE_WAITING_ROOM_MEETING,
            data: meetingData,
        });
    }
};

export const checkMissMeeting = () => (dispatch, getState) => {
    const {
        consult: { onDemandMeeting },
        router: {
            location: { pathname },
        },
    } = getState();

    if (onDemandMeeting && !matchPath(pathname, VIDEO_SESSION_ROUTE)) {
        // user is not navigating to video session route, miss meeting
        dispatch(missMeeting(onDemandMeeting.id));
    }
};

export const MAXIMUM_WAIT_TIME_REACHED = "MAXIMUM_WAIT_TIME_REACHED";
export const maximumWaitTimeReached = (meetingId) => async (dispatch, getState) => {
    // mark the provided meeting as missed if the user is still in the waiting room
    const inWaitRoom = matchPath(window.location.pathname, ON_DEMAND_WAITING_ROOM_ROUTE);

    if (inWaitRoom) {
        const {
            me: { id },
        } = getState();

        logger.info(`Maximum wait room time reached for user ${id}`);
        dispatch(missMeeting(meetingId));
    }
};

export const VIDEO_SESSION = "VIDEO_SESSION";
export const videoSession = (data, showToast = true) => async (dispatch, getState) => {
    const {
        me,
        me: { id, is_coach },
        prices,
    } = getState();

    logger.info(`Initiating video session for user ${id}`);
    if (showToast && is_coach) {
        toast.info(<OnDemandNotification {...data} />, {
            autoClose: false,
            closeOnClick: false,
        });
    }

    return dispatch({
        type: VIDEO_SESSION,
        data,
        me,
        prices,
    });
};

export const REQUEST_MEETINGS = "REQUEST_MEETINGS";
export const RECEIVE_MEETINGS = "RECEIVE_MEETINGS";
export const RECEIVE_MEETINGS_ERROR = "RECEIVE_MEETINGS_ERROR";
export const fetchMeetings = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_MEETINGS));

        const result = await get("/api/v3/meetings/", options);

        return dispatch(gen_received(RECEIVE_MEETINGS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_MEETINGS_ERROR, error));
    }
};

export const REQUEST_ALL_MEETINGS = "REQUEST_ALL_MEETINGS";
export const RECEIVE_ALL_MEETINGS = "RECEIVE_ALL_MEETINGS";
export const RECEIVE_ALL_MEETINGS_ERROR = "RECEIVE_ALL_MEETINGS_ERROR";
export const fetchAllMeetings = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ALL_MEETINGS));

        const result = await get("/api/v3/meetings/", { params });

        return dispatch(gen_received(RECEIVE_ALL_MEETINGS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ALL_MEETINGS_ERROR, error));
    }
};

export const REQUEST_CALENDAR_MEETINGS = "REQUEST_CALENDAR_MEETINGS";
export const RECEIVE_CALENDAR_MEETINGS = "RECEIVE_CALENDAR_MEETINGS";
export const RECEIVE_CALENDAR_MEETINGS_ERROR = "RECEIVE_CALENDAR_MEETINGS_ERROR";
export const fetchCalendarMeetings = (options = {}) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_CALENDAR_MEETINGS));
        const { me } = getState();

        const result = await get("/api/v3/meetings/", options);

        return dispatch({
            type: RECEIVE_CALENDAR_MEETINGS,
            receivedAt: moment(),
            data: result.data,
            me,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CALENDAR_MEETINGS_ERROR, error));
    }
};

export const REQUEST_MEETING = "REQUEST_MEETING";
export const RECEIVE_MEETING = "RECEIVE_MEETING";
export const RECEIVE_MEETING_ERROR = "RECEIVE_MEETING_ERROR";
export const fetchMeeting = (meetingId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REQUEST_MEETING,
            requestedAt: new Date(),
            isFetching: true,
            meetingId,
        });

        const {
            me: { is_coach },
        } = getState();

        const meetingParams = {
            params: {
                expand: exq`
                    coach
                    patient
                `,
            },
        };
        const result = await get(`/api/v3/meetings/${meetingId}/`, meetingParams);

        return dispatch({
            type: RECEIVE_MEETING,
            receivedAt: new Date(),
            isFetching: false,
            data: result.data,
            meetingId,
            is_coach,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_MEETING_ERROR,
            receivedAt: new Date(),
            isFetching: false,
            error,
            meetingId,
        });
    }
};

export const REQUEST_CANCEL_MEETING = "REQUEST_CANCEL_MEETING";
export const RECEIVE_CANCEL_MEETING = "RECEIVE_CANCEL_MEETING";
export const RECEIVE_CANCEL_MEETING_ERROR = "RECEIVE_CANCEL_MEETING_ERROR";
export const cancelMeeting = (id) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_CANCEL_MEETING));
        const cancelResult = await get(`/api/v3/meetings/${id}/cancel/`);
        await dispatch(
            fetchMeetings({
                params: {
                    expand: exq`
                        coach
                        patient
                    `,
                },
            })
        );

        return dispatch(gen_received(RECEIVE_CANCEL_MEETING, cancelResult));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CANCEL_MEETING_ERROR, error));
    }
};

export const REQUEST_MISS_MEETING = "REQUEST_MISS_MEETING";
export const RECEIVE_MISS_MEETING = "RECEIVE_MISS_MEETING";
export const RECEIVE_MISS_MEETING_ERROR = "RECEIVE_MISS_MEETING_ERROR";
export const missMeeting = (id) => async (dispatch, getState) => {
    try {
        const {
            me: { id: meId },
        } = getState();

        dispatch(gen_requested(REQUEST_MISS_MEETING));
        const result = await get(`/api/v3/meetings/${id}/miss/`);
        logger.info(`Missing meeting ${id} for user ${meId}`);

        return dispatch(gen_received(RECEIVE_MISS_MEETING, result));
    } catch (error) {
        return dispatch(gen_received(RECEIVE_MISS_MEETING_ERROR, error));
    }
};

export const REQUEST_CREATE_MEETING = "REQUEST_CREATE_MEETING";
export const RECEIVE_CREATE_MEETING = "RECEIVE_CREATE_MEETING";
export const RECEIVE_CREATE_MEETING_ERROR = "RECEIVE_CREATE_MEETING_ERROR";
export const createMeeting = (appointment, options = {}) => async (dispatch, getState) => {
    try {
        const {
            me: { id: meId },
        } = getState();

        dispatch(gen_requested(REQUEST_CREATE_MEETING));
        const result = await post("/api/v3/meetings/", appointment, options);
        logger.info(`Creating meeting for user ${meId}`);

        return dispatch(gen_received(RECEIVE_CREATE_MEETING, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CREATE_MEETING_ERROR, error));
    }
};

export const REQUEST_UPDATE_MEETING_NOTES = "REQUEST_UPDATE_MEETING_NOTES";
export const RECEIVE_UPDATE_MEETING_NOTES = "RECEIVE_UPDATE_MEETING_NOTES";
export const RECEIVE_UPDATE_MEETING_NOTES_ERROR = "RECEIVE_UPDATE_MEETING_NOTES_ERROR";
export const updateMeetingNotes = (meetingId, subject) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_MEETING_NOTES));
        const result = await patch(`/api/v3/meetings/${meetingId}/`, { subject });

        return dispatch(gen_received(RECEIVE_UPDATE_MEETING_NOTES, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_MEETING_NOTES_ERROR, error));
    }
};

export const REQUEST_SOAP_NOTE_PATIENT_MEETINGS = "REQUEST_SOAP_NOTE_PATIENT_MEETINGS";
export const RECEIVE_SOAP_NOTE_PATIENT_MEETINGS = "RECEIVE_SOAP_NOTE_PATIENT_MEETINGS";
export const RECEIVE_SOAP_NOTE_PATIENT_MEETINGS_ERROR = "RECEIVE_SOAP_NOTE_PATIENT_MEETINGS_ERROR";
export const getSoapNotePatientMeetings = (soapNoteId, patientId, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SOAP_NOTE_PATIENT_MEETINGS));

        const { params: optionsParams = {}, ...optionsRest } = options;
        const params = {
            ...optionsParams,
            patient: patientId,
        };
        const result = await get(`/api/v3/meetings/`, { params, ...(optionsRest || {}) });

        return dispatch({ type: RECEIVE_SOAP_NOTE_PATIENT_MEETINGS, data: result.data.results, soapNoteId });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SOAP_NOTE_PATIENT_MEETINGS_ERROR, error));
    }
};

export const REQUEST_COACH_HOMEPAGE_MEETINGS = "REQUEST_COACH_HOMEPAGE_MEETINGS";
export const RECEIVE_COACH_HOMEPAGE_MEETINGS = "RECEIVE_COACH_HOMEPAGE_MEETINGS";
export const RECEIVE_COACH_HOMEPAGE_MEETINGS_ERROR = "RECEIVE_COACH_HOMEPAGE_MEETINGS_ERROR";
export const getCoachHomepageMeetings = (options = {}) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_COACH_HOMEPAGE_MEETINGS));

        const result = await get("/api/v3/meetings/", options);

        dispatch(gen_received(RECEIVE_COACH_HOMEPAGE_MEETINGS, result));
    } catch (error) {
        dispatch(gen_error(RECEIVE_COACH_HOMEPAGE_MEETINGS_ERROR, error));
    }
};

export const CLOSE_CONSULT_BANNER = "CLOSE_CONSULT_BANNER";
export const closeConsultBanner = () => (dispatch) => {
    dispatch({ type: CLOSE_CONSULT_BANNER });
};

export const CLOSE_HY_BANNER = "CLOSE_HY_BANNER";
export const closeHYBanner = () => (dispatch) => {
    dispatch({ type: CLOSE_HY_BANNER });
};

export const REQUEST_EXPORT_SOAP_NOTES = "REQUEST_EXPORT_SOAP_NOTES";
export const RECEIVE_EXPORT_SOAP_NOTES = "RECEIVE_EXPORT_SOAP_NOTES";
export const RECEIVE_EXPORT_SOAP_NOTES_ERROR = "RECEIVE_EXPORT_SOAP_NOTES_ERROR";
export const exportSoapNotes = (id) => () => {
    get(`/api/v3/soap_notes/${id}.pdf`, { responseType: "blob" }).then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(blob.data);

        // Open PDF in a new tab
        window.open(url);
    });
};

export const REQUEST_CONSULT_TRANSCRIPT = "REQUEST_CONSULT_TRANSCRIPT";
export const RECEIVE_CONSULT_TRANSCRIPT = "RECEIVE_CONSULT_TRANSCRIPT";
export const RECEIVE_CONSULT_TRANSCRIPT_ERROR = "RECEIVE_CONSULT_TRANSCRIPT_ERROR";
export const getConsultTranscript = (id) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CONSULT_TRANSCRIPT));
        const result = await get(`/api/v3/meetings/${id}/transcribe/`);

        return dispatch(gen_received(RECEIVE_CONSULT_TRANSCRIPT, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CONSULT_TRANSCRIPT_ERROR, error));
    }
};
