import { storageAvailable } from "../../utils/helpers";
import moment from "moment-timezone";
import {
    REQUEST_CHECK_PROMO_CODE,
    RECEIVE_CHECK_PROMO_CODE,
    RECEIVE_CHECK_PROMO_CODE_ERROR,
    PROMPT_FOR_SUBSCRIPTION,
    PROMPT_FOR_SUBSCRIPTION_ERROR,
    CLOSE_SUBSCRIPTION_PROMPT,
    FORCE_SUBSCRIPTION,
    PROMPT_PROVIDER_SUBSCRIPTION,
    REQUEST_GET_CLIENT_SUBSCRIPTIONS,
    RECEIVE_GET_CLIENT_SUBSCRIPTIONS,
    RECEIVE_GET_CLIENT_SUBSCRIPTIONS_ERROR,
    REQUEST_ADD_PROVIDER_SEAT,
    RECEIVE_ADD_PROVIDER_SEAT,
    RECEIVE_ADD_PROVIDER_SEAT_ERROR,
    REQUEST_ADD_CLINIC_SEAT,
    RECEIVE_ADD_CLINIC_SEAT,
    RECEIVE_ADD_CLINIC_SEAT_ERROR,
    UPDATE_SUBSCRIPTION_SEATS_COUNT,
    UPDATE_SUBSCRIPTION_CLINIC_SEATS_COUNT,
    CLEAR_SUBSCRIPTION_STATUS,
    CLEAR_SUBSCRIPTIONS_ERROR,
    RECEIVE_CANCEL_PROVIDER_SUBSCRIPTION,
} from "../actions";

const subscriptionsInitialState = {
    validPromo: false,
    hidePaymentForm: false,
    isOnDemand: false,
    isConsult: true,
    amount_off: 0,
    percent_off: 0,
    subscriptions: [],
    addProviderSeatIsFetching: false,
    addClinicSeatIsFetching: false,
};

export function subscriptions(state = subscriptionsInitialState, action) {
    switch (action.type) {
        case REQUEST_CHECK_PROMO_CODE:
            return {
                ...state,
                checkPromoCodeIsFetching: true,
                checkPromoCodeRequestedAt: action.requestedAt,
            };
        case RECEIVE_CHECK_PROMO_CODE: {
            const {
                promotion_code: {
                    active,
                    coupon: { amount_off, percent_off, valid },
                },
            } = action.data;

            const returnState = {
                ...state,
                checkPromoCodeIsFetching: false,
                checkPromoCodeReceivedAt: action.receivedAt,
            };

            if (active && valid) {
                returnState.validPromo = true;
                returnState.amount_off = amount_off;
                returnState.percent_off = percent_off;
            } else {
                returnState.validPromo = false;
            }

            return returnState;
        }
        case RECEIVE_CHECK_PROMO_CODE_ERROR:
            return {
                ...state,
                checkPromoCodeIsFetching: false,
                checkPromoCodeReceivedAt: action.receivedAt,
                checkPromoCodeError: action.error,
            };
        case PROMPT_FOR_SUBSCRIPTION:
            return {
                ...state,
                promptForSubscription: true,
            };
        case PROMPT_FOR_SUBSCRIPTION_ERROR:
            return {
                ...state,
                promptForSubscriptionError: action.error,
            };
        case PROMPT_PROVIDER_SUBSCRIPTION:
            return {
                ...state,
                promptProviderForSubscription: true,
            };
        case CLOSE_SUBSCRIPTION_PROMPT: {
            const storageIsAvailable = storageAvailable("localStorage");
            if (storageIsAvailable) localStorage.setItem("X_LAST_DISMISS_SUBSCRIPTION_PROMPT", moment().format());
            return {
                ...state,
                promptProviderForSubscription: false,
                promptForSubscription: false,
                forceSubscription: false,
            };
        }
        case FORCE_SUBSCRIPTION: {
            const { hidePaymentForm, isOnDemand, isConsult } = action.data;

            return {
                ...state,
                forceSubscription: true,
                hidePaymentForm,
                isOnDemand,
                isConsult,
            };
        }
        case REQUEST_GET_CLIENT_SUBSCRIPTIONS:
            return {
                ...state,
                getClientSubscriptionsIsFetching: true,
                getClientSubscriptionsRequestedAt: action.requestedAt,
            };
        case RECEIVE_GET_CLIENT_SUBSCRIPTIONS:
            return {
                ...state,
                getClientSubscriptionsIsFetching: false,
                getClientSubscriptionsReceivedAt: action.receivedAt,
                subscriptions: action.data,
            };
        case RECEIVE_GET_CLIENT_SUBSCRIPTIONS_ERROR:
            return {
                ...state,
                getClientSubscriptionsIsFetching: false,
                getClientSubscriptionsReceivedAt: action.receivedAt,
                subscriptionsError: action.error,
            };
        case REQUEST_ADD_PROVIDER_SEAT:
            return {
                ...state,
                addProviderSeatIsFetching: true,
                addProviderSeatRequestedAt: action.requestedAt,
            };
        case RECEIVE_ADD_PROVIDER_SEAT: {
            const { subscriptions: currentSubscriptions } = state;
            const updatedSubscription = currentSubscriptions.find((sub) => sub.id === action.data.id);
            const returnState = {
                ...state,
                addProviderSeatIsFetching: false,
                addProviderSeatReceivedAt: action.receivedAt,
            };

            if (updatedSubscription) {
                updatedSubscription.seats_amount = action.data.seats_amount;
                returnState.addSeatSuccess = true;
            }

            return returnState;
        }
        case RECEIVE_ADD_PROVIDER_SEAT_ERROR:
            return {
                ...state,
                addProviderSeatIsFetching: false,
                addProviderSeatReceivedAt: action.receivedAt,
                addProviderSeatError: action.error,
            };
        case REQUEST_ADD_CLINIC_SEAT:
            return {
                ...state,
                addClinicSeatIsFetching: true,
                addClinicSeatRequestedAt: action.requestedAt,
            };
        case RECEIVE_ADD_CLINIC_SEAT: {
            const { subscriptions: currentSubscriptions } = state;
            const updatedSubscription = currentSubscriptions.find((sub) => sub.id === action.data.id);
            const returnState = {
                ...state,
                addClinicSeatIsFetching: false,
                addClinicSeatReceivedAt: action.receivedAt,
            };

            if (updatedSubscription) {
                updatedSubscription.clinic_seats_amount = action.data.clinic_seats_amount;
                returnState.addClinicSeatSuccess = true;
            }

            return returnState;
        }
        case RECEIVE_ADD_CLINIC_SEAT_ERROR:
            return {
                ...state,
                addClinicSeatIsFetching: false,
                addClinicSeatReceivedAt: action.receivedAt,
                addClinicSeatError: action.error,
            };
        case UPDATE_SUBSCRIPTION_SEATS_COUNT: {
            const { subscriptions: currentSubscriptions } = state;
            const activeSubscription = currentSubscriptions.find((sub) => sub.status === "active");
            if (activeSubscription) {
                activeSubscription.used_seats_amount = activeSubscription.used_seats_amount + 1;
            }
            return {
                ...state,
                subscriptions: currentSubscriptions,
                addClinicSeatSuccess: false,
                addSeatSuccess: false,
            };
        }
        case UPDATE_SUBSCRIPTION_CLINIC_SEATS_COUNT: {
            const { subscriptions: currentSubscriptions } = state;
            const activeSubscription = currentSubscriptions.find((sub) => sub.status === "active");
            if (activeSubscription) {
                activeSubscription.used_clinic_seats_amount = activeSubscription.used_clinic_seats_amount + 1;
            }

            return {
                ...state,
                subscriptions: currentSubscriptions,
                addClinicSeatSuccess: false,
                addSeatSuccess: false,
            };
        }
        case RECEIVE_CANCEL_PROVIDER_SUBSCRIPTION: {
            const { subscriptions: currentSubscriptions } = state;
            const activeSubscription = currentSubscriptions.find((sub) => sub.status === "active");
            if (activeSubscription) {
                activeSubscription.termination_date = moment.unix(action.data.cancel_at).format();
            }

            return {
                ...state,
                subscriptions: currentSubscriptions,
            };
        }
        case CLEAR_SUBSCRIPTION_STATUS:
            return {
                ...state,
                addClinicSeatSuccess: false,
                addSeatSuccess: false,
            };
        case CLEAR_SUBSCRIPTIONS_ERROR:
            return {
                ...state,
                addClinicSeatError: false,
                addProviderSeatError: false,
            };
        default:
            return state;
    }
}
