import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "query-string";

import { sendPageViewEvent, setAnalyticsDefaultValue } from "../../utils/analytics";
import { getVisitorType } from "../../utils/helpers";
import { getPageTitle } from "../../utils/routes";
import { setGATags } from "../../app/actions";
import { getMyConsultationPrice } from "../../utils/subscriptions";

export default function RouteChangeTracker({ history }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const { GATags } = useSelector(({ user }) => user);
    const me = useSelector(({ me }) => me);
    const emailQueryStrings = qs.parse(window.location.search);
    const googleAnalyticsTags = {
        source: emailQueryStrings.utm_source,
        medium: emailQueryStrings.utm_medium,
        campaign: emailQueryStrings.utm_campaign,
    };
    const visitor_type = getVisitorType(me);
    const consultPrice = getMyConsultationPrice(me);

    useEffect(() => {
        updateTags();
    }, []); // eslint-disable-line

    useEffect(() => {
        const page_location = window.location;
        const page_path = window.location.pathname;
        const page_title = getPageTitle(page_path);
        updateTags();
        sendPageViewEvent({ page_title, page_location, page_path });
    }, [location]); // eslint-disable-line

    const updateTags = () => {
        if (
            googleAnalyticsTags &&
            googleAnalyticsTags.campaign &&
            googleAnalyticsTags.medium &&
            googleAnalyticsTags.source &&
            GATags &&
            !GATags.campaign &&
            !GATags.medium &&
            !GATags.source
        )
            dispatch(setGATags(emailQueryStrings));

        if (GATags && !GATags.visitor_type) {
            dispatch(setGATags({ visitor_type }));
        }
        if (consultPrice && consultPrice.stripe_id && GATags && !GATags.price_id) {
            dispatch(setGATags({ price_id: consultPrice.stripe_id }));
        }

        setAnalyticsDefaultValue({
            ...GATags,
            ...emailQueryStrings,
            visitor_type,
            price_id: consultPrice && consultPrice.stripe_id ? consultPrice.stripe_id : null,
            price_title: consultPrice && consultPrice.title ? consultPrice.title : null,
            price_amount:
                consultPrice && consultPrice.stripe_price && consultPrice.stripe_price.unit_amount
                    ? consultPrice.stripe_price.unit_amount
                    : null,
            sso_group_id: me.group && me.group.partner_id ? me.group.partner_id : null,
        });
    };

    return <></>;
}
