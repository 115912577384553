import {
    EXPAND_CATEGORY,
    RECEIVE_CATEGORIES,
    REQUEST_ARTICLE,
    RECEIVE_ARTICLE,
    RECEIVE_ARTICLE_ERROR,
} from "../actions";

const articlesInitialState = {
    categories: [],
};

export function articles(state = articlesInitialState, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIES:
            let categories = [...action.data];
            if (categories.length) {
                categories[0].expanded = true;
            }
            return {
                ...state,
                categories,
            };

        case EXPAND_CATEGORY:
            let cats = [...state.categories];
            for (let i = 0; i < cats.length; i++) {
                if (cats[i].id === action.categoryId) {
                    cats[i].expanded = !cats[i].expanded;
                }
            }
            return {
                ...state,
                cats,
            };
        case REQUEST_ARTICLE:
            return {
                ...state,
                articleIsFetching: true,
                articleRequestedAt: action.requestedAt,
            };
        case RECEIVE_ARTICLE:
            return {
                ...state,
                articleIsFetching: false,
                articleReceivedAt: action.receivedAt,
                article: action.data,
            };
        case RECEIVE_ARTICLE_ERROR:
            return {
                ...state,
                articleIsFetching: false,
                articleReceivedAt: action.receivedAt,
                articleError: action.error,
            };
        default:
            return state;
    }
}
