import React from "react";
import { ToastContainer as DefaultToastContainer, toast as defaultToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CloseButton = ({ closeToast, ...rest }) => (
    <button data-button="button__close-toast" className="toast-container__close-button btn" onClick={closeToast}>
        <i className="fas fa-times" />
    </button>
);

export const toast = defaultToast;

export default function ToastContainer(props) {
    return (
        <DefaultToastContainer
            {...props}
            className="toast-container"
            position="bottom-left"
            closeButton={<CloseButton />}
        />
    );
}
