import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiTextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import MuiTextareaAutosize from "@material-ui/core/TextareaAutosize";
import cx from "classnames";

import FormError from "../form_error/FormError";

const root = {
    width: "100%",
    backgroundColor: "white",
};

const StyledTextField = withStyles({
    root,
})(MuiTextField);

const StyledInputBase = withStyles({
    root,
})(InputBase);

export default function TextInput({
    autoFocus = false,
    autosize = false,
    bare = false,
    error,
    containerClassName = "",
    touched,
    ...rest
}) {
    let Component = StyledTextField;

    if (autosize) {
        Component = MuiTextareaAutosize;

        // merge default classes with cx because this MUI component cannot be styled with convention
        rest.className = cx("w-100 p-3 rounded", rest.className || "");
    } else if (bare) {
        Component = StyledInputBase;
    }

    return (
        <div className={containerClassName}>
            <Component {...rest} autoFocus={autoFocus} />
            <FormError show={error && touched} message={error} />
        </div>
    );
}
