import React from "react";
import { Box, Card, Container, Grid, Stack, Typography, useTheme } from "@mui/material";
import heroBackground from "./res/search-bg.png";
import { useSelector } from "react-redux";

import { getPublicImage, isMFRGroup } from "../../utils/helpers";

import AdvanceSearch from "./AdvanceSearch";
import Image from "../image/Image";
import MapSearch from "./MapSearch";
import MFRHeader from "../../layouts/dashboard/header/MFRHeader";
import Page from "../minimal/Page";
import StateGrid from "./StateGrid";

export default function ProviderSearch() {
    const me = useSelector(({ me }) => me);
    const theme = useTheme();
    const isMFR = isMFRGroup(me.group);
    const mfrDomain =
        window.location.hostname.includes("mfrtherapists.com") || window.location.hostname.includes("mfrhealth.com");

    return (
        <Page
            title="Myofascial Release Therapy Directory - Provider Search"
            mfr={mfrDomain}
            mfrProvider={isMFR && me.is_coach}
            ogTitle="MFR Therapists"
        >
            <Container maxWidth="xl">
                {me && !me.id && <MFRHeader />}
                <Stack sx={{ px: 4, marginTop: me && !me.id && "100px" }}>
                    <Stack
                        sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            p: { xs: "50px", md: "100px", backgroundImage: `url(${heroBackground})` },
                            borderRadius: "20px",
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h1"
                                sx={{
                                    color: theme.palette.common.white,
                                    width: { xs: "100%", md: "70%" },
                                    fontSize: "2rem !important",
                                }}
                            >
                                <span className="text-weight-light">Connect with an</span> MFR Therapist{" "}
                                <span className="text-weight-light">and</span> Get Relief
                            </Typography>
                            <Typography variant="h4" sx={{ color: theme.palette.common.white }}>
                                Over 1,000 therapists nationwide and in 8 countries
                            </Typography>
                        </Box>
                        <Box sx={{ display: { xs: "none", desktop: "block" }, height: "200px", minWidth: "140px" }}>
                            <Image
                                src={getPublicImage("therapist_search.webp")}
                                alt="provider search"
                                className="ts-therapist-search-image"
                            />
                        </Box>
                    </Stack>
                    <Stack sx={{ px: { xs: 0, md: "75px" }, py: { xs: "35px", md: "60px" } }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <StateGrid />
                            </Grid>
                            <Grid item xs={12}>
                                <AdvanceSearch />
                            </Grid>
                            <Grid item xs={12}>
                                <MapSearch />
                            </Grid>
                        </Grid>
                        <Card
                            sx={{
                                mt: 4,
                                px: { xs: "50px", lg: "100px" },
                                py: { xs: "30px", lg: "60px" },
                                background: "linear-gradient(163.52deg, #41616D 0%, #5C2963 100%)",
                                color: theme.palette.common.white,
                            }}
                        >
                            <Typography variant="h4">Finding the right therapist</Typography>
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                Trauma, inflammatory responses, and/or surgical procedures create Myofascial
                                restrictions that can produce tensile pressures of approximately 2,000 pounds per square
                                inch on pain sensitive structures that do not show up in many of the standard tests
                                (x-rays, myelograms, CAT scans, electromyography, etc.)
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 4 }}>
                                The medical approach is to drug patients so they temporarily are free from pain, but
                                does nothing about the “straight-jacket” of pressure that is causing the pain.
                                Traditional physical, occupational and massage therapy treats the symptoms caused by the
                                pressure of the “straightjacket” of the Myofascial system, but does nothing about the
                                “straightjacket” of pressure that causes and perpetuates the symptoms. This is why so
                                many patients only have temporary results never seeming to get better with traditional
                                therapy. Only Myofascial Release treats the entire Myofascial mind/body complex
                                eliminating the pressure of the restricted Myofascial system (the straightjacket) that
                                causes the symptoms. Myofascial Release will safely and gently release the entire
                                Myofascial complex for lasting and comprehensive results and authentic healing.
                            </Typography>
                        </Card>
                    </Stack>
                </Stack>
            </Container>
        </Page>
    );
}
