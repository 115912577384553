import React from "react";
import cx from "classnames";
import { Typography } from "@mui/material";

export default function FormRow({
    title,
    children,
    className,
    bottomSpacing = false,
    field = false,
    fullWidth = false,
}) {
    return (
        <div className={cx("ts-form-row", "d-flex flex-column", bottomSpacing && "mb-3", className)}>
            {title && (
                <Typography variant="caption" className="text-black text-form-label mb-0">
                    {title}
                </Typography>
            )}
            <div className={cx(fullWidth && "w-100 px-1")}>
                <div className={cx(field && "field")}>{children}</div>
            </div>
        </div>
    );
}
