function constructMessage(messageParts) {
    return messageParts.join(" ");
}

class Log {
    constructor() {
        if (!window._LTracker) {
            window._LTracker = [];
        }

        this.levels = [{ name: "ERROR" }, { name: "WARN" }, { name: "INFO" }];
        this.level = 2; // log everything for now
    }

    info = (...messageArgs) => {
        const wholeMessage = constructMessage(messageArgs);

        console.log("%cINFO:", "font-weight: bold; color: blue;", wholeMessage || "");
        this.doLog({ level: 2, message: wholeMessage });
    };

    warn = (...messageArgs) => {
        const wholeMessage = constructMessage(messageArgs);

        console.log("%cWARN:", "font-weight: bold; color: yellow;", wholeMessage || "");
        this.doLog({ level: 1, message: wholeMessage });
    };

    error = (...messageArgs) => {
        const wholeMessage = constructMessage(messageArgs);

        console.log("%cERROR:", "font-weight: bold; color: red;", wholeMessage || "");
        this.doLog({ level: 0, message: wholeMessage });
    };

    doLog = (data) => {
        if (data.level <= this.level) {
            try {
                const payload = {
                    app: "v3",
                    level: this.levels[data.level].name,
                    message: data.message,
                    hostname: document.location.hostname,
                    agent: navigator.userAgent,
                    tzOffsetMinutes: new Date().getTimezoneOffset(),
                };

                window._LTracker.push(payload);
            } catch (error) {
                console.log("LOGGLY ERROR: ", error);
            }
        }
    };
}

export default new Log();
