import { get } from "../../utils/network";
import { gen_error, gen_received, gen_requested } from "./generators";

export const REQUEST_CATEGORIES = "REQUEST_CATEGORIES";
export const RECEIVE_CATEGORIES = "RECEIVE_CATEGORIES";
export const RECEIVE_CATEGORIES_ERROR = "RECEIVE_CATEGORIES_ERROR";

export const fetchCategories = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CATEGORIES));
        const result = await get("/api/v3/blog/categories/", options);
        return dispatch(gen_received(RECEIVE_CATEGORIES, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CATEGORIES_ERROR, error));
    }
};

export const REQUEST_ARTICLE = "REQUEST_ARTICLE";
export const RECEIVE_ARTICLE = "RECEIVE_ARTICLE";
export const RECEIVE_ARTICLE_ERROR = "RECEIVE_ARTICLE_ERROR";

export const fetchArticle = (id) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ARTICLE));
        const result = await get(`/api/v3/blog/articles/${id}/`);
        return dispatch(gen_received(RECEIVE_ARTICLE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ARTICLE_ERROR, error));
    }
};

export const EXPAND_CATEGORY = "EXPAND_CATEGORY";
export function expandCategory(categoryId) {
    return {
        type: EXPAND_CATEGORY,
        categoryId,
    };
}
