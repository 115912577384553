import {
    RECEIVE_REPORTS_CHANNELS,
    RECEIVE_REPORTS_CHANNELS_ERROR,
    RECEIVE_REPORTS_UTILIZATION,
    RECEIVE_REPORTS_UTILIZATION_ERROR,
    RECEIVE_REPORTS_PAIN_DISTRIBUTION,
    RECEIVE_REPORTS_USER_REGISTRATIONS,
    REPORTS_CHANGE_END_DATE,
    REPORTS_CHANGE_SELECTED_CHANNEL_INDEX,
    REPORTS_CHANGE_START_DATE,
    REPORTS_SET_DATE_INTERVAL,
    REPORTS_SET_PAINLOCATION,
    REQUEST_REPORTS_CHANNELS,
    REQUEST_REPORTS_UTILIZATION,
    REQUEST_REPORTS_PAIN_DISTRIBUTION,
    REQUEST_REPORTS_USER_REGISTRATIONS,
    REPORTS_CHANGE_END_DATE_SIGNUPS,
    REPORTS_CHANGE_START_DATE_SIGNUPS,
    REPORTS_CHANGE_FILTER_USERS_BY_CHAPTERS,
    REPORTS_SELECT_CHAPTERS,
    REPORTS_CHANGE_DRAW_USERS_BY_CHAPTERS,
    REQUEST_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION,
    RECEIVE_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION,
    REQUEST_REPORTS_BRANDING_GROUPS,
    RECEIVE_REPORTS_BRANDING_GROUPS,
    REPORTS_SET_FILTER_CODE,
    REPORTS_CHANGE_FILTER_USERS_BY_CODE,
    REQUEST_REPORTS_USERS,
    RECEIVE_REPORTS_USERS,
    REPORTS_CHANGE_SHOW_BILLING_DATA,
    REPORTS_CHANGE_NO_CACHE,
    REQUEST_REPORTS_SATISFACTION,
    RECEIVE_REPORTS_SATISFACTION,
    REQUEST_CONSULTS_REPORT,
    RECEIVE_CONSULTS_REPORT,
    RECEIVE_CONSULTS_REPORT_ERROR,
    REQUEST_POPULATION_REPORT,
    RECEIVE_POPULATION_REPORT,
    RECEIVE_POPULATION_REPORT_ERROR,
    REQUEST_INVOICING_REPORT,
    RECEIVE_INVOICING_REPORT,
    RECEIVE_INVOICING_REPORT_ERROR,
    REQUEST_COACHING_REPORT,
    RECEIVE_COACHING_REPORT,
    RECEIVE_COACHING_REPORT_ERROR,
    REQUEST_RESOURCES_REPORT,
    RECEIVE_RESOURCES_REPORT,
    RECEIVE_RESOURCES_REPORT_ERROR,
    REQUEST_CLINICAL_REPORT,
    RECEIVE_CLINICAL_REPORT,
    RECEIVE_CLINICAL_REPORT_ERROR,
    CLEAR_INVOICING_REPORT,
    CLEAR_QA_REPORT,
    CLEAR_CONSULTS_REPORT,
    REQUEST_QA_REPORT,
    RECEIVE_QA_REPORT,
    RECEIVE_QA_REPORT_ERROR,
} from "../actions";

var today = new Date();
var monthStart = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0);
var yearStart = new Date(today.getFullYear(), 0, 1, 0, 0, 0);
var tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 23, 59, 59, 999);
const reportsInitialState = {
    startDate: monthStart,
    endDate: tomorrow,
    startDateSignups: yearStart,
    endDateSignups: tomorrow,
    painLocation: "lower_back",
    dateInterval: "week",
    filterUsersByChapters: false,
    chapters: [],
    drawUsersByChapters: false,
    filterUsersByCode: false,
    filterCode: "",
    showBillingData: false,
    noCache: false,
};

export function reports(state = reportsInitialState, action) {
    switch (action.type) {
        case REQUEST_REPORTS_CHANNELS:
            return {
                ...state,
                channelsRequestedAt: action.requestedAt,
                channelsIsFetching: true,
            };
        case RECEIVE_REPORTS_CHANNELS:
            return {
                ...state,
                channelsReceivedAt: action.receivedAt,
                channelsIsFetching: false,
                channels: action.data,
                selectedChannelIndex: 0,
            };
        case RECEIVE_REPORTS_CHANNELS_ERROR:
            return {
                ...state,
                channelsReceivedAt: action.receivedAt,
                channelsIsFetching: false,
                error: action.error,
            };
        case REPORTS_CHANGE_START_DATE:
            return {
                ...state,
                startDate: action.date,
            };
        case REPORTS_CHANGE_END_DATE:
            return {
                ...state,
                endDate: action.date,
            };
        case CLEAR_INVOICING_REPORT:
            return {
                ...state,
                invoicingReport: null,
            };
        case CLEAR_QA_REPORT:
            return {
                ...state,
                qaReport: null,
            };
        case CLEAR_CONSULTS_REPORT:
            return {
                ...state,
                consultsReport: null,
            };
        case REPORTS_CHANGE_START_DATE_SIGNUPS:
            return {
                ...state,
                startDateSignups: action.date,
            };
        case REPORTS_CHANGE_END_DATE_SIGNUPS:
            return {
                ...state,
                endDateSignups: action.date,
            };
        case REPORTS_CHANGE_FILTER_USERS_BY_CHAPTERS:
            if (action.checked) {
                return {
                    ...state,
                    filterUsersByChapters: action.checked,
                };
            } else {
                return {
                    ...state,
                    filterUsersByChapters: action.checked,
                    chapters: [],
                };
            }
        case REPORTS_CHANGE_SHOW_BILLING_DATA:
            return {
                ...state,
                showBillingData: action.checked,
            };
        case REPORTS_CHANGE_NO_CACHE:
            return {
                ...state,
                noCache: action.checked,
            };
        case REPORTS_SELECT_CHAPTERS:
            return {
                ...state,
                chapters: action.chapters,
            };
        case REPORTS_CHANGE_FILTER_USERS_BY_CODE:
            if (action.checked) {
                return {
                    ...state,
                    filterUsersByCode: action.checked,
                };
            } else {
                return {
                    ...state,
                    filterUsersByCode: action.checked,
                    filterCode: "",
                };
            }
        case REPORTS_SET_FILTER_CODE:
            return {
                ...state,
                filterCode: action.filterCode,
            };
        case REPORTS_CHANGE_DRAW_USERS_BY_CHAPTERS:
            return {
                ...state,
                drawUsersByChapters: action.checked,
            };
        case REPORTS_CHANGE_SELECTED_CHANNEL_INDEX:
            return {
                ...state,
                selectedChannelIndex: action.index,
            };
        case REPORTS_SET_PAINLOCATION:
            return {
                ...state,
                painLocation: action.painLocation,
            };
        case REPORTS_SET_DATE_INTERVAL:
            return {
                ...state,
                dateInterval: action.dateInterval,
            };
        case REQUEST_REPORTS_PAIN_DISTRIBUTION:
            return {
                ...state,
                pain_distribution: null,
                painDistributionReportIsFetching: true,
            };
        case RECEIVE_REPORTS_PAIN_DISTRIBUTION:
            return {
                ...state,
                pain_distribution: action.data,
                painDistributionReportIsFetching: false,
            };
        case REQUEST_REPORTS_BRANDING_GROUPS:
            return {
                ...state,
                barnding_groups: null,
                brandingGroupsReportIsFetching: true,
            };
        case RECEIVE_REPORTS_BRANDING_GROUPS:
            return {
                ...state,
                branding_groups: action.data.data,
                brandingGroupsReportIsFetching: false,
            };
        case REQUEST_REPORTS_USER_REGISTRATIONS:
            return {
                ...state,
                userRegistrations: null,
                userRegistrationsReportIsFetching: true,
            };
        case RECEIVE_REPORTS_USER_REGISTRATIONS:
            return {
                ...state,
                userRegistrations: action.data,
                userRegistrationsReportIsFetching: false,
            };
        case REQUEST_POPULATION_REPORT:
            return {
                ...state,
                populationReportRequestedAt: action.requestedAt,
                populationReportIsFetching: true,
            };
        case RECEIVE_POPULATION_REPORT: {
            return {
                ...state,
                populationReportReceivedAt: action.receivedAt,
                populationReportIsFetching: false,
                populationReport: { channel: action.channel, ...action.data },
            };
        }
        case RECEIVE_POPULATION_REPORT_ERROR:
            return {
                ...state,
                populationReportReceivedAt: action.receivedAt,
                populationReportIsFetching: false,
                populationReportError: action.error,
            };
        case REQUEST_INVOICING_REPORT:
            return {
                ...state,
                invoicingReportRequestedAt: action.requestedAt,
                invoicingReportIsFetching: true,
            };
        case RECEIVE_INVOICING_REPORT:
            return {
                ...state,
                invoicingReportReceivedAt: action.receivedAt,
                invoicingReportIsFetching: false,
                invoicingReport: { channel: action.channel, ...action.data },
            };
        case RECEIVE_INVOICING_REPORT_ERROR:
            return {
                ...state,
                invoicingReportReceivedAt: action.receivedAt,
                invoicingReportIsFetching: false,
                invoicingReportError: action.error,
            };
        case REQUEST_QA_REPORT:
            return {
                ...state,
                qaReportRequestedAt: action.requestedAt,
                qaReportIsFetching: true,
            };
        case RECEIVE_QA_REPORT:
            return {
                ...state,
                qaReportReceivedAt: action.receivedAt,
                qaReportIsFetching: false,
                qaReport: { coaches: [...action.data], startDate: action.startDate, endDate: action.endDate },
            };
        case RECEIVE_QA_REPORT_ERROR:
            return {
                ...state,
                qaReportReceivedAt: action.receivedAt,
                qaReportIsFetching: false,
                qaReportError: action.error,
            };
        case REQUEST_COACHING_REPORT:
            return {
                ...state,
                coachingReportRequestedAt: action.requestedAt,
                coachingReportIsFetching: true,
            };
        case RECEIVE_COACHING_REPORT: {
            return {
                ...state,
                coachingReportReceivedAt: action.receivedAt,
                coachingReportIsFetching: false,
                coachingReport: { channel: action.channel, ...action.data },
            };
        }
        case RECEIVE_COACHING_REPORT_ERROR:
            return {
                ...state,
                coachingReportReceivedAt: action.receivedAt,
                coachingReportIsFetching: false,
                coachingReportError: action.error,
            };
        case REQUEST_RESOURCES_REPORT:
            return {
                ...state,
                resourcesReportRequestedAt: action.requestedAt,
                resourcesReportIsFetching: true,
            };
        case RECEIVE_RESOURCES_REPORT:
            return {
                ...state,
                resourcesReportReceivedAt: action.receivedAt,
                resourcesReportIsFetching: false,
                resourcesReport: { channel: action.channel, ...action.data },
            };
        case RECEIVE_RESOURCES_REPORT_ERROR:
            return {
                ...state,
                resourcesReportReceivedAt: action.receivedAt,
                resourcesReportIsFetching: false,
                resourcesReportError: action.error,
            };
        case REQUEST_CLINICAL_REPORT:
            return {
                ...state,
                clinicalReportRequestedAt: action.requestedAt,
                clinicalReportIsFetching: true,
            };
        case RECEIVE_CLINICAL_REPORT:
            return {
                ...state,
                clinicalReportReceivedAt: action.receivedAt,
                clinicalReportIsFetching: false,
                clinicalReport: { channel: action.channel, ...action.data },
            };
        case RECEIVE_CLINICAL_REPORT_ERROR:
            return {
                ...state,
                clinicalReportReceivedAt: action.receivedAt,
                clinicalReportIsFetching: false,
                clinicalReportError: action.error,
            };
        case REQUEST_REPORTS_USERS:
            return {
                ...state,
                users: null,
                usersReportIsFetching: true,
            };
        case RECEIVE_REPORTS_USERS:
            return {
                ...state,
                users: action.data,
                usersReportIsFetching: false,
            };
        case REQUEST_CONSULTS_REPORT:
            return {
                ...state,
                consultsReport: null,
                consultsReportIsFetching: true,
            };
        case RECEIVE_CONSULTS_REPORT:
            return {
                ...state,
                consultsReport: { channel: action.channel, ...action.data },
                consultsReportIsFetching: false,
            };
        case RECEIVE_CONSULTS_REPORT_ERROR:
            return {
                ...state,
                consultsReportIsFetching: false,
                consultsReportError: action.error,
            };
        case REQUEST_REPORTS_SATISFACTION:
            return {
                ...state,
                satisfaction: null,
                satisfactionReportIsFetching: true,
            };
        case RECEIVE_REPORTS_SATISFACTION:
            return {
                ...state,
                satisfaction: action.data,
                satisfactionReportIsFetching: false,
            };
        case REQUEST_REPORTS_UTILIZATION:
            return {
                ...state,
                utilization: null,
                utilizationReportIsFetching: true,
            };
        case RECEIVE_REPORTS_UTILIZATION:
            return {
                ...state,
                utilization: action.data,
                utilizationReportIsFetching: false,
            };
        case RECEIVE_REPORTS_UTILIZATION_ERROR:
            return {
                ...state,
                utilizationReportIsFetching: false,
            };
        case REQUEST_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION:
            return {
                ...state,
                user_by_chapters_distribution: null,
                usersByChaptersDistributionReportIsFetching: true,
            };
        case RECEIVE_REPORTS_USERS_BY_CHAPTERS_DISTRIBUTION:
            return {
                ...state,
                user_by_chapters_distribution: action.data,
                usersByChaptersDistributionReportIsFetching: false,
            };
        default:
            return state;
    }
}
