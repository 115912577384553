import { exq } from "../../utils/helpers";

export const brandingFragment = {
    expand: exq`
        tier
    `,
    fields: exq`
        id
        color_logo
        display_name
        is_hidden
        name
        pain_locations
        pay_right_away
        product_logo
        product_name
        idp_signin_url
        white_logo
        subdomain
        tier
        
        is_organization
        organization_stripe_customer_id
        organization_stripe_subscription_id
        organization_stripe_subscription_status
        organization_stripe_connect_account_id
        organization_stripe_connect_product_id
    `,
};

export const groupsFragment = {
    expand: exq`
        branding
        parent
        parent.branding
        parent.signup_config
        signup_config
        tier
    `,
    fields: exq`
        branding
        domain
        hidden
        id
        parent
        partner_id
        pl_hip
        pl_knee
        pl_neck
        pl_lower_back
        pl_other
        pl_shoulder
        pl_unknown
        pl_upper_back
        pl_womens_health
        products
        prices
        signup_config
        title
        uuid
    `,
};

export const tiersFragment = {
    expand: exq`
        provider_prices
    `,
};

export const brandingFilterFragment = {
    expand: exq`
        branding
    `,
    fields: exq`
        id
        hidden
        branding
        branding.display_name
        branding.is_organization
        branding.name
        branding.stripe_subscription_status
        display_name
    `,
};

export const clinicFragment = {
    expand: exq`
        clinic_credentials
        group
        group.parent
        group.clinics
        providers.profile_credentials
        providers.user
        clinic_images
    `,
    fields: exq`
        address1
        address2
        alt_phone
        bio
        city
        clarifying_content_blurb
        clinic_credentials
        clinic_images
        country
        directions
        display_map
        email
        facebook
        group
        id
        instagram
        latitude
        longitude
        municipality
        owner_profile_id
        phone
        postal_code
        providers.active
        providers.id
        providers.photo
        providers.slug
        providers.profile_credentials
        providers.user
        providers.user.first_name
        providers.user.last_name
        providers.user.profile_image
        provinces
        public
        slug
        state
        title
        twitter
        url
        vanity_phone
        zip
    `,
};
