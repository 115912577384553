import moment from "moment-timezone";

import { IsOnboardingFlow, storageAvailable } from "./helpers";
import { isDTCUser } from "./user";
import * as routes from "./routes";

const LAST_INITIAL_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY = "X_LAST_INITIAL_SUBSCRIPTION_PROMPT";
const LAST_DISMISS_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY = "X_LAST_DISMISS_SUBSCRIPTION_PROMPT";

// Old subscription names we used for DTC
// const SUBSCRIPTION_OPTIONS = [
//     "49_month",
//     "19_month",
//     "5_month",
//     "49_one_time",
//     "one-time-consult",
//     "monthly",
//     "provider_connect_client_test_19",
//     "provider_price_79",
//     "provider_price_99",
//     "provider_price_119",
//     "provider_price_149",
//     "DTC Monthly subscription",
//     "DTC One-Time Payment",
// ];

export const BASE_PRODUCTS = [
    { title: "Back and Joined Care" },
    { title: "Directory Basics" },
    {
        title: "Directory Plus",
        product_titles: [
            "Directory Plus - Monthly",
            "Directory Plus - Monthly Discount",
            "Directory Plus - Yearly",
            "Directory Plus - Yearly Discount",
        ],
    },
    { title: "Virtual Consult" },
    { title: "Single Therapist Account" },
    { title: "Multiple Therapist Account" },
    { title: "Split Multiple Therapist Listing" },
    { title: "Multiple Listings" },
    { title: "Extra location" },
];

export const MFR_PRODUCTS = [
    { title: "Directory Basics" },
    {
        title: "Directory Plus",
        product_titles: [
            "Directory Plus - Monthly",
            "Directory Plus - Monthly Discount",
            "Directory Plus - Yearly",
            "Directory Plus - Yearly Discount",
        ],
    },
    { title: "Directory Plus - Monthly" },
    { title: "Directory Plus - Monthly Discount" },
    { title: "Directory Plus - Yearly" },
    { title: "Directory Plus - Yearly Discount" },
    { title: "Single Therapist Account" },
    { title: "Multiple Therapist Account" },
    { title: "Split Multiple Therapist Listing" },
    { title: "Multiple Listings" },
    { title: "Extra location" },
];

export const DTC_SSO_GROUP_IDS = ["211562", "12345"];

export const DTC_BRANDING_NAMES = ["OMSK"];

/**
    subscriptionAllows first checks to see if a subscription is required for the current user
    then checks its status against a list of values that we consider "active". an active
    status allows full use of the app without restriction.
*/
export function subscriptionAllows(me, prices = [], requireDTCSub = false) {
    const {
        group: { products: stripeProducts },
    } = me;
    const isDTC = isDTCUser(me);
    const activeSubscription =
        isDTC && me && me.subscriptions && getMyActiveSubscription({ subscriptions: me.subscriptions });
    const subscription =
        isDTC &&
        stripeProducts &&
        stripeProducts.length > 0 &&
        stripeProducts.find(
            (product) => product && product.prices && product.prices.length > 0 && product.prices[0].recurring
        );

    return !subscription || activeSubscription;
}

export function providerSubscriptionAllows(me, subscriptions) {
    // Check group owner's subscription to mfr directory
    const subscriptionsList = [...subscriptions.subscriptions, ...me.subscriptions];
    const uniqueIds = [...new Set(subscriptionsList.map((item) => item.id))];
    const uniqueSubs = uniqueIds.map((id) => id && subscriptionsList.find((sub) => sub.id === id));
    const validSubscriptions =
        uniqueSubs &&
        uniqueSubs.length > 0 &&
        uniqueSubs.filter((sub) => {
            if (sub.product && sub.product.title && MFR_PRODUCTS.find((p) => p.title === sub.product.title)) {
                return sub;
            }
            return null;
        });

    if (
        subscriptionsList &&
        subscriptionsList.length > 0 &&
        me.group &&
        me.group.access_level === 40 &&
        validSubscriptions &&
        validSubscriptions.length > 0
    ) {
        const activeSubscription =
            uniqueSubs &&
            uniqueSubs.length > 0 &&
            uniqueSubs.find(
                (sub) =>
                    sub.product &&
                    sub.product.title &&
                    validSubscriptions.find((s) => s.product && s.product.title === sub.product.title) &&
                    (sub.status === "active" ||
                        (sub.status === "canceled" &&
                            moment(me.server_time).startOf("day").valueOf() <
                                moment(sub.termination_date).add(7, "days").startOf("day").valueOf()))
            );

        if (activeSubscription) return true;
    }

    return false;
}

/**
    paymentTokenAllows checks to see if a price has a purchase then checks if the existing token has been consumed.
    An active token allows full use of the app without restriction.
*/
export function paymentTokenAllows(me) {
    const consultPrice = getMyConsultationPrice(me);
    const priceWithPurchases =
        consultPrice && consultPrice.purchases && consultPrice.purchases.length > 0 && consultPrice.purchases;

    return !!(priceWithPurchases && priceWithPurchases.length);
}

/**
    existingSubscriptionRequired first checks to see if a subscription is required for
    the current user then checks to see if this user has ever subscribed before in the past.
    users that require a subscription and have never subscribed are presented the subscription
    registration landing page.
*/
export function existingSubscriptionRequired(me) {
    const { prices, stripe_subscription_status } = me;

    return (
        prices &&
        prices.length > 0 &&
        (!stripe_subscription_status ||
            !stripe_subscription_status.length ||
            stripe_subscription_status === "incomplete")
    );
}

/**
    subscriptionAllowsRoute first checks to see if the current user's subscription is required and
    active then does an additional check to see if they are trying to access a
    subscription-restricted route. users that require a subscription and whose current
    status is not active are presented the subscription renewal component.
*/
export function subscriptionAllowsRoute(route, me) {
    const subAllowed = subscriptionAllows(me);
    const allowedInactiveSubscriptionRoutes = [
        routes.HOME_ROUTE,
        routes.SCHEDULE_MEETING_ROUTE,
        routes.ONBOARDING_SURVEY_ROUTE,
        routes.TIME_BASED_SURVEY_ROUTE,
        routes.TELESPINE_INTRODUCTION_SURVEY_ROUTE,
    ];

    return subAllowed || (!subAllowed && allowedInactiveSubscriptionRoutes.includes(route));
}

/**
 *
 * @param {*} me
 * @returns Returns subscription product tied to the branding
 */
export function getMySubscriptionProduct(me) {
    const subscriptionProduct =
        me.group &&
        me.group.products.length > 0 &&
        me.group.products.find((p) => p.prices && p.prices.length > 0 && p.prices[0] && p.prices[0].recurring);

    return subscriptionProduct && subscriptionProduct.prices.length > 0 ? subscriptionProduct : null;
}

/**
 *
 * @param {*} me
 * @returns Returns subscription price tied to the branding
 */
export function getMySubscriptionPrice(me) {
    const subscriptionProduct =
        me.group &&
        me.group.products &&
        me.group.products.length > 0 &&
        me.group.products.find((p) => p.prices && p.prices.length > 0 && p.prices[0] && p.prices[0].recurring);

    return subscriptionProduct && subscriptionProduct.prices.length > 0 ? subscriptionProduct.prices[0] : null;
}

/**
 *
 * @param {*} me
 * @returns Returns consultation product tied to the branding
 */
export function getMyConsultationProduct(me) {
    const consultationProduct =
        me.group &&
        me.group.products.length > 0 &&
        me.group.products.find((p) => p.prices && p.prices.length > 0 && !p.prices[0].recurring);

    return consultationProduct && consultationProduct.prices && consultationProduct.prices.length > 0
        ? consultationProduct
        : null;
}

/**
 *
 * @param {*} me
 * @returns Returns consultation price tied to the branding
 */
export function getMyConsultationPrice(me) {
    const consultationProduct =
        me.group &&
        me.group.products.length > 0 &&
        me.group.products.find((p) => p.prices && p.prices.length > 0 && !p.prices[0].recurring);

    return consultationProduct && consultationProduct.prices && consultationProduct.prices.length > 0
        ? consultationProduct.prices[0]
        : null;
}

/**
 *
 * @param {*} products
 * @returns Returns provider seat products for the provided type
 */
export function getProviderSeatProducts(productType, products) {
    const providerSeatProduct =
        products && products.length > 0 && products.filter((p) => productType.product_titles.includes(p.title));

    return providerSeatProduct;
}

/**
 *
 * @param {*} client
 * @returns Returns active purchases tied to the client
 */
export function getClientActiveCredits(client) {
    const credits =
        client &&
        client.purchased_products &&
        client.purchased_products.length > 0 &&
        client.purchased_products[0].sales_credits.length > 0
            ? client.purchased_products[0].sales_credits
            : [];
    const activeCredits = credits && credits.filter((credit) => credit && !credit.consumed_at);

    return activeCredits;
}

/**
 *
 * @param {*} prices
 * @returns Returns active purchases tied to the user
 */
export function getMyActiveCredits(prices) {
    const credits = prices && prices.credits && prices.credits.length > 0 ? prices.credits : [];
    const activeCredits = credits && credits.filter((credit) => credit && !credit.consumed_at);

    return activeCredits;
}

/**
 *
 * @param {*} subscriptions
 * @returns Returns an active subscription, if one exists
 */
export function getMyActiveSubscription(subscriptions) {
    const activeSubscription =
        subscriptions &&
        subscriptions.subscriptions &&
        subscriptions.subscriptions.length > 0 &&
        subscriptions.subscriptions.find((sub) => sub.status === "active" || sub.status === "trialing");

    return activeSubscription || false;
}

/**
 *
 * @param {*} subscriptions
 * @returns Returns an active subscription, if one exists
 */
export function getProviderDirectorySubscription(me, subscriptions) {
    const subscriptionsList = [...subscriptions.subscriptions, ...me.subscriptions];
    const uniqueIds = [...new Set(subscriptionsList.map((item) => item.id))];
    const uniqueSubs = uniqueIds.map((id) => id && subscriptionsList.find((sub) => sub.id === id));
    const validSubscriptions =
        uniqueSubs &&
        uniqueSubs.length > 0 &&
        uniqueSubs.filter((sub) => {
            if (sub.product && sub.product.title && MFR_PRODUCTS.find((p) => p.title === sub.product.title)) {
                return sub;
            }
            return null;
        });

    if (
        subscriptions.subscriptions &&
        subscriptions.subscriptions.length > 0 &&
        validSubscriptions &&
        validSubscriptions.length > 0
    ) {
        const activeSubscription =
            uniqueSubs &&
            uniqueSubs.length > 0 &&
            uniqueSubs.find(
                (sub) =>
                    sub.product &&
                    sub.product.title &&
                    validSubscriptions.find((s) => s.product && s.product.title === sub.product.title) &&
                    (sub.status === "active" ||
                        (sub.status === "canceled" &&
                            moment(me.server_time).startOf("day").valueOf() <
                                moment(sub.termination_date).add(7, "days").startOf("day").valueOf()))
            );

        if (activeSubscription) return activeSubscription;
    }

    return null;
}

/**
 *
 * @param {*} subscriptions
 * @returns Returns an active subscription, if one exists
 */
export function getProviderScheduledSubscriptions(me, subscriptions) {
    const subscriptionsList = [...subscriptions.subscriptions, ...me.subscriptions];
    const uniqueIds = [...new Set(subscriptionsList.map((item) => item.id))];
    const uniqueSubs = uniqueIds.map((id) => id && subscriptionsList.find((sub) => sub.id === id));
    const validSubscriptions =
        uniqueSubs &&
        uniqueSubs.length > 0 &&
        uniqueSubs.filter((sub) => {
            if (sub.product && sub.product.title && MFR_PRODUCTS.find((p) => p.title === sub.product.title)) {
                return sub;
            }
            return null;
        });

    if (
        subscriptions.subscriptions &&
        subscriptions.subscriptions.length > 0 &&
        validSubscriptions &&
        validSubscriptions.length > 0
    ) {
        const scheduledSubscription =
            uniqueSubs &&
            uniqueSubs.length > 0 &&
            uniqueSubs.find(
                (sub) =>
                    sub.product &&
                    sub.product.title &&
                    validSubscriptions.find((s) => s.product && s.product.title === sub.product.title) &&
                    sub.status === "scheduled"
            );

        if (scheduledSubscription) return scheduledSubscription;
    }

    return null;
}

/**
 *
 * @param {*} prices
 * @returns Returns all used purchases tied to the user
 */
export function getMyUsedCredits(prices) {
    const credits = prices && prices.credits && prices.credits.length > 0 ? prices.credits : false;
    const usedCredits = credits && credits.filter((credit) => credit && credit.consumed_at);

    return usedCredits;
}

export function shouldDisplayInitialProviderSubscriptionPrompt(me, subscriptions) {
    if (providerSubscriptionAllows(me, subscriptions)) {
        return false;
    }

    // const storageIsAvailable = storageAvailable("localStorage");
    // const item = storageIsAvailable && localStorage.getItem(LAST_INITIAL_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY);
    // const dismiss = storageIsAvailable && localStorage.getItem(LAST_DISMISS_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY);
    // if (item && dismiss) {
    //     const lastDayDismissed = moment(dismiss).startOf("day").valueOf();
    //     const lastDayPrompted = moment(item).startOf("day").valueOf();
    //     const startOfToday = moment().startOf("day").valueOf();
    //     if (lastDayPrompted === startOfToday && lastDayDismissed === startOfToday) {
    //         return false;
    //     }
    // }

    // // Don't set item if onboarding or not showing
    // if (storageIsAvailable) localStorage.setItem(LAST_INITIAL_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY, moment().format());

    return true;
}

export function shouldDisplayRenewalProviderSubscriptionPrompt(me, subscriptions) {
    if (providerSubscriptionAllows(me, subscriptions)) {
        return false;
    }

    // const storageIsAvailable = storageAvailable("localStorage");
    // const item = storageIsAvailable && localStorage.getItem(LAST_INITIAL_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY);
    // const dismiss = storageIsAvailable && localStorage.getItem(LAST_DISMISS_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY);
    // if (item && dismiss) {
    //     const lastDayDismissed = moment(dismiss).startOf("day").valueOf();
    //     const lastDayPrompted = moment(item).startOf("day").valueOf();
    //     const startOfToday = moment().startOf("day").valueOf();
    //     if (lastDayPrompted === startOfToday && lastDayDismissed === startOfToday) {
    //         return false;
    //     }
    // }

    // // Don't set item if onboarding or not showing
    // if (storageIsAvailable) localStorage.setItem(LAST_INITIAL_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY, moment().format());

    return true;
}

export function shouldDisplayInitialSubscriptionPrompt(me, prices, subscriptions) {
    const isOnboarding = IsOnboardingFlow();
    // const providerPaymentSuccess = !!(qs.parse(window.location.search).redirect_status === "succeeded");

    // if (!subscriptionAllows(me) && !providerPaymentSuccess && me.branding && me.branding.is_organization) {
    //     return true;
    // }

    if (subscriptionAllows(me, prices) || isOnboarding) {
        return false;
    }

    const storageIsAvailable = storageAvailable("localStorage");
    const item = storageIsAvailable && localStorage.getItem(LAST_INITIAL_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY);
    const dismiss = storageIsAvailable && localStorage.getItem(LAST_DISMISS_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY);
    if (item && dismiss) {
        const lastDayDismissed = moment(dismiss).startOf("day").valueOf();
        const lastDayPrompted = moment(item).startOf("day").valueOf();
        const startOfToday = moment().startOf("day").valueOf();

        if (lastDayPrompted === startOfToday && lastDayDismissed === startOfToday) {
            return false;
        }
    }

    // Don't set item if onboarding or not showing
    if (storageIsAvailable && !isOnboarding && subscriptions.promptForSubscription) {
        localStorage.setItem(LAST_INITIAL_SUBSCRIPTION_PROMPT_LOCAL_STORAGE_KEY, moment().format());
    }

    return true;
}

/**
 *
 * @param {*} subscription
 * @param {*} price
 * @returns Returns a price with discounts calculated
 */
export function calculatePrice(subscription, price) {
    const { validPromo, percent_off, amount_off } = subscription;

    if (!validPromo) return price / 100;

    const discount = percent_off ? price * (percent_off / 100) : amount_off ? amount_off : 0;
    const discountedPrice = ((price - discount) / 100).toFixed(2);

    return discountedPrice;
}

export function getSubscriptionStatusLabel(status) {
    switch (status) {
        case "active":
            return "Active";
        case "past_due":
            return "Past Due";
        case "canceled":
            return "Canceled";
        default:
            return "Incomplete";
    }
}
