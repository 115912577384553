import { push } from "connected-react-router";
import { generatePath, matchPath } from "react-router-dom";

import {
    checkWaitingRoom,
    resetSchedulerInput,
    VIDEO_SESSION,
    REQUEST_MEETINGS,
    RECEIVE_MEETINGS,
    RECEIVE_MEETINGS_ERROR,
    REQUEST_CALENDAR_MEETINGS,
    RECEIVE_CALENDAR_MEETINGS,
    RECEIVE_CALENDAR_MEETINGS_ERROR,
    RECEIVE_CREATE_PATIENT_MEETING,
    REQUEST_CANCEL_MEETING,
    RECEIVE_CANCEL_MEETING,
    RECEIVE_CANCEL_MEETING_ERROR,
    REQUEST_ON_DEMAND_CONSULT,
    RECEIVE_ON_DEMAND_CONSULT,
    RECEIVE_ON_DEMAND_CONSULT_ERROR,
    RECEIVE_MEETING,
    RECEIVE_MEETING_ERROR,
    REQUEST_MEETING,
    REQUEST_CREATE_MEETING,
    RECEIVE_CREATE_MEETING,
    RECEIVE_CREATE_MEETING_ERROR,
    REQUEST_UPDATE_MEETING_NOTES,
    RECEIVE_UPDATE_MEETING_NOTES,
    RECEIVE_UPDATE_MEETING_NOTES_ERROR,
    RECEIVE_VIDEO_SESSION_DATA,
    RECEIVE_VIDEO_SESSION_DATA_ERROR,
    UPDATE_VIDEO_SESSION_DATA,
    REQUEST_VIDEO_SESSION_DATA,
    REQUEST_MISS_MEETING,
    RECEIVE_MISS_MEETING,
    RECEIVE_MISS_MEETING_ERROR,
    RECEIVE_WAITING_ROOM_MEETING,
    REQUEST_SOAP_NOTE_PATIENT_MEETINGS,
    RECEIVE_SOAP_NOTE_PATIENT_MEETINGS,
    RECEIVE_SOAP_NOTE_PATIENT_MEETINGS_ERROR,
    REQUEST_COACH_HOMEPAGE_MEETINGS,
    RECEIVE_COACH_HOMEPAGE_MEETINGS_ERROR,
    RECEIVE_COACH_HOMEPAGE_MEETINGS,
    updateCoachBusyStatus,
    CLOSE_CONSULT_BANNER,
    CLOSE_HY_BANNER,
    REQUEST_CONSULT_TRANSCRIPT,
    RECEIVE_CONSULT_TRANSCRIPT,
    RECEIVE_CONSULT_TRANSCRIPT_ERROR,
} from "../actions";
import {
    COACH_CLIENT_DETAILS_ROUTE,
    COACH_VIDEO_SESSION_ROUTE,
    ON_DEMAND_WAITING_ROOM_ROUTE,
    VIDEO_SESSION_ROUTE,
} from "../../utils/routes";
import { checkOnDemandMeeting, checkUpcomingScheduledMeeting } from "../../utils/helpers";
import moment from "moment";
import { parseMeetings } from "../../utils/scheduler";
const consultInitialState = {
    calendarMeetingsList: [],
    showConsultBanner: true,
    showHYBanner: true,
};

export function consult(state = consultInitialState, action) {
    switch (action.type) {
        case VIDEO_SESSION: {
            const { me } = action;
            const queryString = `?sid=${action.data.opentok_session_id}`;

            if (me.is_coach) {
                // mark coach as busy
                action.asyncDispatch(updateCoachBusyStatus(true));

                // open tab to patient details if not already on it
                const patientId = action.data.patient ? action.data.patient.id : action.data.user_id;
                const patientDetailsRoute = generatePath(COACH_CLIENT_DETAILS_ROUTE, { clientId: patientId });
                const matches = matchPath(window.location.pathname, { path: patientDetailsRoute });

                if (!matches) {
                    window.open(patientDetailsRoute, "_blank");
                }

                // open a new tab to video-session
                window.open(`${COACH_VIDEO_SESSION_ROUTE}${queryString}`, "_blank");
            } else {
                // if (!subscriptionAllows(me, prices)) {
                //     // prompt for subscription and return early
                //     action.asyncDispatch(promptForSubscription("video_session"));
                //     return state;
                // }

                action.asyncDispatch(push(`${VIDEO_SESSION_ROUTE}${queryString}`));
            }

            return {
                ...state,
                videoSession: action.data,
            };
        }
        case REQUEST_VIDEO_SESSION_DATA:
            return {
                ...state,
                videoSessionIsFetching: action.isFetching,
                videoSessionRequestedAt: action.requestedAt,
            };
        case RECEIVE_VIDEO_SESSION_DATA: {
            const meetingData = action.data;
            const { meetingsList } = state;
            const newMeetingsList = meetingsList && meetingsList.length > 0 ? [...meetingsList] : [];
            const meetingIndex = newMeetingsList.findIndex((m) => m.id === meetingData.id);

            if (meetingIndex > -1) {
                // meeting exists in list
                newMeetingsList[meetingIndex] = {
                    ...newMeetingsList[meetingIndex],
                    ...meetingData,
                };
            }

            return {
                ...state,
                meetingsList: newMeetingsList,
                videoSessionData: action.data,
                videoSessionIsFetching: action.isFetching,
                videoSessionReceivedAt: action.receivedAt,
            };
        }
        case RECEIVE_VIDEO_SESSION_DATA_ERROR:
            return {
                ...state,
                videoSessionIsFetching: action.isFetching,
                videoSessionReceivedAt: action.receivedAt,
                videoSessionError: action.error,
            };
        case UPDATE_VIDEO_SESSION_DATA:
            return {
                ...state,
                videoSessionData: { ...action.sessionData },
            };
        case REQUEST_MEETINGS:
            return {
                ...state,
                meetingsListRequestedAt: action.requestedAt,
                meetingsListIsFetching: true,
            };
        case RECEIVE_MEETINGS: {
            const meetingsList = action.data.results;
            const upcomingMeeting =
                meetingsList &&
                meetingsList.length > 0 &&
                meetingsList.find((meeting) => checkUpcomingScheduledMeeting(meeting));
            const onDemandMeeting =
                meetingsList &&
                meetingsList.length > 0 &&
                meetingsList.find((meeting) => checkOnDemandMeeting(meeting));

            return {
                ...state,
                meetingsList,
                upcomingMeeting,
                onDemandMeeting,
                meetingsListReceivedAt: action.receivedAt,
                meetingsListIsFetching: false,
            };
        }
        case RECEIVE_MEETINGS_ERROR:
            return {
                ...state,
                meetingsListError: action.error,
                meetingsListIsFetching: false,
            };
        case REQUEST_CALENDAR_MEETINGS:
            return {
                ...state,
                calendarMeetingsListIsFetching: true,
                calendarMeetingsListRequestedAt: action.requestedAt,
            };
        case RECEIVE_CALENDAR_MEETINGS: {
            const calendarMeetingsList = parseMeetings(action.data);

            return {
                ...state,
                calendarMeetingsListIsFetching: false,
                calendarMeetingsListReceivedAt: action.receivedAt,
                calendarMeetingsList,
            };
        }
        case RECEIVE_CALENDAR_MEETINGS_ERROR:
            return {
                ...state,
                calendarMeetingsListIsFetching: false,
                calendarMeetingsListReceivedAt: action.receivedAt,
                calendarMeetingsListError: action.error,
            };
        case RECEIVE_CREATE_PATIENT_MEETING: {
            const { calendarMeetingsList = [] } = { ...state };
            const meeting = {
                ...action.data,
                start_time: moment(action.data.start_time),
                end_time: moment(action.data.end_time),
            };

            calendarMeetingsList.push(meeting);

            return {
                ...state,
                calendarMeetingsList,
            };
        }
        case REQUEST_MEETING: {
            return {
                ...state,
                meetingRequestedAt: action.requestedAt,
                meetingIsFetching: action.isFetching,
            };
        }
        case RECEIVE_MEETING: {
            const { data: meetingData } = action;

            // see if the user is in the waiting room for the given meeting and handle appropriately
            action.asyncDispatch(checkWaitingRoom(meetingData));

            // update the meeting in the existing list, append new if it doesn't exist
            const { meetingsList } = state;
            const newMeetingsList = meetingsList && meetingsList.length > 0 ? [...meetingsList] : [];
            const existingMeetingIndex =
                meetingsList &&
                meetingsList.length > 0 &&
                meetingsList.findIndex((meeting) => meeting.id === meetingData.id);

            if (existingMeetingIndex > -1) {
                newMeetingsList[existingMeetingIndex] = {
                    ...newMeetingsList[existingMeetingIndex],
                    ...meetingData,
                };
            } else {
                newMeetingsList.push(meetingData);
            }

            return {
                ...state,
                meetingReceivedAt: action.receivedAt,
                meetingIsFetching: action.isFetching,
                meetingsList: newMeetingsList,
            };
        }
        case RECEIVE_MEETING_ERROR:
            return {
                ...state,
                meetingReceivedAt: action.receivedAt,
                meetingIsFetching: action.isFetching,
                meetingError: action.error,
            };
        case REQUEST_CANCEL_MEETING:
            return {
                ...state,
                cancelMeetingRequestedAt: action.requestedAt,
                cancelMeetingIsFetching: true,
            };
        case RECEIVE_CANCEL_MEETING:
            return {
                ...state,
                upcomingMeeting: undefined,
                cancelMeetingIsFetching: action.isFetching,
            };
        case RECEIVE_CANCEL_MEETING_ERROR:
            return {
                ...state,
                cancelMeetingError: action.error,
                cancelMeetingIsFetching: false,
            };
        case REQUEST_MISS_MEETING: {
            return {
                ...state,
                missMeetingRequestedAt: action.requestedAt,
                missMeetingIsFetching: true,
            };
        }
        case RECEIVE_MISS_MEETING: {
            return {
                ...state,
                missMeetingReceivedAt: action.receivedAt,
                missMeetingIsFetching: false,
            };
        }
        case RECEIVE_MISS_MEETING_ERROR: {
            return {
                ...state,
                missMeetingReceivedAt: action.receivedAt,
                missMeetingIsFetching: false,
                missMeetingError: action.error,
            };
        }
        case REQUEST_ON_DEMAND_CONSULT:
            return {
                ...state,
                onDemandRequestedAt: action.requestedAt,
                onDemandIsFetching: true,
            };
        case RECEIVE_ON_DEMAND_CONSULT: {
            action.asyncDispatch(push(ON_DEMAND_WAITING_ROOM_ROUTE));

            return {
                ...state,
                onDemandReceivedAt: action.receivedAt,
                onDemandIsFetching: false,
                onDemandMeeting: action.data,
            };
        }
        case RECEIVE_ON_DEMAND_CONSULT_ERROR:
            return {
                ...state,
                onDemandReceivedAt: action.receivedAt,
                onDemandIsFetching: false,
                onDemandError: action.error,
            };
        case RECEIVE_WAITING_ROOM_MEETING: {
            const { data: meetingData } = action;
            const { onDemandMeeting } = state;
            let newOnDemandMeeting = { ...onDemandMeeting };

            if (
                meetingData.coach_joined &&
                onDemandMeeting &&
                onDemandMeeting.id === meetingData.id &&
                !onDemandMeeting.coach_joined
            ) {
                // on-demand meeting found, redirect to video session
                action.asyncDispatch(push(`${VIDEO_SESSION_ROUTE}?sid=${meetingData.opentok_session_id}`));

                // update current on-demand with new data
                newOnDemandMeeting = {
                    ...newOnDemandMeeting,
                    ...meetingData,
                };
            }

            return {
                ...state,
                onDemandMeeting: newOnDemandMeeting,
            };
        }
        case REQUEST_CREATE_MEETING:
            return {
                ...state,
                isFetching: true,
                requestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_MEETING: {
            action.asyncDispatch(resetSchedulerInput());

            return {
                ...state,
                upcomingMeeting: action.data,
                receivedAt: action.receivedAt,
                isFetching: false,
            };
        }
        case RECEIVE_CREATE_MEETING_ERROR:
            return {
                ...state,
                receivedAt: action.receivedAt,
                isFetching: false,
                error: action.error,
            };
        case REQUEST_UPDATE_MEETING_NOTES:
            return {
                ...state,
                updateMeetingNotesIsFetching: true,
                updateMeetingNotesRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_MEETING_NOTES:
            const { subject } = action.data;
            const { upcomingMeeting } = { ...state };
            upcomingMeeting.subject = subject;

            return {
                ...state,
                updateMeetingNotesIsFetching: false,
                updateMeetingNotesReceivedAt: action.receivedAt,
                upcomingMeeting,
            };
        case RECEIVE_UPDATE_MEETING_NOTES_ERROR:
            return {
                ...state,
                updateMeetingNotesIsFetching: false,
                updateMeetingNotesReceivedAt: action.receivedAt,
                updateMeetingNotesError: action.error,
            };
        case REQUEST_SOAP_NOTE_PATIENT_MEETINGS:
            return {
                ...state,
                soapNotePatientMeetingsRequestedAt: action.requestedAt,
                soapNotePatientMeetingsLoading: true,
            };
        case RECEIVE_SOAP_NOTE_PATIENT_MEETINGS: {
            const { soapNotesData } = state;
            const newSoapNote = { ...(soapNotesData[action.soapNoteId] || {}) };

            newSoapNote.patient_meetings = action.data;

            return {
                ...state,
                soapNotePatientMeetingsReceivedAt: action.receivedAt,
                soapNotePatientMeetingsLoading: false,
                soapNotePatientMeetingsData: action.data,
                soapNotesData: {
                    ...state.soapNotesData,
                    [action.soapNoteId]: newSoapNote,
                },
            };
        }
        case RECEIVE_SOAP_NOTE_PATIENT_MEETINGS_ERROR:
            return {
                ...state,
                soapNotePatientMeetingsReceivedAt: action.receivedAt,
                soapNotePatientMeetingsLoading: false,
                soapNotePatientMeetingsError: action.error,
            };
        case REQUEST_COACH_HOMEPAGE_MEETINGS:
            return {
                ...state,
                coachHomepageMeetingsIsLoading: true,
                coachHomepageMeetingsRequestedAt: action.requestedAt,
            };
        case RECEIVE_COACH_HOMEPAGE_MEETINGS:
            return {
                ...state,
                coachHomepageMeetingsIsLoading: false,
                coachHomepageMeetingsReceivedAt: action.receivedAt,
                coachHomepageMeetingsData: action.data.results,
            };
        case RECEIVE_COACH_HOMEPAGE_MEETINGS_ERROR:
            return {
                ...state,
                coachHomepageMeetingsIsLoading: false,
                coachHomepageMeetingsReceivedAt: action.receivedAt,
                coachHomepageMeetingsError: action.error,
            };
        case CLOSE_CONSULT_BANNER:
            return {
                ...state,
                showConsultBanner: false,
            };
        case CLOSE_HY_BANNER:
            return {
                ...state,
                showHYBanner: false,
            };
        case REQUEST_CONSULT_TRANSCRIPT:
            return {
                ...state,
                consultTranscriptIsFetching: true,
                consultTranscriptRequestedAt: action.requestedAt,
            };
        case RECEIVE_CONSULT_TRANSCRIPT:
            return {
                ...state,
                consultTranscriptIsFetching: false,
                consultTranscriptReceivedAt: action.receivedAt,
                consultTranscriptData: action.data,
            };
        case RECEIVE_CONSULT_TRANSCRIPT_ERROR:
            return {
                ...state,
                consultTranscriptIsFetching: false,
                consultTranscriptReceivedAt: action.receivedAt,
                consultTranscriptError: action.error,
            };
        default:
            return state;
    }
}
