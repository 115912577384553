import moment from "moment-timezone";
import { createRoutinesFromLegacyProgram, getLatestCurrentProgram } from "../../utils/programs";
import { processResource } from "../../utils/resources";
import {
    REQUEST_CLIENT_ASSIGNED_PROGRAMS,
    RECEIVE_CLIENT_ASSIGNED_PROGRAMS,
    RECEIVE_CLIENT_ASSIGNED_PROGRAMS_ERROR,
    REQUEST_PROGRAM,
    RECEIVE_PROGRAM,
    RECEIVE_PROGRAM_ERROR,
    REQUEST_PROGRAMS,
    RECEIVE_PROGRAMS,
    RECEIVE_PROGRAMS_ERROR,
    REQUEST_PROGRAM_UPDATED_EMAIL_NOTIFICATION,
    RECEIVE_PROGRAM_UPDATED_EMAIL_NOTIFICATION,
    RECEIVE_PROGRAM_UPDATED_EMAIL_NOTIFICATION_ERROR,
    RECEIVE_UPDATE_RESOURCE,
    RECEIVE_ITEM_VISITED,
    SELECT_ITEM,
    NEXT_ITEM,
    PREV_ITEM,
    ITEM_WATCHED,
    REQUEST_CREATE_RESOURCES_DATAPOINT,
    RECEIVE_CREATE_RESOURCES_DATAPOINT,
    RECEIVE_CREATE_RESOURCES_DATAPOINT_ERROR,
    SELECT_ROUTINE,
    INIT_PLAYER,
    DEINIT_PLAYER,
    PLAY_PLAYER,
    PAUSE_PLAYER,
    SEEK_PLAYER,
    RECEIVE_ME,
    RECEIVE_LOGIN_DATA,
    SEEK_VIDEO_PLAYER,
    REQUEST_ADD_RESOURCE_TO_LIST,
    RECEIVE_ADD_RESOURCE_TO_LIST,
    RECEIVE_ADD_RESOURCE_TO_LIST_ERROR,
    REQUEST_REMOVE_RESOURCE_FROM_LIST,
    RECEIVE_REMOVE_RESOURCE_FROM_LIST,
    RECEIVE_REMOVE_RESOURCE_FROM_LIST_ERROR,
    RECEIVE_UPDATE_ASSIGNED_PROGRAM_STATUS,
    REQUEST_UPDATE_ASSIGNED_PROGRAM_STATUS,
    RECEIVE_UPDATE_ASSIGNED_PROGRAM_STATUS_ERROR,
    REQUEST_ARTICLE_WATCHED,
    RECEIVE_ARTICLE_WATCHED,
    RECEIVE_ARTICLE_WATCHED_ERROR,
    createResourcesDataPoint,
} from "../actions";

const programsInitialState = { programsIsFetching: false };

export function programs(state = programsInitialState, action) {
    switch (action.type) {
        case REQUEST_CLIENT_ASSIGNED_PROGRAMS:
            return {
                ...state,
                clientAssignedProgramsRequestedAt: action.requestedAt,
                clientAssignedProgramsIsFetching: true,
            };
        case RECEIVE_CLIENT_ASSIGNED_PROGRAMS: {
            const { results: programs } = action.data;

            const programsList =
                programs &&
                programs.length > 0 &&
                programs.map((program, idx) => {
                    const programResources =
                        program &&
                        program.resources_list &&
                        program.resources_list.resources &&
                        program.resources_list.resources.length > 0 &&
                        program.resources_list.resources.map((resource) => processResource(resource));

                    return {
                        ...program,
                        resources_list: { ...program.resources_list, resources: programResources },
                    };
                });

            const processedProgramsList =
                programsList &&
                programsList.length > 0 &&
                programsList
                    .map((program) => {
                        if (
                            program &&
                            program.resources_list &&
                            program.resources_list.resources &&
                            program.resources_list.resources.length > 0 &&
                            program.resources_list.resources[0].group &&
                            program.resources_list.resources[0].group.length > 0
                        ) {
                            return createRoutinesFromLegacyProgram(program);
                        }

                        return program;
                    })
                    .flat(1)
                    .reverse();
            const orderedProgramsList = processedProgramsList.map((program, idx) => ({ ...program, order: idx }));

            const currentProgram = getLatestCurrentProgram(orderedProgramsList);
            let current_item = 0;

            if (currentProgram && currentProgram.last_visited) {
                current_item = currentProgram.last_visited;
            }

            // If user has a current program, ignore all future programs except for the following one
            if (currentProgram) {
                currentProgram.latest_current = true;
                const currentProgramIndex = orderedProgramsList.findIndex(
                    (program) => program.id === currentProgram.id
                );

                if (currentProgramIndex < orderedProgramsList.length - 2)
                    orderedProgramsList.length = currentProgramIndex + 2;

                if (
                    !currentProgram.completed &&
                    currentProgramIndex > 0 &&
                    currentProgramIndex < orderedProgramsList.length - 1
                ) {
                    orderedProgramsList[orderedProgramsList.length - 1].up_next = true;
                }
            }

            return {
                ...state,
                current_item,
                currentProgram,
                programsList: orderedProgramsList,
                clientAssignedProgramsReceivedAt: action.receivedAt,
                clientAssignedProgramsIsFetching: false,
            };
        }
        case RECEIVE_CLIENT_ASSIGNED_PROGRAMS_ERROR:
            return {
                ...state,
                clientAssignedProgramsReceivedAt: action.receivedAt,
                clientAssignedProgramsIsFetching: false,
                clientAssignedProgramsError: action.error,
            };
        case REQUEST_UPDATE_ASSIGNED_PROGRAM_STATUS:
            return {
                ...state,
                updateAssignedProgramStatusRequestedAt: action.requestedAt,
                updateAssignedProgramStatusIsFetching: true,
            };
        case RECEIVE_UPDATE_ASSIGNED_PROGRAM_STATUS: {
            const { programsList: currentAssignedPrograms, programsList } = { ...state };
            const { data: updatedProgram } = action;
            const currentUpdatedProgram = currentAssignedPrograms.find((program) => program.id === action.data.id);
            const currentProgram = getLatestCurrentProgram(programsList);

            if (currentProgram) {
                const isNewerCurrentProgram =
                    updatedProgram.current &&
                    moment(updatedProgram.current).valueOf() > moment(currentProgram.current).valueOf();

                if (isNewerCurrentProgram) {
                    delete currentProgram.latest_current;
                    currentUpdatedProgram.latest_current = true;
                }

                if (currentProgram.latest_current && updatedProgram.completed) {
                    const currentProgramIndex = currentAssignedPrograms.findIndex(
                        (program) => program.id === currentProgram.id
                    );

                    if (currentProgramIndex <= currentAssignedPrograms.length - 1)
                        if (currentAssignedPrograms[currentProgramIndex + 1])
                            delete currentAssignedPrograms[currentProgramIndex + 1].up_next;
                }
            } else {
                currentUpdatedProgram.latest_current = true;
            }

            currentUpdatedProgram.completed = updatedProgram.completed;
            currentUpdatedProgram.current = updatedProgram.current;
            currentUpdatedProgram.effective = updatedProgram.effective;
            currentUpdatedProgram.hidden = updatedProgram.hidden;
            currentUpdatedProgram.initiated = updatedProgram.initiated;
            currentUpdatedProgram.last_visited = updatedProgram.last_visited;

            return {
                ...state,
                updateAssignedProgramStatusReceivedAt: action.receivedAt,
                updateAssignedProgramStatusIsFetching: false,
            };
        }
        case RECEIVE_UPDATE_ASSIGNED_PROGRAM_STATUS_ERROR:
            return {
                ...state,
                updateAssignedProgramStatusReceivedAt: action.receivedAt,
                updateAssignedProgramStatusIsFetching: false,
                updateAssignedProgramStatusError: action.error,
            };
        case RECEIVE_ME:
        case RECEIVE_LOGIN_DATA: {
            let assignedProgram = action.data.assigned_program;
            let current_item = 0;

            if (assignedProgram && assignedProgram.last_visited) {
                current_item = assignedProgram.last_visited;
            }

            return {
                ...state,
                assignedProgram,
                current_item,
            };
        }
        case REQUEST_PROGRAM:
            return {
                ...state,
                programRequestedAt: action.requestedAt,
                programIsFetching: true,
            };
        case RECEIVE_PROGRAM: {
            const processedResources = action.data.resources_list.resources.map((resource) =>
                processResource(resource)
            );

            return {
                ...state,
                program: processedResources,
                programReceivedAt: action.receivedAt,
                programIsFetching: false,
            };
        }
        case RECEIVE_PROGRAM_ERROR:
            return {
                ...state,
                programError: action.error,
                programIsFetching: false,
            };
        case REQUEST_PROGRAMS:
            return {
                ...state,
                programsRequestedAt: action.requestedAt,
                programsIsFetching: true,
            };
        case RECEIVE_PROGRAMS: {
            const {
                data,
                data: { results },
            } = action;
            const programsList = results ? results : data;

            const programs =
                programsList &&
                programsList.length &&
                programsList.length > 0 &&
                programsList.map((program) => {
                    if (results) {
                        const processedResources =
                            program.resources_list &&
                            program.resources_list.resources &&
                            program.resources_list.resources.length &&
                            program.resources_list.resources.length > 0 &&
                            program.resources_list.resources.map((resource) => processResource(resource));

                        program.resources_list.resources = processedResources;
                    }

                    return program;
                });

            return {
                ...state,
                programs,
                programsReceivedAt: action.receivedAt,
                programsIsFetching: false,
            };
        }
        case RECEIVE_PROGRAMS_ERROR:
            return {
                ...state,
                programsError: action.error,
                programsIsFetching: false,
            };
        case REQUEST_ADD_RESOURCE_TO_LIST:
            return {
                ...state,
                addItemToListRequestedAt: action.requestedAt,
                addItemToListIsFetching: true,
            };
        case RECEIVE_ADD_RESOURCE_TO_LIST:
            return {
                ...state,
                addItemToListReceivedAt: action.receivedAt,
                addItemToListIsFetching: false,
            };
        case RECEIVE_ADD_RESOURCE_TO_LIST_ERROR:
            return {
                ...state,
                addItemToListReceivedAt: action.receivedAt,
                addItemToListIsFetching: false,
                addItemToListError: action.error,
            };
        case REQUEST_REMOVE_RESOURCE_FROM_LIST:
            return {
                ...state,
                removeItemFromListRequestedAt: action.requestedAt,
                removeItemFromListIsFetching: true,
            };
        case RECEIVE_REMOVE_RESOURCE_FROM_LIST:
            return {
                ...state,
                removeItemFromListReceivedAt: action.receivedAt,
                removeItemFromListIsFetching: false,
            };
        case RECEIVE_REMOVE_RESOURCE_FROM_LIST_ERROR:
            return {
                ...state,
                removeItemFromListReceivedAt: action.receivedAt,
                removeItemFromListIsFetching: false,
                removeItemFromListError: action.error,
            };
        case REQUEST_PROGRAM_UPDATED_EMAIL_NOTIFICATION:
            return {
                ...state,
                programUpdatedEmailNotificationIsFetching: true,
                programUpdatedEmailNotificationRequestedAt: action.requestedAt,
            };
        case RECEIVE_PROGRAM_UPDATED_EMAIL_NOTIFICATION:
            return {
                ...state,
                programUpdatedEmailNotificationIsFetching: false,
                programUpdatedEmailNotificationReceivedAt: action.receivedAt,
            };
        case RECEIVE_PROGRAM_UPDATED_EMAIL_NOTIFICATION_ERROR:
            return {
                ...state,
                programUpdatedEmailNotificationIsFetching: false,
                programUpdatedEmailNotificationReceivedAt: action.receivedAt,
                programUpdatedEmailNotificationError: action.error,
            };
        case RECEIVE_UPDATE_RESOURCE: {
            const { selectedRoutine: currentRoutine } = { ...state };
            const { completed, sets, reps, hold_duration, sets_finished } = action.data;

            if (!currentRoutine) return { ...state };

            const { resources_list } = currentRoutine;
            const updatedResourceIndex =
                resources_list &&
                resources_list.resources &&
                resources_list.resources.length > 0 &&
                resources_list.resources.findIndex((resource) => resource.id === action.data.id);

            resources_list.resources[updatedResourceIndex].sets = sets;
            resources_list.resources[updatedResourceIndex].reps = reps;
            resources_list.resources[updatedResourceIndex].completed = completed;
            resources_list.resources[updatedResourceIndex].hold_duration = hold_duration;
            resources_list.resources[updatedResourceIndex].sets_finished = sets_finished;

            return {
                ...state,
                updateResourceIsLoading: false,
                updateResourceReceivedAt: action.receivedAt,
            };
        }
        case RECEIVE_ITEM_VISITED: {
            const { currentProgram } = state;
            const { last_visited } = action.data;

            currentProgram.last_visited = last_visited;

            return {
                ...state,
                currentProgram,
                itemVisitedIsFetching: false,
                itemVisitedReceivedAt: action.receivedAt,
            };
        }
        case REQUEST_ARTICLE_WATCHED:
            return {
                ...state,
                articleWatchedIsFetching: true,
                articleWatchedRequestedAt: action.requestedAt,
            };
        case RECEIVE_ARTICLE_WATCHED:
            return {
                ...state,
                articleWatchedIsFetching: false,
                articleWatchedReceivedAt: action.receivedAt,
            };
        case RECEIVE_ARTICLE_WATCHED_ERROR:
            return {
                ...state,
                articleWatchedIsFetching: false,
                articleWatchedReceivedAt: action.receivedAt,
                articleWatchedError: action.error,
            };
        case SELECT_ITEM:
            return {
                ...state,
                current_item: action.current_item,
            };
        case NEXT_ITEM: {
            if (
                state.assignedProgram &&
                state.assignedProgram.resources_list &&
                state.assignedProgram.resources_list.resources &&
                state.current_item < state.assignedProgram.resources_list.resources.length - 1
            ) {
                return {
                    ...state,
                    current_item: state.current_item + 1,
                };
            }
            return state;
        }
        case PREV_ITEM: {
            if (
                state.assignedProgram &&
                state.assignedProgram.resources_list &&
                state.assignedProgram.resources_list.resources &&
                state.current_item > 0
            ) {
                return {
                    ...state,
                    current_item: state.current_item - 1,
                };
            }
            return state;
        }
        case ITEM_WATCHED: {
            const { resourceId } = action;
            const { assignedProgram, current_item } = state;
            const currentResource = assignedProgram.resources_list.resources[current_item];
            let { last_item_watched, last_resource_watched } = state;

            if (resourceId && last_resource_watched !== resourceId) {
                action.asyncDispatch(createResourcesDataPoint(resourceId));
                last_resource_watched = resourceId;
            } else if (!resourceId && last_item_watched !== current_item) {
                last_item_watched = current_item;
                action.asyncDispatch(createResourcesDataPoint(currentResource.id));
            }

            return {
                ...state,
                last_resource_watched,
                last_item_watched,
            };
        }
        case REQUEST_CREATE_RESOURCES_DATAPOINT:
            return {
                ...state,
                createResourcesDatapointIsFetching: true,
                createResourcesDatapointRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_RESOURCES_DATAPOINT: {
            const { assignedProgram, current_item, selectedRoutine: currentRoutine } = state;
            const currentResource = assignedProgram.resources_list.resources[current_item];
            currentResource.data_points.push(action.data.id);

            if (currentRoutine) {
                const {
                    resources_list: { resources },
                } = currentRoutine;

                const updatedResourceIndex =
                    resources &&
                    resources.length > 0 &&
                    resources.findIndex((resource) => resource.id === action.data.resource.id);

                if (updatedResourceIndex && resources[updatedResourceIndex])
                    resources[updatedResourceIndex].data_points.push(action.data.id);
            }

            return {
                ...state,
                createResourcesDatapointIsFetching: false,
                createResourcesDatapointReceivedAt: action.receivedAt,
                assignedProgram,
            };
        }
        case RECEIVE_CREATE_RESOURCES_DATAPOINT_ERROR:
            return {
                ...state,
                createResourcesDatapointIsFetching: false,
                createResourcesDatapointReceivedAt: action.receivedAt,
                createResourcesError: action.error,
            };
        case SELECT_ROUTINE:
            return {
                ...state,
                selectedRoutine: action.routine,
            };
        case INIT_PLAYER:
            return {
                ...state,
                player: action.player,
            };
        case DEINIT_PLAYER:
            return {
                ...state,
                player: null,
            };
        case PLAY_PLAYER:
            state.player && state.player.play();
            return {
                ...state,
            };
        case PAUSE_PLAYER:
            state.player && state.player.pause();
            return {
                ...state,
            };
        case SEEK_VIDEO_PLAYER:
            state.player.seekTo(action.secs);

            return {
                ...state,
            };
        case SEEK_PLAYER:
            if (state.player) {
                state.player.currentTime(action.sec);
                if (state.player.paused()) {
                    state.player.play();
                }
            }
            return {
                ...state,
            };
        default:
            return state;
    }
}
