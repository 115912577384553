import {
    REQUEST_GET_CLIENT_PRICES,
    RECEIVE_GET_CLIENT_PRICES,
    RECEIVE_GET_CLIENT_PRICES_ERROR,
    REQUEST_CREATE_CLIENT_PRICE,
    RECEIVE_CREATE_CLIENT_PRICE,
    RECEIVE_CREATE_CLIENT_PRICE_ERROR,
    REQUEST_REMOVE_CLIENT_PRICE,
    RECEIVE_REMOVE_CLIENT_PRICE,
    RECEIVE_REMOVE_CLIENT_PRICE_ERROR,
    REQUEST_GET_CLIENT_CREDITS,
    RECEIVE_GET_CLIENT_CREDITS,
    RECEIVE_GET_CLIENT_CREDITS_ERROR,
    REQUEST_USE_CLIENT_CREDIT,
    RECEIVE_USE_CLIENT_CREDIT,
    RECEIVE_USE_CLIENT_CREDIT_ERROR,
    REQUEST_ALL_PURCHASES,
    RECEIVE_ALL_PURCHASES,
    RECEIVE_ALL_PURCHASES_ERROR,
    REQUEST_ADD_CREDIT_CARD,
    RECEIVE_ADD_CREDIT_CARD,
    RECEIVE_ADD_CREDIT_CARD_ERROR,
    REQUEST_PURCHASE_PRODUCT,
    RECEIVE_PURCHASE_PRODUCT,
    RECEIVE_PURCHASE_PRODUCT_ERROR,
    SET_PURCHASE_STATUS,
    CLEAR_PURCHASE_STATUS,
    REQUEST_SCHEDULED_PURCHASE_PRODUCT,
    RECEIVE_SCHEDULED_PURCHASE_PRODUCT,
    RECEIVE_SCHEDULED_PURCHASE_PRODUCT_ERROR,
} from "../actions/prices";

const pricesInitialState = {
    prices: [],
    credits: [],
    getClientCreditsIsFetching: false,
    showSubscriptionSuccessPrompt: false,
};

export function prices(state = pricesInitialState, action) {
    switch (action.type) {
        case REQUEST_GET_CLIENT_PRICES:
            return {
                ...state,
                getClientPricesIsFetching: true,
                getClientPricesRequestedAt: action.requestedAt,
            };
        case RECEIVE_GET_CLIENT_PRICES:
            return {
                ...state,
                getClientPricesIsFetching: false,
                getClientPricesReceivedAt: action.receivedAt,
            };
        case RECEIVE_GET_CLIENT_PRICES_ERROR:
            return {
                ...state,
                getClientPricesIsFetching: false,
                getClientPricesReceivedAt: action.receivedAt,
                clientPrices: action.data,
                getClientPricesError: action.error,
            };
        case REQUEST_CREATE_CLIENT_PRICE:
            return {
                ...state,
                createClientPriceIsFetching: true,
                createClientPriceRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_CLIENT_PRICE:
            return {
                ...state,
                createClientPriceIsFetching: false,
                createClientPriceReceivedAt: action.receivedAt,
                clientPrices: action.data,
            };
        case RECEIVE_CREATE_CLIENT_PRICE_ERROR:
            return {
                ...state,
                createClientPriceIsFetching: false,
                createClientPriceReceivedAt: action.receivedAt,
                createClientPriceError: action.error,
            };
        case REQUEST_REMOVE_CLIENT_PRICE:
            return {
                ...state,
                removeClientPriceIsFetching: true,
                removeClientPriceRequestedAt: action.requestedAt,
            };
        case RECEIVE_REMOVE_CLIENT_PRICE:
            return {
                ...state,
                removeClientPriceIsFetching: false,
                removeClientPriceReceivedAt: action.receivedAt,
            };
        case RECEIVE_REMOVE_CLIENT_PRICE_ERROR:
            return {
                ...state,
                removeClientPriceIsFetching: false,
                removeClientPriceReceivedAt: action.receivedAt,
                removeClientPriceError: action.error,
            };
        case REQUEST_GET_CLIENT_CREDITS:
            return {
                ...state,
                getClientCreditsIsFetching: true,
                getClientCreditsRequestedAt: action.requestedAt,
            };
        case RECEIVE_GET_CLIENT_CREDITS:
            return {
                ...state,
                getClientCreditsIsFetching: false,
                getClientCreditsReceivedAt: action.receivedAt,
                credits: action.data,
            };
        case RECEIVE_GET_CLIENT_CREDITS_ERROR:
            return {
                ...state,
                getClientCreditsIsFetching: false,
                getClientCreditsReceivedAt: action.receivedAt,
                creditsError: action.error,
            };
        case REQUEST_USE_CLIENT_CREDIT:
            return {
                ...state,
                useClientPurchaseIsFetching: true,
                useClientPurchaseRequestedAt: action.requestedAt,
            };
        case RECEIVE_USE_CLIENT_CREDIT:
            const { credits } = state;
            const currentProduct = credits.find((credit) => credit.id === action.data.id);

            const updatedCredit = {
                ...currentProduct,
                consumed_at: action.data.consumed_at,
                consumed_reason: action.data.consumed_reason,
            };
            const creditIndex = credits.findIndex((credit) => credit.id === action.data.id);
            credits[creditIndex] = updatedCredit;

            return {
                ...state,
                useClientPurchaseIsFetching: false,
                useClientPurchaseReceivedAt: action.receivedAt,
                credits,
            };
        case RECEIVE_USE_CLIENT_CREDIT_ERROR:
            return {
                ...state,
                useClientPurchaseIsFetching: false,
                useClientPurchaseReceivedAt: action.receivedAt,
                useClientPurchaseError: action.error,
            };
        case REQUEST_ALL_PURCHASES:
            return {
                ...state,
                allPurchasesIsFetching: true,
                allPurchasesRequestedAt: action.requestedAt,
            };
        case RECEIVE_ALL_PURCHASES:
            return {
                ...state,
                allPurchasesIsFetching: false,
                allPurchasesReceivedAt: action.receivedAt,
            };
        case RECEIVE_ALL_PURCHASES_ERROR:
            return {
                ...state,
                allPurchasesIsFetching: false,
                allPurchasesReceivedAt: action.receivedAt,
                allPurchasesError: action.error,
            };
        case REQUEST_ADD_CREDIT_CARD:
            return {
                ...state,
                addCreditCardIsFetching: true,
                addCreditCardRequestedAt: action.requestedAt,
            };
        case RECEIVE_ADD_CREDIT_CARD:
            return {
                ...state,
                addCreditCardIsFetching: false,
                addCreditCardReceivedAt: action.receivedAt,
            };
        case RECEIVE_ADD_CREDIT_CARD_ERROR:
            return {
                ...state,
                addCreditCardIsFetching: false,
                addCreditCardReceivedAt: action.receivedAt,
                addCreditCardError: action.error,
            };
        case REQUEST_PURCHASE_PRODUCT:
            return {
                ...state,
                purchaseStatusIsFetching: true,
                purchaseStatus: null,
                purchaseProduct: null,
                purchaseProductIsFetching: true,
                purchaseProductRequestedAt: action.requestedAt,
            };
        case RECEIVE_PURCHASE_PRODUCT:
            return {
                ...state,
                purchaseProductIsFetching: false,
                purchaseStatusIsFetching: false,
                purchaseProductReceivedAt: action.receivedAt,
                showSubscriptionSuccessPrompt: true,
                purchaseStatus: "success",
            };
        case RECEIVE_PURCHASE_PRODUCT_ERROR: {
            return {
                ...state,
                purchaseProductIsFetching: false,
                purchaseProductReceivedAt: action.receivedAt,
                purchaseProductError: action.error,
                purchaseStatusIsFetching: false,
                purchaseStatus: null,
                purchaseProduct: null,
            };
        }
        case REQUEST_SCHEDULED_PURCHASE_PRODUCT:
            return {
                ...state,
                schedulePurchaseProductIsFetching: true,
                schedulePurchaseProductRequestedAt: action.requestedAt,
            };
        case RECEIVE_SCHEDULED_PURCHASE_PRODUCT:
            return {
                ...state,
                schedulePurchaseProductIsFetching: false,
                schedulePurchaseProductReceivedAt: action.receivedAt,
                showSubscriptionSuccessPrompt: true,
            };
        case RECEIVE_SCHEDULED_PURCHASE_PRODUCT_ERROR:
            return {
                ...state,
                schedulePurchaseProductIsFetching: false,
                schedulePurchaseProductReceivedAt: action.receivedAt,
                schedulePurchaseProductError: action.error,
            };
        case SET_PURCHASE_STATUS:
            return {
                ...state,
                purchaseStatusIsFetching: false,
                purchaseStatus: action.status,
                purchaseProduct: { invoice: action.invoice, product: action.product },
            };
        case CLEAR_PURCHASE_STATUS:
            return {
                ...state,
                purchaseStatusIsFetching: false,
                purchaseStatus: null,
                purchaseProduct: null,
            };
        default:
            return state;
    }
}
