import React, { useState } from "react";
import { connect } from "react-redux";
import qs from "query-string";
import styled from "styled-components";

import { DARK_BACKGROUND_COLOR_ID } from "../../styles/colors";
import { getPublicImage } from "../../utils/helpers";
import { FORGOT_PASSWORD_ROUTE } from "../../utils/routes";
import { getOrbitRedirectLocation, getWellviewRedirectLocation } from "../../utils/branding";

import Image from "../image/Image";
import BrandedLogo from "../logo/BrandedLogo";
import Modal from "../modal/Modal";
import RecaptchaProvider from "../recaptcha_provider/RecaptchaProvider";
import Button from "../button/Button";
import Page from "../minimal/Page";

const getIsInactive = (() => {
    const {
        location: { search },
    } = window;

    if (search && search.length > 0) {
        const { inactive } = qs.parse(search);

        if (inactive && inactive === "true") {
            return true;
        }
    }

    return false;
})();

const getHasChangedPassword = (() => {
    const {
        location: { search },
    } = window;

    if (search && search.length > 0) {
        const { password_change } = qs.parse(search);

        if (password_change && password_change === "true") {
            return true;
        }
    }

    return false;
})();

const getSSORedirectState = (branding) => {
    const { idp_signin_url } = branding || {};

    if (!getIsInactive && idp_signin_url && idp_signin_url.length > 0) {
        return true;
    }

    return false;
};

const ThemedHeader = styled.div`
    background-color: ${(props) => props.theme[DARK_BACKGROUND_COLOR_ID]};
`;

function LoginLayout({ groups, children }) {
    const { signupConfig } = groups;
    const [showInactive, setShowInactive] = useState(getIsInactive);
    const [showChangedPassword, setShowChangedPassword] = useState(getHasChangedPassword);
    const [redirectToSSO, setRedirectToSSO] = useState(getSSORedirectState(signupConfig));
    const { idp_signin_url } = signupConfig || {};
    const orbitRedirectLocation = getOrbitRedirectLocation();
    const wellviewRedirectLocation = getWellviewRedirectLocation();
    const mfrDomain =
        window.location.hostname.includes("mfrtherapists.com") || window.location.hostname.includes("mfrhealth.com");

    if (window.location.href.includes("gnc.orbittelehealth.com/v3/login")) {
        window.location.assign("https://gncmember.myrevive.health/");
    }

    if (getSSORedirectState(signupConfig) || redirectToSSO) {
        window.location.assign(idp_signin_url);
        return false;
    }

    if (orbitRedirectLocation) {
        window.location.assign(orbitRedirectLocation);
        return false;
    }

    if (wellviewRedirectLocation) {
        window.location.assign(wellviewRedirectLocation);
        return false;
    }

    const handleRedirect = () => {
        if (mfrDomain) window.location.assign("https://www.mfrtherapists.com/");
    };

    const handleInactiveConfirmation = () => {
        if (idp_signin_url && idp_signin_url.length > 0) {
            setRedirectToSSO(true);
        } else {
            setShowInactive(false);
        }
    };

    const handleChangePasswordConfirmation = () => {
        setShowChangedPassword(false);
    };

    return (
        <RecaptchaProvider redirectToSSO={redirectToSSO}>
            <Page
                title={`Myofascial Release Therapy Directory - ${
                    window.location.pathname === FORGOT_PASSWORD_ROUTE ? "Forgot Password" : "Login"
                }`}
                mfr={mfrDomain}
            >
                <div className="ts-login-layout">
                    <ThemedHeader className="ts-login-layout__header">
                        <div className="ts-logo hover-pointer" onClick={handleRedirect}>
                            <BrandedLogo type="white_logo" />
                        </div>
                    </ThemedHeader>

                    <div className="ts-login-layout__content">
                        <div className="wrapper">
                            <Image
                                className="dash-shadow"
                                src={getPublicImage("dash_shadow.svg")}
                                alt="image of shadow"
                            />
                            <div className="children-container">{children}</div>
                        </div>
                    </div>

                    <Modal
                        show={showInactive}
                        size="md"
                        heading="Logged Out"
                        content="You have been automatically signed out due to a long period of inactivity"
                        toggleShow={handleInactiveConfirmation}
                        actions={[
                            <Button
                                analyticsClass="button__inactive-logout-accept"
                                key="accept-button"
                                secondary
                                square
                                onClick={handleInactiveConfirmation}
                            >
                                Okay
                            </Button>,
                        ]}
                    />

                    <Modal
                        show={showChangedPassword}
                        size="md"
                        heading="Password changed"
                        content="Your password has been changed and all active sessions have been terminated. Please sign in again to access your account."
                        toggleShow={handleChangePasswordConfirmation}
                        actions={[
                            <Button
                                analyticsClass="button__inactive-logout-accept"
                                key="accept-button"
                                secondary
                                square
                                onClick={handleChangePasswordConfirmation}
                            >
                                Confirm
                            </Button>,
                        ]}
                    />
                </div>
            </Page>
        </RecaptchaProvider>
    );
}

const mapStateToProps = ({ groups }) => ({ groups });

export default connect(mapStateToProps)(LoginLayout);
