export function gen_requested(action_type) {
    return {
        type: action_type,
        requestedAt: Date.now(),
    };
}

export function gen_received(action_type, result) {
    return {
        type: action_type,
        receivedAt: Date.now(),
        data: result.data,
    };
}

export function gen_error(action_type, error) {
    // error.response && toast.error(error.response.data);
    error.response && console.log("%c" + error.response.data, "color: #c62c2c");
    return {
        type: action_type,
        receivedAt: Date.now(),
        error,
    };
}
