import { get, post } from "../../utils/network";
import { gen_requested, gen_error, gen_received } from "./generators";

export const REQUEST_AVAILABLE_TIMES = "REQUEST_AVAILABLE_TIMES";
export const RECEIVE_AVAILABLE_TIMES = "RECEIVE_AVAILABLE_TIMES";
export const RECEIVE_AVAILABLE_TIMES_ERROR = "RECEIVE_AVAILABLE_TIMES_ERROR";
export const getAvailableTimes = (options = {}) => async (dispatch, getState) => {
    try {
        dispatch(gen_requested(REQUEST_AVAILABLE_TIMES));
        const result = await get("/api/v3/slots/", options);
        const { me } = getState();

        const data = {
            times: result.data,
            owner: me.owner,
        };

        return dispatch(gen_received(RECEIVE_AVAILABLE_TIMES, { data }));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_AVAILABLE_TIMES_ERROR, error));
    }
};

export const CLEAR_AVAILABLE_TIMES = "CLEAR_AVAILABLE_TIMES";
export const clearAvailableTimes = () => async (dispatch) => {
    return dispatch({
        type: CLEAR_AVAILABLE_TIMES,
    });
};

export const REQUEST_ALL_SLOTS = "REQUEST_ALL_SLOTS";
export const RECEIVE_ALL_SLOTS = "RECEIVE_ALL_SLOTS";
export const RECEIVE_ALL_SLOTS_ERROR = "RECEIVE_ALL_SLOTS_ERROR";
export const getAllSlots = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ALL_SLOTS));
        const result = await get("/api/v3/slots/", options);

        const data = {
            slots: result.data,
            month: options.month,
            year: options.year,
        };

        return dispatch(gen_received(RECEIVE_ALL_SLOTS, { data }));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ALL_SLOTS_ERROR, error));
    }
};

export const REQUEST_UPDATE_SLOTS = "REQUEST_UPDATE_SLOTS";
export const RECEIVE_UPDATE_SLOTS = "RECEIVE_UPDATE_SLOTS";
export const RECEIVE_UPDATE_SLOTS_ERROR = "RECEIVE_UPDATE_SLOTS_ERROR";
export const updateSlots = (data, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_SLOTS));
        const result = await post("/api/v3/slots/", data, options);

        return dispatch(gen_received(RECEIVE_UPDATE_SLOTS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_SLOTS_ERROR, error));
    }
};

export const RESET_COACH_CALENDAR = "RESET_COACH_CALENDAR";
export const resetCoachCalendar = () => (dispatch) => {
    return dispatch({
        type: RESET_COACH_CALENDAR,
    });
};

export const UPDATE_RENDER_CALENDAR = "UPDATE_RENDER_CALENDAR";
export const updateRenderCalendar = (shouldRender) => (dispatch) => {
    return dispatch({
        type: UPDATE_RENDER_CALENDAR,
        data: shouldRender,
    });
};

export const UPDATE_AVAILABLE_DATES = "UPDATE_AVAILABLE_DATES";
export const updateAvailableDates = (dates) => {
    return {
        type: UPDATE_AVAILABLE_DATES,
        dates,
    };
};

export const UPDATE_SELECTED_COACHES = "UPDATE_SELECTED_COACHES";
export const updateSelectedCoaches = (coaches) => (dispatch) => {
    return dispatch({
        type: UPDATE_SELECTED_COACHES,
        coaches,
    });
};

export const SELECT_DATE = "SELECT_DATE";
export const updateSelectedDate = (date) => (dispatch) => {
    return dispatch({
        type: SELECT_DATE,
        date,
    });
};

export const SELECT_TIME = "SELECT_TIME";
export const updateSelectedTime = (time) => (dispatch) => {
    return dispatch({
        type: SELECT_TIME,
        time,
    });
};

export const SELECT_APPOINTMENT = "SELECT_APPOINTMENT";
export const updateSelectedAppointment = (appointment) => (dispatch) => {
    return dispatch({
        type: SELECT_APPOINTMENT,
        appointment,
    });
};

export const SET_CREATING_PAID_APPOINTMENT = "SET_CREATING_PAID_APPOINTMENT";
export const setCreatingPaidAppointment = (creatingPaidAppointment) => (dispatch) => {
    return dispatch({
        type: SET_CREATING_PAID_APPOINTMENT,
        creatingPaidAppointment,
    });
};

export const RESET_SCHEDULER_INPUT = "RESET_SCHEDULER_INPUT";
export const resetSchedulerInput = () => {
    return {
        type: RESET_SCHEDULER_INPUT,
    };
};

export const UPDATE_CURRENT_CALENDAR_MONTH = "UPDATE_CURRENT_CALENDAR_MONTH";
export const updateCurrentCalendarMonth = (month) => (dispatch) => {
    dispatch({
        type: UPDATE_CURRENT_CALENDAR_MONTH,
        month,
    });
};

export const UPDATE_CALENDAR_SELECTED_COACHES = "UPDATE_CALENDAR_SELECTED_COACHES";
export const updateCalendarSelectedCoaches = (coaches) => (dispatch) => {
    dispatch({
        type: UPDATE_CALENDAR_SELECTED_COACHES,
        coaches,
    });
};
