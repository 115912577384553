import moment from "moment-timezone";
import {
    HIP_LOCATION_ID,
    KNEE_LOCATION_ID,
    LOWER_BACK_LOCATION_ID,
    NECK_LOCATION_ID,
    OTHER_LOCATION_ID,
    SHOULDER_LOCATION_ID,
    UPPER_BACK_LOCATION_ID,
    WOMENS_HEALTH_LOCATION_ID,
} from "./consults";
import { processResource } from "./resources";

export const painLocationOptions = [
    {
        id: 1,
        text: "Neck",
        backendId: NECK_LOCATION_ID,
        image: "neck_area.webp",
        submitField: "backendId",
        lowerCase: "neck",
        upperCase: "Neck",
    },
    {
        id: 2,
        text: "Upper Back",
        backendId: UPPER_BACK_LOCATION_ID,
        image: "upper_back_area.webp",
        submitField: "backendId",
        lowerCase: "upper back",
        upperCase: "Upper Back",
    },
    {
        id: 3,
        text: "Lower Back",
        backendId: LOWER_BACK_LOCATION_ID,
        image: "lower_back_area.webp",
        submitField: "backendId",
        lowerCase: "lower back",
        upperCase: "Lower Back",
    },
    {
        id: 4,
        text: "Hip",
        backendId: HIP_LOCATION_ID,
        image: "hip_area.webp",
        submitField: "backendId",
        lowerCase: "hip",
        upperCase: "Hip",
    },
    {
        id: 5,
        text: "Knee",
        backendId: KNEE_LOCATION_ID,
        image: "knee_area.webp",
        submitField: "backendId",
        lowerCase: "knee",
        upperCase: "Knee",
    },
    {
        id: 6,
        text: "Shoulder",
        backendId: SHOULDER_LOCATION_ID,
        image: "shoulder_area.webp",
        submitField: "backendId",
        lowerCase: "shoulder",
        upperCase: "Shoulder",
    },
    {
        id: 7,
        text: "Other",
        backendId: OTHER_LOCATION_ID,
        image: "other_area.webp",
        submitField: "backendId",
        lowerCase: "other",
        upperCase: "Other",
    },
    {
        id: 8,
        text: "Women's Health",
        backendId: WOMENS_HEALTH_LOCATION_ID,
        image: "womens_health.webp",
        submitField: "backendId",
        lowerCase: "women's health",
        upperCase: "Women's Health",
    },
];

export function createSessionsFromProgramList(list = []) {
    const sessionsList = Object.values(
        list.reduce((groupedList, value) => {
            groupedList[value.group] = [...(groupedList[value.group] || []), value];

            return groupedList;
        }, {})
    ).reverse();

    const resourcesSessionsList = sessionsList.map((session, idx) => {
        const { group: title } = session && session.length > 0 && session[0];
        const resourcesSession = session.map((sessionResource) => processResource(sessionResource));

        return { title, resources: resourcesSession, notes: "", legacy: true, order: idx };
    });

    return resourcesSessionsList;
}

export function createRoutinesFromLegacyProgram(program) {
    const {
        resources_list: { resources: list },
    } = program;

    const sessionsList = Object.values(
        list.reduce((groupedList, value) => {
            groupedList[value.group] = [...(groupedList[value.group] || []), value];

            return groupedList;
        }, {})
    );

    const mockedRoutines = sessionsList
        .map((session, idx) => ({
            ...program,
            resources_list: { resources: session },
            legacy: true,
            legacyTitle: session[0].group,
        }))
        .reverse();

    return mockedRoutines;
}

export function getResourceListIds(list) {
    return list.map((resource) => {
        if (resource.exercise) {
            return { exercise: resource.exercise.id };
        } else {
            return { article: resource.article.id };
        }
    });
}

/**
 *
 * @param {*} programs
 * @returns Returns the latest current program
 */
export function getLatestCurrentProgram(programs) {
    const currentProgramsList =
        programs && programs.length && programs.length > 0 && programs.filter((program) => program.current);
    const sortedCurrentPrograms = currentProgramsList.sort(
        (a, b) => moment(b.current).valueOf() - moment(a.current).valueOf()
    );

    return sortedCurrentPrograms[0];
}
