import { exq } from "../../utils/helpers";

export const resourcesListFragment = {
    expand: exq`
        article
        data_points
        exercise
        exercise.steps
        exercise.tags
        id
        resources
        pain_location
        resources.article
        resources.exercise
        resources.exercise.steps
        resources.exercise.tags
        watched_items
    `,
    fields: exq`
        article
        completed
        created
        data_points
        description
        exercise
        group
        id
        order
        resources.id
        resources.article
        resources.article.id
        resources.article.img
        resources.article.title
        resources.created
        resources.exercise
        resources.exercise.benefit
        resources.exercise.steps
        resources.exercise.tags
        resources.exercise.brightcove_video_id
        resources.exercise.id
        resources.exercise.preview
        resources.exercise.title
        resources.exercise.video
        resources_list.description
        resources_list.title
        title
        pain_location
        watched_items
    `,
};

export const assignedResourcesFragment = {
    expand: exq`
        recommended_list.resources.article
        recommended_list.resources.exercise
        resources_list.resources.article
        resources_list.resources.exercise
    `,
    fields: exq`
        assigned_program.id
        assigned_program.last_visited
        assigned_program.title
        description
        resources.group
        last_visited
        pain_location
        recommended_list.resources.article
        recommended_list.resources.exercise
        resources_list.resources.article
        resources_list.resources.exercise
        resources_list.resources.id
        resources_list.resources.list
        resources_list.resources.order
        resources_list.resources.watched_items
        resources_list.resources.data_points
        title   
    `,
};

export const resourceFragment = {
    expand: exq`
        resource
        assigned_resources_list.resources.article
        assigned_resources_list.resources.exercise
        favorited_resources_list.resources.article
        favorited_resources_list.resources.exercise
        data_points
    `,
    fields: exq`
        assigned_resources_list
        favorited_resources_list
    `,
};

export const resourceSearchFragment = {
    expand: exq`
        resource.exercise
        resource.article
    `,
    fields: exq`
        benefit
        brightcove_video_id
        content
        duration
        id
        img
        preview
        reps
        sets
        title
        video
    `,
};

export const tagsFragment = {
    fields: exq`
        branding
        id
        title
        type
    `,
};
