import React from "react";
import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
//
import Image from "./Image";
import { getPublicImage } from "../../utils/helpers";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    height: "100%",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(8, 2),
}));

// ----------------------------------------------------------------------

EmptyContent.propTypes = {
    title: PropTypes.string.isRequired,
    img: PropTypes.string,
    description: PropTypes.string,
};

export default function EmptyContent({ title, description, img, ...other }) {
    return (
        <RootStyle {...other}>
            <Image disabledEffect visibleByDefault alt="empty content" src={img || getPublicImage("empty_list.png")} />

            <Typography variant="h5" gutterBottom sx={{ height: 30, mb: 3 }}>
                {title}
            </Typography>

            {description && (
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {description}
                </Typography>
            )}
        </RootStyle>
    );
}
