import { patch } from "../../utils/network";
import { gen_error, gen_received, gen_requested } from "./generators";

export const UPDATE_PROFILE_FORM_DATA = "UPDATE_PROFILE_FORM_DATA";
export const updateProfileFormData = (data) => (dispatch) => {
    return dispatch({
        type: UPDATE_PROFILE_FORM_DATA,
        data,
    });
};

export const CLEAR_PROFILE_FORM_DATA = "CLEAR_PROFILE_FORM_DATA";
export const clearProfileFormData = () => (dispatch) => {
    return dispatch({
        type: CLEAR_PROFILE_FORM_DATA,
    });
};

export const REQUEST_UPDATE_NOTIFICATION_SUBSCRIPTION = "REQUEST_UPDATE_NOTIFICATION_SUBSCRIPTION";
export const RECEIVE_UPDATE_NOTIFICATION_SUBSCRIPTION = "RECEIVE_UPDATE_NOTIFICATION_SUBSCRIPTION";
export const RECEIVE_UPDATE_NOTIFICATION_SUBSCRIPTION_ERROR = "RECEIVE_UPDATE_NOTIFICATION_SUBSCRIPTION_ERROR";
export const updateNotificationSubscription = (subscriptionId, data) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_NOTIFICATION_SUBSCRIPTION));

        const result = await patch(`/api/v3/email_notifications/${subscriptionId}/`, data);

        return dispatch(gen_received(RECEIVE_UPDATE_NOTIFICATION_SUBSCRIPTION, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_NOTIFICATION_SUBSCRIPTION_ERROR, error));
    }
};

export const REQUEST_UPDATE_COACH = "REQUEST_UPDATE_COACH";
export const RECEIVE_UPDATE_COACH = "RECEIVE_UPDATE_COACH";
export const RECEIVE_UPDATE_COACH_ERROR = "RECEIVE_UPDATE_COACH_ERROR";
export const updateCoach = (coachId, data, options = {}) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_UPDATE_COACH,
            requestedAt: Date.now(),
            id: coachId,
        });

        const result = await patch(`/api/v3/coaches/${coachId}/`, data, options);

        return dispatch({
            type: RECEIVE_UPDATE_COACH,
            receivedAt: Date.now(),
            id: coachId,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_UPDATE_COACH_ERROR,
            receivedAt: Date.now(),
            id: coachId,
            error,
        });
    }
};

export const REQUEST_UPDATE_ME = "REQUEST_UPDATE_ME";
export const RECEIVE_UPDATE_ME = "RECEIVE_UPDATE_ME";
export const RECEIVE_UPDATE_ME_ERROR = "RECEIVE_UPDATE_ME_ERROR";
export const updateMe = (patientId, data, options = {}) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_UPDATE_ME,
            requestedAt: Date.now(),
            id: patientId,
        });

        const result = await patch(`/api/v3/patients/${patientId}/`, data, options);

        return dispatch({
            type: RECEIVE_UPDATE_ME,
            receivedAt: Date.now(),
            id: patientId,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_UPDATE_ME_ERROR,
            receivedAt: Date.now(),
            id: patientId,
            error,
        });
    }
};

export const SET_CURRENT_ACCOUNT_TAB = "SET_CURRENT_ACCOUNT_TAB";
export const setCurrentAccountTab = (tab) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_ACCOUNT_TAB,
        tab,
    });
};

export const SET_CURRENT_ACTIVE_TAB = "SET_CURRENT_ACTIVE_TAB";
export const setCurrentActiveTab = (tab) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_ACTIVE_TAB,
        tab,
    });
};

export const SET_CURRENT_PROFILE_TAB = "SET_CURRENT_PROFILE_TAB";
export const setCurrentProfileTab = (tab) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_PROFILE_TAB,
        tab,
    });
};

export const SET_CURRENT_RESOURCES_LIBRARY_TAB = "SET_CURRENT_RESOURCES_LIBRARY_TAB";
export const setCurrentResourcesLibraryTab = (tab) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_RESOURCES_LIBRARY_TAB,
        tab,
    });
};

export const SET_CURRENT_RESOURCES_IMPORT_TAB = "SET_CURRENT_RESOURCES_IMPORT_TAB";
export const setCurrentResourcesImportTab = (tab) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_RESOURCES_IMPORT_TAB,
        tab,
    });
};

export const SET_CURRENT_CARE_PLAN_BUILDER_TAB = "SET_CURRENT_CARE_PLAN_BUILDER_TAB";
export const setCurrentCarePlanBuilderTab = (tab) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_CARE_PLAN_BUILDER_TAB,
        tab,
    });
};

export const SET_CURRENT_REPORTS_TAB = "SET_CURRENT_REPORTS_TAB";
export const setCurrentReportsTab = (tab) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_REPORTS_TAB,
        tab,
    });
};
