import {} from "../actions";

const initialState = {
    labels: [
        { id: "inbox", name: "inbox", type: "system" },
        { id: "sent", name: "sent", type: "system" },
        { id: "trash", name: "deleted", type: "system" },
    ],
    mails: [],
};

export function mail(state = initialState, action) {
    switch (action.type) {
        case "FETCH_MAIL": {
            let mails = {};

            switch (action.box) {
                case "sent":
                    mails = [
                        {
                            id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
                            labelIds: [],
                            folder: "folder",
                            isImportant: false,
                            isStarred: false,
                            isUnread: true,
                            subject: "Lead: Cristopher Cardenas",
                            message:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ullamcorper turpis eget risus ullamcorper, vitae ornare libero molestie. Phasellus turpis risus, pretium ut mauris sit amet, semper feugiat orci. Integer at justo commodo, efficitur massa vitae, laoreet lorem. Nulla eget nisl vestibulum, varius arcu ut, tristique lorem.",
                            createdAt: "2023-04-17T22:36:10.733Z",
                            attachments: [],
                            from: {
                                name: "Cristopher Cardenas",
                                email: "nannie_abernathy70@yahoo.com",
                            },
                            to: [{ name: "Ivan Roman", email: "ivan@telespine.comF", avatar: null }],
                        },
                        {
                            id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
                            labelIds: [],
                            folder: "folder",
                            isImportant: false,
                            isStarred: false,
                            isUnread: true,
                            subject: "Lead: Jayvion Simon",
                            message:
                                "Vestibulum molestie leo ex, et consectetur neque porta sit amet. Mauris velit libero, fermentum sit amet ullamcorper lacinia, cursus nec neque. Nullam hendrerit feugiat mi, et imperdiet nisi bibendum at. Maecenas consequat nunc at nunc rutrum ullamcorper. Nulla facilisi. Proin non mauris id nulla pulvinar finibus. In hac habitasse platea dictumst.",
                            createdAt: "2023-04-17T22:36:10.733Z",
                            attachments: [],
                            from: {
                                name: "Jayvion Simon",
                                email: "nannie_abernathy70@yahoo.com",
                            },
                            to: [{ name: "Ivan Roman", email: "ivan@telespine.comF", avatar: null }],
                        },
                        {
                            id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
                            labelIds: [],
                            folder: "folder",
                            isImportant: false,
                            isStarred: false,
                            isUnread: true,
                            subject: "Lead: Lainey Davidson",
                            message:
                                "Cras condimentum elementum sapien quis varius. Aenean congue erat ut pretium viverra. In non malesuada tortor. Proin non dui quis turpis gravida aliquet non nec elit. Praesent tristique sit amet erat id sagittis.",
                            createdAt: "2023-04-17T22:36:10.733Z",
                            attachments: [],
                            from: {
                                name: "Lainey Davidson",
                                email: "nannie_abernathy70@yahoo.com",
                            },
                            to: [{ name: "Ivan Roman", email: "ivan@telespine.comF", avatar: null }],
                        },
                    ];
                    break;
                case "trash":
                    break;
                default:
                    mails = [
                        {
                            id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
                            labelIds: [],
                            folder: "folder",
                            isImportant: false,
                            isStarred: false,
                            isUnread: true,
                            subject: "Lead: Cristopher Cardenas",
                            message:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ullamcorper turpis eget risus ullamcorper, vitae ornare libero molestie. Phasellus turpis risus, pretium ut mauris sit amet, semper feugiat orci. Integer at justo commodo, efficitur massa vitae, laoreet lorem. Nulla eget nisl vestibulum, varius arcu ut, tristique lorem.",
                            createdAt: "2023-04-17T22:36:10.733Z",
                            attachments: [],
                            from: {
                                name: "Cristopher Cardenas",
                                email: "nannie_abernathy70@yahoo.com",
                            },
                            to: [{ name: "Ivan Roman", email: "ivan@telespine.comF", avatar: null }],
                        },
                        {
                            id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
                            labelIds: [],
                            folder: "folder",
                            isImportant: false,
                            isStarred: false,
                            isUnread: true,
                            subject: "Lead: Jayvion Simon",
                            message:
                                "Vestibulum molestie leo ex, et consectetur neque porta sit amet. Mauris velit libero, fermentum sit amet ullamcorper lacinia, cursus nec neque. Nullam hendrerit feugiat mi, et imperdiet nisi bibendum at. Maecenas consequat nunc at nunc rutrum ullamcorper. Nulla facilisi. Proin non mauris id nulla pulvinar finibus. In hac habitasse platea dictumst.",
                            createdAt: "2023-04-17T22:36:10.733Z",
                            attachments: [],
                            from: {
                                name: "Jayvion Simon",
                                email: "nannie_abernathy70@yahoo.com",
                            },
                            to: [{ name: "Ivan Roman", email: "ivan@telespine.comF", avatar: null }],
                        },
                        {
                            id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
                            labelIds: [],
                            folder: "folder",
                            isImportant: false,
                            isStarred: false,
                            isUnread: true,
                            subject: "Lead: Lainey Davidson",
                            message:
                                "Cras condimentum elementum sapien quis varius. Aenean congue erat ut pretium viverra. In non malesuada tortor. Proin non dui quis turpis gravida aliquet non nec elit. Praesent tristique sit amet erat id sagittis.",
                            createdAt: "2023-04-17T22:36:10.733Z",
                            attachments: [],
                            from: {
                                name: "Lainey Davidson",
                                email: "nannie_abernathy70@yahoo.com",
                            },
                            to: [{ name: "Ivan Roman", email: "ivan@telespine.comF", avatar: null }],
                        },
                    ];
                    break;
            }

            return {
                ...state,
                mails,
            };
        }
        default:
            return state;
    }
}
