import React from "react";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar as MUIAvatar } from "@mui/material";

// ----------------------------------------------------------------------

const MFR_COLORS = [
    { label: "default", value: "#FD9E57", text: "#000" },
    { label: "primary", value: "#41616D", text: "#fff" },
    { label: "secondary", value: "#00ADEF", text: "#000" },
    { label: "info", value: "#424242", text: "#fff" },
    { label: "success", value: "#4EC089", text: "#000" },
    { label: "warning", value: "#5C2963", text: "#fff" },
    { label: "error", value: "#A74454", text: "#fff" },
];

const Avatar = forwardRef(({ color = "default", children, sx, ...other }, ref) => {
    const theme = useTheme();
    const mfrColor = MFR_COLORS.find((mfrColor) => mfrColor.label === color).value;
    const mfrTextColor = MFR_COLORS.find((mfrColor) => mfrColor.label === color).text;

    if (color === "default") {
        return (
            <MUIAvatar ref={ref} sx={sx} {...other}>
                {children}
            </MUIAvatar>
        );
    }

    return (
        <MUIAvatar
            ref={ref}
            sx={{
                fontWeight: theme.typography.fontWeightMedium,
                color: mfrTextColor,
                backgroundColor: mfrColor,
                ...sx,
            }}
            {...other}
        >
            {children}
        </MUIAvatar>
    );
});

Avatar.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.object,
    color: PropTypes.oneOf(["default", "primary", "secondary", "info", "success", "warning", "error"]),
};

export default Avatar;
