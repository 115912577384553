import {
    REQUEST_ALL_PATIENTS,
    RECEIVE_ALL_PATIENTS,
    RECEIVE_ALL_PATIENTS_ERROR,
    REQUEST_PATIENTS_COUNT,
    RECEIVE_PATIENTS_COUNT,
    RECEIVE_PATIENTS_COUNT_ERROR,
    RECEIVE_PATIENT,
    RECEIVE_PATIENT_ERROR,
    REQUEST_PATIENT,
    REQUEST_PATIENT_ASSIGNED_PROGRAMS,
    RECEIVE_PATIENT_ASSIGNED_PROGRAMS,
    RECEIVE_PATIENT_ASSIGNED_PROGRAMS_ERROR,
    REQUEST_UPDATE_PATIENT,
    RECEIVE_UPDATE_PATIENT,
    RECEIVE_UPDATE_PATIENT_ERROR,
    REQUEST_UPDATE_PATIENT_ASSIGNED_PROGRAM,
    RECEIVE_UPDATE_PATIENT_ASSIGNED_PROGRAM,
    RECEIVE_UPDATE_PATIENT_ASSIGNED_PROGRAM_ERROR,
    REQUEST_CANCEL_PATIENT_MEETING,
    RECEIVE_CANCEL_PATIENT_MEETING,
    RECEIVE_CANCEL_PATIENT_MEETING_ERROR,
    REQUEST_PATIENT_SUGGESTIONS,
    RECEIVE_PATIENT_SUGGESTIONS,
    RECEIVE_PATIENT_SUGGESTIONS_ERROR,
    REQUEST_PATIENT_CHAT_HISTORY,
    RECEIVE_PATIENT_CHAT_HISTORY,
    RECEIVE_PATIENT_CHAT_HISTORY_ERROR,
    REQUEST_PATIENT_WATCHED_ITEMS,
    RECEIVE_PATIENT_WATCHED_ITEMS,
    RECEIVE_PATIENT_WATCHED_ITEMS_ERROR,
    REQUEST_UPDATE_PATIENT_MEETING_ANSWERS,
    RECEIVE_UPDATE_PATIENT_MEETING_ANSWERS,
    RECEIVE_UPDATE_PATIENT_MEETING_ANSWERS_ERROR,
    REQUEST_UPDATE_PATIENT_MEETING,
    RECEIVE_UPDATE_PATIENT_MEETING,
    RECEIVE_UPDATE_PATIENT_MEETING_ERROR,
    REQUEST_CREATE_PATIENT_MEETING,
    RECEIVE_CREATE_PATIENT_MEETING,
    RECEIVE_CREATE_PATIENT_MEETING_ERROR,
    REQUEST_CREATE_PATIENT_REMINDER,
    RECEIVE_CREATE_PATIENT_REMINDER,
    RECEIVE_CREATE_PATIENT_REMINDER_ERROR,
    REQUEST_DELETE_PATIENT_REMINDER,
    RECEIVE_DELETE_PATIENT_REMINDER,
    RECEIVE_DELETE_PATIENT_REMINDER_ERROR,
    REQUEST_UPDATE_PATIENT_REMINDER,
    RECEIVE_UPDATE_PATIENT_REMINDER,
    RECEIVE_UPDATE_PATIENT_REMINDER_ERROR,
    REQUEST_CREATE_DEMO_PATIENT,
    RECEIVE_CREATE_DEMO_PATIENT,
    RECEIVE_CREATE_DEMO_PATIENT_ERROR,
    REQUEST_DELETE_DEMO_PATIENT,
    RECEIVE_DELETE_DEMO_PATIENT,
    RECEIVE_DELETE_DEMO_PATIENT_ERROR,
    REQUEST_INVITED_CLIENTS,
    RECEIVE_INVITED_CLIENTS,
    RECEIVE_INVITED_CLIENTS_ERROR,
    REQUEST_ALL_PURCHASES,
    RECEIVE_ALL_PURCHASES,
    RECEIVE_ALL_PURCHASES_ERROR,
    REQUEST_REMOVE_CLIENT,
    RECEIVE_REMOVE_CLIENT,
    RECEIVE_REMOVE_CLIENT_ERROR,
    REQUEST_FETCH_ALL_LEADS,
    RECEIVE_FETCH_ALL_LEADS,
    RECEIVE_FETCH_ALL_LEADS_ERROR,
    HANDLE_ADD_PATIENT_RESOURCES,
    HANDLE_REMOVE_PATIENT_RESOURCE,
    UPDATE_PATIENTS_DATA_PARAMS,
    getPatient,
    RECEIVE_CREATE_COACH_PATIENT_ASSESSMENT,
    REQUEST_CREATE_COACH_PATIENT_ASSESSMENT,
    RECEIVE_CREATE_COACH_PATIENT_ASSESSMENT_ERROR,
    RECEIVE_UPDATE_PATIENT_ASSESSMENT,
    SET_SORT_ORDER,
    SET_SORT_ORDER_BY,
    SET_PATIENT_FILTER_PARAMS,
    SET_PATIENT_FILTER_VALUES,
    SET_PATIENTS_PAGE,
    REQUEST_ALL_MEETINGS,
    RECEIVE_ALL_MEETINGS,
    RECEIVE_ALL_MEETINGS_ERROR,
    REQUEST_UPDATE_CLIENT_CONDITION,
    RECEIVE_UPDATE_CLIENT_CONDITION,
    REQUEST_FETCH_LEAD,
    RECEIVE_FETCH_LEAD,
    RECEIVE_FETCH_LEAD_ERROR,
    RECEIVE_USE_CLIENT_CREDIT,
    getUnopenedLeads,
    RECEIVE_SEND_EMAIL,
} from "../actions";

import { patientDetailsFragment } from "../fragments/patients";

import { toast } from "../../components/notifications/Toast";
import { processResource } from "../../utils/resources";
import { createSessionsFromProgramList, getLatestCurrentProgram } from "../../utils/programs";
import { getBrandingFromGroup } from "../../utils/branding";
import { getClientActiveCredits } from "../../utils/subscriptions";

const patientsInitialState = {
    ordering: "",
    orderBy: {},
    patientFilterParams: {},
    patientFilterValues: {
        branding__display_name: "",
        show_all_brandings: false,
        created_date__gte: null,
        created_date__lte: null,
        on_hold: null,
        online: null,
        invitation__active: null,
    },
    patientsListFilterFormData: {},
    patientsPage: 0,
};

export function patients(state = patientsInitialState, action) {
    const getNewPatientMeetings = (patientId, meetingId, meetingData) => {
        const { patient_meetings } = state[patientId] || {};
        const currentMeetings = patient_meetings && patient_meetings.length > 0 ? [...patient_meetings] : [];

        // look for existing meeting, create one if it doesn't exist
        const currentMeetingIdx = currentMeetings.findIndex((meeting) => meeting.id === meetingId);
        if (currentMeetingIdx > -1) {
            // meeting already exists, update it inline
            currentMeetings[currentMeetingIdx] = {
                ...currentMeetings[currentMeetingIdx],
                ...meetingData,
            };
        } else {
            // meeting does not exist, create it with id = `meetingId` so we can look it up later
            currentMeetings.push({
                ...meetingData,
                id: meetingId,
            });
        }

        return currentMeetings;
    };

    switch (action.type) {
        case REQUEST_ALL_PATIENTS:
            return {
                ...state,
                isFetchingPatientsData: true,
                patientDataRequestedAt: action.requestedAt,
            };
        case RECEIVE_ALL_PATIENTS:
            return {
                ...state,
                isFetchingPatientsData: false,
                patientsDataReceivedAt: action.receivedAt,
                count: action.data.count,
                allPatientsList: action.data.results,
                next: action.data.next,
                previous: action.data.previous,
            };
        case RECEIVE_ALL_PATIENTS_ERROR:
            return {
                ...state,
                isFetchingPatientsData: false,
                patientsDataReceivedAt: action.receivedAt,
                allPatientsError: action.error,
            };
        case REQUEST_PATIENTS_COUNT:
            return {
                ...state,
                isFetchingPatientsCount: true,
                patientsCountRequestedAt: action.requestedAt,
            };
        case RECEIVE_PATIENTS_COUNT:
            return {
                ...state,
                isFetchingPatientsCount: false,
                patientsCountReceivedAt: action.receivedAt,
                patientsCount: action.data.count,
            };
        case RECEIVE_PATIENTS_COUNT_ERROR:
            return {
                ...state,
                isFetchingPatientsCount: false,
                patientsCountReceivedAt: action.receivedAt,
                patientsCountError: action.error,
            };
        case REQUEST_PATIENT:
            return {
                ...state,
                [action.id]: {
                    ...action.data,
                    id: action.id,
                    requestedAt: action.requestedAt,
                    isFetching: true,
                },
            };
        case RECEIVE_PATIENT:
            return {
                ...state,
                [action.id]: {
                    id: action.id,
                    receivedAt: action.receivedAt,
                    isFetching: false,
                    ...action.data,
                    branding: getBrandingFromGroup(action.data.group),
                },
            };
        case RECEIVE_PATIENT_ERROR:
            return {
                ...state,
                [action.id]: {
                    id: action.id,
                    receivedAt: action.receivedAt,
                    isFetching: false,
                    error: action.error,
                },
            };
        case REQUEST_PATIENT_ASSIGNED_PROGRAMS:
            return {
                ...state,
                patientAssignedProgramsIsLoading: true,
                patientAssignedProgramsRequestedAt: action.requestedAt,
            };
        case RECEIVE_PATIENT_ASSIGNED_PROGRAMS:
            const existingPatientData = state[action.id] || {};
            const assignedPrograms = action.data.results;

            const mockedPrograms =
                assignedPrograms &&
                assignedPrograms.length &&
                assignedPrograms.length > 0 &&
                assignedPrograms.map((program) => {
                    const isLegacyProgram =
                        program &&
                        program.resources_list &&
                        program.resources_list.resources &&
                        program.resources_list.resources[0].group &&
                        program.resources_list.resources[0].group.length > 0;

                    program.legacy = isLegacyProgram ? true : false;
                    if (isLegacyProgram) {
                        const legacySessions =
                            program.resources_list &&
                            program.resources_list.resources &&
                            program.resources_list.resources.length > 0 &&
                            createSessionsFromProgramList(program.resources_list.resources);

                        const mockedRoutines =
                            legacySessions &&
                            legacySessions.length &&
                            legacySessions.length > 0 &&
                            legacySessions.map((session) => ({
                                ...program,
                                legacyTitle: session.title,
                                resources_list: { ...program.resources_list, resources: session.resources },
                                legacy: true,
                            }));

                        return mockedRoutines;
                    } else {
                        const processedResources =
                            program.resources_list &&
                            program.resources_list.resources &&
                            program.resources_list.resources.length &&
                            program.resources_list.resources.length > 0 &&
                            program.resources_list.resources.map((resource) => processResource(resource));

                        program.resources_list.resources = processedResources;

                        return program;
                    }
                });

            const mockedProgramsList = mockedPrograms.flat(1).map((program) => ({ ...program }));

            const currentProgram = getLatestCurrentProgram(mockedProgramsList);
            if (currentProgram) {
                const currentProgramIndex = mockedProgramsList.findIndex((program) => program.id === currentProgram.id);
                mockedProgramsList[currentProgramIndex].latest_current = true;

                if (currentProgramIndex > 0) {
                    mockedProgramsList[currentProgramIndex - 1].up_next = true;
                }
            }

            return {
                ...state,
                patientAssignedProgramsIsLoading: false,
                [action.id]: {
                    ...existingPatientData,
                    patientAssignedProgramsReceivedAt: action.receivedAt,
                    assigned_programs: mockedProgramsList,
                },
            };
        case RECEIVE_PATIENT_ASSIGNED_PROGRAMS_ERROR:
            return {
                ...state,
                patientAssignedProgramsIsLoading: false,
                patientAssignedProgramsReceivedAt: action.receivedAt,
                patientAssignedProgramsError: action.error,
            };
        case REQUEST_UPDATE_CLIENT_CONDITION: {
            const existingPatientData = state[action.id] || {};

            return {
                ...state,
                [action.id]: {
                    ...existingPatientData,
                    updateClientConditionRequestedAt: action.requestedAt,
                },
            };
        }
        case RECEIVE_UPDATE_CLIENT_CONDITION: {
            const existingPatientData = state[action.id] || {};
            const currentConditions = state[action.id].conditions;
            const { primaryConditions, secondaryConditions, data } = action;
            const primaryCondition =
                primaryConditions &&
                primaryConditions.length > 0 &&
                primaryConditions.find((pc) => pc.id === data.primary);
            const secondary =
                data.secondary &&
                data.secondary.length > 0 &&
                data.secondary.map((sc) => {
                    const condition =
                        secondaryConditions &&
                        secondaryConditions.length > 0 &&
                        secondaryConditions.find((c) => c.id === sc);

                    return condition;
                });

            const newCondition = {
                id: data.id,
                user: action.id,
                primary: primaryCondition,
                secondary,
                created: data.created,
            };

            currentConditions.push(newCondition);

            return {
                ...state,
                [action.id]: {
                    ...existingPatientData,
                    conditions: currentConditions,
                    updateClientConditionReceivedAt: action.receivedAt,
                },
            };
        }
        case REQUEST_UPDATE_PATIENT: {
            const existingPatientData = state[action.id] || {};

            return {
                ...state,
                [action.id]: {
                    ...existingPatientData,
                    id: action.id,
                    requestedAt: action.requestedAt,
                },
            };
        }
        case RECEIVE_UPDATE_PATIENT: {
            const existingPatientData = state[action.id] || {};

            return {
                ...state,
                [action.id]: {
                    ...existingPatientData,
                    id: action.id,
                    receivedAt: action.receivedAt,
                    ...action.data,
                },
            };
        }
        case RECEIVE_UPDATE_PATIENT_ERROR: {
            const existingPatientData = state[action.id] || {};

            return {
                ...state,
                [action.id]: {
                    ...existingPatientData,
                    id: action.id,
                    receivedAt: action.receivedAt,
                    error: action.error,
                },
            };
        }
        case REQUEST_UPDATE_PATIENT_ASSIGNED_PROGRAM: {
            const existingPatientData = state[action.id] || {};

            return {
                ...state,
                [action.id]: {
                    ...existingPatientData,
                    id: action.id,
                    updateProgramIsFetching: true,
                    updateProgramRequestedAt: action.requestedAt,
                },
            };
        }
        case RECEIVE_UPDATE_PATIENT_ASSIGNED_PROGRAM: {
            const {
                data: {
                    coach,
                    created,
                    description,
                    recommended_list,
                    resources_list,
                    id: programId,
                    pain_location,
                    patient,
                    title,
                },
                id,
                receivedAt: updateProgramReceivedAt,
            } = action;
            const existingPatientData = state[id] || {};
            const { assigned_programs } = existingPatientData;
            let newAssignedPrograms = [];

            if (assigned_programs && assigned_programs.length > 0) {
                newAssignedPrograms = [...assigned_programs];
            }

            const newAssignedProgram = {
                coach,
                created,
                description,
                id: programId,
                last_visited: null,
                pain_location,
                patient,
                recommended_list,
                resources_list,
                title,
            };
            newAssignedPrograms.push(newAssignedProgram);

            return {
                ...state,
                [id]: {
                    ...existingPatientData,
                    id,
                    updateProgramIsFetching: false,
                    updateProgramReceivedAt,
                    assigned_programs: newAssignedPrograms,
                },
            };
        }
        case RECEIVE_UPDATE_PATIENT_ASSIGNED_PROGRAM_ERROR: {
            const { error, id: userId, receivedAt } = action;
            const existingPatientData = state[userId] || {};

            return {
                ...state,
                [userId]: {
                    ...existingPatientData,
                    id: userId,
                    updateProgramIsFetching: false,
                    updateProgramReceivedAt: receivedAt,
                    error,
                },
            };
        }
        case REQUEST_CANCEL_PATIENT_MEETING: {
            const existingPatientData = state[action.patientId] || {};

            return {
                ...state,
                [action.patientId]: {
                    ...existingPatientData,
                    requestedAt: action.requestedAt,
                },
            };
        }
        case RECEIVE_CANCEL_PATIENT_MEETING: {
            const { patientId } = action;

            action.asyncDispatch(getPatient(patientId, { params: patientDetailsFragment }));

            return {
                ...state,
            };
        }
        case RECEIVE_CANCEL_PATIENT_MEETING_ERROR: {
            const existingPatientData = state[action.patientId] || {};

            return {
                ...state,
                [action.patientId]: {
                    ...existingPatientData,
                    receivedAt: action.receivedAt,
                    error: action.error,
                },
            };
        }
        case REQUEST_PATIENT_SUGGESTIONS:
            return {
                ...state,
                suggestions: {
                    ...(state.suggestions || {}),
                    requestedAt: action.requestedAt,
                    isFetching: true,
                },
            };
        case RECEIVE_PATIENT_SUGGESTIONS:
            return {
                ...state,
                suggestions: {
                    ...(state.suggestions || {}),
                    receivedAt: action.receivedAt,
                    isFetching: false,
                    data: action.data.results,
                },
            };
        case RECEIVE_PATIENT_SUGGESTIONS_ERROR:
            return {
                ...state,
                suggestions: {
                    ...(state.suggestions || {}),
                    receivedAt: action.receivedAt,
                    isFetching: false,
                    error: action.error,
                },
            };
        case REQUEST_PATIENT_CHAT_HISTORY: {
            const currentData = state[action.patientId] || {};

            return {
                ...state,
                [action.patientId]: {
                    ...(state[action.patientId] || {}),
                    chat_history: {
                        ...(currentData.chat_history || {}),
                        requestedAt: action.requestedAt,
                    },
                },
            };
        }
        case RECEIVE_PATIENT_CHAT_HISTORY: {
            const currentData = state[action.patientId] || {};

            return {
                ...state,
                [action.patientId]: {
                    ...currentData,
                    receivedAt: action.receivedAt,
                    chat_history: {
                        ...(currentData.chat_history || {}),
                        receivedAt: action.receivedAt,
                        data: action.data,
                    },
                },
            };
        }
        case RECEIVE_PATIENT_CHAT_HISTORY_ERROR: {
            const currentData = state[action.patientId] || {};

            return {
                ...state,
                [action.patientId]: {
                    ...currentData,
                    receivedAt: action.receivedAt,
                    chat_history: {
                        ...(currentData.chat_history || {}),
                        receivedAt: action.receivedAt,
                        error: action.error,
                    },
                },
            };
        }
        case REQUEST_PATIENT_WATCHED_ITEMS: {
            const currentData = state[action.patientId] || {};

            return {
                ...state,
                [action.patientId]: {
                    ...(state[action.patientId] || {}),
                    watched_items: {
                        ...(currentData.watched_items || {}),
                        requestedAt: action.requestedAt,
                    },
                },
            };
        }
        case RECEIVE_PATIENT_WATCHED_ITEMS: {
            const currentData = state[action.patientId] || {};

            return {
                ...state,
                [action.patientId]: {
                    ...currentData,
                    receivedAt: action.receivedAt,
                    watched_items: {
                        ...(currentData.watched_items || {}),
                        receivedAt: action.receivedAt,
                        data: action.data,
                    },
                },
            };
        }
        case RECEIVE_PATIENT_WATCHED_ITEMS_ERROR: {
            const currentData = state[action.patientId] || {};

            return {
                ...state,
                [action.patientId]: {
                    ...currentData,
                    receivedAt: action.receivedAt,
                    watched_items: {
                        ...(currentData.watched_items || {}),
                        receivedAt: action.receivedAt,
                        error: action.error,
                    },
                },
            };
        }
        case REQUEST_UPDATE_PATIENT_MEETING_ANSWERS:
            return state;
        case RECEIVE_UPDATE_PATIENT_MEETING_ANSWERS: {
            const {
                data: { answers = [] },
                meetingId,
                patientId,
            } = action;
            const currentPatient = { ...(state[patientId] || {}) };
            const { patient_meetings = [] } = currentPatient;
            const idx = patient_meetings.findIndex((m) => m.id === meetingId);
            let newMeetings = patient_meetings;

            if (idx > -1) {
                patient_meetings[idx].answers = answers;
                newMeetings = getNewPatientMeetings(patientId, meetingId, patient_meetings[idx]);
            }

            return {
                ...state,
                [patientId]: {
                    ...currentPatient,
                    patient_meetings: newMeetings,
                },
            };
        }
        case RECEIVE_UPDATE_PATIENT_MEETING_ANSWERS_ERROR:
            return state;
        case REQUEST_UPDATE_PATIENT_MEETING:
            return state;
        case RECEIVE_UPDATE_PATIENT_MEETING: {
            const { data: meetingData, meetingId, patientId } = action;
            const currentPatient = { ...(state[patientId] || {}) };

            return {
                ...state,
                [patientId]: {
                    ...currentPatient,
                    patient_meetings: getNewPatientMeetings(patientId, meetingId, meetingData),
                },
            };
        }
        case RECEIVE_UPDATE_PATIENT_MEETING_ERROR:
            return state;
        case REQUEST_CREATE_PATIENT_MEETING:
            return state;
        case RECEIVE_CREATE_PATIENT_MEETING: {
            const { data: meetingData } = action;
            const { id: meetingId, patient: patientId } = meetingData;
            const currentPatient = { ...(state[patientId] || {}) };

            return {
                ...state,
                [patientId]: {
                    ...currentPatient,
                    patient_meetings: getNewPatientMeetings(patientId, meetingId, meetingData),
                },
            };
        }
        case RECEIVE_CREATE_PATIENT_MEETING_ERROR:
            return state;

        case REQUEST_CREATE_PATIENT_REMINDER:
            return {
                ...state,
                patientReminderIsLoading: true,
                patientReminderRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_PATIENT_REMINDER: {
            const {
                patient: { id: patientId },
            } = action.data;
            const existingPatientData = { ...(state[patientId] || {}) };
            const { assigned_reminders: existingReminders = [] } = existingPatientData;

            return {
                ...state,
                patientReminderIsLoading: false,
                patientReminderReceivedAt: action.receivedAt,
                [patientId]: {
                    ...existingPatientData,
                    assigned_reminders: [...existingReminders, action.data],
                },
            };
        }
        case RECEIVE_CREATE_PATIENT_REMINDER_ERROR:
            return {
                ...state,
                patientReminderIsLoading: false,
                patientReminderReceivedAt: action.receivedAt,
                patientReminderError: action.error,
            };
        case REQUEST_UPDATE_PATIENT_REMINDER:
            return {
                ...state,
                patientReminderIsLoading: true,
                patientReminderUpdateRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_PATIENT_REMINDER: {
            const {
                data: updatedData,
                data: {
                    id: reminderId,
                    patient: { id: patientId },
                },
            } = action;
            const existingPatientData = state[patientId] || {};
            const { assigned_reminders: updatedReminders = [] } = existingPatientData;
            const existingIndex = updatedReminders.findIndex((r) => r.id === reminderId);

            if (existingIndex > -1) {
                updatedReminders[existingIndex] = {
                    ...updatedReminders[existingIndex],
                    ...updatedData,
                };
            }

            return {
                ...state,
                patientReminderIsLoading: false,
                patientReminderReceivedAt: action.receivedAt,
                [patientId]: {
                    ...existingPatientData,
                    assigned_reminders: updatedReminders,
                },
            };
        }
        case RECEIVE_UPDATE_PATIENT_REMINDER_ERROR:
            return {
                ...state,
                patientReminderIsLoading: false,
                patientReminderReceivedAt: action.receivedAt,
                patientReminderError: action.error,
            };
        case REQUEST_DELETE_PATIENT_REMINDER:
            return {
                ...state,
                patientReminderIsLoading: true,
                patientReminderDeleteRequestedAt: action.requestedAt,
            };
        case RECEIVE_DELETE_PATIENT_REMINDER: {
            const { id: reminderId, patientId } = action.data;
            const existingPatientData = state[patientId] || {};
            const { assigned_reminders: updatedReminders = [] } = existingPatientData;
            const existingIndex = updatedReminders.findIndex((r) => r.id === reminderId);

            if (existingIndex > -1) {
                updatedReminders.splice(existingIndex, 1);
            }

            return {
                ...state,
                patientReminderIsLoading: false,
                patientReminderReceivedAt: action.receivedAt,
                [patientId]: {
                    ...existingPatientData,
                    assigned_reminders: updatedReminders,
                },
            };
        }
        case RECEIVE_DELETE_PATIENT_REMINDER_ERROR:
            return {
                ...state,
                patientReminderIsLoading: false,
                patientReminderReceivedAt: action.receivedAt,
                patientReminderError: action.error,
            };
        case REQUEST_CREATE_DEMO_PATIENT:
            return {
                ...state,
                createDemoPatientIsLoading: true,
                createDemoPatientRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_DEMO_PATIENT:
            return {
                ...state,
                createDemoPatientIsLoading: false,
                createDemoPatientReceivedAt: action.receivedAt,
                demoPatient: action.data,
            };
        case RECEIVE_CREATE_DEMO_PATIENT_ERROR:
            return {
                ...state,
                createDemoPatientIsLoading: false,
                createDemoPatientReceivedAt: action.receivedAt,
                createDemoPatientError: action.error,
            };
        case REQUEST_DELETE_DEMO_PATIENT:
            return {
                ...state,
                deleteDemoPatientIsLoading: true,
                deleteDemoPatientRequestedAt: action.requestedAt,
            };
        case RECEIVE_DELETE_DEMO_PATIENT:
            return {
                ...state,
                deleteDemoPatientIsLoading: false,
                deleteDemoPatientReceivedAt: action.receivedAt,
                demoPatient: null,
            };
        case RECEIVE_DELETE_DEMO_PATIENT_ERROR:
            return {
                ...state,
                deleteDemoPatientIsLoading: false,
                deleteDemoPatientReceivedAt: action.receivedAt,
                deleteDemoPatientError: action.error,
            };
        case REQUEST_INVITED_CLIENTS:
            return {
                ...state,
                invitedClientsIsLoading: true,
                invitedClientsRequestedAt: action.requestedAt,
            };
        case RECEIVE_INVITED_CLIENTS:
            return {
                ...state,
                invitedClientsIsLoading: false,
                invitedClientsReceivedAt: action.receivedAt,
                allPatientsList: action.data.results,
                count: action.data.count,
            };
        case RECEIVE_INVITED_CLIENTS_ERROR:
            return {
                ...state,
                invitedClientsIsLoading: false,
                invitedClientsReceivedAt: action.receivedAt,
                invitedClientsError: action.error,
            };
        case REQUEST_ALL_PURCHASES:
            return {
                ...state,
                allPurchasesIsFetching: true,
                allPurchasesRequestedAt: action.requestedAt,
            };
        case RECEIVE_ALL_PURCHASES: {
            const {
                data: { results, count },
            } = action;

            const allPatientsList =
                (results &&
                    results.length > 0 &&
                    results.map((purchase) => ({
                        ...purchase.user,
                        stripe_invoice_id: purchase.stripe_invoice_id,
                        stripe_price:
                            purchase.product && purchase.product.prices.length > 0
                                ? purchase.product.prices[0].amount
                                : "",
                        purchaseCreated: purchase.created,
                    }))) ||
                [];

            return {
                ...state,
                allPurchasesIsFetching: false,
                allPurchasesReceivedAt: action.receivedAt,
                allPatientsList,
                count,
            };
        }
        case RECEIVE_ALL_PURCHASES_ERROR:
            return {
                ...state,
                allPurchasesIsFetching: false,
                allPurchasesReceivedAt: action.receivedAt,
                allPurchasesError: action.error,
            };
        case REQUEST_ALL_MEETINGS:
            return {
                ...state,
                allMeetingsIsFetching: true,
                allMeetingsRequestedAt: action.requestedAt,
            };
        case RECEIVE_ALL_MEETINGS:
            const {
                data: { results, count },
            } = action;

            const allPatientsList =
                (results &&
                    results.length > 0 &&
                    results.map((meeting) => ({
                        coach: meeting.coach,
                        duration: meeting.duration,
                        finished: meeting.finished,
                        id: meeting.id,
                        interaction_type: meeting.interaction_type,
                        start_time: meeting.start_time,
                        started: meeting.started,
                        status: meeting.status,
                        ...meeting.patient,
                    }))) ||
                [];

            return {
                ...state,
                allMeetingsIsFetching: false,
                allMeetingsReceivedAt: action.receivedAt,
                allPatientsList,
                count,
            };
        case RECEIVE_ALL_MEETINGS_ERROR:
            return {
                ...state,
                allMeetingsIsFetching: false,
                allMeetingsReceivedAt: action.receivedAt,
                allMeetingsError: action.error,
            };
        case REQUEST_REMOVE_CLIENT:
            return {
                ...state,
                removeClientIsLoading: true,
                removeClientRequestedAt: action.requestedAt,
            };
        case RECEIVE_REMOVE_CLIENT:
            return {
                ...state,
                removeClientIsLoading: false,
                removeClientReceivedAt: action.receivedAt,
            };
        case RECEIVE_REMOVE_CLIENT_ERROR:
            return {
                ...state,
                removeClientIsLoading: false,
                removeClientReceivedAt: action.receivedAt,
                removeClientError: action.error,
            };
        case REQUEST_FETCH_ALL_LEADS:
            return {
                ...state,
                allLeadsIsFetching: true,
                allLeadsRequestedAt: action.requestedAt,
            };
        case RECEIVE_FETCH_ALL_LEADS:
            return {
                ...state,
                allLeadsIsFetching: false,
                allLeadsReceivedAt: action.receivedAt,
                allPatientsList: action.data.results,
                count: action.data.count,
            };
        case RECEIVE_FETCH_ALL_LEADS_ERROR:
            return {
                ...state,
                allLeadsIsFetching: false,
                allLeadsReceivedAt: action.receivedAt,
                allLeadsError: action.error,
            };
        case REQUEST_FETCH_LEAD:
            return {
                ...state,
                [action.id]: {
                    id: action.id,
                    leadIsFetching: true,
                    leadRequestedAt: action.requestedAt,
                },
            };
        case RECEIVE_FETCH_LEAD:
            action.asyncDispatch(getUnopenedLeads());
            return {
                ...state,
                [action.id]: {
                    id: action.id,
                    leadIsFetching: true,
                    leadReceivedAt: action.receivedAt,
                    ...action.data,
                },
            };
        case RECEIVE_FETCH_LEAD_ERROR:
            return {
                ...state,
                [action.id]: {
                    id: action.id,
                    leadIsFetching: true,
                    leadReceivedAt: action.receivedAt,
                    leadError: action.error,
                },
            };
        case RECEIVE_SEND_EMAIL: {
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    email_messages: [{ ...action.data }],
                },
            };
        }
        case HANDLE_ADD_PATIENT_RESOURCES: {
            const { patientId, resourcesData } = action;
            const existingPatientData = state[patientId] || {};
            const { resources: existingResources = [] } = existingPatientData;

            return {
                ...state,
                [patientId]: {
                    ...existingPatientData,
                    resources: [...existingResources, ...resourcesData],
                },
            };
        }
        case HANDLE_REMOVE_PATIENT_RESOURCE: {
            const { patientId, resourceId } = action;
            const existingPatientData = state[patientId] || {};
            const { resources: existingResources = [] } = existingPatientData;
            const newResources = existingResources.filter((r) => r.id !== resourceId);

            return {
                ...state,
                [patientId]: {
                    ...existingPatientData,
                    resources: newResources,
                },
            };
        }
        case UPDATE_PATIENTS_DATA_PARAMS:
            return {
                ...state,
                patientsListFilterFormData: action.data.patientsListFilterFormData || state.patientsListFilterFormData,
                ordering: action.data.ordering || "-created_date",
                patientsPage: action.data.page || 0,
            };
        case REQUEST_CREATE_COACH_PATIENT_ASSESSMENT:
            return state;
        case RECEIVE_CREATE_COACH_PATIENT_ASSESSMENT: {
            const { data, patientId } = action;
            const existingPatientData = state[patientId] || {};
            const { surveys: existingSurveys } = existingPatientData;

            return {
                ...state,
                [patientId]: {
                    ...existingPatientData,
                    surveys: [...(existingSurveys || []), data],
                },
            };
        }
        case RECEIVE_CREATE_COACH_PATIENT_ASSESSMENT_ERROR:
            toast.error("Error creating patient assessment");
            return state;
        case RECEIVE_UPDATE_PATIENT_ASSESSMENT: {
            const { data: updatedAssessment, patientId } = action;
            const existingPatientData = state[patientId] || {};
            const { surveys: newSurveys = [] } = existingPatientData;
            const existingIndex = newSurveys.findIndex((s) => s.id === updatedAssessment.id);

            if (existingIndex > -1) {
                newSurveys[existingIndex] = {
                    ...newSurveys[existingIndex],
                    ...updatedAssessment,
                };
            }

            return {
                ...state,
                [patientId]: {
                    ...existingPatientData,
                    surveys: newSurveys,
                },
            };
        }
        case RECEIVE_USE_CLIENT_CREDIT: {
            const {
                data: { user: clientId },
            } = action;
            const existingPatientData = state[clientId] || {};
            const activeSalesCredits = getClientActiveCredits(existingPatientData);
            activeSalesCredits[0].consumed_at = action.data.consumed_at;
            activeSalesCredits[0].consumed_reason = action.data.consumed_reason;

            return {
                ...state,
                [clientId]: {
                    ...state[clientId],
                },
            };
        }
        case SET_SORT_ORDER: {
            const { sortOrder } = action;

            return {
                ...state,
                sortOrder,
            };
        }
        case SET_SORT_ORDER_BY: {
            const { sortOrderBy } = action;

            return {
                ...state,
                sortOrderBy,
            };
        }
        case SET_PATIENT_FILTER_PARAMS: {
            const { patientFilterParams } = action;

            return {
                ...state,
                patientFilterParams,
            };
        }
        case SET_PATIENT_FILTER_VALUES: {
            const { patientFilterValues } = action;

            return {
                ...state,
                patientFilterValues,
            };
        }
        case SET_PATIENTS_PAGE: {
            const { patientsPage } = action;

            return {
                ...state,
                patientsPage,
            };
        }
        case "REDUX_WEBSOCKET::MESSAGE": {
            const {
                payload: { message },
            } = action;

            switch (message.type) {
                case "user_updated":
                    const { user_id, online } = message;
                    const currentData = state[user_id] || {};

                    return {
                        ...state,
                        [user_id]: {
                            ...currentData,
                            online,
                        },
                    };
                default:
                    return {
                        ...state,
                    };
            }
        }
        default:
            return { ...state };
    }
}
