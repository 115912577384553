import { exq } from "../../utils/helpers";

export const conversationFragment = {
    expand: exq`
        messages
        messages.created_by
        messages.visit_tickets
        participants
        participants.owner
    `,
    fields: exq`
        id
        created
        modified
        count_not_visited_by_requester_messages
        count_unread_messages

        messages.id
        messages.body
        messages.created
        messages.created_by.id
        messages.created_by.first_name
        messages.created_by.last_name
        messages.created_by.profile_image
        messages.visit_tickets.user
        messages.visit_tickets.visited

        participants.id
        participants.first_name
        participants.is_coach
        participants.last_name
        participants.owner.id
        participants.owner.first_name
        participants.profile_image
        participants.timezone
        participants.online
    `,
};

export const conversationMessageFragment = {
    expand: exq`
        created_by
        visit_tickets
    `,
    fields: exq`
        id
        created
        body

        created_by.id
        created_by.first_name
        created_by.last_name
        created_by.profile_image

        visit_tickets
    `,
};

export const sidebarPatientFragment = {
    expand: exq`
        assigned_programs
        branding
        conditions.primary
        group
        group.branding
        group.parent
        group.parent.branding
        owner
        surveys
    `,
    fields: exq`
        assigned_programs
        birthday
        branding
        conditions
        count_not_visited_by_requester_messages
        count_unread_messages
        created_date
        first_name
        group
        id
        info
        last_login
        last_meeting
        last_name
        on_hold
        online
        owner
        phone
        preferred_name
        satisfaction_surveys
        stripe_subscription_status
        timezone
        smart_goal
        surveys
        uuid
    `,
};
