import React, { useEffect } from "react";
import { loadExternalScript, unloadExternalScript, unloadRecaptchaBadge } from "../../utils/helpers";

const RECAPTCHA_SCRIPT_ID = "recaptcha";
const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

export default function RecaptchaProvider({ children, redirectToSSO = false }) {
    useEffect(() => {
        if (redirectToSSO) {
            return;
        }

        async function loadScript() {
            await loadExternalScript(
                RECAPTCHA_SCRIPT_ID,
                `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_SITE_KEY}`
            );
        }

        loadScript();

        return () => {
            unloadExternalScript(RECAPTCHA_SCRIPT_ID);
            unloadRecaptchaBadge();
        };
    }, [redirectToSSO]);

    return <div className="h-100">{children}</div>;
}
