import {
    UPDATE_FORM_DATA,
    REQUEST_CHECK_COUPON,
    RECEIVE_CHECK_COUPON,
    RECEIVE_CHECK_COUPON_ERROR,
    REQUEST_CHECK_PREREGISTERED_EMAIL,
    RECEIVE_CHECK_PREREGISTERED_EMAIL,
    RECEIVE_CHECK_PREREGISTERED_EMAIL_ERROR,
    REQUEST_PROVIDER_SIGNUP_USER,
    RECEIVE_PROVIDER_SIGNUP_USER,
    RECEIVE_PROVIDER_SIGNUP_USER_ERROR,
    REQUEST_SIGNUP_USER,
    RECEIVE_SIGNUP_USER,
    RECEIVE_SIGNUP_USER_ERROR,
} from "../actions";

const signupIntiialState = {
    formData: {},
};

export function signup(state = signupIntiialState, action) {
    switch (action.type) {
        case UPDATE_FORM_DATA:
            return {
                ...state,
                formData: {
                    ...state.formData,
                    ...action.data,
                },
            };
        case REQUEST_CHECK_COUPON:
            return {
                ...state,
                checkCodeRequestedAt: action.requestedAt,
                checkCodeIsFetching: true,
            };
        case RECEIVE_CHECK_COUPON:
            return {
                ...state,
                validCode: action.data,
                checkCodeReceivedAt: action.receivedAt,
                checkCodeIsFetching: false,
            };
        case RECEIVE_CHECK_COUPON_ERROR:
            return {
                ...state,
                validCode: false,
                checkCodeReceivedAt: action.receivedAt,
                checkCodeIsFetching: false,
                error: action.error,
            };
        case REQUEST_CHECK_PREREGISTERED_EMAIL:
            return {
                ...state,
                checkPreregisteredEmailIsFetching: true,
                checkPreregisteredEmailRequestedAt: action.requestedAt,
            };
        case RECEIVE_CHECK_PREREGISTERED_EMAIL:
            const validEmail = action.data.email === state.formData.email;

            return {
                ...state,
                checkPreregisteredEmailIsFetching: false,
                checkPreregisteredEmailReceivedAt: action.receivedAt,
                validEmail,
            };
        case RECEIVE_CHECK_PREREGISTERED_EMAIL_ERROR:
            return {
                ...state,
                checkPreregisteredEmailIsFetching: false,
                checkPreregisteredEmailReceivedAt: action.receivedAt,
                checkPreregisteredEmailError: action.error,
                validEmail: false,
            };
        case REQUEST_PROVIDER_SIGNUP_USER:
            return {
                ...state,
                providerSignupRequestedAt: action.requestedAt,
                providerSignupIsFetching: true,
            };
        case RECEIVE_PROVIDER_SIGNUP_USER: {
            return {
                ...state,
                providerSignupReceivedAt: action.receivedAt,
                providerSignupIsFetching: false,
            };
        }
        case RECEIVE_PROVIDER_SIGNUP_USER_ERROR: {
            return {
                ...state,
                providerSignupReceivedAt: action.receivedAt,
                providerSignupIsFetching: false,
                providerSignupError: action.error,
            };
        }
        case REQUEST_SIGNUP_USER:
            return {
                ...state,
                signupRequestedAt: action.requestedAt,
                signupIsFetching: true,
            };
        case RECEIVE_SIGNUP_USER: {
            return {
                ...state,
                signupReceivedAt: action.receivedAt,
                signupIsFetching: false,
            };
        }
        case RECEIVE_SIGNUP_USER_ERROR: {
            return {
                ...state,
                signupReceivedAt: action.receivedAt,
                signupIsFetching: false,
                signupError: action.error,
            };
        }
        default:
            return state;
    }
}
