import { exq } from "../../utils/helpers";
import { resourceFragment } from "./resources";

export const patientDataBasicFragment = {
    fields: exq`
        id
        first_name
        last_name
    `,
};

export const patientDataFragment = {
    expand: exq`
        branding
        group
        group.parent
        group.parent.branding
        invitation
        owner
        subscriptions
        surveys
    `,
    fields: exq`
        consults_total
        created_date
        email
        first_name
        group.branding.name
        group.branding.display_name
        group.domain
        group.hidden
        group.partner_id
        group.parent
        group.title
        id
        invitation.active
        invitation.created
        invitation.id
        invitation.sent
        last_login
        last_name
        online
        on_hold
        owner.first_name
        owner.last_name
        prices
        stripe_customer_id
        subscriptions
        surveys.disability
        surveys.pain
        surveys.red_flags
    `,
};

export const leadsDataFragment = {
    fields: exq`
        archived
        comment
        contact_type
        created
        email
        email_messages
        first_name
        id
        last_name
        lead_opened
        lead_response_sent
        phone
        provider_id
    `,
};

export const patientDetailsFragment = {
    expand: exq`
        assigned_programs
        assigned_reminders
        branding
        conditions.primary
        conditions.secondary
        email_notifications
        group
        group.products
        group.products.prices
        group.parent
        group.parent.branding
        info
        invitation
        owner
        patient_meetings
        patient_meetings.answers
        patient_meetings.coach
        purchased_products
        provider_id
        purchased_products.product
        purchased_products.product.prices
        purchased_products.sales_credits
        satisfaction_surveys
        subscriptions
        subscriptions.product
        surveys
        surveys.created_by
        surveys.modified_by
        uuid
        ${resourceFragment.expand}
    `,
    fields: exq`
        assigned_programs.id
        assigned_programs.created
        assigned_programs.description
        assigned_programs.pain_location
        assigned_programs.recommended_list
        assigned_programs.resources_list
        assigned_programs.title
        assigned_reminders
        bio
        birthday
        branding
        coach_notes
        conditions
        created_date
        description
        email
        email_notifications.id
        email_notifications.email_subscription
        first_name
        gender
        group
        id
        info
        invitation.active
        invitation.id
        invitation.sent
        invitation.utilized
        last_disability
        last_name
        last_pain
        logins_total
        on_hold
        online
        owner
        partner_id
        patient_meetings
        phone
        preferred_name
        prices
        prices_override
        profile_image
        purchased_products
        satisfaction_surveys
        last_login
        smart_goal
        surveys.id
        surveys.created
        surveys.user
        surveys.pain_location
        surveys.red_flag
        surveys.red_flags
        surveys.pain
        surveys.disability
        surveys.stress
        surveys.modified_by.id
        surveys.modified_by.is_coach
        surveys.modified_by.first_name
        surveys.modified_by.last_name
        surveys.modified_by.profile_image
        surveys.created_by.id
        surveys.created_by.is_coach
        surveys.created_by.first_name
        surveys.created_by.last_name
        surveys.created_by.profile_image
        subscriptions
        timezone
        title
        uuid
        ${resourceFragment.fields}
    `,
};

export const leadDetailsFragment = {
    expand: exq`
        branding
        info
        owner
        provider
        subscriptions
        subscriptions.product
        uuid
        ${resourceFragment.expand}
    `,
    fields: exq`
        archived
        city
        created
        contact_type
        email
        email_messages
        first_name
        group
        id
        last_name
        message
        owner
        partner
        phone
        provider
        state
        timezone
        uuid
        ${resourceFragment.fields}
    `,
};

export const patietnActivityFragment = {
    fields: exq`
        conversations_total
        created_date
        last_login
        logins_total
        watched_total
        ${patientDataFragment.fields}
        `,
};
