import React from "react";
import { Box, Card, Stack, Typography, useTheme } from "@mui/material";

import { CANADIAN_PROVINCES, COUNTRIES_SEARCH_LIST, STATES_LIST } from "../../utils/helpers";
import { PROVIDER_MAP_RESULTS_ROUTE } from "../../utils/routes";

import Accordion from "../accordion/Accordion";
import ButtonMain from "../button/ButtonMain";

export default function StateGrid() {
    const theme = useTheme();

    return (
        <Card sx={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Stack sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                    <Typography variant="h3">Search by State</Typography>
                </Stack>

                <Stack sx={{ flexDirection: "row", flexWrap: "wrap" }}>
                    {STATES_LIST.map((state) => (
                        <Box sx={{ p: 0.5 }}>
                            <ButtonMain
                                href={`${PROVIDER_MAP_RESULTS_ROUTE}?state=${
                                    state && state.value.toLowerCase()
                                }&country=us`}
                                sx={{
                                    minWidth: "40px !important",
                                    backgroundColor: theme.palette.slate.main,
                                    color: theme.palette.common.white,
                                    "&: hover": {
                                        backgroundColor: theme.palette.slate.lighter,
                                        color: theme.palette.common.white,
                                    },
                                }}
                            >
                                {state.value}
                            </ButtonMain>
                        </Box>
                    ))}
                </Stack>

                <Stack sx={{ flexDirection: { xs: "column", sm: "row" }, mt: 1 }}>
                    <Typography variant="h3">Search by Canadian Province</Typography>
                </Stack>
                <Stack sx={{ flexDirection: "row", flexWrap: "wrap" }}>
                    {CANADIAN_PROVINCES.map((state) => (
                        <Box sx={{ p: 0.5 }}>
                            <ButtonMain
                                href={`${PROVIDER_MAP_RESULTS_ROUTE}?state=${
                                    state && state.value.toLowerCase()
                                }&country=ca`}
                                sx={{
                                    minWidth: "40px !important",
                                    backgroundColor: theme.palette.slate.main,
                                    color: theme.palette.common.white,
                                    "&: hover": {
                                        backgroundColor: theme.palette.slate.lighter,
                                        color: theme.palette.common.white,
                                    },
                                }}
                            >
                                {state.value}
                            </ButtonMain>
                        </Box>
                    ))}
                </Stack>
                <Stack sx={{ flexDirection: { xs: "column", sm: "row" }, mt: 1 }}>
                    <Typography variant="h3">Search by other Countries</Typography>
                </Stack>
                <Stack sx={{ flexDirection: "row", flexWrap: "wrap" }}>
                    {COUNTRIES_SEARCH_LIST.map((country) => (
                        <Box sx={{ p: 0.5 }}>
                            <ButtonMain
                                href={`${PROVIDER_MAP_RESULTS_ROUTE}?country=${country && country.value.toLowerCase()}`}
                                sx={{
                                    minWidth: "40px !important",
                                    backgroundColor: theme.palette.slate.main,
                                    color: theme.palette.common.white,
                                    "&: hover": {
                                        backgroundColor: theme.palette.slate.lighter,
                                        color: theme.palette.common.white,
                                    },
                                }}
                            >
                                {country.value}
                            </ButtonMain>
                        </Box>
                    ))}
                </Stack>
            </Box>

            {/* Mobile layout */}
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <Accordion title="Search by State, Province, or Country">
                    <Stack sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                        <Typography variant="h3">Search by State</Typography>
                    </Stack>

                    <Stack sx={{ flexDirection: "row", flexWrap: "wrap" }}>
                        {STATES_LIST.map((state) => (
                            <Box sx={{ p: 0.5 }}>
                                <ButtonMain
                                    href={`${PROVIDER_MAP_RESULTS_ROUTE}?state=${state && state.value}&country=US`}
                                    sx={{
                                        width: "40px !important",
                                        minWidth: "40px !important",
                                        backgroundColor: theme.palette.slate.main,
                                        color: theme.palette.common.white,
                                        "&: hover": {
                                            backgroundColor: theme.palette.slate.lighter,
                                        },
                                    }}
                                >
                                    {state.value}
                                </ButtonMain>
                            </Box>
                        ))}
                    </Stack>

                    <Stack sx={{ flexDirection: { xs: "column", sm: "row" }, mt: 1 }}>
                        <Typography variant="h3">Search by Canadian Province</Typography>
                    </Stack>
                    <Stack sx={{ flexDirection: "row", flexWrap: "wrap" }}>
                        {CANADIAN_PROVINCES.map((state) => (
                            <Box sx={{ p: 0.5 }}>
                                <ButtonMain
                                    href={`${PROVIDER_MAP_RESULTS_ROUTE}?state=${state && state.value}&country=CA`}
                                    sx={{
                                        width: "40px !important",
                                        minWidth: "40px !important",
                                        backgroundColor: theme.palette.slate.main,
                                        color: theme.palette.common.white,
                                        "&: hover": {
                                            backgroundColor: theme.palette.slate.lighter,
                                        },
                                    }}
                                >
                                    {state.value}
                                </ButtonMain>
                            </Box>
                        ))}
                    </Stack>

                    <Stack sx={{ flexDirection: { xs: "column", sm: "row" }, mt: 1 }}>
                        <Typography variant="h3">Search by other Countries</Typography>
                    </Stack>
                    <Stack sx={{ flexDirection: "row", flexWrap: "wrap" }}>
                        {COUNTRIES_SEARCH_LIST.map((country) => (
                            <Box sx={{ p: 0.5 }}>
                                <ButtonMain
                                    href={`${PROVIDER_MAP_RESULTS_ROUTE}?country=${country && country.value}`}
                                    sx={{
                                        minWidth: "40px !important",
                                        maxWidth: "130px !important",
                                        backgroundColor: theme.palette.slate.main,
                                        color: theme.palette.common.white,
                                        "&: hover": {
                                            backgroundColor: theme.palette.slate.lighter,
                                        },
                                    }}
                                >
                                    {country.value}
                                </ButtonMain>
                            </Box>
                        ))}
                    </Stack>
                </Accordion>
            </Box>
        </Card>
    );
}
