import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as yup from "yup";

import { updateClinicProfile } from "../../app/actions";

import ButtonLink from "../button/ButtonLink";
import FormProvider from "../minimal/hook-form/FormProvider";
import Modal from "../modal/Modal";
import RHFTextField from "../minimal/hook-form/RHFTextField";
import { toast } from "../notifications/Toast";

const contactSchema = yup.object().shape({
    phone: yup.string(),
    altPhone: yup.string(),
    url: yup
        .string()
        .test("url", "URL must be in the following format: https://www.example.com", (url) => {
            if (url && url.length > 0) {
                const schema = yup
                    .string()
                    .matches(
                        /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
                    );
                return schema.isValidSync(url);
            } else {
                return true;
            }
        })
        .nullable(),
    instagram: yup
        .string()
        .test("instagram", "URL must be in the following format: https://www.instagram.com/username", (instagram) => {
            if (instagram && instagram.length > 0) {
                const schema = yup
                    .string()
                    .matches(
                        /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.(instagram)\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
                    );
                return schema.isValidSync(instagram);
            } else {
                return true;
            }
        })
        .nullable(),
    facebook: yup
        .string()
        .test("facebook", "URL must be in the following format: https://www.facebook.com/username", (facebook) => {
            if (facebook && facebook.length > 0) {
                const schema = yup
                    .string()
                    .matches(
                        /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.(facebook)\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
                    );
                return schema.isValidSync(facebook);
            } else {
                return true;
            }
        })
        .nullable(),
    twitter: yup
        .string()
        .test("twitter", "URL must be in the following format: https://www.twitter.com/username", (twitter) => {
            if (twitter && twitter.length > 0) {
                const schema = yup
                    .string()
                    .matches(/^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.(twitter)\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/);
                return schema.isValidSync(twitter);
            } else {
                return true;
            }
        })
        .nullable(),
});

const initialValues = {
    phone: "",
    altPhone: "",
    vanity_phone: "",
    url: "",
    facebook: "",
    instagram: "",
    twitter: "",
};

export default function EditClinicContactModal({ show, clinic, handleClose }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [altPhoneNumber, setAltPhoneNumber] = useState("");
    const methods = useForm({
        resolver: yupResolver(contactSchema),
        initialValues,
    });

    const {
        getValues,
        setValue,
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        setPhoneNumber(clinic ? clinic.phone : "");
        setAltPhoneNumber(clinic ? clinic.alt_phone : "");

        reset({
            ...initialValues,
            phone: phoneNumber,
            altPhone: clinic && clinic.alt_phone ? clinic.alt_phone : "",
            vanity_phone: clinic ? clinic.vanity_phone : "",
            url: clinic ? clinic.url : "",
            facebook: clinic ? clinic.facebook : "",
            instagram: clinic ? clinic.instagram : "",
            twitter: clinic ? clinic.twitter : "",
        });
    }, [show]); // eslint-disable-line

    const handlePhoneChange = (value) => {
        setPhoneNumber(value ? value : "");
        setValue("phone", value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    };

    const handleAltPhoneChange = (value) => {
        setAltPhoneNumber(value ? value : "");
        setValue("alt_phone", value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    };

    const handleFormSubmit = async () => {
        const { vanity_phone, url, facebook, instagram, twitter } = getValues();
        const body = {
            phone: phoneNumber,
            alt_phone: altPhoneNumber,
            vanity_phone: vanity_phone,
            url: url && url.length > 0 ? url : "",
            facebook: facebook && facebook.length > 0 ? facebook : "",
            instagram: instagram && instagram.length > 0 ? instagram : "",
            twitter: twitter && twitter.length > 0 ? twitter : "",
        };

        const result = await dispatch(updateClinicProfile(clinic.id, body));

        if (result.error) {
            toast.error("Error updating contact info");
        } else {
            toast.success("Contact info updated");
            handleClose();
        }
    };

    return (
        <>
            <Modal
                show={show}
                toggleShow={handleClose}
                content={
                    <div>
                        <Typography variant="h6" sx={{ my: 2 }}>
                            Edit Contact Info
                        </Typography>
                        <FormProvider methods={methods} onSubmit={handleSubmit(handleFormSubmit)}>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12} lg={6}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            maxWidth: "42px",
                                            left: "58px",
                                            top: "9px",
                                            backgroundColor: theme.palette.common.white,
                                            padding: "0 4px",
                                            fontSize: "0.75rem",
                                            position: "relative",
                                            lineHeight: "1.4375em",
                                            color: "#919EAB",
                                        }}
                                    >
                                        Phone
                                    </Typography>
                                    <PhoneInput
                                        international
                                        defaultCountry="US"
                                        countryCallingCodeEditable={false}
                                        placeholder="Enter phone number"
                                        initialValue
                                        value={phoneNumber}
                                        onChange={handlePhoneChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            maxWidth: "75px",
                                            left: "58px",
                                            top: "9px",
                                            backgroundColor: theme.palette.common.white,
                                            padding: "0 4px",
                                            fontSize: "0.75rem",
                                            position: "relative",
                                            lineHeight: "1.4375em",
                                            color: "#919EAB",
                                        }}
                                    >
                                        Alt. Phone
                                    </Typography>
                                    <PhoneInput
                                        international
                                        defaultCountry="US"
                                        countryCallingCodeEditable={false}
                                        placeholder="Enter phone number"
                                        initialValue
                                        value={altPhoneNumber}
                                        onChange={handleAltPhoneChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <RHFTextField name="vanity_phone" label="Vanity Number" />
                                </Grid>
                                <Grid item xs={12} lg={6}></Grid>
                                <Grid item xs={12} lg={6}>
                                    <RHFTextField name="url" label="Website" />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <RHFTextField name="facebook" label="Facebook" />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <RHFTextField name="instagram" label="Instagram" />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <RHFTextField name="twitter" label="Twitter" />
                                </Grid>
                            </Grid>
                            <Stack direction="row" justifyContent="end" spacing={2} sx={{ my: 2 }}>
                                <ButtonLink link onClick={handleClose} className="p-2">
                                    Cancel
                                </ButtonLink>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    className="text-right"
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </FormProvider>
                    </div>
                }
            />
        </>
    );
}
