import React from "react";
import { DatePicker as MuiDatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import {
    getThemeColor,
    PRIMARY_ALT_COLOR_ID,
    PRIMARY_COLOR_ID,
    SECONDARY_ALT_COLOR_ID,
    SECONDARY_ORANGE_COLOR_ID,
} from "../../styles/colors";

const StaticDatePickerTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                display: "none",
            },
        },
        MuiPickersDay: {
            day: {
                color: getThemeColor(SECONDARY_ORANGE_COLOR_ID),
                textDecoration: "underline",
                "&:hover": {
                    color: "#fff",
                    backgroundColor: getThemeColor(SECONDARY_ALT_COLOR_ID),
                },
            },
            daySelected: {
                backgroundColor: getThemeColor(PRIMARY_COLOR_ID),
                textDecoration: "none",
                "&:hover": {
                    color: "#fff",
                    backgroundColor: getThemeColor(PRIMARY_ALT_COLOR_ID),
                },
            },
            dayDisabled: {
                textDecoration: "none",
            },
        },
    },
});

export default function DatePicker({
    allowKeyboard = false,
    Component = MuiDatePicker,
    containerClassName = "",
    themed = true,
    ...rest
}) {
    return (
        <div className={containerClassName}>
            {allowKeyboard ? (
                <KeyboardDatePicker {...rest} />
            ) : (
                // Specific MuiThemeProvider for the date picker styles, independent of the global App MuiThemeProvider
                <MuiThemeProvider theme={themed ? StaticDatePickerTheme : undefined}>
                    <Component {...rest} fullWidth />
                </MuiThemeProvider>
            )}
        </div>
    );
}
