import { axiosDelete, get, patch, post } from "../../utils/network";
import { gen_error, gen_received, gen_requested } from "./generators";

export const REQUEST_ADD_FAVORITE_RESOURCE = "REQUEST_ADD_FAVORITE_RESOURCE";
export const RECEIVE_ADD_FAVORITE_RESOURCE = "RECEIVE_ADD_FAVORITE_RESOURCE";
export const RECEIVE_ADD_FAVORITE_RESOURCE_ERROR = "RECEIVE_ADD_FAVORITE_RESOURCE_ERROR";
export const addFavoriteResource = (body, resource) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ADD_FAVORITE_RESOURCE));

        const result = await post("/api/v3/resources/", body);

        return dispatch({
            type: RECEIVE_ADD_FAVORITE_RESOURCE,
            receivedAt: new Date(),
            data: result.data,
            resource,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ADD_FAVORITE_RESOURCE_ERROR, error));
    }
};

export const REQUEST_ASSIGN_PATIENT_RESOURCE = "REQUEST_ASSIGN_PATIENT_RESOURCE";
export const RECEIVE_ASSIGN_PATIENT_RESOURCE = "RECEIVE_ASSIGN_PATIENT_RESOURCE";
export const RECEIVE_ASSIGN_PATIENT_RESOURCE_ERROR = "RECEIVE_ASSIGN_PATIENT_RESOURCE_ERROR";
export const assignPatientResource = (body, resource) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ASSIGN_PATIENT_RESOURCE));

        const result = await post("/api/v3/resources/", body);

        return dispatch({
            type: RECEIVE_ASSIGN_PATIENT_RESOURCE,
            receivedAt: new Date(),
            data: result.data,
            resource,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ASSIGN_PATIENT_RESOURCE_ERROR, error));
    }
};

export const REQUEST_ARTICLES = "REQUEST_ARTICLES";
export const RECEIVE_ARTICLES = "RECEIVE_ARTICLES";
export const RECEIVE_ARTICLES_ERROR = "RECEIVE_ARTICLES_ERROR";
export const getArticles = (params = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ARTICLES));

        const result = await get("/api/v3/blog/articles/", { params });

        return dispatch({
            type: RECEIVE_ARTICLES,
            receivedAt: new Date(),
            data: result.data,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ARTICLES_ERROR, error));
    }
};

export const REQUEST_FETCH_WEBINARS = "REQUEST_FETCH_WEBINARS";
export const RECEIVE_FETCH_WEBINARS = "RECEIVE_FETCH_WEBINARS";
export const RECEIVE_FETCH_WEBINARS_ERROR = "RECEIVE_FETCH_WEBINARS_ERROR";
export const fetchWebinars = (params = { page_size: 20, tag__in: "Webinar" }) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_FETCH_WEBINARS));

        const result = await get("/api/v3/exercises/", { params });

        return dispatch({
            type: RECEIVE_FETCH_WEBINARS,
            receivedAt: new Date(),
            data: result.data,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_FETCH_WEBINARS_ERROR, error));
    }
};

export const REQUEST_LIST = "REQUEST_LIST";
export const RECEIVE_LIST = "RECEIVE_LIST";
export const RECEIVE_LIST_ERROR = "RECEIVE_LIST_ERROR";
export const fetchList = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_LIST));

        const result = await get(`/api/v3/resources/`, options);

        return dispatch({
            type: RECEIVE_LIST,
            receivedAt: new Date(),
            data: result.data,
            list: options.params.list,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_LIST_ERROR, error));
    }
};

export const REQUEST_LIST_INFO = "REQUEST_LIST_INFO";
export const RECEIVE_LIST_INFO = "RECEIVE_LIST_INFO";
export const RECEIVE_LIST_INFO_ERROR = "RECEIVE_LIST_INFO_ERROR";
export const fetchListInfo = (list_id) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_LIST_INFO));

        const result = await get(`/api/v3/resources_lists/${list_id}/`);

        return dispatch(gen_received(RECEIVE_LIST_INFO, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_LIST_INFO_ERROR, error));
    }
};

export const REQUEST_RESOURCES_LIST = "REQUEST_RESOURCES_LIST";
export const RECEIVE_RESOURCES_LIST = "RECEIVE_RESOURCES_LIST";
export const RECEIVE_RESOURCES_LIST_ERROR = "RECEIVE_RESOURCES_LIST_ERROR";
export const getResourcesList = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_RESOURCES_LIST));

        const result = await get(`/api/v3/resources/`, options);

        return dispatch({
            type: RECEIVE_RESOURCES_LIST,
            receivedAt: new Date(),
            data: result.data,
            list: options.params.list,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_RESOURCES_LIST_ERROR, error));
    }
};

export const REQUEST_RESOURCE_BY_ID = "REQUEST_RESOURCE_BY_ID";
export const RECEIVE_RESOURCE_BY_ID = "RECEIVE_RESOURCE_BY_ID";
export const RECEIVE_RESOURCE_BY_ID_ERROR = "RECEIVE_RESOURCE_BY_ID_ERROR";
export const getResourceById = (resourceId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_RESOURCE_BY_ID));

        const result = await get(`/api/v3/exercises/${resourceId}/`);

        return dispatch(gen_received(RECEIVE_RESOURCE_BY_ID, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_RESOURCE_BY_ID_ERROR, error));
    }
};

export const REQUEST_UPDATE_RESOURCE = "REQUEST_UPDATE_RESOURCE";
export const RECEIVE_UPDATE_RESOURCE = "RECEIVE_UPDATE_RESOURCE";
export const RECEIVE_UPDATE_RESOURCE_ERROR = "RECEIVE_UPDATE_RESOURCE_ERROR";
export const updateResource = (body, resourceId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UPDATE_RESOURCE));

        const result = await patch(`/api/v3/resources/${resourceId}/`, body);

        return dispatch(gen_received(RECEIVE_UPDATE_RESOURCE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UPDATE_RESOURCE_ERROR, error));
    }
};

export const REQUEST_COACH_UPDATE_RESOURCE = "REQUEST_COACH_UPDATE_RESOURCE";
export const RECEIVE_COACH_UPDATE_RESOURCE = "RECEIVE_COACH_UPDATE_RESOURCE";
export const RECEIVE_COACH_UPDATE_RESOURCE_ERROR = "RECEIVE_COACH_UPDATE_RESOURCE_ERROR";
export const coachUpdateResource = (body, resourceId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_COACH_UPDATE_RESOURCE));

        const result = await patch(`/api/v3/resources/${resourceId}/`, body);

        return dispatch(gen_received(RECEIVE_COACH_UPDATE_RESOURCE, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_COACH_UPDATE_RESOURCE_ERROR, error));
    }
};

export const SET_ASSIGNED_RESOURCES = "SET_ASSIGNED_RESOURCES";
export const setAssignedResources = (list) => (dispatch) => {
    dispatch({
        type: SET_ASSIGNED_RESOURCES,
        list,
    });
};

export const SET_FAVORITED_RESOURCES = "SET_FAVORITED_RESOURCES";
export const setFavoritedResources = (list) => (dispatch) => {
    dispatch({
        type: SET_FAVORITED_RESOURCES,
        list,
    });
};

export const REQUEST_RESOURCES = "REQUEST_RESOURCES";
export const RECEIVE_RESOURCES = "RECEIVE_RESOURCES";
export const RECEIVE_RESOURCES_ERROR = "RECEIVE_RESOURCES_ERROR";
export const getResources = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_RESOURCES));

        const result = await get("/api/v3/resources/", options);

        return dispatch(gen_received(RECEIVE_RESOURCES, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_RESOURCES_ERROR, error));
    }
};

export const REQUEST_EXERCISES = "REQUEST_EXERCISES";
export const RECEIVE_EXERCISES = "RECEIVE_EXERCISES";
export const RECEIVE_EXERCISES_ERROR = "RECEIVE_EXERCISES_ERROR";
export const getExercises = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_EXERCISES));

        const result = await get("/api/v3/exercises/", options);

        return dispatch({
            type: RECEIVE_EXERCISES,
            receivedAt: new Date(),
            data: result.data,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_EXERCISES_ERROR, error));
    }
};

export const REQUEST_ALL_TAGS = "REQUEST_ALL_TAGS";
export const RECEIVE_ALL_TAGS = "RECEIVE_ALL_TAGS";
export const RECEIVE_ALL_TAGS_ERROR = "RECEIVE_ALL_TAGS_ERROR";
export const getAllTags = (options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_ALL_TAGS));

        const result = await get("/api/v3/tags/", options);

        return dispatch(gen_received(RECEIVE_ALL_TAGS, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_ALL_TAGS_ERROR, error));
    }
};

export const REQUEST_TAGS_BY_TYPE = "REQUEST_TAGS_BY_TYPE";
export const RECEIVE_TAGS_BY_TYPE = "RECEIVE_TAGS_BY_TYPE";
export const RECEIVE_TAGS_BY_TYPE_ERROR = "RECEIVE_TAGS_BY_TYPE_ERROR";
export const getTagsByType = (tagType, options = {}) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_TAGS_BY_TYPE));

        const result = await get(`/api/v3/tags/`, options);

        return dispatch({
            type: RECEIVE_TAGS_BY_TYPE,
            receivedAt: new Date(),
            data: result.data,
            tagType,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_TAGS_BY_TYPE_ERROR, error));
    }
};

export const REQUEST_REMOVE_FAVORITE_RESOURCE = "REQUEST_REMOVE_FAVORITE_RESOURCE";
export const RECEIVE_REMOVE_FAVORITE_RESOURCE = "RECEIVE_REMOVE_FAVORITE_RESOURCE";
export const RECEIVE_REMOVE_FAVORITE_RESOURCE_ERROR = "RECEIVE_REMOVE_FAVORITE_RESOURCE_ERROR";
export const removeFavoriteResource = (resourceId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_REMOVE_FAVORITE_RESOURCE));

        const result = await axiosDelete(`/api/v3/resources/${resourceId}/`);

        return dispatch({
            type: RECEIVE_REMOVE_FAVORITE_RESOURCE,
            receivedAt: new Date(),
            data: result.data,
            resourceId,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_REMOVE_FAVORITE_RESOURCE_ERROR, error));
    }
};

export const REQUEST_UNASSIGN_PATIENT_RESOURCE = "REQUEST_UNASSIGN_PATIENT_RESOURCE";
export const RECEIVE_UNASSIGN_PATIENT_RESOURCE = "RECEIVE_UNASSIGN_PATIENT_RESOURCE";
export const RECEIVE_UNASSIGN_PATIENT_RESOURCE_ERROR = "RECEIVE_UNASSIGN_PATIENT_RESOURCE_ERROR";
export const unassignPatientResource = (resourceId) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_UNASSIGN_PATIENT_RESOURCE));

        const result = await axiosDelete(`/api/v3/resources/${resourceId}/`);

        return dispatch({
            type: RECEIVE_UNASSIGN_PATIENT_RESOURCE,
            receivedAt: new Date(),
            data: result.data,
            resourceId,
        });
    } catch (error) {
        return dispatch(gen_error(RECEIVE_UNASSIGN_PATIENT_RESOURCE_ERROR, error));
    }
};

export const SET_PAGINATION_PAGE = "SET_PAGINATION_PAGE";
export const setPaginationPage = (page) => (dispatch) => {
    dispatch({
        type: SET_PAGINATION_PAGE,
        page,
    });
};

export const SET_USER_PROGRAM_RESOURCES = "SET_USER_PROGRAM_RESOURCES";
export const setUserProgramResources = (list) => (dispatch) => {
    dispatch({
        type: SET_USER_PROGRAM_RESOURCES,
        list,
    });
};

export const SET_PATIENT_ACTION_CARDS = "SET_PATIENT_ACTION_CARDS";
export const setPatientActionCards = (cards) => (dispatch) => {
    dispatch({
        type: SET_PATIENT_ACTION_CARDS,
        cards,
    });
};

export const SET_VIEWING_RESOURCE = "SET_VIEWING_RESOURCE";
export const setViewingResource = (resource) => (dispatch) => {
    dispatch({
        type: SET_VIEWING_RESOURCE,
        resource,
    });
};

export const SET_SELECTED_RESOURCE_LIBRARY = "SET_SELECTED_RESOURCE_LIBRARY";
export const setSelectedResourceLibrary = (library) => (dispatch) => {
    dispatch({
        type: SET_SELECTED_RESOURCE_LIBRARY,
        library,
    });
};

export const SET_VIEWING_PROGRAM_EXERCISE = "SET_VIEWING_PROGRAM_EXERCISE";
export const setViewingProgramExercise = (resource) => (dispatch) => {
    dispatch({
        type: SET_VIEWING_PROGRAM_EXERCISE,
        resource,
    });
};

export const UPDATE_CURRENT_LIST = "UPDATE_CURRENT_LIST";
export const updateCurrentList = (list) => (dispatch) => {
    dispatch({
        type: UPDATE_CURRENT_LIST,
        list,
    });
};
