import React, { useEffect, useState } from "react";
import { FormControlLabel, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import moment from "moment-timezone";
import { Formik } from "formik";
import * as yup from "yup";

import { EMAIL, REQUIRED_FIELD } from "../../utils/validations";
import { sendCheckboxtFieldChangedEvent, sendTextFieldFocusEvent } from "../../utils/analytics";
import { submitLeadContactForm, updateContactFormData } from "../../app/actions";
import { executeRecaptcha, getPublicImage } from "../../utils/helpers";

import Button from "../button/Button";
import ButtonLink from "../button/ButtonLink";
import FormError from "../form_error/FormError";
import Image from "../image/Image";
import Modal from "../modal/Modal";
import RecaptchaProvider from "../recaptcha_provider/RecaptchaProvider";
import { toast } from "../notifications/Toast";
import TSCheckbox from "../checkbox/Checkbox";

const emailId = "email";
const checkboxClassName = "p-0 text-xs";
const formControlClassName = "my-0 mx-2";

const schema = yup.object().shape({
    first_name: yup.string().required(REQUIRED_FIELD.message),
    last_name: yup.string().required(REQUIRED_FIELD.message),
    email: yup.string().required(REQUIRED_FIELD.message).email(EMAIL.message),
    contactByPhone: yup.boolean(),
    phone: yup.string().when("contactByPhone", {
        is: (value) => value === true,
        then: yup.string().required(REQUIRED_FIELD.message),
    }),
});

export default function ProviderContactForm({ show, provider, toggleShow, clinic }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const mfr = useSelector(({ mfr }) => mfr);
    const [clientName, setClientName] = useState("");
    const [header, setHeader] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [contactMethodLabel, setContactMethodLabel] = useState("");
    const isLoading = mfr && mfr.leadContactFormIsFetching;

    const initialFormValues = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        contactByEmail: true,
        contactByPhone: true,
        message: "",
        provider_id: "",
    };

    const [contactByEmail, setContactByEmail] = useState(initialFormValues.contactByEmail);
    const [contactByPhone, setContactByPhone] = useState(initialFormValues.contactByPhone);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        let headerText = provider
            ? `Contact ${provider.user.first_name} ${provider.user.last_name}`
            : clinic
            ? `Contact ${clinic.title}`
            : "Contact";

        let placeholderText = provider
            ? `Please provide a message for ${provider.user.first_name} ${provider.user.last_name}`
            : clinic
            ? `Please provide a message for ${clinic.title}`
            : "Please provide a message";

        let contactMethodLabelText = `How would you like ${
            provider ? provider.user.first_name : clinic ? clinic.title : ""
        }
        to contact you? *`;

        //  Hardcoded contact copy for John F. Barnes
        if (provider && provider.id === 198) {
            headerText = "Contact MFR/The Sanctuary";
            placeholderText = "Provide a message for MFR/The Sanctuary";
            contactMethodLabelText = "How would you like to be contacted?";
        } else if (provider && provider.id === 2) {
            headerText = "Contact Therapy On The Rocks";
            placeholderText = "Provide a message for Therapy On The Rocks";
            contactMethodLabelText = "How would you like to be contacted?";
        }

        setHeader(headerText);
        setPlaceholder(placeholderText);
        setContactMethodLabel(contactMethodLabelText);
    }, []); //eslint-disable-line

    const handleFormFocus = () => (event) => {
        const {
            target: { id },
        } = event;

        if (id) sendTextFieldFocusEvent(id);
    };

    const handleBlogRedirect = () => {
        window.open(
            "https://www.mfrtherapists.com/how-fascia-impacts-the-health-of-your-vagus-nerve/",
            "_blank",
            "noopener"
        );
        toggleShow();
    };

    const handlePhoneChange = (setFieldValue) => (value) => {
        // const phoneId = id && id.length > 0 && id.split(" ")[0];
        let formData = {
            phone: value,
        };

        dispatch(updateContactFormData(formData));
        setPhoneNumber(value && value.length > 0 ? value : "");
        setFieldValue("phone", value && value.length > 0 ? value : "");
    };

    const handleFormChange = (status, setStatus, setFieldValue) => (event) => {
        const {
            target: { value, id },
        } = event;
        const fieldId = (id && id.length > 0 && id.split(" ")[0]) || [];
        let formData = {
            [fieldId]: value.trim(),
        };

        if (status && Object.keys(status).includes(fieldId)) {
            setStatus(fieldId, null);
        }

        dispatch(updateContactFormData(formData));
        setFieldValue(fieldId, value);
    };

    const handleCheckboxChange = (value, setFieldValue) => () => {
        if (value === "email") {
            setContactByEmail(!contactByEmail);
            setFieldValue("contactByEmail", !contactByEmail);
            sendCheckboxtFieldChangedEvent("checkbox__contact_by_email", !contactByEmail);
        } else if (value === "phone") {
            setContactByPhone(!contactByPhone);
            setFieldValue("contactByPhone", !contactByPhone);
            sendCheckboxtFieldChangedEvent("checkbox__contact_by_phone", !contactByPhone);
        }
    };

    const handleFormSubmission = (values, actions, notifySuccess = false) => {
        executeRecaptcha("providersignup").then(async (recaptchaResponse) => {
            if (recaptchaResponse) {
                const provider_id = provider ? provider.id : clinic ? clinic.owner_info.profile_id : "";
                let leadState = "";
                let leadCity = "";

                if (mfr.leadLocation && mfr.leadLocation.state && mfr.leadLocation.city) {
                    leadState = mfr.leadLocation.state;
                    leadCity = mfr.leadLocation.city;
                } else if (clinic && clinic.state && clinic.city) {
                    leadCity = clinic.city;
                    leadState = clinic.state;
                } else if (provider) {
                    const providerClinic =
                        provider && provider.clinics && provider.clinics.length > 0
                            ? provider.clinics[0]
                            : provider.found_clinics && provider.found_clinics.length > 0 && provider.found_clinics[0];

                    leadCity = providerClinic && providerClinic.city;
                    leadState = providerClinic && providerClinic.state;
                }

                const body = {
                    email: values.email,
                    phone: phoneNumber,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    message: values.message,
                    state: leadState,
                    city: leadCity,
                    contact_type:
                        values.contactByEmail && values.contactByPhone
                            ? "By phone and email"
                            : values.contactByPhone
                            ? "By phone"
                            : values.contactByEmail
                            ? "By email"
                            : null,
                    provider_id,
                    timezone: moment.tz.guess(),
                    "g-recaptcha-response": recaptchaResponse,
                };

                const result = await dispatch(submitLeadContactForm(body));
                const { error } = result;

                if (error) {
                    if (
                        error.response &&
                        error.response.data &&
                        typeof error.response.data === "object" &&
                        Object.keys(error.response.data).length > 0
                    ) {
                        Object.keys(error.response.data).forEach((key) => {
                            toast.error(`${key}: ${error.response.data[key][0]}`);
                        });
                    } else {
                        toast.error("Error submitting form, please try again later");
                    }
                } else {
                    setClientName(values.first_name);
                    setShowSuccessMessage(true);
                }
            }
        });
    };

    return (
        <Modal
            show={show}
            toggleShow={toggleShow}
            content={
                <>
                    {showSuccessMessage ? (
                        <Stack
                            sx={{
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "300px",
                                p: 2,
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: "700 !important",
                                    fontSize: "1.5rem !important",
                                    color: theme.palette.grey[600],
                                }}
                            >
                                Thanks, {clientName}!
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: "400 !important",
                                    color: theme.palette.grey[600],
                                    textAlign: "center",
                                }}
                            >
                                Your message has been sent to{" "}
                                {provider ? provider.user && provider.user.first_name : clinic ? clinic.title : ""}, and
                                they should be responding soon.
                            </Typography>
                            <Stack
                                sx={{
                                    flexDirection: { xs: "column", lg: "row" },
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Image
                                    src={getPublicImage("lead_contact_confirmation.png")}
                                    alt="provider holding clipboard"
                                    className="mt-4"
                                />
                                <Typography
                                    variant="body1"
                                    sx={{ mt: "32px !important", textAlign: "center", color: theme.palette.grey[600] }}
                                >
                                    In the meantime, check out the MFR blog to learn more about myofascial release,
                                    questions you might have for your therapist, and what you might be able to do at
                                    home or on your own to help manage your condition and find relief.
                                </Typography>
                            </Stack>
                            <Stack sx={{ flexDirection: "row", mt: 4, mb: 2 }}>
                                <ButtonLink sx={{ color: theme.palette.grey[500], mr: 2 }} onClick={toggleShow}>
                                    Close
                                </ButtonLink>
                                <Button compact square secondary onClick={handleBlogRedirect}>
                                    Check our blog
                                </Button>
                            </Stack>
                        </Stack>
                    ) : (
                        <RecaptchaProvider>
                            <div className="py-4">
                                <Typography variant="h4">{header}</Typography>
                                <Stack>
                                    <Formik
                                        initialValues={{
                                            first_name: initialFormValues.first_name.replace(/"/g, ""),
                                            last_name: initialFormValues.last_name.replace(/"/g, ""),
                                            email: initialFormValues[emailId],
                                            phone: initialFormValues.phone,
                                            contactByEmail: initialFormValues.contactByEmail,
                                            contactByPhone: initialFormValues.contactByPhone,
                                            message: initialFormValues.message,
                                            provider_id: initialFormValues.provider_id,
                                        }}
                                        enableReinitialize={true}
                                        onSubmit={handleFormSubmission}
                                        validationSchema={schema}
                                    >
                                        {({
                                            handleChange,
                                            handleSubmit,
                                            setStatus,
                                            setFieldValue,
                                            setFieldError,
                                            isSubmitting,
                                            status,
                                            values,
                                            errors,
                                            touched,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Stack
                                                    direction={{ xs: "column", lg: "row" }}
                                                    spacing={{ xs: 2, lg: 3 }}
                                                    sx={{ my: { xs: 2, lg: 4 } }}
                                                >
                                                    <TextField
                                                        id="first_name form-field__mfr-contact-first-name"
                                                        name="first_name"
                                                        label="First Name"
                                                        size="small"
                                                        onFocus={handleFormFocus()}
                                                        onChange={handleFormChange(status, setStatus, setFieldValue)}
                                                        value={values.first_name}
                                                        error={errors.first_name && touched.first_name}
                                                        autoComplete="given-name"
                                                        variant="outlined"
                                                        required
                                                        sx={{ width: { xs: "100%", lg: "50%" } }}
                                                    />
                                                    <TextField
                                                        id="last_name form-field__mfr-contact-last-name"
                                                        name="last_name"
                                                        label="Last Name"
                                                        size="small"
                                                        onFocus={handleFormFocus()}
                                                        onChange={handleFormChange(status, setStatus, setFieldValue)}
                                                        value={values.last_name}
                                                        error={errors.last_name && touched.last_name}
                                                        autoComplete="family-name"
                                                        variant="outlined"
                                                        required
                                                        sx={{ width: { xs: "100%", lg: "50%" } }}
                                                    />
                                                </Stack>
                                                <Stack
                                                    direction={{ xs: "column", lg: "row" }}
                                                    spacing={2}
                                                    sx={{ my: { xs: 2, lg: 4 } }}
                                                >
                                                    <Stack sx={{ width: "100%" }}>
                                                        <TextField
                                                            id={`${emailId} form-field__mfr-contact-email`}
                                                            name={emailId}
                                                            type={emailId}
                                                            label="Email"
                                                            size="small"
                                                            onFocus={handleFormFocus()}
                                                            onChange={handleFormChange(
                                                                status,
                                                                setStatus,
                                                                setFieldValue
                                                            )}
                                                            value={values[emailId]}
                                                            error={errors[emailId] && touched[emailId]}
                                                            autoComplete={emailId}
                                                            variant="outlined"
                                                            required
                                                        />
                                                        <FormError
                                                            show={
                                                                errors && errors[emailId] && touched && touched[emailId]
                                                            }
                                                            message={errors && errors[emailId]}
                                                            noMargin
                                                        />
                                                    </Stack>
                                                    <Stack sx={{ width: "100%", paddingTop: "0px !important" }}>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                maxWidth: "42px",
                                                                left: "417px",
                                                                top: "211px",
                                                                backgroundColor: theme.palette.common.white,
                                                                padding: "0 4px",
                                                                fontSize: "0.75rem",
                                                                position: "absolute",
                                                                lineHeight: "1.4375em",
                                                                color: "#919EAB",
                                                            }}
                                                        >
                                                            Phone
                                                        </Typography>
                                                        <PhoneInput
                                                            international
                                                            // initialValueFormat="national"
                                                            defaultCountry="US"
                                                            countryCallingCodeEditable={false}
                                                            placeholder="Enter phone number"
                                                            initialValue
                                                            label="Phone"
                                                            value={phoneNumber}
                                                            onChange={handlePhoneChange(setFieldValue)}
                                                        />
                                                        {/* <MaskedInput
                                                            id="phone form-field__mfr-contact-phone"
                                                            name="phone"
                                                            label="Phone"
                                                            type="tel"
                                                            onFocus={handleFormFocus()}
                                                            onChange={(event) =>
                                                                handlePhoneNumberChange(event, setFieldValue)
                                                            }
                                                            value={values.phone}
                                                            error={errors.phone && touched.phone}
                                                            autoComplete="tel-local"
                                                            defaultValue=""
                                                            variant="outlined"
                                                            required
                                                            mask="(999) 999-9999"
                                                        >
                                                            {(inputProps) => (
                                                                <TextField {...inputProps} label="Phone" />
                                                            )}
                                                        </MaskedInput> */}
                                                        <FormError
                                                            show={errors && errors.phone && touched && touched.phone}
                                                            message={errors && errors.phone}
                                                            noMargin
                                                        />
                                                    </Stack>
                                                </Stack>
                                                <Stack sx={{ mb: 4, ml: 0.25 }}>
                                                    <Typography variant="h6" sx={{ color: theme.palette.grey[600] }}>
                                                        {contactMethodLabel}
                                                    </Typography>
                                                    <Stack direction="row">
                                                        <FormControlLabel
                                                            control={
                                                                <TSCheckbox
                                                                    checked={contactByEmail}
                                                                    onChange={handleCheckboxChange(
                                                                        "email",
                                                                        setFieldValue
                                                                    )}
                                                                    name="checkbox__email"
                                                                    className={checkboxClassName}
                                                                />
                                                            }
                                                            label={
                                                                <Typography variant="caption" className="my-1 ml-2">
                                                                    Email
                                                                </Typography>
                                                            }
                                                            className={formControlClassName}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <TSCheckbox
                                                                    checked={contactByPhone}
                                                                    onChange={handleCheckboxChange(
                                                                        "phone",
                                                                        setFieldValue
                                                                    )}
                                                                    name="checkbox__phone"
                                                                    className={checkboxClassName}
                                                                />
                                                            }
                                                            label={
                                                                <Typography variant="caption" className="my-1 ml-2">
                                                                    Phone
                                                                </Typography>
                                                            }
                                                            className={formControlClassName}
                                                        />
                                                    </Stack>
                                                </Stack>
                                                <TextField
                                                    id="message form-field__mfr-contact-message"
                                                    name="message"
                                                    label="Message"
                                                    placeholder={placeholder}
                                                    type="tel"
                                                    onFocus={handleFormFocus()}
                                                    onChange={handleFormChange(status, setStatus, setFieldValue)}
                                                    value={values.message}
                                                    error={errors.message}
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                    multiline
                                                    minRows={4}
                                                    maxRows={4}
                                                    sx={{ width: "100%" }}
                                                />
                                                <Stack direction="row" justifyContent="end" sx={{ mt: 3, mb: 1 }}>
                                                    <Button
                                                        compact
                                                        square
                                                        outlineSecondary
                                                        analyticsClass="button__mfr-contact-form-close"
                                                        onClick={toggleShow}
                                                    >
                                                        Close
                                                    </Button>
                                                    <Button
                                                        compact
                                                        square
                                                        secondary
                                                        className="ml-3"
                                                        disabled={isSubmitting || isLoading}
                                                        type="submit"
                                                        analyticsClass="button__mfr-contact-form-submit"
                                                    >
                                                        Send Message
                                                    </Button>
                                                </Stack>
                                            </form>
                                        )}
                                    </Formik>
                                </Stack>
                            </div>
                        </RecaptchaProvider>
                    )}
                </>
            }
        />
    );
}
