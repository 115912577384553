import { convertPainLocationOptions, storageAvailable } from "../../utils/helpers";
import moment from "moment-timezone";

import {
    // REQUEST_ALL_BRANDINGS,
    // RECEIVE_ALL_BRANDINGS,
    // RECEIVE_ALL_BRANDINGS_ERROR,
    // REQUEST_BRANDING,
    // RECEIVE_BRANDING,
    // RECEIVE_BRANDING_ERROR,
    REQUEST_BRANDING_TIERS,
    RECEIVE_BRANDING_TIERS,
    RECEIVE_BRANDING_TIERS_ERROR,
    REQUEST_CREATE_SUBSCRIPTION_PAYMENT_KEY,
    RECEIVE_CREATE_SUBSCRIPTION_PAYMENT_KEY,
    RECEIVE_CREATE_SUBSCRIPTION_PAYMENT_KEY_ERROR,
    REQUEST_CREATE_ONE_TIME_PAYMENT_KEY,
    RECEIVE_CREATE_ONE_TIME_PAYMENT_KEY,
    RECEIVE_CREATE_ONE_TIME_PAYMENT_KEY_ERROR,
    CLOSE_PROVIDER_PROFILE_BANNER,
    SHOW_PROVIDER_PROFILE_BANNER,
    REQUEST_ALL_PARENT_GROUPS,
    RECEIVE_ALL_PARENT_GROUPS,
    RECEIVE_ALL_PARENT_GROUPS_ERROR,
    REQUEST_DEFAULT_GROUP,
    RECEIVE_DEFAULT_GROUP,
    RECEIVE_DEFAULT_GROUP_ERROR,
} from "../actions";
import { getBrandingFromGroup, getSignupConfigFromGroup } from "../../utils/branding";
const brandingInitialState = {
    hasCompletedOnboarding: false,
    showProfileBanner: true,
    tiers: [],
    branding: {},
    signupConfig: {
        signup_enabled: false,
        signup_preregistered_enabled: false,
        coupon_code_required: false,
        idp_signin_url: false,
    },
};

export function groups(state = brandingInitialState, action) {
    switch (action.type) {
        // case REQUEST_ALL_BRANDINGS:
        //     return {
        //         ...state,
        //         isFetchingAllBrandings: true,
        //         allBrandingsRequestedAt: action.requestedAt,
        //     };
        // case RECEIVE_ALL_BRANDINGS:
        //     const allBrandings = action.data.sort((a, b) =>
        //         a.display_name.toLowerCase().localeCompare(b.display_name.toLowerCase())
        //     );

        //     return {
        //         ...state,
        //         isFetchingAllBrandings: false,
        //         allBrandingsReceivedAt: action.receivedAt,
        //         allBrandings,
        //     };
        // case RECEIVE_ALL_BRANDINGS_ERROR:
        //     return {
        //         ...state,
        //         isFetchingAllBrandings: false,
        //         allBrandingsReceivedAt: action.receivedAt,
        //         allBrandingsError: action.error,
        //     };
        case REQUEST_ALL_PARENT_GROUPS:
            return {
                ...state,
                isFetchingAllParentGroups: true,
                allParentGroupsRequestedAt: action.requestedAt,
            };
        case RECEIVE_ALL_PARENT_GROUPS:
            const sortedBrandings = action.data.sort((a, b) =>
                a.branding.display_name.toLowerCase().localeCompare(b.branding.display_name.toLowerCase())
            );
            const allBrandings = sortedBrandings.map((b) => b.branding);

            return {
                ...state,
                isFetchingAllParentGroups: false,
                allParentGroupsReceivedAt: action.receivedAt,
                allBrandings,
            };
        case RECEIVE_ALL_PARENT_GROUPS_ERROR:
            return {
                ...state,
                isFetchingAllParentGroups: false,
                allParentGroupsReceivedAt: action.receivedAt,
                allParentGroupsError: action.error,
            };
        case REQUEST_DEFAULT_GROUP:
            return {
                ...state,
                isFetchingDefaultGroup: true,
                defaultGroupRequestedAt: action.requestedAt,
            };
        case RECEIVE_DEFAULT_GROUP:
            const pain_locations = convertPainLocationOptions(action.data);

            return {
                ...state,
                isFetchingDefaultGroup: false,
                defaultGroupReceivedAt: action.receivedAt,
                group: action.data,
                branding: { ...getBrandingFromGroup(action.data), pain_locations },
                signupConfig: getSignupConfigFromGroup(action.data),
            };
        case RECEIVE_DEFAULT_GROUP_ERROR:
            return {
                ...state,
                isFetchingDefaultGroup: false,
                defaultGroupReceivedAt: action.receivedAt,
                groupError: action.error,
            };
        // case REQUEST_BRANDING:
        //     return {
        //         ...state,
        //         isFetching: true,
        //         requestedAt: action.requestedAt,
        //     };
        // case RECEIVE_BRANDING:
        //     return {
        //         ...state,
        //         ...action.data[0],
        //         isFetching: false,
        //         receivedAt: action.receivedAt,
        //     };
        // case RECEIVE_BRANDING_ERROR:
        //     return {
        //         ...state,
        //         isFetching: false,
        //         receivedAt: action.receivedAt,
        //         error: action.error,
        //     };
        case REQUEST_BRANDING_TIERS:
            return {
                ...state,
                brandingTiersIsFetching: true,
                brandingTiersRequestedAt: action.requestedAt,
            };
        case RECEIVE_BRANDING_TIERS: {
            const availableTiers = action.data.filter(
                (tier) => tier && tier.provider_prices && tier.provider_prices.length > 0
            );
            const tiers = [
                // {
                //     id: 1,
                //     features: [...commonTierFeatures],
                //     additionalFeatures: [],
                //     title: "Plan builder",
                //     subtitle: "Expand your practice to include delivering home therapy plans to your clients",
                //     option_id: "plan_builder",
                // },
                {
                    id: 2,
                    features: [
                        "Care Plan Builder",
                        "Orbit Resource Library",
                        "HIPAA compliant chat",
                        "Video Telehealth",
                        "SOAP Notes",
                    ],
                    title: "Virtual clinic",
                    subtitle:
                        "Take your clinic to the next level with personalized care plans, unlimited patients and new revenue opportunities.",
                    option_id: "virtual_clinic",
                },
            ];

            const combinedTiersInfo = tiers.map((tier) => {
                const validTier = availableTiers.find((t) => tier.option_id === t.option_id);

                if (validTier) {
                    tier.provider_prices =
                        validTier.provider_prices && validTier.provider_prices.length > 0 && validTier.provider_prices;

                    tier.price =
                        validTier.provider_prices &&
                        validTier.provider_prices.length > 0 &&
                        validTier.provider_prices[0].stripe_price &&
                        validTier.provider_prices[0].stripe_price.unit_amount;

                    tier.interval =
                        validTier.provider_prices &&
                        validTier.provider_prices.length > 0 &&
                        validTier.provider_prices[0].stripe_price &&
                        validTier.provider_prices[0].stripe_price.recurring &&
                        validTier.provider_prices[0].stripe_price.recurring.interval;
                }

                return tier;
            });

            return {
                ...state,
                brandingTiersIsFetching: false,
                brandingTiersReceivedAt: action.receivedAt,
                tiers: combinedTiersInfo,
            };
        }
        case RECEIVE_BRANDING_TIERS_ERROR:
            return {
                ...state,
                brandingTiersIsFetching: false,
                brandingTiersReceivedAt: action.receivedAt,
                brandingTiersError: action.error,
            };
        case REQUEST_CREATE_SUBSCRIPTION_PAYMENT_KEY:
            return {
                ...state,
                createSubscriptionPaymentKeyIsFetching: true,
                createSubscriptionPaymentKeyRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_SUBSCRIPTION_PAYMENT_KEY:
            return {
                ...state,
                createSubscriptionPaymentKeyIsFetching: false,
                createSubscriptionPaymentKeyReceivedAt: action.receivedAt,
            };
        case RECEIVE_CREATE_SUBSCRIPTION_PAYMENT_KEY_ERROR:
            return {
                ...state,
                createSubscriptionPaymentKeyIsFetching: false,
                createSubscriptionPaymentKeyReceivedAt: action.receivedAt,
                createSubscriptionPaymentKeyError: action.error,
            };
        case REQUEST_CREATE_ONE_TIME_PAYMENT_KEY:
            return {
                ...state,
                createOneTimePaymentKeyIsFetching: true,
                createOneTimePaymentKeyRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_ONE_TIME_PAYMENT_KEY:
            return {
                ...state,
                createOneTimePaymentKeyIsFetching: false,
                createOneTimePaymentKeyReceivedAt: action.receivedAt,
            };
        case RECEIVE_CREATE_ONE_TIME_PAYMENT_KEY_ERROR:
            return {
                ...state,
                createOneTimePaymentKeyIsFetching: false,
                createOneTimePaymentKeyReceivedAt: action.receivedAt,
                createOneTimePaymentKeyError: action.error,
            };
        case SHOW_PROVIDER_PROFILE_BANNER:
            return {
                ...state,
                showProfileBanner: true,
            };
        case CLOSE_PROVIDER_PROFILE_BANNER: {
            const storageIsAvailable = storageAvailable("localStorage");
            if (storageIsAvailable) localStorage.setItem("X_LAST_DISMISS_PROFILE_BANNER", moment().format());

            return {
                ...state,
                showProfileBanner: false,
            };
        }
        default:
            return state;
    }
}
