import {
    articleToResource,
    exerciseToResource,
    processResource,
    EXERCISES_LIST_ITEM_ID,
    FAVORITES_LIST_ITEM_ID,
    RECOMMENDED_LIST_ITEM_ID,
    PROGRAM_TAGS,
    reorderTagList,
} from "../../utils/resources";
import {
    REQUEST_ADD_FAVORITE_RESOURCE,
    RECEIVE_ADD_FAVORITE_RESOURCE,
    RECEIVE_ADD_FAVORITE_RESOURCE_ERROR,
    REQUEST_ALL_TAGS,
    RECEIVE_ALL_TAGS,
    RECEIVE_ALL_TAGS_ERROR,
    REQUEST_TAGS_BY_TYPE,
    RECEIVE_TAGS_BY_TYPE,
    RECEIVE_TAGS_BY_TYPE_ERROR,
    REQUEST_ARTICLES,
    RECEIVE_ARTICLES,
    RECEIVE_ARTICLES_ERROR,
    REQUEST_ASSIGN_PATIENT_RESOURCE,
    RECEIVE_ASSIGN_PATIENT_RESOURCE,
    RECEIVE_ASSIGN_PATIENT_RESOURCE_ERROR,
    REQUEST_RESOURCES,
    RECEIVE_RESOURCES,
    RECEIVE_RESOURCES_ERROR,
    REQUEST_EXERCISES,
    RECEIVE_EXERCISES,
    RECEIVE_EXERCISES_ERROR,
    REQUEST_RESOURCE_SEARCH,
    RECEIVE_RESOURCE_SEARCH,
    RECEIVE_RESOURCE_SEARCH_ERROR,
    REQUEST_LIST,
    RECEIVE_LIST,
    RECEIVE_LIST_ERROR,
    REQUEST_REMOVE_FAVORITE_RESOURCE,
    RECEIVE_REMOVE_FAVORITE_RESOURCE,
    RECEIVE_REMOVE_FAVORITE_RESOURCE_ERROR,
    REQUEST_RESOURCES_LIST,
    RECEIVE_RESOURCES_LIST,
    RECEIVE_RESOURCES_LIST_ERROR,
    REQUEST_UNASSIGN_PATIENT_RESOURCE,
    RECEIVE_UNASSIGN_PATIENT_RESOURCE,
    RECEIVE_UNASSIGN_PATIENT_RESOURCE_ERROR,
    REQUEST_UPDATE_RESOURCE,
    RECEIVE_UPDATE_RESOURCE,
    RECEIVE_UPDATE_RESOURCE_ERROR,
    SET_ASSIGNED_RESOURCES,
    SET_FAVORITED_RESOURCES,
    SET_PAGINATION_PAGE,
    SET_VIEWING_PROGRAM_EXERCISE,
    SET_VIEWING_RESOURCE,
    SET_SELECTED_RESOURCE_LIBRARY,
    SET_USER_PROGRAM_RESOURCES,
    SET_PATIENT_ACTION_CARDS,
    UPDATE_CURRENT_LIST,
    REQUEST_LIST_INFO,
    RECEIVE_LIST_INFO,
    RECEIVE_LIST_INFO_ERROR,
    RECEIVE_PROGRAMS,
} from "../actions";

const initialState = {
    resourcesList: [],
    resourcesLists: [],
    searchResults: [],
    hasViewedResource: false,
    userProgramResourcesList: [],
    assignedResourcesList: [],
    favoritedResourcesList: [],
    editingList: [],
    selectedResourcesList: [],
    selectedImportLists: [],
    activeList: EXERCISES_LIST_ITEM_ID,
    assignedResourcesId: null,
    paginationPage: 1,
    favoritedResourcesId: null,
    viewingProgramResources: [],
    selectedResourceLibrary: 0,
};

export function resources(state = initialState, action) {
    switch (action.type) {
        case REQUEST_ADD_FAVORITE_RESOURCE:
            return {
                ...state,
                addFavoriteResourceIsLoading: true,
                addFavoriteResourceRequestedAt: action.requestedAt,
            };
        case RECEIVE_ADD_FAVORITE_RESOURCE: {
            const { favoritedResourcesList: currentFavoritedResourcesList } = { ...state };
            const { data: favoritedResource, resource } = action;

            const combinedResource = {
                ...favoritedResource,
                ...resource,
                id: favoritedResource.id,
            };

            currentFavoritedResourcesList.unshift(combinedResource);

            const returnState = {
                ...state,
                addFavoriteResourceIsLoading: false,
                addFavoriteResourceReceivedAt: action.receivedAt,
                favoritedResourcesList: currentFavoritedResourcesList,
            };

            if (state.activeList === FAVORITES_LIST_ITEM_ID) {
                returnState.resourcesList = currentFavoritedResourcesList;
            }

            if (state.viewingResource) {
                returnState.viewingResource = combinedResource;
            }

            if (state.viewingProgramExercise) {
                returnState.viewingProgramExercise = combinedResource;
            }

            return returnState;
        }
        case RECEIVE_ADD_FAVORITE_RESOURCE_ERROR:
            return {
                ...state,
                addFavoriteResourceIsLoading: false,
                addFavoriteResourceReceivedAt: action.receivedAt,
                addFavoriteResourceError: action.error,
            };
        case REQUEST_ALL_TAGS:
            return {
                ...state,
                allTagsIsLoading: true,
                allTagsRequestedAt: action.requestedAt,
            };
        case RECEIVE_ALL_TAGS: {
            const tags = JSON.parse(JSON.stringify(action.data));
            const groupedTags = Object.values(
                tags.reduce((acc, item) => {
                    if (!acc) {
                        acc = {};
                        acc[item.type] = [...(acc[item.type] || []), item];
                        return acc;
                    }

                    acc[item.type] = [...(acc[item.type] || []), item];

                    return acc;
                }, 0)
            );

            const filteredTags = groupedTags.filter((tag) => !PROGRAM_TAGS.includes(tag[0].type));

            const orderedGroupTags = filteredTags
                .map((group) => {
                    return reorderTagList(group);
                })
                .sort((a, b) => {
                    const titleA = a.title.toLowerCase();
                    const titleB = b.title.toLowerCase();

                    return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
                });

            return {
                ...state,
                allTagsIsLoading: false,
                allTagsReceivedAt: action.receivedAt,
                allTags: orderedGroupTags,
                tags,
            };
        }
        case RECEIVE_ALL_TAGS_ERROR:
            return {
                ...state,
                allTagsIsLoading: false,
                allTagsReceivedAt: action.receivedAt,
                allTagsError: action.error,
            };
        case REQUEST_TAGS_BY_TYPE: {
            return {
                ...state,
                tagsByTypeIsLoading: true,
                tagsByTypeRequestedAt: action.requestedAt,
            };
        }
        case RECEIVE_TAGS_BY_TYPE: {
            return {
                ...state,
                tagsByTypeIsLoading: false,
                tagsByTypeReceivedAt: action.receivedAt,
                [action.tagType]: action.data,
            };
        }
        case RECEIVE_TAGS_BY_TYPE_ERROR:
            return {
                ...state,
                tagsByTypeIsLoading: false,
                tagsByTypeReceivedAt: action.receivedAt,
                tagsByTypeError: action.error,
            };
        case REQUEST_ARTICLES:
            return {
                ...state,
                articlesListIsLoading: true,
                articlesListRequestedAt: action.requestedAt,
            };
        case RECEIVE_ARTICLES: {
            const {
                data: { results: newArticles, count, next, previous },
            } = action;

            const articles = newArticles ? newArticles.map((article) => articleToResource(article)) : [];

            return {
                ...state,
                articlesListIsLoading: false,
                articlesListReceivedAt: action.receivedAt,
                resourcesList: articles,
                searchResults: articles,
                count,
                next,
                previous,
            };
        }
        case RECEIVE_ARTICLES_ERROR:
            return {
                ...state,
                articlesListIsLoading: false,
                articlesListReceivedAt: action.receivedAt,
                articlesListError: action.error,
            };
        case REQUEST_ASSIGN_PATIENT_RESOURCE:
            return {
                ...state,
                assignPatientResourceIsLoading: true,
                assignPatientResourceRequestedAt: action.requestedAt,
            };
        case RECEIVE_ASSIGN_PATIENT_RESOURCE: {
            const { assignedResourcesList: currentAssignedResourcesList } = { ...state };
            const { data: assignedResource, resource } = action;

            const combinedResource = {
                ...assignedResource,
                ...resource,
                id: assignedResource.id,
            };

            currentAssignedResourcesList.unshift(combinedResource);

            return {
                ...state,
                assignPatientResourceIsLoading: false,
                assignPatientResourceReceivedAt: action.receivedAt,
                assignedResourcesList: currentAssignedResourcesList,
            };
        }
        case RECEIVE_ASSIGN_PATIENT_RESOURCE_ERROR:
            return {
                ...state,
                assignPatientResourceIsLoading: false,
                assignPatientResourceReceivedAt: action.receivedAt,
                assignPatientResourceError: action.error,
            };
        case RECEIVE_PROGRAMS: {
            const {
                data: { count, next, results: programs },
            } = action;

            const programsList =
                programs &&
                programs.length > 0 &&
                programs.map((program) => {
                    const programResources =
                        program &&
                        program.resources_list &&
                        program.resources_list.resources &&
                        program.resources_list.resources.length > 0 &&
                        program.resources_list.resources.map((resource) => processResource(resource));

                    return {
                        ...program,
                        resources_list: { ...program.resources_list, resources: programResources },
                    };
                });

            return {
                ...state,
                programs: programsList,
                count,
                next,
            };
        }
        case REQUEST_RESOURCES:
            return {
                ...state,
                getResourcesIsLoading: true,
                getResourcesRequestedAt: action.requestedAt,
            };
        case RECEIVE_RESOURCES: {
            const {
                data: { results: resultResources, count, next, previous },
            } = action;
            const exercises = resultResources ? resultResources.map((exercise) => exerciseToResource(exercise)) : [];

            return {
                ...state,
                getResourcesIsLoading: false,
                getResourcesReceivedAt: action.receivedAt,
                resourcesList: exercises,
                count,
                next,
                previous,
            };
        }
        case RECEIVE_RESOURCES_ERROR:
            return {
                ...state,
                getResourcesIsLoading: false,
                getResourcesReceivedAt: action.receivedAt,
                getResourcesError: action.error,
            };
        case REQUEST_EXERCISES:
            return {
                ...state,
                exercisesListIsLoading: true,
                exercisesListRequestedAt: action.requestedAt,
            };
        case RECEIVE_EXERCISES: {
            const {
                data: { results: currentExercises, count, next, previous },
            } = action;
            const exercises = currentExercises ? currentExercises.map((exercise) => exerciseToResource(exercise)) : [];

            return {
                ...state,
                exercisesListIsLoading: false,
                exercisesListReceivedAt: action.receivedAt,
                resourcesList: exercises,
                count,
                next,
                previous,
            };
        }
        case RECEIVE_EXERCISES_ERROR:
            return {
                ...state,
                exercisesListIsLoading: false,
                exercisesListReceivedAt: action.receivedAt,
                exercisesListError: action.error,
            };
        case REQUEST_RESOURCE_SEARCH: {
            return {
                ...state,
                resourceSearchIsLoading: true,
                resourceSearchReceivedAt: action.requestedAt,
            };
        }
        case RECEIVE_RESOURCE_SEARCH: {
            const {
                data,
                data: { count, next, previous, results },
            } = action;
            let searchResults = [];

            if (count) {
                searchResults =
                    results && results.length > 0 ? results.map((result) => exerciseToResource(result)) : [];
            } else {
                searchResults = data && data.length > 0 ? data.map((result) => exerciseToResource(result)) : [];
            }

            return {
                ...state,
                resourceSearchIsLoading: false,
                resourceSearchReceivedAt: action.receivedAt,
                searchResults,
                count,
                next,
                previous,
            };
        }
        case RECEIVE_RESOURCE_SEARCH_ERROR:
            return {
                ...state,
                resourceSearchIsLoading: false,
                resourceSearchReceivedAt: action.receivedAt,
                resourceSearchError: action.error,
            };
        case REQUEST_REMOVE_FAVORITE_RESOURCE:
            return {
                ...state,
                removeFavoriteResourceIsLoading: true,
                removeFavoriteResourceRequestedAt: action.requestedAt,
            };
        case RECEIVE_REMOVE_FAVORITE_RESOURCE: {
            const { resourceId } = action;
            const { favoritedResourcesList: currentFavoritedResourcesList, resourcesList: currentResourcesList } = {
                ...state,
            };

            const filteredFavoritedResources =
                currentFavoritedResourcesList &&
                currentFavoritedResourcesList.length > 0 &&
                currentFavoritedResourcesList.filter((favoritedResource) => favoritedResource.id !== resourceId);

            let filteredResources = [...currentResourcesList];
            if (state.activeList === FAVORITES_LIST_ITEM_ID) {
                filteredResources = currentResourcesList.filter((resource) => resource.id !== resourceId);
            }

            return {
                ...state,
                removeFavoriteResourceIsLoading: false,
                removeFavoriteResourceReceivedAt: action.receivedAt,
                favoritedResourcesList: filteredFavoritedResources,
                resourcesList: filteredResources,
            };
        }
        case RECEIVE_REMOVE_FAVORITE_RESOURCE_ERROR:
            return {
                ...state,
                removeFavoriteResourceIsLoading: false,
                removeFavoriteResourceReceivedAt: action.receivedAt,
                removeFavoriteResourceError: action.error,
            };
        case REQUEST_LIST:
            return {
                ...state,
                listIsFetching: true,
                listRequestedAt: action.requestedAt,
            };
        case RECEIVE_LIST:
            return {
                ...state,
                listIsFetching: false,
                listReceivedAt: action.receivedAt,
            };
        case RECEIVE_LIST_ERROR:
            return {
                ...state,
                listIsFetching: false,
                listReceivedAt: action.receivedAt,
            };
        case REQUEST_LIST_INFO:
            return {
                ...state,
                listInfoIsFetching: true,
                listInfoRequestedAt: action.requestedAt,
            };
        case RECEIVE_LIST_INFO:
            return {
                ...state,
                listInfoIsFetching: false,
                listInfoReceivedAt: action.receivedAt,
                listInfo: action.data,
            };
        case RECEIVE_LIST_INFO_ERROR:
            return {
                ...state,
                listInfoIsFetching: false,
                listInfoReceivedAt: action.receivedAt,
                listInfoError: action.error,
            };
        case REQUEST_RESOURCES_LIST:
            return {
                ...state,
                resourcesListIsFetching: true,
                resourcesListRequestedAt: action.requestedAt,
            };
        case RECEIVE_RESOURCES_LIST: {
            const { assignedResourcesId, favoritedResourcesId } = state;
            const {
                data: resources,
                data: { id: resourceListId },
                list: requestListId,
                receivedAt: resourcesListReceivedAt,
            } = action;

            const processedResourcesList = resources.length
                ? resources.map((resource) => processResource(resource))
                : [];

            const returnState = {
                ...state,
                resourcesListIsFetching: false,
                resourcesListReceivedAt,
                resourcesList: processedResourcesList,
            };

            if (resourceListId === assignedResourcesId || (!resourceListId && requestListId === assignedResourcesId)) {
                returnState.assignedResourcesList = processedResourcesList;
            } else if (
                resourceListId === favoritedResourcesId ||
                (!resourceListId && requestListId === favoritedResourcesId)
            ) {
                returnState.favoritedResourcesList = processedResourcesList;
            }

            return returnState;
        }
        case RECEIVE_RESOURCES_LIST_ERROR: {
            const { receivedAt: resourcesListReceivedAt, error: resourcesListError } = action;

            return {
                ...state,
                resourcesListIsFetching: false,
                resourcesListReceivedAt,
                resourcesListError,
            };
        }
        case REQUEST_UNASSIGN_PATIENT_RESOURCE:
            return {
                ...state,
                unassignPatientResourceIsLoading: true,
                unassignPatientResourceRequestedAt: action.requestedAt,
            };
        case RECEIVE_UNASSIGN_PATIENT_RESOURCE: {
            const { resourceId } = action;
            const { assignedResourcesList: currentAssignedResourcesList, resourcesList: currentResourcesList } = {
                ...state,
            };
            const assignedIndex = currentAssignedResourcesList.findIndex(
                (assignedResource) => assignedResource.id === resourceId
            );
            let filteredResources = [...currentResourcesList];
            currentAssignedResourcesList.splice(assignedIndex, 1);

            if (state.activeList === RECOMMENDED_LIST_ITEM_ID) {
                filteredResources = currentResourcesList.filter((resource) => resource.id !== resourceId);
            }

            return {
                ...state,
                unassignPatientResourceIsLoading: false,
                unassignPatientResourceReceivedAt: action.receivedAt,
                assignedResourcesList: currentAssignedResourcesList,
                resourcesList: filteredResources,
            };
        }
        case RECEIVE_UNASSIGN_PATIENT_RESOURCE_ERROR:
            return {
                ...state,
                unassignPatientResourceIsLoading: false,
                unassignPatientResourceReceivedAt: action.receivedAt,
                unassignPatientResourceError: action.error,
            };
        case REQUEST_UPDATE_RESOURCE:
            return {
                ...state,
                updateResourceIsLoading: true,
                updateResourceRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_RESOURCE: {
            return {
                ...state,
                updateResourceIsLoading: false,
                updateResourceReceivedAt: action.receivedAt,
            };
        }
        case RECEIVE_UPDATE_RESOURCE_ERROR:
            return {
                ...state,
                updateResourceIsLoading: false,
                updateResourceReceivedAt: action.receivedAt,
                updateResourceError: action.error,
            };
        case SET_ASSIGNED_RESOURCES: {
            const assignedResources =
                action.list && action.list.resources
                    ? action.list.resources.map((resource) => processResource(resource))
                    : [];

            return {
                ...state,
                assignedResourcesId: action.list.id,
                assignedResourcesList: assignedResources,
                resourcesList: assignedResources,
            };
        }
        case SET_FAVORITED_RESOURCES: {
            const { list } = action;
            const favoritedResources =
                list && list.resources ? list.resources.map((resource) => processResource(resource)) : [];

            const returnState = {
                ...state,
                favoritedResourcesId: list && list.id,
                favoritedResourcesList: favoritedResources,
            };

            if (state.activeList === FAVORITES_LIST_ITEM_ID) {
                returnState.resourcesList = favoritedResources;
            }

            return returnState;
        }
        case SET_PAGINATION_PAGE:
            return {
                ...state,
                paginationPage: action.page,
            };
        case SET_VIEWING_PROGRAM_EXERCISE: {
            const { resource: viewingProgramExercise } = action;

            return {
                ...state,
                viewingProgramExercise,
            };
        }
        case SET_VIEWING_RESOURCE: {
            const { resource: viewingResource } = action;

            return {
                ...state,
                viewingResource,
                hasViewedResource: true,
            };
        }
        case SET_SELECTED_RESOURCE_LIBRARY: {
            return {
                ...state,
                selectedResourceLibrary: action.library,
            };
        }
        case SET_USER_PROGRAM_RESOURCES: {
            const processedProgramList = action.list ? action.list.map((resource) => processResource(resource)) : [];

            return {
                ...state,
                userProgramIsLoading: false,
                userProgramReceivedAt: action.receivedAt,
                userProgramResourcesList: processedProgramList,
            };
        }
        case SET_PATIENT_ACTION_CARDS:
            return {
                ...state,
                patientActionCards: action.cards,
            };
        case UPDATE_CURRENT_LIST:
            return {
                ...state,
                activeList: action.list,
            };
        default:
            return state;
    }
}
