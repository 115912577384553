import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import OnDemandSound from "./res/OnDemand.wav";
import { updateCoachBusyStatus, videoSession } from "../../app/actions";

import Button from "../button/Button";
import { toast } from "../notifications/Toast";

export default function OnDemandNotification(data) {
    const audioPlayer = new Audio(OnDemandSound);
    const dispatch = useDispatch();

    useEffect(() => {
        function init() {
            // play sound with looping enabled
            audioPlayer.loop = true;
            audioPlayer.play();
        }

        init();

        return () => {
            // stop playing sound
            audioPlayer.pause();
        };
    });

    const dismissToast = () => {
        toast.dismiss();
    };

    const handleAcceptClick = () => {
        dispatch(videoSession(data, false));
        dispatch(updateCoachBusyStatus(true));
        dismissToast();
    };

    return (
        <div>
            <h3>Incoming Call</h3>
            <div className="mb-4">From: {data.user_name}</div>
            <div>
                <Button square className="bg-green text-white" onClick={handleAcceptClick}>
                    Accept
                </Button>
                <Button square className="bg-red text-white ml-2" onClick={dismissToast}>
                    Decline
                </Button>
            </div>
        </div>
    );
}
