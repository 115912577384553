import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";

import LoginLayout from "./LoginLayout";
import TextInput from "../text_input/TextInput";
import { toast } from "../notifications/Toast";
import Link from "../link/Link";

import { EMAIL, REQUIRED_FIELD } from "../../utils/validations";
import { logMeIn } from "../../app/actions";
import { FORGOT_PASSWORD_ROUTE, SIGNUP_ROUTE } from "../../utils/routes";
import { executeRecaptcha, getSystemTimezone } from "../../utils/helpers";
import Button from "../button/Button";

const schema = yup.object().shape({
    email: yup.string().required(REQUIRED_FIELD.message).email(EMAIL.message),
    password: yup.string().required(REQUIRED_FIELD.message),
});

function Login({ groups, dispatch }) {
    const {
        defaultGroupReceivedAt,
        signupConfig: { signup_enabled },
    } = groups;
    const [isLoading, setIsLoading] = useState(false);
    const mfrDomain =
        window.location.hostname.includes("mfrtherapists.com") || window.location.hostname.includes("mfrhealth.com");

    const handleLogin = (values, notifySuccess = false) => {
        const { email, password } = values;

        setIsLoading(true);

        executeRecaptcha("login")
            .then(async (recaptchaResponse) => {
                const params = {
                    email,
                    password,
                    timezone: getSystemTimezone(),
                    "g-recaptcha-response": recaptchaResponse,
                };
                const result = await dispatch(logMeIn(params));
                const { error, data } = result;

                if (error || !data) {
                    const errorMessage = (error.response && error.response.data) || error.message || "Error logging in";
                    setIsLoading(false);
                    toast.error(errorMessage);
                }
            })
            .catch((error) => {
                console.log("Error executing Recaptcha:", error.toString());
                setIsLoading(false);
                toast.error(error);
            });
    };

    return (
        <>
            {defaultGroupReceivedAt && (
                <LoginLayout>
                    <div className="ts-login">
                        <div className="ts-login__content">
                            <Formik
                                initialValues={{ email: "", password: "" }}
                                onSubmit={handleLogin}
                                validationSchema={schema}
                            >
                                {({ errors, values, handleChange, handleSubmit, touched }) => (
                                    <form onSubmit={handleSubmit}>
                                        <h1 className="mb-3 text-xl">
                                            {mfrDomain ? "Myofascial Release Therapist Login" : "Login"}
                                        </h1>

                                        <div className="form-row mb-3">
                                            <div className="col">
                                                <TextInput
                                                    id="email"
                                                    name="email"
                                                    label="Email"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    type="email"
                                                    error={errors.email}
                                                    touched={touched.email}
                                                    autoComplete="email"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col">
                                                <TextInput
                                                    id="password"
                                                    name="password"
                                                    label="Password"
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    type="password"
                                                    error={errors.password}
                                                    touched={touched.password}
                                                    autoComplete="current-password"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row mb-4">
                                            <div className="col">
                                                <Link
                                                    analyticsClass="button__forgot-password"
                                                    to={FORGOT_PASSWORD_ROUTE}
                                                >
                                                    I forgot my password
                                                </Link>
                                            </div>
                                            {signup_enabled && (
                                                <div className="col">
                                                    <Link analyticsClass="button__patient-signup" to={SIGNUP_ROUTE}>
                                                        Sign Up
                                                    </Link>
                                                </div>
                                            )}
                                        </div>

                                        <div className="form-row">
                                            <div className="col text-center">
                                                <Button
                                                    square
                                                    secondary
                                                    analyticsClass="button__log-in"
                                                    type="submit"
                                                    disabled={isLoading}
                                                >
                                                    Log In
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </LoginLayout>
            )}
        </>
    );
}

const mapStateToProps = ({ groups }) => ({ groups });

export default connect(mapStateToProps)(Login);
