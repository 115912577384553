import React, { useState } from "react";
import TextInput from "../text_input/TextInput";
import Button from "../button/Button";

export default function PasswordField(props) {
    const [showPasswordRules, setShowPasswordRules] = useState(false);

    return (
        // Used instead of an adornment so that keyboard tabbing focuses the icon before the text field
        <div className="ts-password-field">
            <div className="rules-button-wrapper">
                <Button
                    square
                    link
                    analyticsClass="button__show-password-rules"
                    type="button"
                    onClick={() => setShowPasswordRules(!showPasswordRules)}
                >
                    <i className="help-icon far fa-question-circle text-main-dark"></i>
                </Button>
            </div>
            <TextInput {...props} autoComplete="off" />
            {showPasswordRules && (
                <div className="alert alert-info mt-1 mb-0">
                    Password must have:
                    <ul className="m-0">
                        <li>Minimum length of 8 characters</li>
                        <li>1 uppercase letter</li>
                        <li>1 lowercase letter</li>
                        <li>1 number</li>
                    </ul>
                </div>
            )}
        </div>
    );
}
