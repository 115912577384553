import React from "react";
import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getPublicImage } from "../../utils/helpers";
import { forwardRef } from "react";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const Page = forwardRef(
    (
        {
            children,
            mfr = false,
            mfrProvider = false,
            title = "",
            ogTitle = "",
            siteName = "orbitelehealth.com",
            meta,
            ...other
        },
        ref
    ) => (
        <>
            <HelmetProvider>
                <Helmet>
                    <link rel="canonical" href={window.location} />
                    <title>{title}</title>
                    {mfr && <link type="image/png" rel="icon" href={getPublicImage("mfr_favicon.png")} />}
                    {ogTitle && ogTitle.length > 0 && <meta property="og:title" content={ogTitle} />}
                    {siteName && siteName.length > 0 && <meta property="og:site_name" content="siteName" />}
                    {meta &&
                        meta.length > 0 &&
                        meta.map((m, idx) => <meta key={idx} property={m.property} content={m.content} />)}
                </Helmet>
            </HelmetProvider>

            <Box ref={ref} {...other} className="h-100">
                {children}
            </Box>
        </>
    )
);

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
};

export default Page;
