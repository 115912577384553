// ----------------------------------------------------------------------

export default function Card(theme) {
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    position: "relative",
                    boxShadow: "0 13px 27px -7px rgba(200, 200, 200, 0.25), 0 6px 12px -6px rgba(0, 0, 0, 0.3)",
                    borderRadius: Number(theme.shape.borderRadius) * 2,
                    border: `1px solid ${theme.palette.grey[300]}`,
                    // zIndex: 0, // Fix Safari overflow: hidden with border radius
                },
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: { variant: "h6" },
                subheaderTypographyProps: { variant: "body2", marginTop: theme.spacing(0.5) },
            },
            styleOverrides: {
                root: {
                    padding: theme.spacing(3, 3, 0),
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(3),
                },
            },
        },
    };
}
