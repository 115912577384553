import React, { useEffect } from "react";
import cx from "classnames";

import { Stack, Typography, useTheme } from "@mui/material";

import { patientAppClassName } from "../../utils/helpers";

const NO_SCROLL_CLASS_NAME = "no-scroll";

const Modal = ({
    actions,
    className,
    closeAnalyticsClass,
    content,
    fullscreen,
    heading,
    hideClose = false,
    modalClassName,
    narrow = false,
    show,
    size = "md",
    toggleShow,
    top = false,
}) => {
    const theme = useTheme();
    useEffect(() => {
        const patientAppEle = document.querySelector(`.${patientAppClassName}`);
        const removeNoScroll = () => {
            document.body.classList.remove(NO_SCROLL_CLASS_NAME);

            if (patientAppEle) patientAppEle.classList.remove(NO_SCROLL_CLASS_NAME);
        };

        function init() {
            if (show) {
                document.body.classList.add(NO_SCROLL_CLASS_NAME);

                if (patientAppEle) patientAppEle.classList.add(NO_SCROLL_CLASS_NAME);
            } else {
                removeNoScroll();
            }
        }

        init();

        return () => {
            removeNoScroll();
        };
    }, [show]);

    return (
        show && (
            <div className={cx("ts-modal", className, show && "show", top && "top")}>
                <div className="ts-modal__overlay" onClick={toggleShow} />
                <div
                    className={cx(
                        "ts-modal__wrapper",
                        modalClassName,
                        fullscreen && "fullscreen",
                        !fullscreen && "container",
                        !fullscreen && size !== undefined && size
                    )}
                >
                    <div>
                        <Stack sx={{ flexDirection: "row", justifyContent: "end", px: 3, pt: 3 }}>
                            {!hideClose && (
                                <div
                                    data-button={closeAnalyticsClass}
                                    className="close-button hover-pointer"
                                    onClick={toggleShow}
                                >
                                    <i data-button={closeAnalyticsClass} className="fas fa-times fa-lg" />
                                </div>
                            )}
                        </Stack>
                        <Stack direction="row" justifyContent="center" alignItems="center">
                            {heading && (
                                <div className="heading-wrapper">
                                    <Typography
                                        variant="h4"
                                        sx={{ color: theme.palette.grey[600] }}
                                        className="heading text-bold"
                                    >
                                        {heading}
                                    </Typography>
                                </div>
                            )}
                        </Stack>
                        <div className={cx("content", narrow && "narrow")}>{content}</div>
                        {actions && <div className="actions">{actions}</div>}
                    </div>
                </div>
            </div>
        )
    );
};

export default Modal;
