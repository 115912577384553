import {
    REQUEST_COACH_SIDEBAR_PATIENT_CONVERSATIONS,
    RECEIVE_COACH_SIDEBAR_PATIENT_CONVERSATIONS,
    RECEIVE_COACH_SIDEBAR_PATIENT_CONVERSATIONS_ERROR,
    REQUEST_COACH_SIDEBAR_RECENT_CONVERSATIONS,
    RECEIVE_COACH_SIDEBAR_RECENT_CONVERSATIONS,
    RECEIVE_COACH_SIDEBAR_RECENT_CONVERSATIONS_ERROR,
    REQUEST_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS,
    RECEIVE_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS,
    RECEIVE_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS_ERROR,
    REQUEST_CREATE_CHAT_MESSAGE,
    RECEIVE_CREATE_CHAT_MESSAGE,
    RECEIVE_CREATE_CHAT_MESSAGE_ERROR,
    RECEIVE_GET_COACH_CONVERSATION,
    RECEIVE_GET_PATIENT_CONVERSATION,
    REQUEST_GET_PATIENT_SIDEBAR_CONVERSATIONS,
    RECEIVE_GET_PATIENT_SIDEBAR_CONVERSATIONS,
    RECEIVE_GET_PATIENT_SIDEBAR_CONVERSATIONS_ERROR,
    REQUEST_SELECT_COACH_SIDEBAR_PATIENT,
    RECEIVE_SELECT_COACH_SIDEBAR_PATIENT,
    RECEIVE_SELECT_COACH_SIDEBAR_PATIENT_ERROR,
    REQUEST_VIEW_CHAT_MESSAGES,
    RECEIVE_VIEW_CHAT_MESSAGES_ERROR,
    TOGGLE_SIDEBAR,
    SET_CHATBOX_OPEN,
    checkPatientUnreadMessages,
    viewChatMessages,
    getCoachSidebarPatientConversations,
} from "../actions/chat";
import { RECEIVE_UPDATE_PATIENT } from "../actions/patients";
import { newMessageNotification, parseConversations } from "../../utils/chat";
import { chatMessageInputId } from "../../components/chat/ChatMessageInput";
import { getBrandingFromGroup } from "../../utils/branding";

const initialState = {
    chatboxOpen: false,
    coachSidebarPatients: [],
    sidebarConversations: {},
    sidebarOpen: false,
};

export function chat(state = initialState, action) {
    switch (action.type) {
        case REQUEST_CREATE_CHAT_MESSAGE:
            return {
                ...state,
                createChatMessageIsLoading: true,
                createChatMessageRequestedAt: action.requestedAt,
            };
        case RECEIVE_CREATE_CHAT_MESSAGE: {
            const {
                data: { id: conversationId },
            } = action;

            const newConversation = parseConversations({ [conversationId]: action.data });

            return {
                ...state,
                createChatMessageIsLoading: false,
                createChatMessageReceivedAt: action.receivedAt,
                sidebarConversations: {
                    ...state.sidebarConversations,
                    ...newConversation,
                },
            };
        }
        case RECEIVE_CREATE_CHAT_MESSAGE_ERROR:
            return {
                ...state,
                createChatMessageIsLoading: false,
                createChatMessageReceivedAt: action.receivedAt,
                createChatMessageError: action.error,
            };
        case REQUEST_COACH_SIDEBAR_PATIENT_CONVERSATIONS:
            return {
                ...state,
                coachSidebarPatientConversationsIsLoading: true,
                coachSidebarPatientConversationsRequestedAt: action.requestedAt,
            };
        case RECEIVE_COACH_SIDEBAR_PATIENT_CONVERSATIONS: {
            // merge conversations into existing and update accordingly
            const { sidebarConversations } = state;
            const {
                data: { myId, results: newConversations },
            } = action;

            newConversations.forEach((c) => {
                const { id: conversationId, messages } = c;
                const messagesToVisit = messages.filter((m) =>
                    m.visit_tickets.find((t) => !t.visited && t.user === myId)
                );

                sidebarConversations[conversationId] = {
                    ...(sidebarConversations[conversationId] || {}),
                    ...c,
                };

                if (messagesToVisit.length) {
                    action.asyncDispatch(viewChatMessages(messagesToVisit.map((m) => m.id)));
                }
            });

            const newSidebarConversations = parseConversations(sidebarConversations);

            return {
                ...state,
                coachSidebarPatientConversationsIsLoading: false,
                coachSidebarPatientConversationsReceivedAt: action.receivedAt,
                sidebarConversations: newSidebarConversations,
            };
        }
        case RECEIVE_COACH_SIDEBAR_PATIENT_CONVERSATIONS_ERROR:
            return {
                ...state,
                coachSidebarPatientConversationsIsLoading: false,
                coachSidebarPatientConversationsReceivedAt: action.receivedAt,
                coachSidebarPatientConversationsError: action.error,
            };
        case REQUEST_COACH_SIDEBAR_RECENT_CONVERSATIONS:
            return {
                ...state,
                coachSidebarRecentConversationsIsLoading: true,
                coachSidebarRecentConversationsRequestedAt: action.requestedAt,
            };
        case RECEIVE_COACH_SIDEBAR_RECENT_CONVERSATIONS: {
            const {
                data: { results },
            } = action;
            const uniqueConversations = results.reduce((acc, val) => ({ ...acc, [val.id]: val }), {});
            const parsedConversations = {
                ...state.sidebarConversations,
                ...parseConversations(uniqueConversations),
            };

            return {
                ...state,
                coachSidebarRecentConversationsIsLoading: false,
                coachSidebarRecentConversationsReceivedAt: action.receivedAt,
                sidebarConversations: parsedConversations,
            };
        }
        case RECEIVE_COACH_SIDEBAR_RECENT_CONVERSATIONS_ERROR:
            return {
                ...state,
                coachSidebarRecentConversationsIsLoading: false,
                coachSidebarRecentConversationsReceivedAt: action.receivedAt,
                coachSidebarRecentConversationsError: action.error,
            };
        case REQUEST_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS:
            return {
                ...state,
                coachSidebarUnansweredConversationsIsLoading: true,
                coachSidebarUnansweredConversationsRequestedAt: action.requestedAt,
            };
        case RECEIVE_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS: {
            const { data, me } = action;

            const newConversationsWithoutOwner =
                data &&
                data.length > 0 &&
                data.filter((conversation) => {
                    const clientParticipant = conversation.participants.find((participant) => !participant.is_owner);
                    const showConversation =
                        (clientParticipant.owner && clientParticipant.owner.id === me.id) || !clientParticipant.owner;

                    return showConversation;
                });

            const uniqueConversations =
                newConversationsWithoutOwner &&
                newConversationsWithoutOwner.length > 0 &&
                newConversationsWithoutOwner.reduce((acc, val) => ({ ...acc, [val.id]: val }), {});

            const parsedConversations = {
                ...state.sidebarConversations,
                ...parseConversations(uniqueConversations),
            };

            return {
                ...state,
                coachSidebarUnansweredConversationsIsLoading: false,
                coachSidebarUnansweredConversationsReceivedAt: action.receivedAt,
                sidebarConversations: parsedConversations,
            };
        }
        case RECEIVE_COACH_SIDEBAR_UNANSWERED_CONVERSATIONS_ERROR:
            return {
                ...state,
                coachSidebarUnansweredConversationsIsLoading: false,
                coachSidebarUnansweredConversationsReceivedAt: action.receivedAt,
                coachSidebarUnansweredConversationsError: action.error,
            };
        case RECEIVE_GET_COACH_CONVERSATION: {
            const {
                data: conversationData,
                data: { id: conversationId, messages, me },
            } = action;
            const { selectedPatientId, sidebarConversations: newConversations = {}, sidebarOpen } = state;

            newConversations[conversationId] = {
                ...(newConversations[conversationId] || {}),
                ...conversationData,
            };

            const coachSidebarConversations = parseConversations(newConversations);

            // visit messages if patient is selected
            const messagesToVisit = messages.filter((m) => m.visit_tickets.find((t) => !t.visited && t.user === me.id));
            const conversationPatient = conversationData.participants.find((p) => !p.is_coach);
            if (conversationPatient) {
                if (conversationPatient.id === selectedPatientId && messagesToVisit.length && sidebarOpen) {
                    // patient participant selected, visit messages
                    action.asyncDispatch(viewChatMessages(messagesToVisit.map((m) => m.id)));
                } else {
                    // patient not selected, notify of new message
                    const lastMessage = messages.length && messages[messages.length - 1];

                    if (
                        lastMessage &&
                        lastMessage.created_by.id !== me.id &&
                        selectedPatientId !== conversationPatient.id
                    ) {
                        newMessageNotification("You have a new message!");
                    }
                }
            }

            return {
                ...state,
                sidebarConversations: coachSidebarConversations,
            };
        }
        case RECEIVE_GET_PATIENT_CONVERSATION: {
            // update existing or add new conversation
            const {
                data: { id: conversationId },
            } = action;
            const { sidebarConversations: newConversations = {} } = state;

            newConversations[conversationId] = {
                ...(newConversations[conversationId] || {}),
                ...action.data,
            };

            const patientSidebarConversations = parseConversations(newConversations);

            action.asyncDispatch(checkPatientUnreadMessages(patientSidebarConversations));

            setTimeout(() => {
                document.getElementById("ts-conversation-list").scrollTo({
                    block: "end",
                    behavior: "smooth",
                    top: 0,
                });
            }, 500);

            return {
                ...state,
                sidebarConversations: patientSidebarConversations,
            };
        }
        case REQUEST_GET_PATIENT_SIDEBAR_CONVERSATIONS:
            return {
                ...state,
                patientSidebarConversationsIsLoading: true,
                patientSidebarConversationsRequestedAt: action.requestedAt,
            };
        case RECEIVE_GET_PATIENT_SIDEBAR_CONVERSATIONS: {
            const {
                data: { results: actionResults = [] },
            } = action;

            const patientSidebarConversations = parseConversations(actionResults);
            action.asyncDispatch(checkPatientUnreadMessages(patientSidebarConversations));

            return {
                ...state,
                patientSidebarConversationsIsLoading: false,
                patientSidebarConversationsReceivedAt: action.receivedAt,
                sidebarConversations: patientSidebarConversations,
            };
        }
        case RECEIVE_GET_PATIENT_SIDEBAR_CONVERSATIONS_ERROR:
            return {
                ...state,
                patientSidebarConversationsIsLoading: false,
                patientSidebarConversationsReceivedAt: action.receivedAt,
                patientSidebarConversationsError: action.error,
            };
        case RECEIVE_UPDATE_PATIENT: {
            const { id: patientId, owner } = action.data;
            const { coachSidebarPatients: newCoachSidebarPatients = [] } = { ...state };
            const updatedPatient = newCoachSidebarPatients.find((patient) => patient.id === patientId);
            if (updatedPatient) updatedPatient.owner = owner;

            return {
                ...state,
                coachSidebarPatients: newCoachSidebarPatients,
            };
        }
        case REQUEST_SELECT_COACH_SIDEBAR_PATIENT:
            return {
                ...state,
                coachSidebarActivePatientsIsLoading: true,
                coachSidebarActivePatientsRequestedAt: action.requestedAt,
            };
        case RECEIVE_SELECT_COACH_SIDEBAR_PATIENT: {
            const {
                data: { patient },
            } = action;
            const { id: patientId } = patient || {};
            const { coachSidebarPatients: newCoachSidebarPatients = [] } = state;

            if (patientId) {
                const existingPatientIdx = newCoachSidebarPatients.findIndex((p) => p.id === patientId);
                const newPatientData = {
                    ...patient,
                    branding: getBrandingFromGroup(patient.group),
                };

                if (existingPatientIdx > -1) {
                    newCoachSidebarPatients[existingPatientIdx] = newPatientData;
                } else {
                    newCoachSidebarPatients.push(newPatientData);
                }

                action.asyncDispatch(getCoachSidebarPatientConversations(patientId));
            }

            return {
                ...state,
                coachSidebarActivePatientsIsLoading: false,
                coachSidebarActivePatientsReceivedAt: action.receivedAt,
                coachSidebarPatients: newCoachSidebarPatients,
                selectedPatientId: patientId,
            };
        }
        case RECEIVE_SELECT_COACH_SIDEBAR_PATIENT_ERROR:
            return {
                ...state,
                coachSidebarActivePatientsIsLoading: false,
                coachSidebarActivePatientsReceivedAt: action.receivedAt,
                coachSidebarActivePatientsError: action.error,
            };
        case TOGGLE_SIDEBAR: {
            const { sidebarOpen: currentSidebarOpen } = state;

            return {
                ...state,
                sidebarOpen: !currentSidebarOpen,
            };
        }
        case SET_CHATBOX_OPEN: {
            const { data: chatboxOpen, sidebarConversations } = action;

            if (chatboxOpen) {
                action.asyncDispatch(checkPatientUnreadMessages(sidebarConversations));

                // wait for the box to open then scroll it into view for mobile keyboards
                setTimeout(() => {
                    document.getElementById(chatMessageInputId).scrollIntoView({
                        block: "end",
                        behavior: "smooth",
                    });
                }, 500);
            }

            let returnState = {
                ...state,
                chatboxOpen,
            };

            if (!chatboxOpen) {
                returnState.sidebarConversations = {};
                returnState.patientSidebarConversationsRequestedAt = null;
            }

            return returnState;
        }
        case REQUEST_VIEW_CHAT_MESSAGES:
            return {
                ...state,
                viewChatMessagesIsLoading: true,
                viewChatMessagesRequestedAt: action.requestedAt,
            };
        case RECEIVE_VIEW_CHAT_MESSAGES_ERROR:
            return {
                ...state,
                viewChatMessagesIsLoading: false,
                viewChatMessagesReceivedAt: action.receivedAt,
                viewChatMessagesError: action.error,
            };
        case "REDUX_WEBSOCKET::MESSAGE": {
            const {
                payload: { message },
            } = action;

            switch (message.type) {
                case "user_updated":
                    const { user_id, online } = message;
                    const { coachSidebarPatients = [], sidebarConversations } = state;
                    const existingPatientIdx = coachSidebarPatients.findIndex((p) => p.id === user_id);
                    const patientConversationIds = Object.keys(sidebarConversations).filter((conversation) => {
                        return (
                            sidebarConversations[conversation] &&
                            sidebarConversations[conversation].participants &&
                            sidebarConversations[conversation].participants.length > 0 &&
                            sidebarConversations[conversation].participants.find(
                                (participant) => participant.id === user_id
                            )
                        );
                    });

                    if (patientConversationIds) {
                        patientConversationIds.forEach((conversationId) => {
                            const conversation = {
                                ...sidebarConversations[conversationId],
                            };

                            const conversationPatient =
                                conversation &&
                                conversation.participants &&
                                conversation.participants.length > 0 &&
                                conversation.participants.find((participant) => participant.id === user_id);

                            conversationPatient.online = online;
                        });
                    }

                    if (existingPatientIdx > -1) coachSidebarPatients[existingPatientIdx].online = online;

                    return {
                        ...state,
                        coachSidebarPatients,
                        sidebarConversations,
                    };
                default:
                    return { ...state };
            }
        }
        default:
            return {
                ...state,
            };
    }
}
