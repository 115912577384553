import React, { useEffect, useState } from "react";
import { Card, Grid, MenuItem, Stack, TextField, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import cx from "classnames";

import { updateProviderProfile } from "../../app/actions";
import { useDispatch } from "react-redux";

import Modal from "../modal/Modal";
import { toast } from "../notifications/Toast";
import { EXPERTISE_OPTIONS } from "../../utils/helpers";

export default function EditExpertiseModal({ show, handleClose, provider = null }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [selectedExpertise, setSelectedExpertise] = useState([]);

    useEffect(() => {
        if (provider && provider.expertise_level) setSelectedExpertise(provider.expertise_level);
    }, [show]); //eslint-disable-line

    const onExpertiseChange = async (event) => {
        setSelectedExpertise(event.target.value);
    };

    const saveExpertise = async () => {
        const result = await dispatch(updateProviderProfile(provider.id, { expertise_level: selectedExpertise }));
        const { error } = result;

        if (error) {
            toast.error("Error updating skill level");
        } else {
            toast.success("Skill level updated");
            handleClose();
        }
    };

    return (
        <>
            <Modal
                show={show}
                size="lg"
                top
                toggleShow={handleClose}
                content={
                    <div>
                        <Typography variant="h6" sx={{ my: 2 }}>
                            MFR Experience
                        </Typography>

                        <TextField
                            id="skill"
                            name="skill"
                            label="Skill Level"
                            value={selectedExpertise}
                            sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-input": { padding: "17.5px 14px" },
                            }}
                            select
                            onChange={onExpertiseChange}
                        >
                            {EXPERTISE_OPTIONS &&
                                EXPERTISE_OPTIONS.length > 0 &&
                                EXPERTISE_OPTIONS.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        sx={{
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: "body2",
                                            textTransform: "capitalize",
                                        }}
                                        className={cx(option.value === "" ? "text-gray" : "text-black")}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <Typography variant="subtitle1" sx={{ my: 2 }}>
                            Requirements
                        </Typography>
                        <Card sx={{ p: 3, my: 2 }}>
                            <Stack sx={{ flexDirection: "row" }}>
                                <Typography variant="h6" sx={{ mr: 2 }}>
                                    Novice
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: theme.palette.grey[600], lineHeight: "2.2" }}
                                >
                                    Completed one or more of the following:
                                </Typography>
                            </Stack>
                            <Grid container>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• Myofascial Release I</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• Fascial Pelvis</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card sx={{ p: 3, my: 2 }}>
                            <Stack sx={{ flexDirection: "row" }}>
                                <Typography variant="h6" sx={{ mr: 2 }}>
                                    Intermediate
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: theme.palette.grey[600], lineHeight: "2.2" }}
                                >
                                    Completed Novice Skills, plus:
                                </Typography>
                            </Stack>
                            <Grid container>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• Myofascial Unwinding</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• Myofascial Release II</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• One of the following 3 courses:</Typography>
                                </Grid>
                                <Grid container sx={{ ml: 2 }}>
                                    <Grid item xs={12} sx={{ color: theme.palette.grey[600] }}>
                                        <Typography variant="body1">• Cervical Thoracic Myofascial Release</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ color: theme.palette.grey[600] }}>
                                        <Typography variant="body1">• Myofascial Rebounding</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ color: theme.palette.grey[600] }}>
                                        <Typography variant="body1">• Skill Enhancement Seminar</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card sx={{ p: 3, my: 2 }}>
                            <Stack sx={{ flexDirection: "row" }}>
                                <Typography variant="h6" sx={{ mr: 2 }}>
                                    Advanced
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: theme.palette.grey[600], lineHeight: "2.2" }}
                                >
                                    Completed all Intermediate Skills, plus:
                                </Typography>
                            </Stack>
                            <Grid container>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• Cervical Thoracic Myofascial Release</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• Myofascial Rebounding</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• Advanced Myofascial Unwinding</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• Skill Enhancement Seminar</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• 1+ year of experience using MFR</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card sx={{ p: 3, my: 2 }}>
                            <Stack sx={{ flexDirection: "row" }}>
                                <Typography variant="h6" sx={{ mr: 2 }}>
                                    Expert
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: theme.palette.grey[600], lineHeight: "2.2" }}
                                >
                                    Completed all Advanced Skills, plus:
                                </Typography>
                            </Stack>
                            <Grid container>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">
                                        • Therapy for the Therapist (minimum 1 week)
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">• MFR III</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ color: theme.palette.grey[600] }}>
                                    <Typography variant="body1">
                                        • 2+ years of experience using MFR exclusively
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>

                        <Stack direction="row" justifyContent="end" spacing={2} sx={{ my: 2 }}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                className="text-right"
                                onClick={saveExpertise}
                            >
                                Save
                            </LoadingButton>
                        </Stack>
                    </div>
                }
            />
        </>
    );
}
