import { exq } from "../../utils/helpers";

export const assignedProgramsFragment = {
    expand: exq`
        resources_list
        resources_list.resources.article
        resources_list.resources.exercise
        resources_list.resources.exercise.reps
        resources_list.resources.exercise.steps
        resources_list.resources.exercise.tags
        resources_list.resources.held_duration
        resources_list.resources.notes
        resources_list.resources.reps
        resources_list.resources.sets
        resources_list.resources.sets_finished
        tags
    `,
    fields: exq`
        completed
        created
        created_by
        current
        description
        editable_by_orbit_coaches
        hidden
        id
        initiated
        is_global
        is_routine
        last_visited
        modified
        notes
        order
        pain_location
        provider_copy_allowed
        resources_list.id
        resources_list.modified
        resources_list.resources
        resources_list.title
        tags
        title
        updated
    `,
};

export const programFragment = {
    expand: exq`
        recommended_list.resources.article
        recommended_list.resources.exercise
        recommended_list.resources.exercise.steps
        recommended_list.resources.exercise.tags
        resources_list.resources.article
        resources_list.resources.exercise
        resources_list.resources.exercise.reps
        resources_list.resources.exercise.steps
        resources_list.resources.exercise.tags
        resources_list.resources.held_duration
        resources_list.resources.notes
        resources_list.resources.reps
        resources_list.resources.sets
        resources_list.resources.sets_finished
    `,
    fields: exq`
        completed
        created
        current
        description
        effective
        hidden
        id
        initiated
        last_visited
        modified
        instructions
        notes
        pain_location
        recommended_list.id
        recommended_list.resources
        recommended_list.title
        resources_list.id
        resources_list.resources
        resources_list.title
        title
    `,
};

export const dataPointsFragment = {
    expand: exq`
        resource
    `,
    fields: exq`
        id
        type
        user
        created
        resource
    `,
};
