function getAnalyticsFunction() {
    return window.gtag || (window.gtag = () => {});
}

export function setAnalyticsDefaultValue(params) {
    const gtag = getAnalyticsFunction();
    gtag("set", params);
}

export function sendAnalyticsEvent(name, options) {
    const gtag = getAnalyticsFunction();
    gtag("event", name, options);
}

export function setAnalyticsValues(params) {
    const gtag = getAnalyticsFunction();
    gtag("set", params);
}

export function sendPageViewEvent(params) {
    setAnalyticsValues(params);
}

export function sendTextFieldFocusEvent(field_id) {
    sendAnalyticsEvent("text_field_focus", { field_id });
}

export function sendCheckboxtFieldChangedEvent(field_id, value) {
    sendAnalyticsEvent("checkbox", { field_id, value });
}

export function sendButtonGroupClickEvent(field_id, field_value) {
    sendAnalyticsEvent("click", { field_id, field_value });
}

export function sendDatePickerOpenEvent() {
    sendAnalyticsEvent("date_picker_open");
}

export function sendButtonClick(button_id) {
    sendAnalyticsEvent("click", { button_id });
}

export function sendOnboardingStepDisplayedEvent(screen_id) {
    sendAnalyticsEvent("onboarding_screen_visit", { screen_id });
}

export function sendCheckoutSessionCreatedEvent(price_id) {
    sendAnalyticsEvent("checkout_session_created", {
        price_id,
    });
}

export function sendCheckoutSuccessfulEvent(uid) {
    sendAnalyticsEvent("checkout_success", { uid });
}

export function sendCheckoutFailureEvent(uid) {
    sendAnalyticsEvent("checkout_failure", { uid });
}

export function sendSubscriptionPromptCoachChatEvent(uid) {
    sendAnalyticsEvent("subscription_prompt_coach_chat", { uid });
}

export function sendSubscriptionPromptDismissEvent(uid) {
    sendAnalyticsEvent("subscription_prompt_dismiss", { uid });
}

export function sendSubscriptionPromptDisplayEvent(uid, context) {
    sendAnalyticsEvent("subscription_prompt_display", { context, uid });
}

export function sendProviderSubscriptionPromptDisplayEvent(uid, context) {
    sendAnalyticsEvent("provider_subscription_prompt_display", { context, uid });
}

export function sendProviderSubscriptionPromptSuccessDisplayEvent(uid) {
    sendAnalyticsEvent("subscription_prompt_success_display", { uid });
}

export function sendSubscriptionPromptSuccessDisplayEvent(uid) {
    sendAnalyticsEvent("subscription_prompt_success_display", { uid });
}

export function sendSubscriptionPromptSuccessDismissEvent(uid) {
    sendAnalyticsEvent("subscription_prompt_success_dismiss", { uid });
}
