import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import * as yup from "yup";

import { signUpUser, updateFormData } from "../../app/actions";
import { PASSWORD_MATCH, PASSWORD_REGEX, REQUIRED_FIELD } from "../../utils/validations";
import { executeRecaptcha, getSystemTimezone, storageAvailable } from "../../utils/helpers";
import { HOME_ROUTE, LOGIN_ROUTE } from "../../utils/routes";
import { sendButtonClick, sendTextFieldFocusEvent } from "../../utils/analytics";

import BrandedLogo from "../logo/BrandedLogo";
import Button from "../button/Button";
import FormError from "../form_error/FormError";
import FormRow from "../form_row/FormRow";
import PasswordField from "../password_field/PasswordField";
import RecaptchaProvider from "../recaptcha_provider/RecaptchaProvider";
import TextInput from "../text_input/TextInput";
import { toast } from "../notifications/Toast";
import { ONBOARDING_TYPEFORM_ANSWERS_LOCAL_STORAGE_KEY } from "../../utils/flows";
import Page from "../minimal/Page";

export default function DTCSignup() {
    const dispatch = useDispatch();
    const { branding } = useSelector(({ groups }) => groups);
    const signup = useSelector(({ signup }) => signup);
    const [prefilledFields, setPrefilledFields] = useState({});
    const [initialFormValues, setInitialFormValues] = useState({
        password: "",
        confirmPassword: "",
        first_name: "",
        last_name: "",
        email: "",
    });
    const productName = branding.product_name;

    const schema = yup.object().shape({
        password: yup.string().required(REQUIRED_FIELD.message).matches(PASSWORD_REGEX.value, PASSWORD_REGEX.message),
        confirmPassword: yup
            .string()
            .required(REQUIRED_FIELD.message)
            .oneOf([yup.ref("password"), null], PASSWORD_MATCH.message),
    });

    useEffect(() => {
        async function init() {
            const {
                location: { search },
            } = window;

            if (search !== "") {
                let queryFields = qs.parse(search);
                const email = queryFields.email && queryFields.email.replace(" ", "+");

                if (!email) window.location.assign(LOGIN_ROUTE);

                const storageIsAvailable = storageAvailable("localStorage");
                if (storageIsAvailable) {
                    const { disability, flags, goal, location, motivation, pain, stress } = queryFields;

                    localStorage.setItem(
                        ONBOARDING_TYPEFORM_ANSWERS_LOCAL_STORAGE_KEY,
                        JSON.stringify({ goal, motivation, location, flags, pain, stress, disability })
                    );
                }

                setPrefilledFields({ ...queryFields, email });
                setInitialFormValues({ ...initialFormValues, ...queryFields, email });
                dispatch(updateFormData({ ...initialFormValues, ...queryFields, email }));
            }
        }
        init();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (branding && branding.receivedAt && branding.name !== "OMSK") window.location.assign(LOGIN_ROUTE);
    }, [branding]);

    const handleFormFocus = () => (event) => {
        const {
            target: { id },
        } = event;

        if (id) sendTextFieldFocusEvent(id);
    };

    const handleFormChange = (status, setStatus, setFieldValue) => (event) => {
        const {
            target: { value, id },
        } = event;
        const fieldId = (id && id.length > 0 && id.split(" ")[0]) || "";
        let formData = {
            [fieldId]: value.trim(),
        };

        if (status && Object.keys(status).includes(fieldId)) {
            setStatus(fieldId, null);
        }

        dispatch(updateFormData(formData));
        setFieldValue(fieldId, value);
    };

    const handleFormSubmission = (values, actions, notifySuccess = false) => {
        const { first_name, last_name, email, password, confirmPassword } = signup.formData;

        executeRecaptcha("providersignup")
            .then(async (recaptchaResponse) => {
                let params = {
                    ...initialFormValues,
                    first_name,
                    last_name,
                    email,
                    password,
                    confirmPassword,
                    timezone: getSystemTimezone(),
                    tos_accepted: true,
                    registered_from_site: true,
                    "g-recaptcha-response": recaptchaResponse,
                };

                await sendButtonClick("dtc_sign_up_submit");

                const result = await dispatch(signUpUser(params));
                const { error } = result;

                if (error) {
                    const errorMessage = error.response ? error.response.data : "Error creating account.";

                    actions.setStatus(error.response.data);

                    if (typeof errorMessage === "string") {
                        toast.error(errorMessage);
                    } else {
                        toast.error(
                            "Something went wrong signing you up. Please address the errors above and try again."
                        );
                    }
                } else {
                    window.location.assign(HOME_ROUTE);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    return (
        <RecaptchaProvider>
            <Page title="DTC Signup">
                <div className="ts-signup">
                    <div className="container mb-5">
                        <header className="ts-signup__header">
                            {branding && !branding.isFetching && branding.receivedAt && (
                                <div className="ts-signup__header__logo">
                                    <BrandedLogo type="color_logo" className="ts-branded-logo-signup" />
                                </div>
                            )}
                        </header>
                        <div className="ts-signup-container row no-gutters">
                            <div className="ts-signup-form col-12 col-lg-8 px-0 px-md-3 px-md-5 py-3 py-md-5">
                                {initialFormValues && (
                                    <Formik
                                        initialValues={{
                                            first_name: initialFormValues.first_name,
                                            last_name: initialFormValues.last_name,
                                            email: initialFormValues.email,
                                            password: initialFormValues.password,
                                            confirmPassword: initialFormValues.confirmPassword,
                                        }}
                                        enableReinitialize={true}
                                        onSubmit={handleFormSubmission}
                                        validationSchema={schema}
                                    >
                                        {({
                                            handleSubmit,
                                            setStatus,
                                            setFieldValue,
                                            status,
                                            values,
                                            errors,
                                            touched,
                                        }) => (
                                            <div>
                                                <div className="col-12 offset-lg-0 px-0 mb-2 mb-md-4">
                                                    <div className="ts-welcome-title text-main text-medium-bold text-xl">
                                                        {branding.is_organization
                                                            ? "Welcome!"
                                                            : `Welcome to ${productName}`}
                                                    </div>
                                                    <div className="ts-welcome-subtitle text-secondary text-normal text-md my-3">
                                                        Start feeling better with proven pain therapy.
                                                    </div>
                                                </div>
                                                <form className="col-12 px-0" onSubmit={handleSubmit}>
                                                    <FormRow bottomSpacing>
                                                        <span className="text-bold text-main">
                                                            Login email: {prefilledFields.email}
                                                        </span>
                                                    </FormRow>
                                                    <FormRow bottomSpacing>
                                                        <PasswordField
                                                            id="password form-field__password"
                                                            name="password"
                                                            className="form-field__password"
                                                            label="Password"
                                                            onFocus={handleFormFocus()}
                                                            onChange={handleFormChange(
                                                                status,
                                                                setStatus,
                                                                setFieldValue
                                                            )}
                                                            value={values.password}
                                                            type="password"
                                                            error={errors.password}
                                                            touched={touched.password}
                                                            required
                                                        />
                                                        <FormError
                                                            show={
                                                                errors && !errors.password && status && status.password
                                                            }
                                                            message={status && status.password}
                                                        />
                                                    </FormRow>
                                                    <FormRow bottomSpacing>
                                                        <TextInput
                                                            id="confirmPassword form-field__confirmPassword"
                                                            name="confirmPassword"
                                                            label="Re-Enter Password"
                                                            onFocus={handleFormFocus()}
                                                            onChange={handleFormChange(
                                                                status,
                                                                setStatus,
                                                                setFieldValue
                                                            )}
                                                            value={values.confirmPassword}
                                                            type="password"
                                                            error={errors.confirmPassword}
                                                            touched={touched.confirmPassword}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </FormRow>
                                                    <FormRow bottomSpacing className="ts-signup-tos text-main text-xs">
                                                        By completing your registration you are agreeing to the{" "}
                                                        <a
                                                            href="https://orbittelehealth.com/terms/"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-secondary text-underline"
                                                        >
                                                            Terms and Conditions
                                                        </a>
                                                    </FormRow>
                                                    <FormRow>
                                                        <Button
                                                            square
                                                            secondary
                                                            className="ts-signup-submit"
                                                            analyticsClass="button__submit-invite-registration"
                                                            type="submit"
                                                            disabled={signup.signupIsFetching}
                                                        >
                                                            Complete Registration
                                                        </Button>
                                                    </FormRow>
                                                </form>
                                            </div>
                                        )}
                                    </Formik>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </RecaptchaProvider>
    );
}
