import React from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { getThemeColor, PRIMARY_COLOR_ID, SECONDARY_COLOR_ID } from "./colors";

const primaryColor = getThemeColor(PRIMARY_COLOR_ID);
const theme = createTheme({
    palette: {
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: getThemeColor(SECONDARY_COLOR_ID),
        },
    },
    MuiFormLabel: {
        root: {
            color: primaryColor,
        },
    },
});

export default function ThemeProvider({ children }) {
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
