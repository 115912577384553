import moment from "moment-timezone";
import { meetingsFragment, slotsFragment } from "../app/fragments/scheduler";

export const TIME_SLOT_FORMAT_STRING = "h:mm A";

const EXTENDED_SLOTS_LOWER_BOUNDARY = 6;
const EXTENDED_SLOTS_UPPER_BOUNDARY = 18;
const BLOCK_HEIGHT_HOUR = 40;
const BLOCK_HEIGHT_MINUTE = 0.67;

export function getTimeSlots() {
    const startOfTomorrow = moment().add(1, "days").startOf("day");
    const startOfWorkDay = moment("6:00 AM", [TIME_SLOT_FORMAT_STRING]);
    const endOfWorkDay = moment("6:00 PM", [TIME_SLOT_FORMAT_STRING]);
    let i = moment().startOf("day");
    let timeSlots = [];

    while (i < startOfTomorrow) {
        timeSlots.push({
            start_time: i.format(TIME_SLOT_FORMAT_STRING),
            end_time: i.clone().add(30, "minutes").format(TIME_SLOT_FORMAT_STRING),
            offHours: !i.isBetween(startOfWorkDay, endOfWorkDay, undefined, "[)"),
        });

        i = i.add(30, "minutes");
    }

    return timeSlots;
}

export function calculateOpenSlots(meetings, availableSlots, allTimeSlots, showExtendedSlots, date, coach, userId) {
    const lower = date.clone().startOf("day").add(EXTENDED_SLOTS_LOWER_BOUNDARY, "hour");
    const upper = date.clone().startOf("day").add(EXTENDED_SLOTS_UPPER_BOUNDARY, "hour");
    const hasAvailability = !!availableSlots.length;
    let openSlotsForCoach =
        allTimeSlots &&
        allTimeSlots.length &&
        allTimeSlots.map((slot) => ({
            start_time: date.clone().set({
                hour: slot.start_time.format("HH"),
                minute: slot.start_time.format("mm"),
            }),
            end_time: date.clone().set({
                hour: slot.end_time.format("HH"),
                minute: slot.end_time.format("mm"),
            }),
        }));

    if (!hasAvailability && coach.id !== userId) {
        return [];
    } else if (hasAvailability && coach.id !== userId) {
        openSlotsForCoach =
            availableSlots &&
            availableSlots.length &&
            availableSlots.map((availableSlot) => {
                const matchingSlot = openSlotsForCoach.find((openSlot) => {
                    if (
                        openSlot.start_time.valueOf() === availableSlot.start_time.valueOf() &&
                        openSlot.end_time.valueOf() === availableSlot.end_time.valueOf()
                    ) {
                        return openSlot;
                    }

                    return false;
                });

                if (matchingSlot) {
                    return matchingSlot;
                }

                return false;
            });
    }

    const openSlots =
        openSlotsForCoach.length > 0 &&
        openSlotsForCoach
            .map((slot) => {
                const overlappingMeeting =
                    meetings &&
                    meetings.length &&
                    meetings.find((meeting) => {
                        return (
                            slot.start_time.isBetween(meeting.start_time, meeting.end_time, undefined, "[)") ||
                            slot.end_time.isBetween(meeting.start_time, meeting.end_time, undefined, "(]")
                        );
                    });

                if (!overlappingMeeting && showExtendedSlots) {
                    return slot;
                } else if (
                    !overlappingMeeting &&
                    !showExtendedSlots &&
                    slot.start_time.isBetween(lower, upper, "hour", "[)")
                ) {
                    return slot;
                }

                return false;
            })
            .filter((slot) => slot && slot.start_time.isAfter(moment()));

    return openSlots;
}

export function generateTimeBlocks(times) {
    const sortedTimes = times && times.sort((a, b) => a.start_time.valueOf() - b.start_time.valueOf());

    let start_time = null;
    let end_time = null;
    let blocks = [];

    for (let i = 0; i <= sortedTimes.length - 1; i++) {
        const currentBlockStart = sortedTimes[i].start_time;
        const currentBlockEnd = sortedTimes[i].end_time;
        let nextBlockStart = null;
        let dif = 0;

        if (i < sortedTimes.length - 1) {
            nextBlockStart = sortedTimes[i + 1].start_time;
            dif = moment.duration(nextBlockStart.diff(currentBlockStart));
        }

        if (!start_time && i === sortedTimes.length - 1) {
            // last item, no prev start time
            start_time = currentBlockStart;
            end_time = currentBlockEnd;
        } else if (start_time && i === sortedTimes.length - 1) {
            // last item, w/ prev start time
            end_time = currentBlockEnd;
        } else if (dif.hours() === 0 && dif.minutes() <= 30) {
            // If next block time difference is 30 mins or less and we have no start time
            if (!start_time) {
                start_time = currentBlockStart;
            }
        } else if (dif.hours() > 0 || dif.minutes() > 30) {
            // If next block time difference is over 30 mins, close the block
            if (!start_time) {
                start_time = currentBlockStart;
                end_time = currentBlockEnd;
            } else {
                end_time = currentBlockEnd;
            }
        }

        // Save and reset
        if (start_time && end_time) {
            blocks.push({ start_time, end_time });
            start_time = null;
            end_time = null;
        }
    }

    return blocks;
}

export function getTimeRangeParamsForMonth(month, year, currentMonths) {
    const firstOfMonth = moment([year, month]).startOf("month").format();
    const lastOfMonth = moment([year, month]).endOf("month").format();
    const daysToPrepend = moment(firstOfMonth).days();
    const daysToAppend = 6 - moment(lastOfMonth).days();
    const start_time__gte = moment(firstOfMonth).subtract(daysToPrepend, "days").startOf("day").format();
    const start_time__lte = moment(lastOfMonth).add(daysToAppend, "days").endOf("day").format();
    const calendarMonth = currentMonths.find((m) => {
        return m.month === month && m.year === year;
    });

    const meetingsParams = {
        params: {
            page_size: 0,
            status__in: "upcoming,in_progress,completed",
            start_time__gte,
            start_time__lte,
            ...meetingsFragment,
        },
    };

    const slotsParams = {
        month,
        year,
        params: {
            page_size: 0,
            start_time__gte,
            start_time__lte,
            ...slotsFragment,
        },
    };

    return { calendarMonth, meetingsParams, slotsParams };
}

export function calculateNewDate(today, date, month, year, nextMonth = null, nextDay = null) {
    let updatedMonth = month;
    let updatedYear = year;
    let updatedDate = null;

    if (nextMonth >= -1 && !nextDay) {
        if (nextMonth < month) {
            updatedMonth = nextMonth >= 0 ? nextMonth : 11;

            if (nextMonth < 0) {
                updatedYear -= 1;
            }
        } else {
            updatedMonth = nextMonth <= 11 ? nextMonth : 0;

            if (nextMonth > 11) {
                updatedYear += 1;
            }
        }

        if (moment([updatedYear, updatedMonth]).month() !== moment().month()) {
            updatedDate = moment([updatedYear, updatedMonth]).startOf("month");
        } else {
            updatedDate = today;
        }
    } else if (nextDay) {
        const firstOfMonth = moment([year, month]).startOf("month").format();
        const lastOfMonth = moment([year, month]).endOf("month").format();
        updatedDate = moment(nextDay);

        if (moment(updatedDate).isBefore(moment(date).format())) {
            updatedMonth = moment(updatedDate).isBefore(firstOfMonth) ? updatedMonth - 1 : month;

            if (updatedMonth < 0) {
                updatedMonth = 11;
                updatedYear -= 1;
            }
        } else {
            updatedMonth = moment(updatedDate).isAfter(lastOfMonth) ? updatedMonth + 1 : month;

            if (updatedMonth > 11) {
                updatedMonth = 0;
                updatedYear += 1;
            }
        }
    }

    return { updatedDate, updatedMonth, updatedYear };
}

function getWorkingCoachesForDate(slots, meetings, coaches, date) {
    const momentDate = date.startOf("day").format();
    const availableSlots =
        (slots &&
            slots.length &&
            slots.filter(
                (slot) =>
                    coaches.includes(slot.coach.id) && slot.start_time.clone().startOf("day").format() === momentDate
            )) ||
        [];
    const meetingsSlots =
        (meetings &&
            meetings.length &&
            meetings.filter(
                (meeting) =>
                    coaches.includes(meeting.coach.id) &&
                    meeting.start_time.clone().startOf("day").format() === momentDate
            )) ||
        [];

    const availableCoaches = Array.from(new Set(availableSlots && availableSlots.map((slot) => slot.coach.id))).map(
        (id) => {
            const availableSlot = availableSlots.find((slot) => slot.coach.id === id);

            if (availableSlot) {
                return availableSlot.coach;
            }

            return false;
        }
    );
    const workingCoaches = Array.from(new Set(meetingsSlots.map((slot) => slot.coach.id)))
        .map((id) => {
            const meetingSlot = meetings.find((meeting) => meeting.coach.id === id);
            const isAvailable = availableCoaches.find((availableCoach) => meetingSlot.coach.id === availableCoach.id);

            if (meetingSlot && !isAvailable) {
                return meetingSlot.coach;
            }

            return null;
        })
        .filter((coach) => coach);
    const combinedWorkingCoaches = availableCoaches.concat(workingCoaches);

    return combinedWorkingCoaches;
}

export function generateCalendar(slots, meetings, coaches, month, year, today) {
    const daysInMonth = moment([year, month]).daysInMonth();
    const firstOfMonth = moment([year, month]).startOf("month");
    const lastOfMonth = moment([year, month]).endOf("month");
    const daysToPrepend = firstOfMonth.days();
    const daysToAppend = 6 - lastOfMonth.days();
    const availableCoachIds = Array.from(new Set(coaches.map((coach) => coach.id)));
    let currentWeek = 0;

    let allDays = [];
    for (let i = daysToPrepend; i >= 1; i--) {
        const date = firstOfMonth.clone().subtract(i, "days");

        const availableCoaches = getWorkingCoachesForDate(slots, meetings, availableCoachIds, date);

        allDays.push({
            date,
            dateLabel: date.format("D"),
            additionalDay: true,
            availableCoaches,
        });
    }

    for (let i = 0; i <= daysInMonth - 1; i++) {
        const date = firstOfMonth.clone().add(i, "days");

        const availableCoaches = getWorkingCoachesForDate(slots, meetings, availableCoachIds, date);

        allDays.push({
            date,
            dateLabel: date.format("D"),
            additionalDay: false,
            availableCoaches,
        });
    }

    for (let i = 1; i <= daysToAppend; i++) {
        const date = lastOfMonth.clone().add(i, "days");

        const availableCoaches = getWorkingCoachesForDate(slots, meetings, availableCoachIds, date);

        allDays.push({
            date,
            dateLabel: date.format("D"),
            additionalDay: true,
            availableCoaches,
        });
    }

    let weeks = [];
    for (let i = 0; i < allDays.length; i += 7) {
        const days = allDays.slice(i, i + 7);

        if (days.find((day) => day.date.valueOf() === today.valueOf())) {
            currentWeek = i / 7;
        }

        weeks.push({
            days,
        });
    }

    return { weeks, currentWeek };
}

export function checkForExtendedSlots(date, coaches, slots, meetings) {
    let hasExtendedSlots = false;
    const momentDate = date.startOf("day").format();

    coaches.forEach((coach) => {
        const coachSlots =
            slots &&
            slots.length &&
            slots.filter(
                (slot) => slot.start_time.clone().startOf("day").format() === momentDate && slot.coach.id === coach.id
            );

        const coachMeetings =
            meetings &&
            meetings.length &&
            meetings.filter(
                (meeting) =>
                    !meeting.on_demand &&
                    meeting.coach.id === coach.id &&
                    meeting.start_time.clone().startOf("day").format() === momentDate
            );

        if (coachSlots) {
            coachSlots.forEach((slot) => {
                if (
                    slot.start_time.isBefore(date.startOf("day").add(EXTENDED_SLOTS_LOWER_BOUNDARY, "hours")) ||
                    slot.start_time.isAfter(date.startOf("day").add(EXTENDED_SLOTS_UPPER_BOUNDARY, "hours"))
                ) {
                    hasExtendedSlots = true;
                }
            });

            if (!hasExtendedSlots && coachMeetings.length > 0) {
                coachMeetings.forEach((meeting) => {
                    if (
                        meeting.start_time.isBefore(date.startOf("day").add(EXTENDED_SLOTS_LOWER_BOUNDARY, "hours")) ||
                        meeting.start_time.isAfter(date.startOf("day").add(EXTENDED_SLOTS_UPPER_BOUNDARY, "hours"))
                    ) {
                        hasExtendedSlots = true;
                    }
                });
            }
        }
    });

    return hasExtendedSlots;
}

export function calculateCellTopBottom(slot, showExtendedHours) {
    const shiftHours = showExtendedHours ? 0 : 6;
    const topHour = slot.start_time.format("k");
    const bottomHour = slot.end_time.format("k");
    const startTimeMinutes = slot.start_time.format("m");
    const endTimeMinutes = slot.end_time.format("m");

    let top = topHour === "24" ? 0 : topHour - shiftHours;
    let bottom = bottomHour === "24" && slot.start_time.day() === slot.end_time.day() ? 0 : bottomHour - shiftHours;

    if (startTimeMinutes > 0) {
        top = top * BLOCK_HEIGHT_HOUR + startTimeMinutes * BLOCK_HEIGHT_MINUTE;
    } else {
        top *= BLOCK_HEIGHT_HOUR;
    }

    if (endTimeMinutes > 0) {
        bottom = bottom * -BLOCK_HEIGHT_HOUR - endTimeMinutes * BLOCK_HEIGHT_MINUTE;
    } else {
        bottom *= -BLOCK_HEIGHT_HOUR;
    }

    return { top, bottom };
}

export function groupOverlappingConsults(meetings) {
    const meetingBlocks = [];
    const sortedMeetings =
        meetings && meetings.length > 0 && meetings.sort((a, b) => a.start_time.valueOf() - b.start_time.valueOf());
    let meetingGroup = [];
    let start_time = null;
    let end_time = null;
    let dif = 0;

    for (let i = 0; i <= sortedMeetings.length - 1; i++) {
        const currentBlockStart = sortedMeetings[i].start_time;
        const currentBlockEnd = sortedMeetings[i].end_time;
        let nextBlockStart = null;
        meetingGroup.push(sortedMeetings[i]);

        if (i < sortedMeetings.length - 1) {
            nextBlockStart = sortedMeetings[i + 1].start_time;
            dif = moment.duration(nextBlockStart.diff(currentBlockStart));
        }

        if (!start_time && i === sortedMeetings.length - 1) {
            // last item, no prev start time
            start_time = currentBlockStart;
            end_time = currentBlockEnd;
        } else if (start_time && i === sortedMeetings.length - 1) {
            // last item, w/ prev start time
            end_time = currentBlockEnd;
        } else if (dif.hours() === 0 && dif.minutes() < 30) {
            // If next block time difference is 30 mins or less and we have no start time
            if (!start_time) {
                start_time = currentBlockStart;
            }
        } else if (dif.hours() > 0 || dif.minutes() >= 30) {
            // If next block time difference is over 30 mins, close the block
            if (!start_time) {
                start_time = currentBlockStart;
                end_time = currentBlockEnd;
            } else {
                end_time = currentBlockEnd;
            }
        }

        // Save the meeting group and reset
        if (start_time && end_time) {
            meetingBlocks.push(meetingGroup);
            meetingGroup = [];
            start_time = null;
            end_time = null;
        }
    }

    const shiftedMeetingBlocks =
        meetingBlocks &&
        meetingBlocks.length > 0 &&
        meetingBlocks.map((meetingBlock) => {
            const width = 100 / meetingBlock.length;
            const middleIndex = Math.floor(meetingBlock.length / 2);
            const shiftedBlocks = meetingBlock.map((block, idx) => {
                if (idx === 0) {
                    block.left = 0;
                    block.right = 100 - width;
                } else if (idx === meetingBlock.length - 1) {
                    block.left = 100 - width;
                    block.right = 0;
                } else if (meetingBlock.length % 2 === 0) {
                    if (idx < middleIndex) {
                        block.left = width * idx;
                        block.right = 100 - width * (idx + 1);
                    } else if (idx >= middleIndex) {
                        block.left = width * idx;
                        block.right = 100 - width * (idx + 1);
                    }
                } else if (meetingBlock.length % 2 !== 0) {
                    if (idx === middleIndex) {
                        block.left = width * Math.trunc(meetingBlock.length / 2);
                        block.right = width * Math.trunc(meetingBlock.length / 2);
                    } else if (idx < middleIndex) {
                        block.left = width * idx;
                        block.right = 100 - width * (idx + 1);
                    } else if (idx > middleIndex) {
                        block.left = width * idx;
                        block.right = 100 - width * (idx + 1);
                    }
                }

                return block;
            });

            return shiftedBlocks;
        });

    return shiftedMeetingBlocks;
}

export function getUpdatedCoachSelections(
    availableCoaches,
    currentMonthAvailableCoaches,
    selectedCoaches,
    meetingCoaches,
    userId
) {
    const combinedCoachLists = availableCoaches.concat(meetingCoaches, currentMonthAvailableCoaches);
    const uniqueCoachIds = [
        ...new Set(combinedCoachLists && combinedCoachLists.length && combinedCoachLists.map((coach) => coach.id)),
    ];

    const updatedAvailableCoaches =
        uniqueCoachIds &&
        uniqueCoachIds.length &&
        uniqueCoachIds.map((coachId) => {
            const availableCoach = combinedCoachLists.find((coach) => coach.id === coachId);

            if (availableCoach) {
                return availableCoach;
            }

            return false;
        });

    const currentCoach = updatedAvailableCoaches.find((coach) => coach.id === userId);
    const currentCoachIndex = updatedAvailableCoaches.indexOf(currentCoach);
    updatedAvailableCoaches.splice(currentCoachIndex, 1);
    updatedAvailableCoaches.unshift(currentCoach);

    const newCoaches =
        uniqueCoachIds &&
        uniqueCoachIds.length &&
        uniqueCoachIds
            .filter((coachId) => {
                const availableCoach = availableCoaches.find((availableCoach) => availableCoach.id === coachId);
                const previouslySelectedCoach = selectedCoaches.find((selectedCoach) => selectedCoach.id === coachId);

                if (!availableCoach && !previouslySelectedCoach) {
                    return true;
                }

                return false;
            })
            .map((coachId) => combinedCoachLists.find((coach) => coach.id === coachId));

    const updatedSelectedCoaches =
        selectedCoaches.length > 0 ? selectedCoaches.concat(newCoaches) : updatedAvailableCoaches;

    return { updatedAvailableCoaches, updatedSelectedCoaches };
}

/**
 *
 * @param {*} slots
 * @returns A list of slots with momentized start and end times
 */
export function parseSlots(slots = []) {
    const momentSlots =
        slots &&
        slots.length &&
        slots.map((slot) => {
            return { coach: slot.coach, start_time: moment(slot.start_time), end_time: moment(slot.end_time) };
        });

    return momentSlots;
}

/**
 *
 * @param {*} meetings
 * @returns A list of meetings with momentized start and end times
 */
export function parseMeetings(meetings = []) {
    const calendarMeetingsList =
        (meetings &&
            meetings.length &&
            meetings.map((meeting) => ({
                ...meeting,
                start_time: moment(meeting.start_time),
                end_time: moment(meeting.end_time),
            }))) ||
        [];

    return calendarMeetingsList;
}
