import React from "react";
import { Button, Link, useTheme } from "@mui/material";

import Spinner from "../spinner/Spinner";

export default function ButtonLink({
    analyticsClass = "",
    children,
    compact = false,
    extraPadding = false,
    loading = false,
    disabled = false,
    primary = false,
    link = false,
    underline = false,
    styleOverride = null,
    href = null,
    ...rest
}) {
    const theme = useTheme();

    return (
        <Button
            component={Link}
            sx={{
                textTransform: "none",
                ...styleOverride,
                position: "relative",
                paddingTop: compact ? "0.5rem" : 1,
                paddingBottom: compact ? "0.5rem" : 1,
                padding: extraPadding && 5,
                fontWeight: 400,
                minWidth: "24px !important",
                textDecoration: underline && "underline",
                color: link
                    ? `${theme.palette.grey[600]} !important`
                    : primary
                    ? `${theme.palette.primary.main} !important`
                    : `${theme.palette.tertiary.main} !important`,
                "&:hover": {
                    color: !link && `${theme.palette.tertiary.darker} !important`,
                    backgroundColor: theme.palette.common.white,
                },
                "&:disabled": {
                    color: `${theme.palette.grey[600]} !important`,
                    backgroundColor: theme.palette.common.white,
                },
            }}
            data-button={analyticsClass}
            href={href}
            type="button"
            {...rest}
        >
            {children}
            {loading && (
                <div className="absolute spinner">
                    <Spinner />
                </div>
            )}
        </Button>
    );
}
