import { get, post } from "../../utils/network";
import { gen_requested, gen_error, gen_received } from "./generators";

export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";
export const updateFormData = (data) => (dispatch) => {
    return dispatch({
        type: UPDATE_FORM_DATA,
        data,
    });
};

export const REQUEST_CHECK_COUPON = "REQUEST_CHECK_COUPON";
export const RECEIVE_CHECK_COUPON = "RECEIVE_CHECK_COUPON";
export const RECEIVE_CHECK_COUPON_ERROR = "RECEIVE_CHECK_COUPON_ERROR";
export const checkCoupon = (code) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CHECK_COUPON));
        const result = await get(`/api/v5/groups/check_coupon/?coupon=${code}`);

        return dispatch(gen_received(RECEIVE_CHECK_COUPON, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CHECK_COUPON_ERROR, error));
    }
};

export const REQUEST_CHECK_PREREGISTERED_EMAIL = "REQUEST_CHECK_PREREGISTERED_EMAIL";
export const RECEIVE_CHECK_PREREGISTERED_EMAIL = "RECEIVE_CHECK_PREREGISTERED_EMAIL";
export const RECEIVE_CHECK_PREREGISTERED_EMAIL_ERROR = "RECEIVE_CHECK_PREREGISTERED_EMAIL_ERROR";
export const checkPreregisteredEmail = (email) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_CHECK_PREREGISTERED_EMAIL));
        const result = await get(`/api/v3/preregistered_users/${email}/?expand=branding&fields=email,branding.name`);

        return dispatch(gen_received(RECEIVE_CHECK_PREREGISTERED_EMAIL, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_CHECK_PREREGISTERED_EMAIL_ERROR, error));
    }
};

export const REQUEST_PROVIDER_SIGNUP_USER = "REQUEST_PROVIDER_SIGNUP_USER";
export const RECEIVE_PROVIDER_SIGNUP_USER = "RECEIVE_PROVIDER_SIGNUP_USER";
export const RECEIVE_PROVIDER_SIGNUP_USER_ERROR = "RECEIVE_PROVIDER_SIGNUP_USER_ERROR";
export const signUpProvider = (data) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_PROVIDER_SIGNUP_USER));
        const result = await post("/api/v3/signup-provider", data);

        return dispatch(gen_received(RECEIVE_PROVIDER_SIGNUP_USER, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_PROVIDER_SIGNUP_USER_ERROR, error));
    }
};

export const REQUEST_SIGNUP_USER = "REQUEST_SIGNUP_USER";
export const RECEIVE_SIGNUP_USER = "RECEIVE_SIGNUP_USER";
export const RECEIVE_SIGNUP_USER_ERROR = "RECEIVE_SIGNUP_USER_ERROR";
export const signUpUser = (data) => async (dispatch) => {
    try {
        dispatch(gen_requested(REQUEST_SIGNUP_USER));
        const result = await post("/api/v3/signup", data);

        return dispatch(gen_received(RECEIVE_SIGNUP_USER, result));
    } catch (error) {
        return dispatch(gen_error(RECEIVE_SIGNUP_USER_ERROR, error));
    }
};
