import { get, post } from "../../utils/network";

export const REQUEST_OUTREACH_CHANNELS = "REQUEST_OUTREACH_CHANNELS";
export const RECEIVE_OUTREACH_CHANNELS = "RECEIVE_OUTREACH_CHANNELS";
export const RECEIVE_OUTREACH_CHANNELS_ERROR = "RECEIVE_OUTREACH_CHANNELS_ERROR";

export const getOutreachChannels = () => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_OUTREACH_CHANNELS,
            requestedAt: Date.now(),
        });

        const result = await get("/api/v3/reports/channels/");

        return dispatch({
            type: RECEIVE_OUTREACH_CHANNELS,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_OUTREACH_CHANNELS_ERROR,
            error,
        });
    }
};

export const OUTREACH_SELECT_CHANNEL = "OUTREACH_SELECT_CHANNEL";

export function outreachSelectChannel(index) {
    return {
        type: OUTREACH_SELECT_CHANNEL,
        index: index,
    };
}

export const REQUEST_OUTREACH_USERS = "REQUEST_OUTREACH_USERS";
export const RECEIVE_OUTREACH_USERS = "RECEIVE_OUTREACH_USERS";
export const RECEIVE_OUTREACH_USERS_ERROR = "RECEIVE_OUTREACH_USERS_ERROR";

export const fetchOutreachUsers = (startDate = null, endDate = null) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REQUEST_OUTREACH_USERS,
        });

        const state = getState();
        const params = {
            branding__name: state.outreach.channels[state.outreach.selectedChannelIndex].name,
            page_size: 999999,
            last_login__gte: state.outreach.lastSigninStartDate,
            last_login__lte: state.outreach.lastSigninEndDate,
            last_billable_survey__gte: state.outreach.lastBillableSurveyStartDate,
            last_billable_survey__lte: state.outreach.lastBillableSurveyEndDate,
            fields:
                "id,first_name,last_name,email,last_login,patients_meetings,patient_meetings.id,patient_meetings.answers,last_billable_survey,smart_goals",
            expand: "patient_meetings.answers,smart_goals",
        };
        const result = await get("/api/v3/patients/", { params });

        return dispatch({
            type: RECEIVE_OUTREACH_USERS,
            data: result.data,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_OUTREACH_USERS_ERROR,
            error,
        });
    }
};

export const OUTREACH_USER_SELECT = "OUTREACH_USER_SELECT";
export const outreachUserSelect = (index) => async (dispatch) => {
    dispatch({
        type: OUTREACH_USER_SELECT,
        index,
    });
};

export const OUTREACH_USER_SELECT_ALL = "OUTREACH_USER_SELECT_ALL";
export const outreachUserSelectAll = (selected) => async (dispatch) => {
    dispatch({
        type: OUTREACH_USER_SELECT_ALL,
        selected: selected,
    });
};

export const OUTREACH_LAST_SIGNIN_START_DATE = "OUTREACH_LAST_SIGNIN_START_DATE";
export const outreachLastSigninStartDate = (date) => async (dispatch) => {
    dispatch({
        type: OUTREACH_LAST_SIGNIN_START_DATE,
        date: date,
    });
};

export const OUTREACH_LAST_SIGNIN_END_DATE = "OUTREACH_LAST_SIGNIN_END_DATE";
export const outreachLastSigninEndDate = (date) => async (dispatch) => {
    dispatch({
        type: OUTREACH_LAST_SIGNIN_END_DATE,
        date: date,
    });
};

export const OUTREACH_ENABLE_LAST_SIGNIN_INTERVAL = "OUTREACH_ENABLE_LAST_SIGNIN_INTERVAL";
export const outreachEnableLastSigninInterval = (enabled) => async (dispatch) => {
    dispatch({
        type: OUTREACH_ENABLE_LAST_SIGNIN_INTERVAL,
        enabled: enabled,
    });
};

export const OUTREACH_LAST_BILLABLE_SURVEY_START_DATE = "OUTREACH_LAST_BILLABLE_SURVEY_START_DATE";
export const outreachLastBillableSurveyStartDate = (date) => async (dispatch) => {
    dispatch({
        type: OUTREACH_LAST_BILLABLE_SURVEY_START_DATE,
        date: date,
    });
};

export const OUTREACH_LAST_BILLABLE_SURVEY_END_DATE = "OUTREACH_LAST_BILLABLE_SURVEY_END_DATE";
export const outreachLastBillableSurveyEndDate = (date) => async (dispatch) => {
    dispatch({
        type: OUTREACH_LAST_BILLABLE_SURVEY_END_DATE,
        date: date,
    });
};

export const OUTREACH_ENABLE_LAST_BILLABLE_SURVEY_INTERVAL = "OUTREACH_ENABLE_LAST_BILLABLE_SURVEY_INTERVAL";
export const outreachEnableLastBillableSurveyInterval = (enabled) => async (dispatch) => {
    dispatch({
        type: OUTREACH_ENABLE_LAST_BILLABLE_SURVEY_INTERVAL,
        enabled: enabled,
    });
};

export const OUTREACH_ENABLE_PERSONALIZE_MESSAGE = "OUTREACH_ENABLE_PERSONALIZE_MESSAGE";
export const outreachEnablePerosnalizeMessage = (enabled) => async (dispatch) => {
    dispatch({
        type: OUTREACH_ENABLE_PERSONALIZE_MESSAGE,
        enabled: enabled,
    });
};

export const OUTREACH_ENABLE_PERSONALIZE_MESSAGE_SOAP_NOTE_ADDITIONAL =
    "OUTREACH_ENABLE_PERSONALIZE_MESSAGE_SOAP_NOTE_ADDITIONAL";
export const outreachEnablePerosnalizeMessageSoapNoteAdditional = (enabled) => async (dispatch) => {
    dispatch({
        type: OUTREACH_ENABLE_PERSONALIZE_MESSAGE_SOAP_NOTE_ADDITIONAL,
        enabled: enabled,
    });
};

export const OUTREACH_ENABLE_PERSONALIZE_MESSAGE_SMART_GOAL = "OUTREACH_ENABLE_PERSONALIZE_MESSAGE_SMART_GOAL";
export const outreachEnablePerosnalizeMessageSmartGoal = (enabled) => async (dispatch) => {
    dispatch({
        type: OUTREACH_ENABLE_PERSONALIZE_MESSAGE_SMART_GOAL,
        enabled: enabled,
    });
};

export const OUTREACH_PREPEND_TEXT_CHANGE = "OUTREACH_PREPEND_TEXT_CHANGE";
export const outreachPrependTextChange = (value) => async (dispatch) => {
    dispatch({
        type: OUTREACH_PREPEND_TEXT_CHANGE,
        value: value,
    });
};

export const REQUEST_OUTREACH_GENERATE_MESSAGE = "REQUEST_OUTREACH_GENERATE_MESSAGE";
export const RECEIVE_OUTREACH_GENERATE_MESSAGE = "RECEIVE_OUTREACH_GENERATE_MESSAGE";
export const RECEIVE_OUTREACH_GENERATE_MESSAGE_ERROR = "RECEIVE_OUTREACH_GENERATE_MESSAGE_ERROR";
export const outreachGenerateMessage = (i) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REQUEST_OUTREACH_GENERATE_MESSAGE,
            i: i,
            requestedAt: Date.now(),
        });

        const state = getState();
        let gptRequest = state.outreach.prependText;

        if (state.outreach.perosnalizeMessageSmartGoal) {
            gptRequest += "\n\n" + state.outreach.users[i].smartGoal;
        }

        if (state.outreach.perosnalizeMessageSoapNoteAdditional) {
            gptRequest += "\n\n" + state.outreach.users[i].soapNoteAdditional;
        }

        const requestData = {
            prompt: gptRequest,
        };

        const result = await post("/api/v3/gpt", requestData);

        return dispatch({
            type: RECEIVE_OUTREACH_GENERATE_MESSAGE,
            i: i,
            result: result.data.result,
        });
    } catch (error) {
        return dispatch({
            type: RECEIVE_OUTREACH_GENERATE_MESSAGE_ERROR,
            i: i,
            error,
        });
    }
};

export const OUTREACH_SET_SOAP_NOTE_ADDITIONAL = "OUTREACH_SET_SOAP_NOTE_ADDITIONAL";
export const outreachSetSoapNoteAdditional = (value, i) => async (dispatch) => {
    dispatch({
        type: OUTREACH_SET_SOAP_NOTE_ADDITIONAL,
        value: value,
        i: i,
    });
};

export function SmartGoal(user) {
    let data = "";
    if (user.smart_goals.length > 0) {
        let lastSmartGoal = user.smart_goals[user.smart_goals.length - 1];
        data = `Patient's goal was: "${lastSmartGoal.goal} to ${lastSmartGoal.motivator}"`;
    }
    return data;
}

export function SoapNoteAdditional(user) {
    let data = "";
    if (user.patient_meetings.length > 0) {
        for (let i = 0; i < user.patient_meetings[0].answers.length; i++) {
            if (user.patient_meetings[0].answers[i].question_key === "additional_notes") {
                data += user.patient_meetings[0].answers[i].value;
            }
        }
    }
    return data;
}
