import moment from "moment-timezone";
import { getAvailableDates, getAvailableTimes } from "../../utils/helpers";
import { parseSlots } from "../../utils/scheduler";

import {
    REQUEST_AVAILABLE_TIMES,
    RECEIVE_AVAILABLE_TIMES,
    RECEIVE_AVAILABLE_TIMES_ERROR,
    UPDATE_AVAILABLE_DATES,
    UPDATE_SELECTED_COACHES,
    SELECT_DATE,
    SELECT_TIME,
    SELECT_APPOINTMENT,
    RESET_SCHEDULER_INPUT,
    UPDATE_RENDER_CALENDAR,
    REQUEST_ALL_SLOTS,
    RECEIVE_ALL_SLOTS_ERROR,
    RECEIVE_ALL_SLOTS,
    RECEIVE_CALENDAR_MEETINGS,
    RESET_COACH_CALENDAR,
    RECEIVE_CREATE_PATIENT_MEETING,
    UPDATE_CURRENT_CALENDAR_MONTH,
    UPDATE_CALENDAR_SELECTED_COACHES,
    updateRenderCalendar,
    SET_CREATING_PAID_APPOINTMENT,
    CLEAR_AVAILABLE_TIMES,
} from "../actions";

const timesInitialState = {
    allMonths: [],
    calendarSelectedCoaches: [],
    currentCalendarMonth: {},
    meetingsCoaches: [],
    renderCalendar: true,
    creatingPaidAppointment: false,
};

const PRIORITY_COACH_ID = 19787;

export function scheduler(state = timesInitialState, action) {
    switch (action.type) {
        case REQUEST_AVAILABLE_TIMES:
            return {
                ...state,
                timesIsFetching: true,
                timesRequestedAt: action.requestedAt,
            };
        case RECEIVE_AVAILABLE_TIMES: {
            const {
                data: { times: allAvailableTimes, owner },
            } = action;

            let availableCoaches = Array.from(
                new Set(allAvailableTimes.map((appointment) => appointment.coach.id))
            ).map((id) => {
                const availableTime = allAvailableTimes.find((item) => item.coach.id === id);
                if (availableTime) {
                    return availableTime.coach;
                }

                return false;
            });

            let selectedCoaches = [];
            if (owner && owner.id && availableCoaches.find((item) => item.id === owner.id)) {
                selectedCoaches = [availableCoaches.find((c) => c.id === owner.id)];
            } else if (!action.coaches || !action.coaches.length) {
                // Select all coaches
                selectedCoaches = availableCoaches;
            }

            const availableDates = getAvailableDates(allAvailableTimes, selectedCoaches);

            const selectedDate = availableDates[0];
            const availableTimes = getAvailableTimes(allAvailableTimes, selectedCoaches, selectedDate);

            return {
                ...state,
                availableCoaches,
                availableDates,
                allAvailableTimes,
                availableTimes,
                selectedCoaches,
                selectedDate,
                selectedTime: null,
                selectedAppointment: null,
                timesIsFetching: false,
                timesReceivedAt: action.receivedAt,
            };
        }
        case CLEAR_AVAILABLE_TIMES: {
            return {
                ...state,
                availableCoaches: [],
                availableDates: [],
                allAvailableTimes: [],
                allAvailableSlots: [],
                availableTimes: [],
                timesReceivedAt: null,
            };
        }
        case REQUEST_ALL_SLOTS:
            return {
                ...state,
                slotsIsFetching: true,
                slotsRequestedAt: action.requestedAt,
            };
        case RECEIVE_ALL_SLOTS: {
            const { slots: allAvailableSlots, month, year } = action.data;
            const availableCoaches = Array.from(new Set(allAvailableSlots.map((slot) => slot.coach.id))).map((id) => {
                const availableSlot = allAvailableSlots.find((item) => item.coach.id === id);

                if (availableSlot) {
                    return availableSlot.coach;
                }

                return false;
            });

            let allCurrentMonths = [...state.allMonths];
            allCurrentMonths.forEach((currentMonth) => {
                if (currentMonth.month === month && currentMonth.year === year) {
                    const index = allCurrentMonths.indexOf(currentMonth);
                    allCurrentMonths.splice(index, 1);
                }
            });

            const momentSlots = parseSlots(allAvailableSlots);

            allCurrentMonths.push({
                month,
                year,
                slots: momentSlots,
                availableCoaches,
            });

            return {
                ...state,
                slotsIsFetching: false,
                slotsReceivedAt: action.receivedAt,
                allMonths: allCurrentMonths,
            };
        }
        case RECEIVE_ALL_SLOTS_ERROR:
            return {
                ...state,
                slotsIsFetching: false,
                slotsReceivedAt: action.receivedAt,
                slotsError: action.error,
            };
        case RECEIVE_CALENDAR_MEETINGS: {
            const { data: meetings, me } = action;
            const meetingCoachesIds = [new Set(meetings.map((meeting) => meeting.coach.id))];
            const currentCoach = meetingCoachesIds.find((coachId) => me.id === coachId);

            const meetingsCoaches = meetingCoachesIds.filter((meetingCoachId) =>
                meetings.find((meeting) => meetingCoachId === meeting.coach.di)
            );

            if (!currentCoach) {
                const { first_name, last_name, id, profile_image } = me;

                meetingsCoaches.unshift({
                    first_name,
                    last_name,
                    id,
                    profile_image,
                });
            }

            return {
                ...state,
                meetingsCoaches,
            };
        }
        case RESET_COACH_CALENDAR:
            return {
                ...state,
                allMonths: [],
            };
        case UPDATE_RENDER_CALENDAR:
            return {
                ...state,
                renderCalendar: action.data,
            };
        case RECEIVE_AVAILABLE_TIMES_ERROR:
            return {
                ...state,
                availableTimesError: action.error,
            };
        case UPDATE_AVAILABLE_DATES:
            let updatedDates = [];
            let currentSelectedDate = state.selectedDate;

            for (let i = 0; i < action.availableDates.length; i++) {
                updatedDates.push(moment(action.dates[i].start_time).format());
            }

            if (!updatedDates.includes(currentSelectedDate)) {
                currentSelectedDate = null;
            }

            return {
                ...state,
                dates: updatedDates,
                selectedDate: currentSelectedDate,
            };
        case UPDATE_SELECTED_COACHES: {
            const { allAvailableTimes, availableCoaches } = state;
            const selectedCoaches = action.coaches;
            const allSelectedCoaches = !selectedCoaches || !selectedCoaches.length ? availableCoaches : selectedCoaches;
            const availableDates = getAvailableDates(allAvailableTimes, allSelectedCoaches);
            const selectedDate = availableDates && availableDates.length > 0 ? availableDates[0] : null;
            const availableTimes =
                selectedDate && getAvailableTimes(allAvailableTimes, allSelectedCoaches, selectedDate);

            // Date picker component doesn't have a way to be controlled when displayed statically, so we have to unrender and re-render it with an initial state
            setTimeout(() => {
                action.asyncDispatch(updateRenderCalendar(true));
            }, 1);

            return {
                ...state,
                availableDates,
                availableTimes,
                selectedCoaches,
                selectedDate,
                selectedTime: null,
                renderCalendar: false,
            };
        }
        case SELECT_DATE: {
            const { allAvailableTimes, selectedCoaches } = state;
            const selectedDate = action.date;
            const availableTimes = getAvailableTimes(allAvailableTimes, selectedCoaches, selectedDate);

            return {
                ...state,
                availableTimes,
                selectedDate,
                selectedTime: null,
                selectedAppointment: null,
            };
        }
        case SELECT_TIME: {
            const { allAvailableTimes, selectedDate, selectedCoaches } = state;
            const { time: selectedTime } = action;
            let selectedAppointment = null;

            const possibleAppointments = allAvailableTimes.filter((availableTime) => {
                const { start_time } = availableTime;
                const availableTimeDay = moment(start_time).startOf("day");
                const selectedDateDay = moment(selectedDate).startOf("day");

                if (selectedDateDay.format() === availableTimeDay.format()) {
                    const appointmentTime = moment(start_time).format("h:mm A");

                    return appointmentTime === selectedTime;
                } else {
                    return false;
                }
            });

            if (selectedCoaches && selectedCoaches.length === 1) {
                selectedAppointment = possibleAppointments.find((item) => item.coach.id === selectedCoaches[0].id);
            } else {
                // Select priority coach if available
                const priorityAppointment = possibleAppointments.find((appt) => appt.coach.id === PRIORITY_COACH_ID);

                if (priorityAppointment) {
                    selectedAppointment = priorityAppointment;
                } else {
                    selectedAppointment = possibleAppointments[0];
                }
            }

            return {
                ...state,
                selectedTime,
                selectedAppointment,
            };
        }
        case SELECT_APPOINTMENT:
            return {
                ...state,
                selectedAppointment: action.appointment,
            };
        case SET_CREATING_PAID_APPOINTMENT:
            return {
                ...state,
                creatingPaidAppointment: action.creatingPaidAppointment,
            };
        case RESET_SCHEDULER_INPUT:
            return {
                ...state,
                selectedDate: null,
                selectedTime: null,
                selectedAppointment: null,
            };
        case RECEIVE_CREATE_PATIENT_MEETING: {
            const { currentCalendarMonth } = { ...state };
            const { start_time, end_time, coach } = action.data;
            const startMoment = moment(start_time);
            const endMoment = moment(end_time);
            const updatedCalendarMonthSlots =
                currentCalendarMonth.slots &&
                currentCalendarMonth.slots.length > 0 &&
                currentCalendarMonth.slots.filter((slot) => {
                    const { start_time: slotStartTime, end_time: slotEndTime, coach: slotCoach } = slot;

                    return (
                        (slotStartTime.valueOf() !== startMoment.valueOf() &&
                            slotEndTime.valueOf() !== endMoment.valueOf()) ||
                        (slotStartTime.valueOf() === startMoment.valueOf() &&
                            slotEndTime.valueOf() === endMoment.valueOf() &&
                            slotCoach.id !== coach.id)
                    );
                });

            currentCalendarMonth.slots = updatedCalendarMonthSlots;

            return {
                ...state,
                currentCalendarMonth,
            };
        }
        case UPDATE_CURRENT_CALENDAR_MONTH:
            return {
                ...state,
                currentCalendarMonth: action.month,
            };
        case UPDATE_CALENDAR_SELECTED_COACHES:
            return {
                ...state,
                calendarSelectedCoaches: action.coaches,
            };
        default:
            return state;
    }
}
