import { connect } from "@giantmachines/redux-websocket";
import qs from "query-string";
import { push } from "connected-react-router";
import {
    REQUEST_FINISH_FLOW_STEPS,
    RECEIVE_FINISH_FLOW_STEPS_DATA,
    RECEIVE_FINISH_FLOW_STEPS_ERROR,
    REQUEST_FINISH_SINGLE_FLOW_STEP,
    RECEIVE_FINISH_SINGLE_FLOW_STEP,
    RECEIVE_FINISH_SINGLE_FLOW_STEP_ERROR,
    REQUEST_LOGIN,
    RECEIVE_LOGIN_DATA,
    RECEIVE_LOGIN_ERROR,
    REQUEST_LOGOUT,
    RECEIVE_LOGOUT_DATA,
    RECEIVE_LOGOUT_ERROR,
    RECEIVE_ME,
    RECEIVE_ME_ERROR,
    REQUEST_ME,
    fetchCategories,
    REQUEST_TIMEZONE_UPDATE,
    RECEIVE_TIMEZONE_UPDATE,
    RECEIVE_TIMEZONE_UPDATE_ERROR,
    REQUEST_UPDATE_COACH_BUSY_STATUS,
    RECEIVE_UPDATE_COACH_BUSY_STATUS,
    RECEIVE_UPDATE_COACH_BUSY_STATUS_ERROR,
    updateMyTimezone,
    RECEIVE_REFRESH_PROVIDER_SUBSCRIPTION_DATA,
    REQUEST_REFRESH_SUBSCRIPTION,
    RECEIVE_REFRESH_SUBSCRIPTION_DATA,
    RECEIVE_REFRESH_SUBSCRIPTION_ERROR,
    initializeFlow,
    REQUEST_INITIALIZE_FLOW,
    RECEIVE_INITIALIZE_FLOW,
    RECEIVE_INITIALIZE_FLOW_ERROR,
    flowRedirect,
    REQUEST_UPDATE_ME,
    RECEIVE_UPDATE_ME,
    RECEIVE_UPDATE_ME_ERROR,
    refreshProviderSubscription,
    REQUEST_SUBMIT_SUBDOMAIN,
    RECEIVE_SUBMIT_SUBDOMAIN,
    RECEIVE_SUBMIT_SUBDOMAIN_ERROR,
    SET_CLIENT_PAID,
    getClientCredits,
    getClientSubscriptions,
    RECIEVE_VALIDATE_SES_VERIFICATION,
    getUnopenedLeads,
} from "../actions";
import {
    DTC_SIGNUP_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    INVITE_SIGNUP_ROUTE_PUBLIC,
    LOGIN_ROUTE,
    PROVIDER_MAP_RESULTS_ROUTE,
    PROVIDER_SEARCH_ROUTE,
    PROVIDER_SIGNUP_ROUTE,
    RESET_PASSWORD_ROUTE,
    SIGNUP_ROUTE,
    PROVIDER_RESULT_ROUTE,
    PROVIDER_CLINIC_ROOT,
} from "../../utils/routes";
import { TEST_SUBDOMAINS, getSystemTimezone, isMFRGroup } from "../../utils/helpers";
import { knownFlowStep } from "../../utils/flows";
import { requestNotificationPermissions } from "../../utils/notifications";
import { websocketURL } from "../../utils/network";
import { getBrandingFromGroup, getGroupDomain } from "../../utils/branding";
import { creditsFragment } from "../fragments/prices";
import { getMyActiveSubscription } from "../../utils/subscriptions";

const meInitialState = {
    isFetching: false,
    clientPaid: false,
    refreshSubscriptionIsFetching: false,
    stripe_subscription_status: null,
    program: undefined,
};

const IGNORE_REDIRECT_DOMAINS = ["directory.mfrtherapists.com", "mfrtherapists.com"];

export function me(state = meInitialState, action) {
    switch (action.type) {
        case REQUEST_ME:
            return {
                ...state,
                isFetching: true,
                meRequestedAt: action.requestedAt,
                meReceivedAt: undefined,
                error: undefined,
            };
        case RECEIVE_ME:
        case RECEIVE_LOGIN_DATA: {
            const windowDomain = window.location.hostname;
            const windowPath = window.location.pathname;
            const groupDomain = getGroupDomain(action.data.group);
            const search = window.location.search;
            const params = qs.parse(search);

            // If a group domain exists and we arent in a dev environment, redirect to the proper domain
            if (
                groupDomain &&
                !TEST_SUBDOMAINS.includes(windowDomain) &&
                !IGNORE_REDIRECT_DOMAINS.includes(windowDomain) &&
                groupDomain !== windowDomain
            ) {
                window.location.assign(
                    `https://${groupDomain}${params && params.redirect ? `/v3/${params.redirect}` : windowPath}`
                );
            } else if (params && params.redirect) {
                window.location.assign(`https://${windowDomain}/v3/${params.redirect}`);
            }

            action.asyncDispatch(connect(websocketURL()));
            action.asyncDispatch(fetchCategories({ params: { page_size: 0 } }));

            const meData = { ...action.data };
            const branding = getBrandingFromGroup(meData.group);
            const {
                id: myId,
                is_coach,
                timezone,
                group: {
                    products: childProducts,
                    parent: { products: parentProducts },
                },
            } = meData;
            meData.branding = branding;
            const products = [...childProducts, ...parentProducts];
            const isMFR = isMFRGroup(meData.group);

            const activeSubscription = getMyActiveSubscription(meData.subscriptions);
            meData.stripe_subscription_status = activeSubscription?.status;

            if (meData.group && meData.group.owner && meData.group.owner.stripe_publishable_key) {
                const stripe =
                    window && window.Stripe ? window.Stripe(meData.group.owner.stripe_publishable_key) : null;

                if (stripe) meData.stripe_instance = stripe;
            } else if (
                meData.group &&
                meData.group.parent &&
                meData.group.parent.owner &&
                meData.group.parent.owner.stripe_publishable_key
            ) {
                const stripe =
                    window && window.Stripe ? window.Stripe(meData.group.parent.owner.stripe_publishable_key) : null;

                if (stripe) meData.stripe_instance = stripe;
            }

            if (isMFR) action.asyncDispatch(getUnopenedLeads());
            if (products && products.length > 0) {
                // Refresh subscription status manually from Stripe for the case when Stripe hooks delayed
                // action.asyncDispatch(refreshSubscription());
                action.asyncDispatch(
                    getClientCredits({
                        page_size: 0,
                        ...creditsFragment,
                    })
                );
                action.asyncDispatch(
                    getClientSubscriptions({
                        page_size: 0,
                        ...creditsFragment,
                    })
                );
            }

            if (meData.is_coach && meData.branding && meData.branding.is_organization) {
                // Refresh provider subscription status manually from Stripe for the case when Stripe hooks delayed
                action.asyncDispatch(refreshProviderSubscription());
            }

            // update the user's timezone if their current system time is different than what we have stored
            const currentTimezone = getSystemTimezone();
            if (currentTimezone && currentTimezone.length && currentTimezone !== timezone) {
                action.asyncDispatch(updateMyTimezone(myId, currentTimezone));
            }

            // dispatch an action to configure the user's flow and remove it from meData
            action.asyncDispatch(initializeFlow(action.data));
            delete meData.flow;

            // request notification permissions for coaches
            if (is_coach) requestNotificationPermissions();

            return {
                ...state,
                ...meData,
                isFetching: false,
                meReceivedAt: action.receivedAt,
            };
        }
        case RECEIVE_ME_ERROR:
            const {
                location: { pathname },
            } = window;
            const publicRoutes = [
                FORGOT_PASSWORD_ROUTE,
                DTC_SIGNUP_ROUTE,
                INVITE_SIGNUP_ROUTE_PUBLIC,
                LOGIN_ROUTE,
                PROVIDER_SIGNUP_ROUTE,
                RESET_PASSWORD_ROUTE,
                SIGNUP_ROUTE,
                PROVIDER_SEARCH_ROUTE,
                PROVIDER_RESULT_ROUTE,
                PROVIDER_MAP_RESULTS_ROUTE,
                PROVIDER_RESULT_ROUTE,
                PROVIDER_CLINIC_ROOT,
            ];

            // redirect to login if on a non-public route
            if (!publicRoutes.some((r) => pathname.includes(r)) || pathname === INVITE_SIGNUP_ROUTE_PUBLIC) {
                if (window.location.pathname && window.location.pathname !== "/") {
                    window.location.assign(
                        `${LOGIN_ROUTE}${window.location.search}${
                            window.location.search ? "&" : "?"
                        }redirect=${window.location.pathname.replace("/v3/", "")}`
                    );
                } else {
                    action.asyncDispatch(push(`${LOGIN_ROUTE}${window.location.search}`));
                }
            }

            return {
                ...state,
                error: action.error,
                isFetching: false,
                meReceivedAt: action.receivedAt,
            };

        case RECEIVE_REFRESH_PROVIDER_SUBSCRIPTION_DATA: {
            const { stripe_subscription_status } = action.data;
            state.details_submitted = action.data.stripe_account && action.data.stripe_account.details_submitted;

            if (
                stripe_subscription_status &&
                state.branding &&
                state.branding.stripe_subscription_status !== stripe_subscription_status
            ) {
                return {
                    ...state,

                    branding: {
                        ...state.branding,
                        stripe_subscription_status, // comment this line to test locally if no stripe webhook exists
                    },
                };
            }

            return state;
        }
        case REQUEST_REFRESH_SUBSCRIPTION:
            return {
                ...state,
                refreshSubscriptionIsFetching: true,
                refreshSubscriptionRequestedat: action.requestedAt,
            };
        case RECEIVE_REFRESH_SUBSCRIPTION_DATA: {
            const { stripe_subscription_status } = action.data;

            const returnState = {
                ...state,
                refreshSubscriptionIsFetching: false,
                refreshSubscriptionReceivedAt: action.receivedAt,
            };

            if (state.stripe_subscription_status !== stripe_subscription_status) {
                returnState.stripe_subscription_status = stripe_subscription_status; // comment this line to test locally if no stripe webhook exists
            }

            return returnState;
        }
        case RECEIVE_REFRESH_SUBSCRIPTION_ERROR:
            return {
                ...state,
                refreshSubscriptionIsFetching: false,
                refreshSubscriptionReceivedAt: action.receivedAt,
                refreshSubscriptionError: action.error,
            };
        case REQUEST_LOGOUT:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_LOGOUT_DATA:
            action.asyncDispatch(push(LOGIN_ROUTE));
            return meInitialState;
        case RECEIVE_LOGOUT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case REQUEST_LOGIN:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            };

        case RECEIVE_LOGIN_ERROR:
            return {
                ...state,
                error: action.error,
                isFetching: false,
            };

        case REQUEST_FINISH_FLOW_STEPS:
            return {
                ...state,
                finishFlowStepsRequestedAt: action.requestedAt,
                finishFlowStepsIsFetching: true,
            };
        case RECEIVE_FINISH_FLOW_STEPS_DATA: {
            const {
                data: { stepIds, ...rest },
            } = action;
            const {
                flow: { stepGroups },
            } = state;
            const newStepGroups = { ...stepGroups };

            if (!stepIds || stepIds.length < 1) {
                return state;
            }

            // mark each received step as complete
            stepIds.forEach((stepId) => {
                if (Object.prototype.hasOwnProperty.call(newStepGroups, stepId)) {
                    newStepGroups[stepId].complete = true;
                }
            });

            // update current step group to next incomplete known item
            const newCurrentStepGroup = Object.keys(newStepGroups).find(
                (key) => knownFlowStep(key) && newStepGroups[key].complete === false
            );

            action.asyncDispatch(flowRedirect(newCurrentStepGroup, rest));

            return {
                ...state,
                flow: {
                    stepGroups: newStepGroups,
                    currentStepGroupId: newCurrentStepGroup,
                },
                finishFlowStepsReceivedAt: action.receivedAt,
                finishFlowStepsIsFetching: false,
            };
        }
        case RECEIVE_FINISH_FLOW_STEPS_ERROR:
            return {
                ...state,
                finishFlowStepsError: action.error,
                finishFlowStepsIsFetching: false,
            };
        case REQUEST_FINISH_SINGLE_FLOW_STEP:
            return {
                ...state,
                finishSingleFlowStepRequestedAt: action.requestedAt,
                finishSingleFlowStepIsFetching: true,
            };
        case RECEIVE_FINISH_SINGLE_FLOW_STEP: {
            const {
                data: { stepIds },
            } = action;
            const {
                flow: { stepGroups },
            } = state;
            const stepIdGroup = stepIds[0].split("/")[0];
            const stepIdName = stepIds[0].split("/")[1];
            const newStepGroups = { ...stepGroups };

            if (!stepIds || stepIds.length < 1) {
                return state;
            }

            // Mark step as complete
            stepIds.forEach((stepId) => {
                if (Object.prototype.hasOwnProperty.call(newStepGroups, stepIdGroup)) {
                    const updatedStepGroups = newStepGroups[stepIdGroup].steps.filter((step) => step !== stepIdName);
                    newStepGroups[stepIdGroup].steps = updatedStepGroups;
                }
            });

            return {
                ...state,
                finishSingleFlowStepReceivedAt: action.receivedAt,
                finishSingleFlowStepIsFetching: false,
                flow: {
                    stepGroups: newStepGroups,
                },
            };
        }
        case RECEIVE_FINISH_SINGLE_FLOW_STEP_ERROR:
            return {
                ...state,
                finishSingleFlowStepReceivedAt: action.receivedAt,
                finishSingleFlowStepIsFetching: false,
                finishSingleFlowStepError: action.error,
            };
        case REQUEST_TIMEZONE_UPDATE:
            return {
                ...state,
                updateTimezoneRequestedAt: action.requestedAt,
            };
        case RECEIVE_TIMEZONE_UPDATE:
            return {
                ...state,
                timezone: action.data,
                updateTimezoneReceivedAt: action.receivedAt,
            };
        case RECEIVE_TIMEZONE_UPDATE_ERROR:
            return {
                ...state,
                updateTimezoneError: action.error,
            };
        case REQUEST_UPDATE_COACH_BUSY_STATUS:
            return state;
        case RECEIVE_UPDATE_COACH_BUSY_STATUS:
            return {
                ...state,
                is_busy: action.data.is_busy,
            };
        case RECEIVE_UPDATE_COACH_BUSY_STATUS_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case REQUEST_INITIALIZE_FLOW:
            return {
                ...state,
                flowRequestedAt: action.requestedAt,
                flowIsFetching: true,
            };
        case RECEIVE_INITIALIZE_FLOW:
            return {
                ...state,
                flowReceivedAt: action.receivedAt,
                flowIsFetching: false,
                flow: action.data,
            };
        case RECEIVE_INITIALIZE_FLOW_ERROR:
            return {
                ...state,
                flowReceivedAt: action.receivedAt,
                flowIsFetching: false,
                flowError: action.error,
            };
        case REQUEST_UPDATE_ME:
            return {
                ...state,
                updateMeIsLoading: true,
                updateMeRequestedAt: action.requestedAt,
            };
        case RECEIVE_UPDATE_ME:
            return {
                ...state,
                ...action.data,
                updateMeIsLoading: false,
                updateMeReceivedAt: action.receivedAt,
            };
        case RECEIVE_UPDATE_ME_ERROR:
            return {
                ...state,
                updateMeIsLoading: false,
                updateMeReceivedAt: action.receivedAt,
                updateMeError: action.error,
            };
        case REQUEST_SUBMIT_SUBDOMAIN:
            return {
                ...state,
                submitSubdomainIsFetching: true,
                submitSubdomainRequestedAt: action.requestedAt,
            };
        case RECEIVE_SUBMIT_SUBDOMAIN: {
            const { subdomain } = action.data;
            const currentMe = { ...state };
            currentMe.branding.subdomain = subdomain;

            return {
                ...state,
                submitSubdomainIsFetching: false,
                submitSubdomainReceivedAt: action.receivedAt,
                submitDomainSuccess: true,
            };
        }
        case RECEIVE_SUBMIT_SUBDOMAIN_ERROR:
            return {
                ...state,
                submitSubdomainIsFetching: false,
                submitSubdomainReceivedAt: action.receivedAt,
                submitSubdomainError: action.error,
            };
        case RECIEVE_VALIDATE_SES_VERIFICATION: {
            return {
                ...state,
                aws_ses_identity_verified_for_sending: true,
            };
        }
        case SET_CLIENT_PAID:
            return {
                ...state,
                clientPaid: action.paid,
            };
        default:
            return state;
    }
}
