import React, { useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import qs from "query-string";
import * as yup from "yup";

import { activateUser, getUserInvite, updateFormData } from "../../app/actions";
import { PASSWORD_MATCH, PASSWORD_REGEX, REQUIRED_FIELD } from "../../utils/validations";
import { executeRecaptcha } from "../../utils/helpers";
import { getSignupCopy } from "../../utils/branding";
import { COACH_HOME_ROUTE, HOME_ROUTE, LOGIN_ROUTE } from "../../utils/routes";
import { sendTextFieldFocusEvent } from "../../utils/analytics";

import BrandedLogo from "../logo/BrandedLogo";
import Button from "../button/Button";
import FormError from "../form_error/FormError";
import FormRow from "../form_row/FormRow";
import PasswordField from "../password_field/PasswordField";
import RecaptchaProvider from "../recaptcha_provider/RecaptchaProvider";
import TextInput from "../text_input/TextInput";
import { toast } from "../notifications/Toast";
import Spinner from "../spinner/Spinner";
import Page from "../minimal/Page";

export default function InviteSignup() {
    const dispatch = useDispatch();
    const history = useHistory();
    let { uuid } = useParams();
    const { branding } = useSelector(({ groups }) => groups);
    const signup = useSelector(({ signup }) => signup);
    const { sendGetUserInviteIsLoading, userInfo } = useSelector(({ user }) => user);
    const initialFormValues = {
        password: "",
        confirmPassword: "",
    };
    const productName = branding.product_name;

    const schema = yup.object().shape({
        password: yup.string().required(REQUIRED_FIELD.message).matches(PASSWORD_REGEX.value, PASSWORD_REGEX.message),
        confirmPassword: yup
            .string()
            .required(REQUIRED_FIELD.message)
            .oneOf([yup.ref("password"), null], PASSWORD_MATCH.message),
    });

    useEffect(() => {
        async function init() {
            const result = await dispatch(getUserInvite(uuid));
            const { error } = result;

            if (error) history.push(LOGIN_ROUTE);
        }

        init();
    }, []); // eslint-disable-line

    const handleFormFocus = () => (event) => {
        const {
            target: { id },
        } = event;

        if (id) sendTextFieldFocusEvent(id);
    };

    const handleFormChange = (status, setStatus, setFieldValue) => (event) => {
        const {
            target: { value, id },
        } = event;
        const fieldId = (id && id.length > 0 && id.split(" ")[0]) || "";
        let formData = {
            [fieldId]: value.trim(),
        };

        if (status && Object.keys(status).includes(fieldId)) {
            setStatus(fieldId, null);
        }

        dispatch(updateFormData(formData));
        setFieldValue(fieldId, value);
    };

    const handleFormSubmission = (values, actions, notifySuccess = false) => {
        const { password } = signup.formData;

        executeRecaptcha("providersignup")
            .then(async (recaptchaResponse) => {
                let params = {
                    password,
                    "g-recaptcha-response": recaptchaResponse,
                };

                const result = await dispatch(activateUser(uuid, params));
                const { error } = result;

                if (error) {
                    const errorMessage = error.response ? error.response.data : "Error creating account.";

                    actions.setStatus(error.response.data);

                    if (typeof errorMessage === "string") {
                        toast.error(errorMessage);
                    } else {
                        toast.error(
                            "Something went wrong signing you up. Please address the errors above and try again."
                        );
                    }
                } else {
                    const {
                        location: { search },
                    } = window;

                    let queryFields = qs.parse(search);
                    if (queryFields.is_provider) {
                        window.location.assign(COACH_HOME_ROUTE);
                    } else {
                        window.location.assign(HOME_ROUTE);
                    }
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    return (
        <RecaptchaProvider>
            <Page title="Invite Signup">
                <div className="ts-signup">
                    <div className="container mb-5">
                        <header className="ts-signup__header">
                            {branding && !branding.isFetching && branding.receivedAt && (
                                <div className="ts-signup__header__logo">
                                    <BrandedLogo type="color_logo" className="ts-branded-logo-signup" />
                                </div>
                            )}
                        </header>
                        <div className="ts-signup-container row no-gutters">
                            <div className="ts-signup-form col-12 col-lg-8 px-0 px-md-3 px-md-5 py-3 py-md-5">
                                {initialFormValues && (
                                    <Formik
                                        initialValues={{
                                            password: initialFormValues.password,
                                            confirmPassword: initialFormValues.confirmPassword,
                                        }}
                                        enableReinitialize={true}
                                        onSubmit={handleFormSubmission}
                                        validationSchema={schema}
                                    >
                                        {({
                                            handleSubmit,
                                            setStatus,
                                            setFieldValue,
                                            status,
                                            values,
                                            errors,
                                            touched,
                                        }) => (
                                            <div>
                                                <div className="col-12 offset-lg-0 px-0 mb-2 mb-md-4">
                                                    <div className="ts-welcome-title text-main text-medium-bold text-xl">
                                                        {branding.is_organization
                                                            ? "Welcome!"
                                                            : `Welcome to ${productName}`}
                                                    </div>
                                                    <div className="ts-welcome-subtitle text-secondary text-normal text-md my-3">
                                                        {getSignupCopy("title", branding, productName)}
                                                    </div>
                                                    <div className="ts-welcome-paragraph text-main text-md mb-2">
                                                        {getSignupCopy("subtitle", branding, productName)}
                                                    </div>
                                                </div>
                                                {sendGetUserInviteIsLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <Spinner />
                                                    </div>
                                                ) : (
                                                    <form className="col-12 px-0" onSubmit={handleSubmit}>
                                                        <FormRow bottomSpacing>
                                                            <span className="text-bold text-main">
                                                                Login email: {userInfo.email}
                                                            </span>
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <PasswordField
                                                                id="password form-field__password"
                                                                name="password"
                                                                className="form-field__password"
                                                                label="Password"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values.password}
                                                                type="password"
                                                                error={errors.password}
                                                                touched={touched.password}
                                                                required
                                                            />
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors.password &&
                                                                    status &&
                                                                    status.password
                                                                }
                                                                message={status && status.password}
                                                            />
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <TextInput
                                                                id="confirmPassword form-field__confirmPassword"
                                                                name="confirmPassword"
                                                                label="Re-Enter Password"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values.confirmPassword}
                                                                type="password"
                                                                error={errors.confirmPassword}
                                                                touched={touched.confirmPassword}
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        </FormRow>
                                                        <FormRow
                                                            bottomSpacing
                                                            className="ts-signup-tos text-main text-xs"
                                                        >
                                                            By completing your registration you are agreeing to the{" "}
                                                            <a
                                                                href="https://orbittelehealth.com/provider-patient-terms/"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-secondary text-underline"
                                                            >
                                                                Terms and Conditions
                                                            </a>
                                                        </FormRow>
                                                        <FormRow>
                                                            <Button
                                                                square
                                                                secondary
                                                                className="ts-signup-submit"
                                                                analyticsClass="button__submit-invite-registration"
                                                                type="submit"
                                                                disabled={signup.providerSignupIsFetching}
                                                            >
                                                                Complete Registration
                                                            </Button>
                                                        </FormRow>
                                                    </form>
                                                )}
                                            </div>
                                        )}
                                    </Formik>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </RecaptchaProvider>
    );
}
