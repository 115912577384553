import React, { useRef } from "react";

import Slider from "react-slick";
import Image from "./Image";

export default function ImageCarousel({ imagesContainer }) {
    const images = imagesContainer.map((i) => i.image);
    const ref = useRef(null);

    const settings = {
        customPaging: function (i) {
            return (
                <div>
                    <img src={images[i]} alt="office thumbnail" />
                </div>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Slider ref={ref} {...settings}>
            {images.length > 0 && images.map((image, idx) => <Image key={idx} src={image} alt="Clinic image" />)}
        </Slider>
    );
}
