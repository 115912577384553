import React from "react";
import { Button, Link, useTheme } from "@mui/material";

import Spinner from "../spinner/Spinner";

export default function ButtonMain({
    analyticsClass = "",
    children,
    compact = false,
    disabled = false,
    extraPadding = false,
    fullHeight = false,
    fullWidth = false,
    loading = false,
    outlined = false,
    styleOverride = {},
    href = null,
    ...rest
}) {
    const theme = useTheme();

    return (
        <Button
            component={Link}
            disabled={disabled}
            sx={{
                ...styleOverride,
                position: "relative !important",
                paddingTop: "0.5rem !important",
                paddingBottom: "0.5rem !important",
                padding: extraPadding
                    ? "8px 40px !important"
                    : outlined
                    ? "4px 16px !important"
                    : "6px 16px !important",
                borderRadius: "10px !important",
                width: fullWidth ? "100% !important" : "auto !important",
                height: fullHeight ? "100% !important" : "auto !important",
                fontWeight: "400 !important",
                fontSize: "16px !important",
                color: outlined
                    ? `${theme.palette.primary.main} !important`
                    : `${theme.palette.common.white} !important`,
                border: outlined ? `1px solid ${theme.palette.primary.main} !important` : "none !important",
                backgroundColor: outlined
                    ? `${theme.palette.common.white} !important`
                    : `${theme.palette.primary.main} !important`,
                "&:hover": {
                    backgroundColor: outlined
                        ? `${theme.palette.primary.main} !important`
                        : `${theme.palette.primary.dark} !important`,
                    color: outlined
                        ? `${theme.palette.common.white} !important`
                        : `${theme.palette.common.white} !important`,
                },
                "&:disabled": {
                    border: `1px solid ${theme.palette.grey[600]} !important`,
                    color: `${theme.palette.grey[600]} !important`,
                    backgroundColor: "white !important",
                },
            }}
            data-button={analyticsClass}
            type="button"
            href={href}
            {...rest}
        >
            {children}
            {loading && (
                <div className="absolute spinner">
                    <Spinner />
                </div>
            )}
        </Button>
    );
}
