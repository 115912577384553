import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip, InputAdornment, TextField, Typography } from "@mui/material";
import cx from "classnames";

import { createProviderCredential, getAllCredentials } from "../../app/actions";

import Iconify from "../minimal/Iconify";
import { toast } from "../notifications/Toast";
import { isEnterKey } from "../../utils/helpers";

export default function CredentialsSelector({ credentials, providerId, updateSelectedCredentials }) {
    const dispatch = useDispatch();
    const mfr = useSelector(({ mfr }) => mfr);
    const [isSearchingTags, setIsSearchingTags] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [showSearchPopover, setShowSearchPopover] = useState(false);

    useEffect(() => {
        async function init() {
            dispatch(getAllCredentials());
        }

        init();
    }, []); // eslint-disable-line

    useEffect(() => {
        const credsFilterTimer = setTimeout(() => {
            if (isSearchingTags && inputValue && inputValue.length > 1) {
                const credFilters =
                    mfr &&
                    mfr.credentialsList &&
                    mfr.credentialsList.length > 0 &&
                    mfr.credentialsList.filter((cred) => {
                        const title = cred.toLowerCase();
                        return title.includes(inputValue.toLowerCase());
                    });
                const credOptions = credFilters.map((filteredCred, idx) => ({ label: filteredCred }));

                setSuggestions(credOptions);
                setShowSearchPopover(true);
                setIsSearchingTags(false);
            } else if (showSearchPopover && inputValue && inputValue.length < 3) {
                setShowSearchPopover(false);
            }
        }, 100);

        return () => {
            clearTimeout(credsFilterTimer);
        };
    }, [inputValue]); //eslint-disable-line

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setIsSearchingTags(true);
    };

    const handleAddCredential = async (credential) => {
        const existingCred = credentials.find((c) => c.credential.toLowerCase() === credential.label.toLowerCase());
        if (existingCred) return;

        const result = await dispatch(
            createProviderCredential({ profile: providerId, credential: credential.label.toUpperCase() })
        );
        const { error, data } = result;

        if (error) {
            toast.error("Error adding credential");
        } else {
            updateSelectedCredentials(data.credential);
            setInputValue("");
            setSuggestions([]);
            setShowSearchPopover(false);
        }
    };

    const handleKeyPress = async (event) => {
        const existingCred = credentials.find((c) => c.credential.toLowerCase() === event.target.value.toLowerCase());
        if (existingCred) return;

        if (isEnterKey(event) && ((inputValue && inputValue.length > 1) || inputValue === "")) {
            event.preventDefault();

            const result = await dispatch(
                createProviderCredential({ profile: providerId, credential: event.target.value.toUpperCase() })
            );
            const { error, data } = result;

            if (error) {
                toast.error("Error adding credential");
            } else {
                updateSelectedCredentials(data.credential);
                setInputValue("");
                setSuggestions([]);
                setShowSearchPopover(false);
            }
        }
    };

    return (
        <>
            <div className="px-3 py-2 flex-column">
                <TextField
                    className="rounded-pill outline-none"
                    placeholder="Search for credentials"
                    variant="outlined"
                    onChange={handleInputChange}
                    onKeyUp={handleKeyPress}
                    value={inputValue}
                    fullWidth
                    sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        "& input": { py: "16.5px", height: "23px" },
                        width: "100%",
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify
                                    icon={"eva:search-fill"}
                                    sx={{
                                        color: "text.disabled",
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
                {/* {credentials &&
                    credentials.length > 0 &&
                    credentials.map((c, idx) => (
                        <Chip
                            key={idx}
                            size="small"
                            label={c.credential}
                            sx={{ m: 0.5 }}
                            onClick={() => handleCredentialClick(c)}
                        />
                    ))} */}
            </div>
            {suggestions && suggestions.length > 0 && (
                <Typography variant="overline" sx={{ px: 2 }}>
                    Suggestions
                </Typography>
            )}
            {suggestions && suggestions.length > 0 && (
                <div className="px-3 py-2 flex-column">
                    {suggestions.map((credential, idx) => (
                        <Chip
                            key={idx}
                            size="small"
                            label={credential.label}
                            sx={{ m: 0.5 }}
                            onClick={() => handleAddCredential(credential)}
                        />
                    ))}
                </div>
            )}
            {showSearchPopover && suggestions.length === 0 && (
                <div
                    className={cx(
                        "d-flex flex-fill p-3",
                        suggestions && suggestions.length > 0
                            ? "ts-resource-search-helper align-items-center"
                            : "align-items-center"
                    )}
                >
                    <div className="d-flex align-items-center w-100">
                        <Typography variant="body2">
                            Press Enter key to add <span className="text-bold">"{inputValue}"</span> as credential
                        </Typography>
                        <div className="flex-fill text-right">
                            <Iconify
                                icon={"bi:arrow-return-left"}
                                sx={{
                                    color: "text.disabled",
                                    width: 20,
                                    height: 20,
                                    mr: 2,
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {/* <SearchPopover
                open
                tags={mfr.credentialsList}
                hideSearch
                handleTagClick={handleCredentialClick}
                inputValue={inputValue}
                is_coach
            /> */}
        </>
    );
}
