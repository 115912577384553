import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import qs from "query-string";
import moment from "moment-timezone";
import * as yup from "yup";
import { InputAdornment } from "@material-ui/core";
import { useHistory, generatePath } from "react-router-dom";

import { checkCoupon, checkPreregisteredEmail, signUpUser, updateFormData } from "../../app/actions";
import { executeRecaptcha, getSystemTimezone, storageAvailable } from "../../utils/helpers";
import { ONBOARDING_TYPEFORM_ANSWERS_LOCAL_STORAGE_KEY } from "../../utils/flows";
import { getBrandingSubdomain, getSignupCopy } from "../../utils/branding";
import { HOME_ROUTE, LOGIN_ROUTE } from "../../utils/routes";
import { otherGenderId } from "../../utils/user";
import {
    DATE_OF_BIRTH_MINIMUM,
    EMAIL,
    INVALID_CODE,
    INVALID_DATE,
    INVALID_DATE_FORMAT,
    INVALID_WELLVIEW_EMAIL,
    MOBILE_NUMBER_DIGITS,
    MOBILE_NUMBER_LENGTH,
    PASSWORD_MATCH,
    PASSWORD_REGEX,
    REQUIRED_FIELD,
} from "../../utils/validations";
import {
    sendButtonClick,
    sendButtonGroupClickEvent,
    sendDatePickerOpenEvent,
    sendTextFieldFocusEvent,
} from "../../utils/analytics";

import BrandedLogo from "../logo/BrandedLogo";
import Button from "../button/Button";
import DatePicker from "../date_picker/DatePicker";
import FormError from "../form_error/FormError";
import FormRow from "../form_row/FormRow";
import GenderSelectionButtonGroup from "../button/GenderSelectionButtonGroup";
import PasswordField from "../password_field/PasswordField";
import RecaptchaProvider from "../recaptcha_provider/RecaptchaProvider";
import TextInput from "../text_input/TextInput";
import { toast } from "../notifications/Toast";
import Page from "../minimal/Page";

const couponCodeId = "coupon_used";
const emailId = "email";
const couponCheckDelay = 500;

function ValidationAdornment({ show, valid }) {
    return (
        <InputAdornment>
            {show && (
                <div className="text-lg">
                    {valid ? <i className="fas fa-check text-green" /> : <i className="fas fa-times text-red" />}
                </div>
            )}
        </InputAdornment>
    );
}

export default function Signup() {
    const dispatch = useDispatch();
    const history = useHistory();
    const signup = useSelector(({ signup }) => signup);
    const {
        branding,
        defaultGroupReceivedAt,
        isFetchingDefaultGroup,
        signupConfig: { signup_enabled, signup_preregistered_enabled, coupon_code_required },
    } = useSelector(({ groups }) => groups);
    const [promoCode, setPromoCode] = useState();
    const [extraParams, setExtraParams] = useState(null);
    const [initialFormValues, setInitialFormValues] = useState({
        first_name: "",
        last_name: "",
        birthday: null,
        gender: "",
        otherGender: "",
        coupon_used: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
    });
    const [prefilledFields, setPrefilledFields] = useState();
    const subdomain = getBrandingSubdomain();
    const productName = branding && branding.product_name;
    const termsLink =
        branding && branding.is_organization
            ? "https://orbittelehealth.com/provider-patient-terms/"
            : "https://orbittelehealth.com/terms/";
    const requireCode = coupon_code_required || (coupon_code_required && !signup_preregistered_enabled) ? true : false;

    const schema = yup.object().shape({
        first_name: yup.string().required(REQUIRED_FIELD.message),
        last_name: yup.string().required(REQUIRED_FIELD.message),
        birthday: yup
            .date()
            .max(moment().subtract(DATE_OF_BIRTH_MINIMUM.value, "years").format(), DATE_OF_BIRTH_MINIMUM.message)
            .required(REQUIRED_FIELD.message)
            .typeError(INVALID_DATE_FORMAT.message)
            .nullable(),
        gender: yup.string().required(REQUIRED_FIELD.message).min(1, REQUIRED_FIELD.message),
        coupon_used: requireCode && yup.string().required(REQUIRED_FIELD.message),
        email: yup.string().required(REQUIRED_FIELD.message).email(EMAIL.message),
        phone: yup
            .string()
            .length(MOBILE_NUMBER_LENGTH.value, MOBILE_NUMBER_LENGTH.message)
            .matches(MOBILE_NUMBER_DIGITS.value, MOBILE_NUMBER_DIGITS.message),
        password: yup.string().required(REQUIRED_FIELD.message).matches(PASSWORD_REGEX.value, PASSWORD_REGEX.message),
        confirmPassword: yup
            .string()
            .required(REQUIRED_FIELD.message)
            .oneOf([yup.ref("password"), null], PASSWORD_MATCH.message),
    });

    useEffect(() => {
        function initialize() {
            if (
                branding &&
                !isFetchingDefaultGroup &&
                branding.receivedAt &&
                !signup_enabled &&
                branding.name !== "TELE"
            ) {
                history.replace(LOGIN_ROUTE);
            }
        }

        initialize();
    }, [branding]); // eslint-disable-line

    useEffect(() => {
        const couponCheckTimer = setTimeout(
            () => dispatch(checkCoupon(signup.formData.coupon_used, subdomain)),
            couponCheckDelay
        );

        return () => clearTimeout(couponCheckTimer);
    }, [signup.formData.coupon_used]); // eslint-disable-line

    useEffect(() => {
        function prefillForm() {
            const {
                location: { search },
            } = window;

            if (search !== "") {
                let queryFields = qs.parse(search);

                if (queryFields && queryFields.email) queryFields.email = queryFields.email.replace(" ", "+");

                if (queryFields && queryFields.extra) {
                    const params = Object.fromEntries(new URLSearchParams(window.atob(queryFields.extra)));

                    if (params) {
                        setExtraParams({ nimble_user_id: params.user_id, nimble_client_id: params.client_id });
                    }
                }

                const storageIsAvailable = storageAvailable("localStorage");
                if (storageIsAvailable) {
                    const { disability, flags, goal, location, motivation, pain, stress } = queryFields;

                    localStorage.setItem(
                        ONBOARDING_TYPEFORM_ANSWERS_LOCAL_STORAGE_KEY,
                        JSON.stringify({ goal, motivation, location, flags, pain, stress, disability })
                    );
                }

                setPromoCode(queryFields.promotion_code);
                setPrefilledFields(queryFields);
                setInitialFormValues({ ...initialFormValues, ...queryFields });
                dispatch(updateFormData({ ...initialFormValues, ...queryFields }));
            }
        }

        prefillForm();
    }, []); // eslint-disable-line

    const handleFormFocus = () => (event) => {
        const {
            target: { id },
        } = event;

        if (id) sendTextFieldFocusEvent(id);
    };

    const handleDatePickerOpen = () => {
        sendDatePickerOpenEvent();
    };

    const handleFormChange = (status, setStatus, setFieldValue) => (event) => {
        const {
            target: { value, id },
        } = event;
        const fieldId = (id && id.length > 0 && id.split(" ")[0]) || [];
        let formData = {
            [fieldId]: value.trim(),
        };

        if (!requireCode && signup_preregistered_enabled && fieldId === emailId) {
            dispatch(checkPreregisteredEmail(value));
        }

        if (status && Object.keys(status).includes(fieldId)) {
            setStatus(fieldId, null);
        }

        dispatch(updateFormData(formData));
        setFieldValue(fieldId, value);
    };

    const handleGenderChange = (id, value, setFieldValue) => () => {
        const formData = {
            [id]: value.trim(),
        };

        sendButtonGroupClickEvent(id, value);
        dispatch(updateFormData(formData));
        setFieldValue(id, value);
    };

    const handlePhoneNumberChange = (event, setFieldValue) => {
        const {
            target: { value, id },
        } = event;
        const sanitizedNumber = value.replace(/[^0-9]/g, "").substr(0, 10);
        const phoneId = id && id.length > 0 && id.split(" ")[0];
        let formData = {
            [phoneId]: sanitizedNumber,
        };

        dispatch(updateFormData(formData));
        setFieldValue(phoneId, sanitizedNumber, true);
    };

    const handleDateChange = (id, date, setFieldValue) => {
        const formattedDate = moment(date).format("YYYY-M-D");
        let formData = {
            [id]: formattedDate,
        };

        dispatch(updateFormData(formData));
        setFieldValue(id, date, true);
    };

    const handleFormSubmission = (values, actions, notifySuccess = false) => {
        const {
            birthday,
            confirmPassword,
            coupon_used,
            email,
            first_name,
            gender,
            last_name,
            otherGender,
            password,
            phone,
        } = signup.formData;

        if (
            (requireCode && signup.validCode) ||
            (!requireCode && branding && signup_preregistered_enabled && signup.validEmail) ||
            (!requireCode && branding && !signup_preregistered_enabled)
        ) {
            executeRecaptcha("signup")
                .then(async (recaptchaResponse) => {
                    let params = {
                        ...initialFormValues,
                        first_name,
                        last_name,
                        birthday,
                        gender,
                        coupon_used: requireCode ? coupon_used : "",
                        email,
                        phone: `+1${phone}`,
                        password,
                        confirmPassword,
                        timezone: getSystemTimezone(),
                        tos_accepted: true,
                        registered_from_site: true,
                        "g-recaptcha-response": recaptchaResponse,
                    };

                    if (gender === otherGenderId && otherGender !== "") {
                        params.gender = otherGender;
                    }

                    if (extraParams) {
                        params = { ...params, ...extraParams };
                    }

                    await sendButtonClick("sign_up_submit");

                    const result = await dispatch(signUpUser(params));
                    const { error } = result;

                    if (error) {
                        const errorMessage = error.response ? error.response.data : "Error creating account.";

                        actions.setStatus(error.response.data);

                        if (typeof errorMessage === "string") {
                            toast.error(errorMessage);
                        } else {
                            toast.error(
                                "Something went wrong signing you up. Please address the errors above and try again."
                            );
                        }
                    } else {
                        if (promoCode) {
                            window.location.assign(
                                generatePath(`${HOME_ROUTE}?promotion_code=:promoCode`, {
                                    promoCode,
                                })
                            );
                        } else {
                            window.location.assign(HOME_ROUTE);
                        }
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        }
    };

    return (
        <RecaptchaProvider>
            <Page title="Signup">
                <div className="ts-signup">
                    {branding && !isFetchingDefaultGroup && defaultGroupReceivedAt && (
                        <div className="container mb-5">
                            <header className="ts-signup__header">
                                <div className="ts-signup__header__logo d-flex align-items-center">
                                    <BrandedLogo type="color_logo" className="ts-branded-logo-signup" />
                                </div>
                            </header>
                            <div className="ts-signup-container row no-gutters">
                                <div className="ts-signup-form col-12 col-lg-8 px-0 px-md-3 px-md-5 py-3 py-md-5">
                                    {initialFormValues && (
                                        <Formik
                                            initialValues={{
                                                first_name: initialFormValues.first_name.replace(/"/g, ""),
                                                last_name: initialFormValues.last_name.replace(/"/g, ""),
                                                birthday:
                                                    (initialFormValues.birthday &&
                                                        moment(initialFormValues.birthday).utc().format("M/D/YYYY")) ||
                                                    null,
                                                gender: initialFormValues.gender,
                                                otherGender: initialFormValues.otherGender,
                                                coupon_used: initialFormValues.coupon_used,
                                                email: initialFormValues[emailId],
                                                phone: initialFormValues.phone,
                                                password: initialFormValues.password,
                                                confirmPassword: initialFormValues.confirmPassword,
                                            }}
                                            enableReinitialize={true}
                                            onSubmit={handleFormSubmission}
                                            validationSchema={schema}
                                        >
                                            {({
                                                handleChange,
                                                handleSubmit,
                                                setStatus,
                                                setFieldValue,
                                                setFieldError,
                                                status,
                                                values,
                                                errors,
                                                touched,
                                            }) => (
                                                <div>
                                                    <div className="col-12 offset-lg-0 px-0 mb-2 mb-md-4">
                                                        <div className="ts-welcome-title text-main text-medium-bold text-xl">
                                                            {branding.is_organization
                                                                ? "Welcome!"
                                                                : `Welcome to ${productName}`}
                                                        </div>
                                                        <div className="ts-welcome-subtitle text-secondary text-normal text-md my-3">
                                                            {getSignupCopy("title", branding, productName)}
                                                        </div>
                                                        <div className="ts-welcome-paragraph text-main text-md mb-2">
                                                            {getSignupCopy("subtitle", branding, productName)}
                                                        </div>
                                                    </div>
                                                    <form className="col-12 px-0" onSubmit={handleSubmit}>
                                                        <FormRow bottomSpacing>
                                                            <TextInput
                                                                id="first_name form-field__first-name"
                                                                name="first_name"
                                                                label="First Name"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values.first_name}
                                                                error={errors.first_name}
                                                                touched={touched.first_name}
                                                                disabled={prefilledFields && prefilledFields.first_name}
                                                                autoComplete="given-name"
                                                                required
                                                            />
                                                            {/* This component is used throughout the form to display server side and custom field errors */}
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors.first_name &&
                                                                    status &&
                                                                    status.first_name
                                                                }
                                                                message={status && status.first_name}
                                                            />
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <TextInput
                                                                id="last_name form-field__last-name"
                                                                name="last_name"
                                                                label="Last Name"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values.last_name}
                                                                error={errors.last_name}
                                                                touched={touched.last_name}
                                                                disabled={prefilledFields && prefilledFields.last_name}
                                                                autoComplete="family-name"
                                                                required
                                                            />
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors.last_name &&
                                                                    status &&
                                                                    status.last_name
                                                                }
                                                                message={status && status.last_name}
                                                            />
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <DatePicker
                                                                allowKeyboard
                                                                disableFuture
                                                                autoOk
                                                                variant="dialog"
                                                                className="w-100"
                                                                id="birthday form-field__birthday"
                                                                name="birthday"
                                                                invalidDateMessage={INVALID_DATE.message}
                                                                format="MM/dd/yyyy"
                                                                label="Date of Birth"
                                                                placeholder="MM/DD/YYYY"
                                                                onFocus={handleFormFocus()}
                                                                onOpen={handleDatePickerOpen}
                                                                onChange={(date) =>
                                                                    handleDateChange("birthday", date, setFieldValue)
                                                                }
                                                                value={values.birthday}
                                                                initialFocusedDate={moment().subtract(
                                                                    DATE_OF_BIRTH_MINIMUM.value,
                                                                    "years"
                                                                )}
                                                                required
                                                            />
                                                            <FormError
                                                                show={errors && errors.birthday && touched.birthday}
                                                                message={errors.birthday}
                                                            />
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors.birthday &&
                                                                    status &&
                                                                    status.birthday
                                                                }
                                                                message={status && status.birthday}
                                                            />
                                                        </FormRow>
                                                        <GenderSelectionButtonGroup
                                                            values={values}
                                                            status={status}
                                                            errors={errors}
                                                            touched={touched}
                                                            setFieldValue={setFieldValue}
                                                            setStatus={setStatus}
                                                            handleGenderChange={handleGenderChange}
                                                            handleFormChange={handleFormChange}
                                                            disabled={prefilledFields && prefilledFields.gender}
                                                        />
                                                        {requireCode && (
                                                            <FormRow bottomSpacing>
                                                                <TextInput
                                                                    id={`${couponCodeId} form-field__code`}
                                                                    name={couponCodeId}
                                                                    label="Registration Code"
                                                                    onFocus={handleFormFocus()}
                                                                    onChange={handleFormChange(
                                                                        status,
                                                                        setStatus,
                                                                        setFieldValue,
                                                                        setFieldError
                                                                    )}
                                                                    value={values.coupon_used}
                                                                    error={errors.coupon_used}
                                                                    touched={touched.coupon_used}
                                                                    required={requireCode}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <div className="d-flex align-items-center">
                                                                                <ValidationAdornment
                                                                                    show={
                                                                                        values.coupon_used &&
                                                                                        values.coupon_used.length > 0
                                                                                    }
                                                                                    valid={signup.validCode}
                                                                                />
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip className="schedule-info">
                                                                                            <div className="text-left p-2">
                                                                                                Don’t have a
                                                                                                registration code?
                                                                                                Please contact your
                                                                                                provider or benefits
                                                                                                sponsor for assistance.
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <i className="help-icon far fa-question-circle" />
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        ),
                                                                    }}
                                                                />
                                                                <FormError
                                                                    show={
                                                                        errors &&
                                                                        !errors.coupon_used &&
                                                                        touched.coupon_used &&
                                                                        !signup.validCode
                                                                    }
                                                                    message={INVALID_CODE.message}
                                                                />
                                                                <FormError
                                                                    show={
                                                                        errors &&
                                                                        !errors.coupon_used &&
                                                                        status &&
                                                                        status.coupon_used
                                                                    }
                                                                    message={status && status.coupon_used}
                                                                />
                                                            </FormRow>
                                                        )}
                                                        <FormRow bottomSpacing>
                                                            <TextInput
                                                                id={`${emailId} form-field__email`}
                                                                name={emailId}
                                                                label="Email"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                disabled={prefilledFields && prefilledFields.email}
                                                                value={values[emailId]}
                                                                type="email"
                                                                error={errors[emailId]}
                                                                touched={touched[emailId]}
                                                                autoComplete={emailId}
                                                                required
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <ValidationAdornment
                                                                            show={
                                                                                !requireCode &&
                                                                                signup_preregistered_enabled &&
                                                                                values[emailId] &&
                                                                                values[emailId].length > 0
                                                                            }
                                                                            valid={signup.validEmail}
                                                                        />
                                                                    ),
                                                                }}
                                                            />
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors[emailId] &&
                                                                    touched[emailId] &&
                                                                    !requireCode &&
                                                                    signup_preregistered_enabled &&
                                                                    !signup.validEmail
                                                                }
                                                                message={INVALID_WELLVIEW_EMAIL.message}
                                                            />
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors[emailId] &&
                                                                    status &&
                                                                    status[emailId]
                                                                }
                                                                message={status && status[emailId]}
                                                            />
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <TextInput
                                                                id="phone form-field__phone"
                                                                name="phone"
                                                                label="Mobile Number"
                                                                type="tel"
                                                                onFocus={handleFormFocus()}
                                                                onChange={(event) =>
                                                                    handlePhoneNumberChange(event, setFieldValue)
                                                                }
                                                                value={values.phone}
                                                                error={errors.phone}
                                                                touched={touched.phone}
                                                                disabled={prefilledFields && prefilledFields.phone}
                                                                autoComplete="tel-local"
                                                                required
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment>
                                                                            <div className="phone-adornment pr-2">
                                                                                +1
                                                                            </div>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            <FormError
                                                                show={errors && !errors.phone && status && status.phone}
                                                                message={status && status.phone}
                                                            />
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <PasswordField
                                                                id="password form-field__password"
                                                                name="password"
                                                                label="Password"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values.password}
                                                                type="password"
                                                                error={errors.password}
                                                                touched={touched.password}
                                                                required
                                                            />
                                                            <FormError
                                                                show={
                                                                    errors &&
                                                                    !errors.password &&
                                                                    status &&
                                                                    status.password
                                                                }
                                                                message={status && status.password}
                                                            />
                                                        </FormRow>
                                                        <FormRow bottomSpacing>
                                                            <TextInput
                                                                id="confirmPassword form-field__confirmPassword"
                                                                name="confirtmPassword"
                                                                label="Re-Enter Password"
                                                                onFocus={handleFormFocus()}
                                                                onChange={handleFormChange(
                                                                    status,
                                                                    setStatus,
                                                                    setFieldValue
                                                                )}
                                                                value={values.confirmPassword}
                                                                type="password"
                                                                error={errors.confirmPassword}
                                                                touched={touched.confirmPassword}
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        </FormRow>
                                                        <FormRow
                                                            bottomSpacing
                                                            className="ts-signup-tos text-main text-xs"
                                                        >
                                                            By completing your registration you are agreeing to the{" "}
                                                            <a
                                                                href={termsLink}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-secondary text-underline"
                                                                data-button="button__signup-tos"
                                                            >
                                                                Terms and Conditions
                                                            </a>
                                                        </FormRow>
                                                        <FormRow bottomSpacing className="ts-signup-cta-formrow">
                                                            <Button
                                                                square
                                                                secondary
                                                                className="ts-signup-submit"
                                                                analyticsClass="button__submit-patient-registration"
                                                                type="submit"
                                                                disabled={signup.signupIsFetching}
                                                            >
                                                                Complete Registration
                                                            </Button>
                                                        </FormRow>
                                                    </form>
                                                </div>
                                            )}
                                        </Formik>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Page>
        </RecaptchaProvider>
    );
}
